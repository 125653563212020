import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Clients } from "../../../../services/reddyApi/Clients/Clients";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getStatisticsDashboard, getStatisticsFinance } from "../../statistics/statistics.saga";
import { updateClientDiscountFetch, updateClientDiscountSuccess } from "../clients.slice";
import { UpdateClientDiscountPayload } from "../payload/UpdateClientDiscount/UpdateClientDiscountPayload";

function* updateClientDiscountWorker(action: PayloadAction<UpdateClientDiscountPayload>) {
    const { vendorId, clientId, discountId, filter } = action.payload;
    const dataParams = {
        vendorId,
        clientId,
        discountId,
    }

    try {
        const { data } = yield call(Clients.updateClientDiscount, dataParams);

        yield put(updateClientDiscountSuccess({ clientId, discountId }));

        yield getStatisticsDashboard({ vendorId, filter });

        yield getStatisticsFinance({ vendorId, filter });

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => updateClientDiscountFetch(action.payload);
            
            yield handleServerError(error, actionCreator);
        }
    }
}

export default updateClientDiscountWorker;