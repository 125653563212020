import React from "react";
import { shallowEqual } from "react-redux";
import ButtonInfo from "../../components/general/buttons/ButtonInfo/ButtonInfo";
import RectProgress from "../../components/dataDisplay/progress/Rect/RectProgress";
import Avatar from "../../components/dataDisplay/user/Avatar/Avatar";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { setFonVisiable } from "../../store/state/all/all.slice";
import { setActiveDateForm } from "../../store/state/vendors/vendors.slice";
import Button from "../../components/general/buttons/Button/Button";
import { DateApi } from "../../services/Date/Date";
import { VendorsColumn } from "./models/VendorsColumn";
import withMatch from "../../components/other/hoc/withMatch";
import IconFont from "../../components/general/icons/IconFont/IconFont";
import VendorsModalContainer from "./VendorsForms/VendorsModal/VendorsModal.container";
import VendorsMessage from "./Vendors.message";

const VendorsConatiner = () => {
    const dispatch = useAppDispatch();
    const vendors = useAppSelector(state => state.vendors.vendorsArray, shallowEqual);

    const toggleFon = (status: boolean) => {
        dispatch(setFonVisiable({ visiable: status }));
    }

    const clickStatusButtonHandler = (vendorId: number) => () => {
        dispatch(setActiveDateForm({ vendorId, active: true }));
        toggleFon(true);
    }

    const data: VendorsColumn[] = vendors.map(item => ({
        avatar:
            <Avatar
                name={item.name}
                width={50}
                height={50}
                background="#A6AEC5"
                backgroundName="#A6AEC5"
                fontSize={18}
                lineHeight={21}
                maxHeightImg={"100%"}
                maxWidthImg={"100%"}
            />,
        name: item.name,
        ownerName: item.owner.name,
        status:
            <Button
                textDecoration="underline"
                justifyContent="flex-start"
                handleClick={clickStatusButtonHandler(item.id)}
                title={item.active ? "Активний" : "Чекає підтвердження"}
                colorText={item.active ? "#4775FE" : "#FA5D54"}
            />,
        confirmedTo: item.confirmedTo ? DateApi.parseDateStringPrint(item.confirmedTo) : "-",
        studentsCount: item.students.total,
        locationsCount: item.locations.length,
        progress: <RectProgress progress={Math.floor(item.students.fullness)} />,
        phone: <ButtonInfo icon={<IconFont color="#A6AEC5" iconClass="icon-tel" fontSize={13} />} info={item.owner.phone} />,
        email: <ButtonInfo icon={<IconFont color="#A6AEC5" iconClass="icon-email" fontSize={13} />} info={item.owner.email} />
    }));

    const modals = vendors.map(item => (
        <VendorsModalContainer
            confirmedFrom={item.confirmedFrom || ""}
            confirmedTo={item.confirmedTo || ""}
            vendorId={item.id}
            active={item.activeDateForm}
        />
    ));

    return (
        <VendorsMessage
            data={data}
            modals={modals}
        />
    );
}

export default React.memo(withMatch(VendorsConatiner, [1]));