import React, { ChangeEvent, FC } from "react";
import Field from "../../../../../../components/dataEntry/inputs/Field/Field";
import { DateApi } from "../../../../../../services/Date/Date";
import { DURATION_TYPE_MOUNTH } from "../../../../../../utl/constants/duration";
import { Lessons } from "../../../../models/GroupsValue";

interface Props {
    startDate: string;
    isSelectedService: boolean;
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    handleBlur: {
        (e: React.FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    }
    serviceLessons: string[] | undefined;
    lessons: Lessons[]
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    error: string | undefined;
    touched: boolean | undefined;
}

const StartDateField: FC<Props> = ({
    startDate,
    isSelectedService,
    handleBlur,
    handleChange,
    serviceLessons,
    lessons,
    setFieldValue,
    error,
    touched,
}) => {
    const countServiceLessons = serviceLessons?.length || 0;
    const countLessons = lessons.length;

    const changeSartDateHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        let daysWeek = [DateApi.getDayOfDate(value)];

        handleChange("startDate")(event);
        if (isSelectedService) {
            const schedule = DateApi.calculateSchedule(
                value,
                daysWeek,
                DURATION_TYPE_MOUNTH,
                countLessons === 0 ? countServiceLessons : countLessons,
            );

            setFieldValue("endDate", schedule[schedule.length - 1]);
            const scheduleLessons = lessons.map((lesson, index) => ({
                ...lesson,
                date: schedule[index],
            }));

            setFieldValue("lessons", scheduleLessons);
            setFieldValue("lessonWeekDays", daysWeek.join(","));
        }
    }

    const blurHandler = () => {
        handleBlur("startDate");
    }

    return (
        <Field
            label="Дата старту"
            type="date"
            maxWidth={150}
            marginBottom={0}
            labelLeftShift={0}
            value={startDate}
            handleChange={changeSartDateHandler}
            handleBlur={blurHandler}
            error={error}
            touched={touched}
        />
    );
}

export default StartDateField;