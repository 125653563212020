import { FormikProps, FormikProvider } from "formik";
import React, { FC } from "react";
import ModalForm from "../../../../../../components/dataEntry/forms/ModalForm/ModalForm";
import Field from "../../../../../../components/dataEntry/inputs/Field/Field";
import Button from "../../../../../../components/general/buttons/Button/Button";
import IconFont from "../../../../../../components/general/icons/IconFont/IconFont";
import Title from "../../../../../../components/general/typography/Title/Title";
import Row from "../../../../../../components/layout/Row/Row";
import Wrp from "../../../../../../components/layout/Wrp/Wrp";
import { CreateClientFormValues } from "../../../../models/CreateClientFormValues";
import DiscountsDropdown from "./components/DiscountsDropdown/DiscountsDropdown";
import RelationshipStatusesDropdown from "./components/RelationshipStatusesDropdown/RelationshipStatusesDropdown";
import StudentsList from "./components/StudentsList/StudentsList";

interface Props {
    formik: FormikProps<CreateClientFormValues>;
    visiable: boolean;
    closeModalHandler: () => void;
}

const CreateClientForm: FC<Props> = ({ visiable, closeModalHandler, formik }) => {
    const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched } = formik;

    const submitButtonHandler = () => {
        handleSubmit();
    }

    const clickCancelButtonHandler = () => {
        closeModalHandler();
    }

    const relationshipStatus = Number(values.relationshipStatus);

    const discountId = values.discountId ? Number(values.discountId) : null;

    const students = values.students;

    return (
        <FormikProvider value={formik}>
            <ModalForm
                title="Додати клієнта"
                visiable={visiable}
                maxWidth={480}
                handleClick={closeModalHandler}
            >
                <Wrp flexDirection="column" alignItems="center">
                    <Field
                        label="Телефон"
                        labelLeftShift={0}
                        maxWidth={440}
                        marginRight={0}
                        marginBottom={30}
                        handleChange={handleChange("phone")}
                        handleBlur={handleBlur("phone")}
                        value={values.phone}
                        error={errors.phone}
                        touched={touched.phone}
                        mask={"+380YYXXXXXXX"}
                    />

                    <Field
                        label="Email"
                        type="email"
                        labelLeftShift={0}
                        maxWidth={440}
                        marginRight={0}
                        marginBottom={30}
                        handleChange={handleChange("email")}
                        handleBlur={handleBlur("email")}
                        value={values.email}
                        error={errors.email}
                        touched={touched.email}
                    />

                    <Row maxWidth={440} marginBottom={30}>
                        <Field
                            label="Прізвище *"
                            labelLeftShift={0}
                            maxWidth={210}
                            marginRight={20}
                            marginBottom={0}
                            handleChange={handleChange("surname")}
                            handleBlur={handleBlur("surname")}
                            value={values.surname}
                            error={errors.surname}
                            touched={touched.surname}
                        />

                        <Field
                            label="Ім’я *"
                            labelLeftShift={0}
                            maxWidth={210}
                            marginRight={0}
                            marginBottom={0}
                            handleChange={handleChange("name")}
                            handleBlur={handleBlur("name")}
                            value={values.name}
                            error={errors.name}
                            touched={touched.name}
                        />
                    </Row>

                    <Row maxWidth={440} marginBottom={30}>
                        <RelationshipStatusesDropdown
                            relationshipStatus={relationshipStatus}
                            error={errors.relationshipStatus}
                            touched={touched.relationshipStatus}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                        />

                        <DiscountsDropdown
                            discountId={discountId}
                            error={errors.discountId}
                            touched={touched.discountId}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                        />
                    </Row>

                    <Title
                        level={2}
                        title="Діти"
                        marginBottom={20}
                        textAlign="start"
                        width="100%"
                    />

                    <StudentsList
                        error={errors.students}
                        touched={touched.students}
                        students={students}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                    />

                    <Row maxWidth={340}>
                        <Button
                            disabled={!isValid}
                            maxWidth={160}
                            handleClick={submitButtonHandler}
                            icon={<IconFont iconClass="icon-plus" fontSize={15} color="#FFF" />}
                            backgroundColor="#4775FE"
                            title="Створити"
                            marginBottom={0}
                            marginRight={15}
                        />

                        <Button
                            maxWidth={160}
                            handleClick={clickCancelButtonHandler}
                            backgroundColor="#A6AEC5"
                            title="Скасувати"
                            marginBottom={0}
                            marginRight={0}
                        />
                    </Row>
                </Wrp>
            </ModalForm>
        </FormikProvider>
    );
}

export default CreateClientForm;