import { PayloadAction } from "@reduxjs/toolkit";
import { UpsellState } from "../models/UpsellsState";
import { SetUpsellsPayload } from "../payload/SetUpsellsPayload";

const setUpsells = (state: UpsellState, action: PayloadAction<SetUpsellsPayload>) => {
    const { upsells } = action.payload;

    state.upsells = upsells;
}

export default setUpsells;