import { useEffect } from "react";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { getClientMoneyTransactionsByVendorFetch } from "../../../../store/state/clients/clients.slice";

interface Payload {
    getAllItems: boolean;
    from: number;
    to: number;
    vendorId: number;
    clientId: number;
}

const useGetClientMoneyTransactionsByVendor = (payload: Payload) => {
    const { getAllItems, from, to, vendorId, clientId } = payload;

    const dispatch = useAppDispatch();

    useEffect(() => {
        let filter = undefined;

        if (!getAllItems) {
            filter = {
                range: {
                    from,
                    to
                }
            }
        }

        dispatch(getClientMoneyTransactionsByVendorFetch({ vendorId, filter, clientId }));
    }, [clientId, vendorId]);
}

export default useGetClientMoneyTransactionsByVendor;