import { FormikProps, FormikProvider } from "formik";
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import InfoView from "../../../../components/dataDisplay/InfoView/InfoView";
import Field from "../../../../components/dataEntry/inputs/Field/Field";
import Switch from "../../../../components/dataEntry/inputs/Switch/Switch";
import Button from "../../../../components/general/buttons/Button/Button";
import Row from "../../../../components/layout/Row/Row";
import Wrp from "../../../../components/layout/Wrp/Wrp";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import { GroupsValue } from "../../models/GroupsValue";
import { DateApi } from "../../../../services/Date/Date";
import ServicesDropdown from "./components/ServicesDropdown/ServicesDropdown";
import LocationsDropdown from "./components/LocationsDropdown/LocationsDropdown";
import PaymentParts from "./components/PaymentParts/PaymentParts";
import NotificationDropdown from "./components/NotificationDropdown/NotificationDropdown";
import UpsellsTagInput from "../../../Services/Basics/Basic/components/UpsellsTagInput/UpsellsTagInput";
import DaysWeekTagInput from "./components/DaysWeekTagInput/DaysWeekTagInput";
import StartDateField from "./components/StartDateField/StartDateField";
import TimeField from "./components/TimeField/TimeField";
import Lessons from "./components/Lessons/Lessons";
import useServiceLoading from "./hook/useServiceLoading";
import DurationInHoursField from "./components/DurationInHoursField/DurationInHoursField";
import TeachersTagInput from "./components/TeachersTagInput/TeachersTagInput";
import { SUBMIT_ACTIONS } from "../../../../utl/constants/submitActions";

interface Props {
    formik: FormikProps<GroupsValue>;
    active?: boolean;
    isNewItemForm?: boolean;
}

const GroupSettings: FC<Props> = ({ isNewItemForm, formik, active }) => {
    const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched } = formik;

    const selectedService = useAppSelector(state => state.groups.selectedService, shallowEqual);

    const updated = Boolean(isNewItemForm);

    const serviceLoading = useServiceLoading({ updated, selectedService, setFieldValue });

    const sendButtonSubmitHandler = () => {
        setFieldValue("submitAction", SUBMIT_ACTIONS.SAVE);
        handleSubmit();
    }

    const archiveButtonSubmitHandler = () => {
        setFieldValue("submitAction", SUBMIT_ACTIONS.DELETE);
        handleSubmit();
    }

    const paymentParts = values.paymentParts;

    const upsellsIds = values.upsellsIds

    const startDate = values.startDate;

    const lessons = values.lessons;

    const countLessons = values.lessons.length;
    const serviceLessons = selectedService?.lessons;
    const lessonWeekDays = values.lessonWeekDays;

    const days = Number(values.notificationBeforeLesson.days);

    const serviceId = Number(values.serviceId);

    const locationId = Number(values.locationId);

    const employeesIds = values.employeesIds;

    const isSelectedService = selectedService !== null && selectedService !== undefined;

    return (
        <FormikProvider value={formik}>
            <Wrp maxWidth={1300} flexDirection="column">
                <Row marginBottom={20} alignItems="flex-start">
                    <ServicesDropdown
                        fieldName="serviceId"
                        serviceId={serviceId}
                        error={errors.serviceId}
                        touched={touched.serviceId}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        visiableDateServices={true}
                    />

                    <>
                        {countLessons > 0 &&
                            <InfoView
                                title="К-ть занять"
                                marginRight={20}
                                value={`${countLessons}`}
                            />
                        }
                    </>

                    <Switch
                        marginRight={20}
                        value={values.confirmed}
                        title="Статус"
                        handleChange={handleChange("confirmed")}
                    />

                    <Switch
                        marginRight={20}
                        value={values.online}
                        title="Онлайн"
                        handleChange={handleChange("online")}
                    />
                </Row>
                <Row marginBottom={30}>
                    <Field
                        label="Назвва групи"
                        labelLeftShift={0}
                        maxWidth={420}
                        marginRight={20}
                        marginBottom={0}
                        handleChange={handleChange("name")}
                        handleBlur={handleBlur("name")}
                        value={values.name}
                        error={errors.name}
                        touched={touched.name}
                    />

                    <LocationsDropdown
                        locationId={locationId}
                        error={errors.locationId}
                        touched={touched.locationId}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                    />

                    <Field
                        type="number"
                        label="К-ть учнів min"
                        labelLeftShift={0}
                        maxWidth={100}
                        marginRight={20}
                        marginBottom={0}
                        handleChange={handleChange("studentsMin")}
                        handleBlur={handleBlur("studentsMin")}
                        value={values.studentsMin}
                        error={errors.studentsMin}
                        touched={touched.studentsMin}
                    />

                    <Field
                        type="number"
                        label="К-ть учнів max"
                        labelLeftShift={0}
                        maxWidth={100}
                        marginRight={20}
                        marginBottom={0}
                        handleChange={handleChange("studentsMax")}
                        handleBlur={handleBlur("studentsMax")}
                        value={values.studentsMax}
                        error={errors.studentsMax}
                        touched={touched.studentsMax}
                    />
                </Row>
                <Row alignItems="flex-start" justifyContent="flex-start" marginBottom={20}>
                    <Field
                        label="Вартість послуги"
                        type="number"
                        maxWidth={180}
                        marginBottom={0}
                        labelLeftShift={0}
                        paddingLeft={35}
                        value={values.price}
                        handleChange={handleChange("price")}
                        handleBlur={handleBlur("price")}
                        error={errors.price}
                        touched={touched.price}
                        topPositionIcon={29.2}
                        icon={<span style={{ fontSize: 14, fontWeight: "500" }}>₴</span>}
                    />

                    <PaymentParts
                        paymentParts={paymentParts}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        error={errors.paymentParts}
                        touched={touched.paymentParts}
                        setFieldValue={setFieldValue}
                    />

                    <Switch
                        marginRight={20}
                        value={values.notificable}
                        title="Сповіщення"
                        handleChange={handleChange("notificable")}
                    />

                    <Wrp marginTop={20} maxWidth={240}>
                        <NotificationDropdown
                            days={days}
                            setFieldValue={setFieldValue}
                        />
                    </Wrp>

                    <Field
                        label="Година"
                        type="time"
                        maxWidth={110}
                        marginBottom={0}
                        labelLeftShift={0}
                        value={values.notificationBeforeLesson.time}
                        handleChange={handleChange("notificationBeforeLesson.time")}
                        handleBlur={handleBlur("notificationBeforeLesson.time")}
                        error={errors.notificationBeforeLesson?.time}
                        touched={touched.notificationBeforeLesson?.time}
                    />
                </Row>
                <>{isSelectedService && countLessons > 0 && <>
                    <Row marginBottom={20}>
                        <StartDateField
                            startDate={startDate}
                            serviceLessons={serviceLessons}
                            lessons={lessons}
                            isSelectedService={isSelectedService}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            error={errors.startDate}
                            touched={touched.startDate}
                        />

                        <DaysWeekTagInput
                            lessonWeekDays={lessonWeekDays}
                            startDate={startDate}
                            error={errors.lessonWeekDays}
                            touched={touched.lessonWeekDays}
                            handleBlur={handleBlur("lessonWeekDays")}
                            setFieldValue={setFieldValue}
                            lessons={lessons}
                            isSelectedService={isSelectedService}
                        />

                        <UpsellsTagInput
                            upsellsIdsValues={upsellsIds}
                            error={errors.upsellsIds}
                            touched={touched.upsellsIds}
                            handleBlur={handleBlur("upsellsIds")}
                            setFieldValue={setFieldValue}
                        />
                    </Row>
                    <Row marginBottom={30} alignItems="flex-start">
                        <>{values.endDate !== "" &&
                            <InfoView
                                marginRight={20}
                                title="Дата закінчення"
                                value={DateApi.parseDateStringPrint(values.endDate)}
                            />
                        }</>

                        <TeachersTagInput 
                            employeesIdsValues={employeesIds}
                            error={errors.employeesIds}
                            touched={touched.employeesIds}
                            handleBlur={handleBlur("employeesIds")}
                            setFieldValue={setFieldValue}
                            marginRight={30}
                        />

                        <TimeField
                            lessons={lessons}
                            setFieldValue={setFieldValue}
                        />

                        <DurationInHoursField
                            lessons={lessons}
                            setFieldValue={setFieldValue}
                        />
                    </Row>
                    <Row marginBottom={30}>
                        <Lessons
                            lessons={lessons}
                            error={errors.lessons}
                            touched={touched.lessons}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />
                    </Row>
                </>}</>
                <Row>
                    <Button
                        disabled={!isValid}
                        backgroundColor="#4775FE"
                        title="Зберегти"
                        handleClick={sendButtonSubmitHandler}
                        marginRight={20}
                    />
                    <>
                        {!isNewItemForm &&
                            <Button
                                backgroundColor="#A6AEC5"
                                title={active ? "Архівувати" : "Активувати"}
                                handleClick={archiveButtonSubmitHandler}
                            />
                        }
                    </>
                </Row>
            </Wrp>
        </FormikProvider>
    );
}

export default GroupSettings;