import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Clients } from "../../../../services/reddyApi/Clients/Clients";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getClientsListForVendorFetch, getClientsListForVendorSuccess, setPageDataClients } from "../clients.slice";
import { GetClientsListForVendorPayload } from "../payload/GetClientsListForVendor/GetClientsListForVendorPayload";

function* getClientsListForVendorWorker(action: PayloadAction<GetClientsListForVendorPayload>) {
    const { vendorId, filter } = action.payload;
    const dataParams = {
        vendorId,
        filter
    }

    try {
        const { data } = yield call(Clients.getListForVendor, dataParams);
        const { totalCount, items } = data;

        yield put(getClientsListForVendorSuccess({ totalCount, items, filter }));
        yield put(setPageDataClients({ clinets: [...items] }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getClientsListForVendorFetch(action.payload);
            
            yield handleServerError(error, actionCreator);
        }
    }
}

export default getClientsListForVendorWorker;