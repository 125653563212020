import * as Yup from 'yup';
import { DateApi } from '../../../../services/Date/Date';
import { TODAY } from '../../../../utl/constants/date';

export default Yup.object().shape({
    sum: Yup.number()
        .min(1, "invalid value")
        .required("Required"),
    date: Yup.date()
        .transform(DateApi.parseDateString)
        .min(TODAY)
        .required("Заповніть це поле"),
    fromPurseId: Yup.number().required("Required"),
    toPurseId: Yup.number().required("Required"),
    comment: Yup.string(),
});