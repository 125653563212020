import { useFormik } from "formik";
import React, { FC, useEffect } from "react";
import Location from "./Location";
import validationSchema from "./Location.scheme";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { createLocationFetch, setNewItemId, uploadLocationImagesFetch } from "../../../../store/state/locations/locations.slice";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import { shallowEqual } from "react-redux";
import { LocationValues } from "../models/LocationValues";
import json from "../../../../assets/json/lang_ua.json";
import { TypeList } from "../models/TypeList";
import withMatch from "../../../../components/other/hoc/withMatch";
import useRedirectToNewItemPage from "../../../../utl/hooks/useRedirectToNewItemPage";
import useSubmitAction from "../../../../utl/hooks/useSubmitAction";
import { SUBMIT_ACTIONS } from "../../../../utl/constants/submitActions";

const NewLocationContainer: FC = () => {
    const dispatch = useAppDispatch();
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const searchList = (Array.isArray(json) && json.map((item: TypeList) => item.city_name)) || [];
    const newItemId = useAppSelector(state => state.locations.newItemId, shallowEqual);

    const payload = {
        newItemId,
        setNewItemId: (id: number | null) => {
            dispatch(setNewItemId({ id }));
        }
    }

    useRedirectToNewItemPage(payload);

    const initialValues: LocationValues = {
        name: "",
        address: "",
        city: "",
        desc: "",
        coord: "",
        confirmed: false,
        active: true,
        phone: "",
        submitAction: SUBMIT_ACTIONS.SAVE,
    }

    const submitActionHandler = useSubmitAction();

    const formik = useFormik<LocationValues>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const { name, address, city, coord, desc, confirmed, phone, submitAction, active } = values;
            const coordinates = coord.split(", ").map(item => Number(item));

            submitActionHandler(
                submitAction,
                () => createLocationFetch({
                    vendorId,
                    location: {
                        name,
                        address,
                        city,
                        description: desc,
                        coordinates: [coordinates[0], coordinates[1]],
                        confirmed,
                        phone,
                        active,
                    }
                })
            );
        },
    });

    return (
        <>
            <Location
                formik={formik}
                isNewItemForm={true}
                searchListCity={searchList}
            />
        </>
    );
}

export default React.memo(withMatch(NewLocationContainer, [2, 3, 4]));