import { PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { Clients } from "../../../../services/reddyApi/Clients/Clients";
import { GetListForVendorResponse } from "../../../../services/reddyApi/Clients/Clients.response";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { setPageDataClients } from "../../clients/clients.slice";
import { GetClientsListForVendorPayload } from "../../clients/payload/GetClientsListForVendor/GetClientsListForVendorPayload";
import { getClientsListForVendorFetch, getClientsListForVendorSuccess } from "../dashboard.slice";

function* getClientsListForVendorWorker(action: PayloadAction<GetClientsListForVendorPayload>) {
    const { vendorId, filter } = action.payload;
    const dataParams = {
        vendorId,
        filter
    }

    try {
        const { data }: AxiosResponse<GetListForVendorResponse> = yield call(Clients.getListForVendor, dataParams);
        const { totalCount, items } = data;
        
        yield put(getClientsListForVendorSuccess({ totalCount, items, filter }));
        yield put(setPageDataClients({ clinets: [...items] }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getClientsListForVendorFetch(action.payload);
            
            yield handleServerError(error, actionCreator);
        }
    }
}

export default getClientsListForVendorWorker;