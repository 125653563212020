import React, { FC, MouseEvent } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import { getLocationsDataFetch, setPageLocations } from "../../../store/state/locations/locations.slice";
import culcPageTable from "../../../utl/helpers/culcPageTable";
import usePagination from "../../../utl/hooks/usePagination";
import { Filter } from "../../../utl/models/Filters";
import Locations from "./Locations";
import { LocationsColumns } from "./models/LocationsColumns";

interface Props {
    data: LocationsColumns[];
    handleClickRow: (item: any, event: MouseEvent<HTMLTableRowElement>) => void;
    vendorId: number;
}

const LocationsPagination: FC<Props> = ({ data, handleClickRow, vendorId }) => {
    const dispatch = useAppDispatch();

    const page = useAppSelector(state => state.locations.page, shallowEqual);
    const totalCount = useAppSelector(state => state.locations.totalCount, shallowEqual);
    const pageAll = useAppSelector(state => state.locations.pageAll, shallowEqual);
    const pageSize = useAppSelector(state => state.locations.pageSize, shallowEqual);
    const getAllItems = useAppSelector(state => state.locations.getAllItems, shallowEqual);

    const setPage = (filter: Filter | undefined, pageNumber: number) => {
        dispatch(getLocationsDataFetch({ vendorId, filter }));
        dispatch(setPageLocations({ page: pageNumber }));
    };

    const pagination = usePagination({
        getAllItems,
        pageAll,
        page,
        totalCount,
        pageSize,
        setPage,
        active: true,
    });

    const pagesRange = pagination.pagesRange;

    const pageTable = culcPageTable<LocationsColumns>(data, pagesRange.from, pagesRange.to);

    return (
        <Locations
            handleClickRow={handleClickRow}
            page={page}
            data={pageTable}
            pages={pagination.pages}
            handleRightButtonPagination={pagination.handleRightButtonPagination}
            handleLeftButtonPagination={pagination.handleLeftButtonPagination}
        />
    );
}

export default React.memo(LocationsPagination);