import { PayloadAction } from "@reduxjs/toolkit";
import { GroupsState } from "../models/GroupsState";
import { SetGroupFilterPayload } from "../payload/SetGroupFilterPayload";

const setGroupsFilter = (state: GroupsState, action: PayloadAction<SetGroupFilterPayload>) => {
    const { categoryId, typeId, confirmed, active } = action.payload;

    state.filter.categoryId = categoryId;
    state.filter.typeId = typeId;
    state.filter.confirmed = confirmed;
    state.filter.active = active;
}

export default setGroupsFilter;