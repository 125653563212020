import React, { FC } from "react";
import ParametersBlock from "../../../../components/dataDisplay/ParametersBlock/ParametersBlock";
import ParameterDisplayer from "../../../../components/layout/ParameterDisplayer/ParameterDisplayer";
import { Statistics } from "../../../../store/state/clients/models/Statistics";

interface Props {
    period: string;
    statistics: Statistics | null;
}

const ParametersClientsBlock: FC<Props> = ({ period, statistics }) => {
    const title = "Показник росту";
    let avgNumberOfPurchasesPercent = calcPersentParamsDashboard(
        statistics?.actual.avgNumberOfPurchases, statistics?.previous.avgNumberOfPurchases
    );
    let avgClientCheckPercent = calcPersentParamsDashboard(statistics?.actual.avgClientCheck, statistics?.previous.avgClientCheck);
    let numberOfNewClientsPercent = calcPersentParamsDashboard(
        statistics?.actual.numberOfNewClients, statistics?.previous.numberOfNewClients
    );

    return (
        <ParametersBlock title={title} briefInfo={period}>
            <>
                {statistics && <ParameterDisplayer
                    title={"Середня к-ть покупок клієнта"}
                    param={`${statistics?.actual.avgNumberOfPurchases}`}
                    paramStatus={avgNumberOfPurchasesPercent && avgNumberOfPurchasesPercent.persent + "%"}
                    mode={avgNumberOfPurchasesPercent && avgNumberOfPurchasesPercent.mode}
                    marginBottom={30}
                />}
                {statistics && <ParameterDisplayer
                    title={"Нові клієнти"}
                    param={`${statistics?.actual.numberOfNewClients}`}
                    paramStatus={numberOfNewClientsPercent && numberOfNewClientsPercent.persent + "%"}
                    mode={numberOfNewClientsPercent && numberOfNewClientsPercent.mode}
                    marginBottom={30}
                />}
                {statistics && <ParameterDisplayer
                    title={"Середній чек клієнта"}
                    param={`₴ ${statistics?.actual.avgClientCheck}`}
                    paramStatus={avgClientCheckPercent && avgClientCheckPercent.persent + "%"}
                    mode={avgClientCheckPercent && avgClientCheckPercent.mode}
                />}
            </>
        </ParametersBlock>
    )
}

interface ReturnParamsDashboard {
    persent: string;
    mode: "success" | "failure"
}

const calcPersentParamsDashboard = (a: number | undefined, b: number | undefined): ReturnParamsDashboard | undefined => {
    if (!a || !b) {
        return undefined;
    }

    if (a > b) {
        const A = a;
        const B = b;
        const persent = calcuPercentageNumber(A, B)
        return {
            persent: `+${persent}`,
            mode: "success"
        };
    } else {
        const A = b;
        const B = a;

        const persent = calcuPercentageNumber(A, B)
        return {
            persent: `-${persent}`,
            mode: "failure"
        };
    }
}

const calcuPercentageNumber = (a: number, b: number): string => {
    return ((a - b) / a * 100).toFixed(1);
}

export default ParametersClientsBlock;