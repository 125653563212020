import { takeLatest } from "redux-saga/effects";
import changeLocationActivityWorker from "./changeLocationActivityWorker";
import createLocationWorker from "./createLocationWorker";
import deleteLocationWorker from "./deleteLocationWorker";
import getLocationsWorker from "./getLocationsWorker";
import getLocationWorker from "./getLocationWorker";
import updateLocationWorker from "./updateLocationWorker";
import uploadLocationImagesWorker from "./uploadLocationImagesWorker";

export function* locationsWatcher() {
    yield takeLatest("locations/getLocationsDataFetch", getLocationsWorker);
    yield takeLatest("locations/createLocationFetch", createLocationWorker);
    yield takeLatest("locations/updateLocationFetch", updateLocationWorker);
    yield takeLatest("locations/deleteLocationFetch", deleteLocationWorker);
    yield takeLatest("locations/getLocationFetch", getLocationWorker);
    yield takeLatest("locations/changeLocationActivityFetch", changeLocationActivityWorker);
    yield takeLatest("locations/uploadLocationImagesFetch", uploadLocationImagesWorker);
}