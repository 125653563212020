import React, { FC } from "react";
import styles from "./PriceCategoryList.module.css";

interface Props {
    name: string;
    price: string;
}

const PriceCategoryItem: FC<Props> = ({name, price}) => {
    return (
        <li className={styles.priceItem}>
            <span>
                {name}
            </span>
            <span className={styles.price}>
                {price} ₴
            </span>
        </li>
    );
}

export default PriceCategoryItem;