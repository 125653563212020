import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Services } from "../../../../../services/reddyApi/Services/Services";
import handleServerError from "../../../all/saga/handleServerError";
import setServerErrorDefault from "../../../all/saga/setServerErrorDefault";
import getNotifications from "../../../notifications/saga/getNotifications";
import { CreateServicePayload } from "../payload/CreateService/CreateServicePayload";
import { createServicesFetch, createServicesSuccess } from "../service.slice";
import getShortServices from "./getShortServices";

function* createServiceWorker(action: PayloadAction<CreateServicePayload>) {
    const {vendorId, service} = action.payload;
   
    try {
        const {status, data} = yield call(Services.create, {vendorId, service});
        const {serviceId} = data;
        
        if(status === 200) {
            yield put(createServicesSuccess({service: {...service, id: serviceId}}));

            yield getShortServices({ vendorId });

            yield getNotifications(vendorId);

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => createServicesFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default createServiceWorker;