import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Money } from "../../../../services/reddyApi/Money/Money";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { GetClientMoneyTransactionsByVendorPayload } from "../../finance/payload/GetClientMoneyTransactionsByVendor/GetClientMoneyTransactionsByVendorPayload";
import { getClientMoneyTransactionsByVendorFetch, getClientMoneyTransactionsByVendorSuccess } from "../clients.slice";

function* getClientMoneyTransactionsByVendorWorker(action: PayloadAction<GetClientMoneyTransactionsByVendorPayload>) {
    const { vendorId, clientId, filter } = action.payload;
    const dataParams = {
        vendorId,
        clientId,
        filter,
    }

    try {
        const { data } = yield call(Money.getClientMoneyTransactionsByVendor, dataParams);
        const { items, totalCount } = data;

        yield put(getClientMoneyTransactionsByVendorSuccess({ items, totalCount, filter, clientId }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getClientMoneyTransactionsByVendorFetch(action.payload);
            
            yield handleServerError(error, actionCreator);
        }
    }
}

export default getClientMoneyTransactionsByVendorWorker;