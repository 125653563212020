import { PayloadAction } from "@reduxjs/toolkit";
import updateItem from "../../../../../helpers/updateItem";
import { ServiceState } from "../../models/ServiceState";
import { UpdateServiceSuccessPayload } from "../../payload/UpdateService/UpdateServiceSuccessPayload";

const updateServicesSuccess = (state: ServiceState, action: PayloadAction<UpdateServiceSuccessPayload>) => {
    const service = action.payload;

    const services = state.basics;
    updateItem(services, service);

    const pageServicesData = state.pageData;
    const pageServicesDataIndex = pageServicesData.findIndex(pageData => pageData.id === service.id);
    if (pageServicesDataIndex > -1) {
        pageServicesData[pageServicesDataIndex] = { ...pageServicesData[pageServicesDataIndex], ...service };
    }

    state.message = "Послугу оновлено"
    state.messageVisiable = true;
    state.isLoading = false;
    state.isError = null;
}

export default updateServicesSuccess;