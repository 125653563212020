import { PayloadAction } from "@reduxjs/toolkit";
import { GroupsState } from "../models/GroupsState";
import { SetGroupsPayload } from "../payload/SetGroupsPayload";

const setGroups = (state: GroupsState, action: PayloadAction<SetGroupsPayload>) => {
    const { groups } = action.payload;

    state.groups = groups;
}

export default setGroups;