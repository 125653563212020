import { Formik, FormikProps, FormikProvider } from "formik";
import React, { FC } from "react";
import ListFields from "../../../components/dataEntry/inputs/ListFields/ListFields";
import Wrp from "../../../components/layout/Wrp/Wrp";
import { MoneyValues } from "./models/MoneyValues";
import Button from "../../../components/general/buttons/Button/Button";
import Row from "../../../components/layout/Row/Row";

interface Props {
    formik: FormikProps<MoneyValues>;
}

const Money: FC<Props> = ({ formik }) => {
    const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit } = formik

    const sendButtonSubmitHandler = () => {
        handleSubmit();
    }

    return (
        <FormikProvider value={formik}>
            <Wrp maxWidth={1105} flexDirection="column">
                <Row alignItems="flex-start">
                    <ListFields
                        name="operationsIncome"
                        keyIndex="1"
                        values={
                            values.operationsIncome
                                .map(operation => ({ id: operation.id, name: operation.name, income: operation.income }))
                        }
                        title="Категорії прихід"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched.operationsIncome}
                        errors={errors.operationsIncome}
                    />

                    <ListFields
                        name="operationsExpense"
                        keyIndex="4"
                        values={
                            values.operationsExpense
                                .map(operation => ({ id: operation.id, name: operation.name, income: operation.income }))
                        }
                        title="Категорії розхід"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched.operationsExpense}
                        errors={errors.operationsExpense}
                    />

                    <ListFields
                        name="purses"
                        keyIndex="2"
                        values={values.purses.map(item => ({ id: item.id, name: item.name }))}
                        title="Рахунки каси"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched.purses}
                        errors={errors.purses}
                    />
                </Row>
                <Row>
                    <ListFields
                        name="discounts"
                        hasName={true}
                        keyIndex="3"
                        values={values.discounts.map(item => ({ id: item.id, name: item.name, value: `${item.value}` }))}
                        title="Знижки"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched.discounts}
                        errors={errors.discounts}
                    />
                </Row>
            </Wrp>
            <Wrp maxWidth={1105}>
                <Button
                    disabled={!isValid}
                    backgroundColor="#4775FE"
                    title="Зберегти"
                    handleClick={sendButtonSubmitHandler}
                    marginRight={20}
                />
            </Wrp>
        </FormikProvider>
    )
}

export default Money;