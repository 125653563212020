import React, { FC, FocusEvent } from "react";
import { shallowEqual } from "react-redux";
import TagInput from "../../../../../../components/dataEntry/inputs/TagInput/TagInput";
import { useAppSelector } from "../../../../../../store/hooks/selector.hook";
import { teacherNameAdapter } from "../../../../../../utl/adapters/teacherNameAdapter";

interface Props {
    maxWidth?: number;
    marginRight?: number;
    employeesIdsValues: string;
    error: string | undefined;
    touched: boolean | undefined;
    handleBlur: (event: FocusEvent<HTMLElement>) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    obligatoryEmployeesIds?: number[];
}

const TeachersTagInput: FC<Props> = ({
    employeesIdsValues,
    error,
    touched,
    handleBlur,
    setFieldValue,
    maxWidth = 300,
    marginRight,
    obligatoryEmployeesIds
}) => {
    let teachers = useAppSelector(state => state.employees.teachers, shallowEqual)
    let teachersList = teachers.map(teacher => ({ id: teacher.employeeId, name: teacher.fullname }));

    if (obligatoryEmployeesIds) {
        teachersList = teachersList.filter(teacher =>
            obligatoryEmployeesIds.findIndex(employeeId => teacher.id === employeeId) > -1
        );
    }

    const activeTeachers = employeesIdsValues.length > 0 ? employeesIdsValues.split(',')
        .map(teacherId => (
            {
                id: Number(teacherId),
                name: teacherNameAdapter(teachers, Number(teacherId))
            }
        )) : [];

    const changeHandler = (employeesIds: string) => {
        setFieldValue("employeesIds", employeesIds);
    }

    return (
        <TagInput
            label="Вчителі"
            maxWidth={maxWidth}
            marginRight={marginRight}
            tagsArray={teachersList}
            activeTagsArray={activeTeachers}
            handleChange={changeHandler}
            handleBlur={handleBlur}
            error={error}
            touched={touched}
            values={employeesIdsValues}
        />
    )
}

export default TeachersTagInput;