import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import { 
    getServicesListFetch, 
    setPageServices, 
    setServices, 
    setServiceFilter 
} from "../../../../store/state/services/service/service.slice";
import { configListAdapter } from "../../../../utl/adapters/ConfigListAdapter";
import { culcPage } from "../../../../utl/helpers/culcPage";
import { Filter } from "../../../../utl/models/Filters";
import DropdownFilter, { DropdownFilterItem } from "./DropdownFilter/DropdownFilter";


const TypeFilter: FC = () => {
    const types = useAppSelector(state => state.config.vendorConfig?.service.types, shallowEqual);
    const typesList = configListAdapter(types);

    const dropdownList: DropdownFilterItem[] = typesList.map(type => ({ name: type.name, value: String(type.id) }));
    dropdownList.push({
        name: "Всі",
        value: "undefined"
    });

    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const confirmed = useAppSelector(state => state.services.filter.confirmed, shallowEqual);
    const active = useAppSelector(state => state.services.filter.active, shallowEqual);

    const typeId = useAppSelector(state => state.services.filter.typeId, shallowEqual);
    const value = String(typeId);

    const categoryId = useAppSelector(state => state.services.filter.categoryId, shallowEqual);

    const page = 1;
    const totalCount = 0;
    const pageAll = 0;
    const pageSize = useAppSelector(state => state.services.pageSize, shallowEqual);
    const getAllItems = useAppSelector(state => state.services.getAllItems, shallowEqual);
    const pagesRange = culcPage(page, pageAll, totalCount, pageSize);

    const dispatch = useAppDispatch();

    const changeHandler = (value: string) => {
        let filter: Filter = {
            confirmed,
            categoryId,
            active,
        };

        if (!getAllItems) {
            filter.range = {
                from: pagesRange.from,
                to: pagesRange.to
            }
        }

        if (value.localeCompare('undefined') !== 0) {
            const typeIdValue = parseInt(value);

            filter.typeId = typeIdValue;

            dispatch(setServiceFilter({ typeId: typeIdValue, confirmed, categoryId }));
        } else {
            dispatch(setServiceFilter({ typeId: undefined, confirmed, categoryId }));
        }

        dispatch(setServices({ services: [] }));
        dispatch(getServicesListFetch({ vendorId, filter }));
        dispatch(setPageServices({ page }));
    }

    return (
        <DropdownFilter
            label="Тип"
            changeHandler={changeHandler}
            dropdownList={dropdownList}
            value={value}
            maxWidth={200}
        />
    )
}

export default TypeFilter;