import React, { FC, MouseEvent } from "react";
import Wrp from "../../../layout/Wrp/Wrp";
import styles from "./DoubleButton.module.css";

interface Props {
    handleFirst: (event: MouseEvent<HTMLButtonElement>) => void;
    handleSecond: (event: MouseEvent<HTMLButtonElement>) => void;
    nameFirst: string;
    nameSecond: string;
    maxWidth?: number; 
    backgroundColor?: string;
}

const DoubleButton: FC<Props> = ({handleFirst, handleSecond, nameFirst, nameSecond, maxWidth, backgroundColor = "#A6AEC5"}) => {
    return (
        <Wrp
            maxWidth={maxWidth} 
            flexDirection="row" 
            alignItems="center" 
            justifyContent="center"
        >   
            <button
                type="button"
                className={styles.firstButton}
                style={{backgroundColor}}
                onClick={handleFirst}
            >
                {nameFirst}
            </button>
            <button
                type="button"
                className={styles.secondButton}
                style={{backgroundColor}}
                onClick={handleSecond}
            >
                {nameSecond}
            </button>
        </Wrp>
    )
}

export default DoubleButton;