import { FormikProps, useFormik } from "formik";
import React, { FC } from "react";
import Employee from "./Employee";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { setMessageVisiable } from "../../../store/state/employees/employees.slice";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import { shallowEqual } from "react-redux";
import Message from "../../../components/feedback/messages/Message/Message";
import { useMessageActions } from "../../../utl/hooks/useMessageActions";
import { EmployeeValues } from "../models/EmployeeValues";

interface Props {
    formik: FormikProps<EmployeeValues>;
    active: boolean;
}

const EmployeeMessage: FC<Props> = ({ formik, active }) => {
    const dispatch = useAppDispatch();

    const message = useAppSelector(state => state.employees.message, shallowEqual);
    const visiable = useAppSelector(state => state.employees.messageVisiable, shallowEqual);

    const setMessage = () => {
        dispatch(setMessageVisiable({ visiable: false }));
    }

    const visiableActions = useMessageActions(visiable, setMessage);

    return (
        <>
            <Message
                content={message || ""}
                visiable={visiableActions}
                position="top"
            />

            <Employee
                formik={formik}
                active={active}
            />
        </>
    )
}

export default React.memo(EmployeeMessage);