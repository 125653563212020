import { FormikProps } from "formik";
import React, { FC, useState } from "react";
import ModalForm from "../../../../components/dataEntry/forms/ModalForm/ModalForm";
import Field from "../../../../components/dataEntry/inputs/Field/Field";
import Button from "../../../../components/general/buttons/Button/Button";
import Row from "../../../../components/layout/Row/Row";
import Wrp from "../../../../components/layout/Wrp/Wrp";
import { CounteragentsAsClients } from "../../../../store/state/finance/models/CounteragentsAsClients";
import AddFormValue from "../../models/AddFormValue";
import CounteragentField from "./components/CounteragentField/CounteragentField";
import GroupsDropdown from "./components/GroupsDropdown/GroupsDropdown";
import OperationsDropdown from "./components/OperationsDropdown/OperationsDropdown";
import PursesDropdown from "./components/PursesDropdown/PursesDropdown";
import StudentsDropdown from "./components/StudentsDropdown/StudentsDropdown";

interface Props {
    formik: FormikProps<AddFormValue>;
    visiable: boolean;
    closeModalHandler: () => void;
    handleSearchCounteragents: (value: string) => void;
    counteragentsAsClients: CounteragentsAsClients[];
}

const AddForm: FC<Props> =
    ({
        visiable,
        closeModalHandler,
        formik,
        handleSearchCounteragents,
        counteragentsAsClients,
    }) => {
        const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched } = formik;

        const [selectValue, setSelectValue] = useState<CounteragentsAsClients | null>(null);

        const submitButtonHandler = () => {
            handleSubmit();
            setSelectValue(null);
        }

        const closeForm = () => {
            closeModalHandler();
            setSelectValue(null);
        }

        const purseId = values.purseId;

        const operationId = values.operationId;

        const students = (selectValue && selectValue.students) || [];
        const studentId = values.studentId;
        const indexStudent = students.findIndex(student => student.id === studentId);

        const student = students[indexStudent];

        const groups = (student && student.groups) || [];
        const groupId = values.groupId;

        const changeSumHandler = (value: string | number) => {
            setFieldValue("sum", value);
        }

        let discount = undefined;
        if (selectValue) {
            discount = selectValue.discount?.value;
        }

        return (
            <ModalForm
                title="Додати дохід"
                visiable={visiable}
                maxWidth={480}
                handleClick={closeForm}
            >
                <Wrp flexDirection="column" alignItems="center">
                    <CounteragentField
                        counteragentsAsClients={counteragentsAsClients}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        setSelectValue={setSelectValue}
                        handleSearchCounteragents={handleSearchCounteragents}
                        error={errors.counteragent}
                        touched={touched.counteragent}
                    />

                    <Row marginBottom={20}>
                        <Field
                            label="Сума"
                            type="number"
                            maxWidth={210}
                            marginBottom={0}
                            labelLeftShift={0}
                            paddingLeft={35}
                            topPositionIcon={29.2}
                            icon={<span style={{ fontSize: 14, fontWeight: "500" }}>₴</span>}
                            value={values.sum}
                            handleBlur={handleBlur("sum")}
                            handleChange={handleChange("sum")}
                            setFieldValue={changeSumHandler}
                            touched={touched.sum}
                            error={errors.sum}
                            discount={discount}
                            step={10}
                        />

                        <PursesDropdown
                            lable="Рахунок"
                            nameField="purseId"
                            purseId={purseId}
                            error={errors.purseId}
                            touched={touched.purseId}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                        />
                    </Row>
                    <Row marginBottom={20}>
                        <OperationsDropdown
                            income={true}
                            operationId={operationId}
                            error={errors.operationId}
                            touched={touched.operationId}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                        />

                        <Field
                            label="Дата надходження"
                            type="date"
                            maxWidth={210}
                            marginBottom={0}
                            marginRight={0}
                            labelLeftShift={0}
                            value={values.date}
                            handleBlur={handleBlur("date")}
                            handleChange={handleChange("date")}
                            touched={touched.date}
                            error={errors.date}
                        />
                    </Row>

                    <StudentsDropdown
                        label="Студент"
                        student={student}
                        students={students}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        error={errors.studentId}
                        touched={touched.studentId}
                    />

                    <GroupsDropdown
                        groupId={groupId}
                        groups={groups}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        error={errors.groupId}
                        touched={touched.groupId}
                    />

                    <Field
                        maxWidth={440}
                        label="Коментар"
                        marginBottom={20}
                        labelLeftShift={0}
                        marginRight={0}
                        value={values.comment}
                        handleBlur={handleBlur("comment")}
                        handleChange={handleChange("comment")}
                        touched={touched.comment}
                        error={errors.comment}
                    />
                    <Button
                        disabled={!isValid}
                        maxWidth={160}
                        handleClick={submitButtonHandler}
                        backgroundColor="#4775FE"
                        title="Додати"
                        marginBottom={0}
                        marginRight={15}
                    />
                </Wrp>
            </ModalForm>
        );
    }

export default AddForm;