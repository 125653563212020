import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { User } from "../../../../services/reddyApi/User/User";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { searchClientsFetch, searchClientsSuccess } from "../groups.slice";
import { SearchClientsPayload } from "../payload/SearchClients/SearchClientsPayload";

function* searchClientsWorker(action: PayloadAction<SearchClientsPayload>) {
    const { vendorId, roleId, value } = action.payload;

    try {
        const { data } = yield call(User.searchUsersForVendor, { vendorId, roleId, value });

        yield put(searchClientsSuccess(data));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => searchClientsFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default searchClientsWorker;