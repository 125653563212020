import React, { MouseEvent } from "react";
import { shallowEqual } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import StatusView from "../../../components/dataDisplay/table/views/StatusView/StatusView";
import Avatar from "../../../components/dataDisplay/user/Avatar/Avatar";
import ButtonIcon from "../../../components/general/buttons/ButtonIcon/ButtonIcon";
import IconFont from "../../../components/general/icons/IconFont/IconFont";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import { deleteAdditionalFetch } from "../../../store/state/services/upsells/upsells.slice";
import { UpsellsColumn } from "./models/UpsellsColumn";
import UpsellsPagination from "./Upsells.pagination";

const UpsellsContainer = () => {
    let location = useLocation();
    let navigation = useNavigate();
    const path = location.pathname;
    const dispatch = useAppDispatch();
    const upsells = useAppSelector(state => state.upsellsServices.upsells, shallowEqual)

    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const data: UpsellsColumn[] = upsells.map(item => {
        const editClickButton = () => {
            navigation(`${path}/${item.id}`);
        }

        const deleteClickButton = () => {
            dispatch(deleteAdditionalFetch({ vendorId: vendorId || -1, upsellId: item.id }));
        }

        const confirmed = item.confirmed;
        const active = item.active;

        return {
            id: item.id,
            miniature:
                <Avatar
                    name={item.name}
                    width={50}
                    height={50}
                    background="#A6AEC5"
                    backgroundName="#A6AEC5"
                    fontSize={18}
                    lineHeight={21}
                    maxHeightImg={"100%"}
                    maxWidthImg={"100%"}
                />,
            name: item.name,
            cost: item.price,
            status: <StatusView status={active ? Number(confirmed) : 2} />,
            edit:
                <ButtonIcon
                    width={25}
                    height={25}
                    icon={<IconFont color="#A6AEC5" iconClass="icon-edit" fontSize={12} />}
                    handleClick={editClickButton}

                />,
            delete:
                <ButtonIcon
                    width={25}
                    height={25}
                    icon={<IconFont color="#A6AEC5" iconClass="icon-delete" fontSize={12} />}
                    handleClick={deleteClickButton}
                />,
        }
    });

    const clickRowTableHandler = (item: any, event: MouseEvent<HTMLTableRowElement>) => {
        navigation(`${item.id}`, {})
    }

    return (
        <UpsellsPagination
            handleClickRow={clickRowTableHandler}
            data={data}
            vendorId={vendorId}
        />
    );
}

export default UpsellsContainer;