import { PayloadAction } from "@reduxjs/toolkit";
import { Service } from "../../models/Service";
import { ServiceState } from "../../models/ServiceState";
import { GetServicesListSuccessPayload } from "../../payload/GetServicesList/GetServicesListSuccessPayload";

const getShortServicesListSuccess = (state: ServiceState, action: PayloadAction<GetServicesListSuccessPayload>) => {
    const { items } = action.payload;

    let newArray: Service[] = [];
    items.forEach(function (item) {
        newArray.push({ ...item });
    });

    state.shortServices = newArray;
    state.isLoading = false;
    state.isError = null;
}

export default getShortServicesListSuccess;