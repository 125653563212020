import { PayloadAction } from "@reduxjs/toolkit";
import { ResSearchUsersForVendor } from "../../../../../services/reddyApi/User/User.response";
import { FinanceState } from "../../models/FinanceState";
import { SearchUsersForVendorSuccessPayload } from "../../payload/SearchUsersForVendor/SearchUsersForVendorSuccessPayload";

const searchUsersForVendorSuccess = (state: FinanceState, actions: PayloadAction<SearchUsersForVendorSuccessPayload>) => {
    const counterpartis = actions.payload;
    state.counterpartis = counterpartis;
    state.isLoading = true;
    state.isError = null;
}

export default searchUsersForVendorSuccess;