import React, { FC, useState } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./ButtonFunctions.module.css";
import { ContextValue } from "./models/ContextValue";
import "./transition.css";

interface Props {
    name: string;
    children: JSX.Element[] | JSX.Element;
    marginRight?: number;
    backgroundColor?: string;
    maxWidth?: number;
}

export const Context = React.createContext<ContextValue | null>(null);

const ButtonFunctions: FC<Props> = ({ name, children, maxWidth = 195, marginRight, backgroundColor = "#4775FE" }) => {
    const [toggle, setToggle] = useState(false);

    const clickButtonHandler = () => {
        setToggle(prev => !prev);
    }

    const blurHandler = () => {
        setToggle(false);
    }

    return (
        <Context.Provider value={{ blurHandler }}>
            <div
                style={{ maxWidth, marginRight }}
                className={styles.buttonFunctions}
            >
                <button
                    onClick={clickButtonHandler}
                    style={{ backgroundColor }}
                    className={styles.btn}>
                    {name}
                </button>
                <CSSTransition
                    in={toggle}
                    timeout={400}
                    unmountOnExit
                    classNames="button-import-list"
                >
                    <ul className={styles.buttonList}>
                        {children}
                    </ul>
                </CSSTransition>
            </div>
        </Context.Provider>
    );
}

export default ButtonFunctions;