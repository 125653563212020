import React, { FC } from "react";
import styles from "./TabPanel.module.css";
import classNames from "classnames";

const cx = classNames.bind(styles);

interface Props {
    name: string;
    handleClick: () => void;
    active: boolean;
}

const TabPanelItem: FC<Props> = ({name, handleClick, active}) => {
    return (
        <button onClick={handleClick} className={cx(styles.tabItem, active && styles.active)}>
            {name}
        </button>
    );
}

export default TabPanelItem;