import { FormikErrors, FormikTouched } from "formik";
import React, { FC, SetStateAction } from "react";
import SearchField from "../../../../../../components/dataEntry/inputs/SearchField/SearchField";
import IconFont from "../../../../../../components/general/icons/IconFont/IconFont";
import { Counteragent } from "../../../../../../store/state/finance/models/Counteragent";
import { CounteragentsAsClients } from "../../../../../../store/state/finance/models/CounteragentsAsClients";

interface Props {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
    setSelectValue: (value: SetStateAction<CounteragentsAsClients | null>) => void;
    handleSearchCounteragents: (value: string) => void;
    counteragentsAsClients: CounteragentsAsClients[];
    error: FormikErrors<Counteragent> | undefined;
    touched: FormikTouched<Counteragent> | undefined;
}

const CounteragentField: FC<Props> = ({
    setFieldTouched,
    setFieldValue,
    setSelectValue,
    handleSearchCounteragents,
    counteragentsAsClients,
    error,
    touched
}) => {
    const bluCounteragentHanndler = (touched: boolean) => {
        setFieldTouched("counteragent.name", touched);
    }

    const changeCounteragentHanndler = (counteragentAsClients: CounteragentsAsClients) => {
        const name = counteragentAsClients.fullname;
        const userId = counteragentAsClients.userId;
        const clientId = counteragentAsClients.clientId;

        const counteragent: Counteragent = {
            userId,
            name,
            clientId,
        }

        setFieldValue("counteragent", counteragent);
        setSelectValue(counteragentAsClients);
    }

    return (
        <SearchField
            topPositionIcon={32}
            paddingLeft={50}
            icon={<IconFont iconClass="icon-search" fontSize={20} color="#A6AEC5" />}
            maxWidth={440}
            label="Контрагент"
            marginBottom={20}
            labelLeftShift={0}
            marginRight={0}
            handleBlur={bluCounteragentHanndler}
            setFieldValue={changeCounteragentHanndler}
            touched={touched?.name}
            error={error?.name}
            handleSearch={handleSearchCounteragents}
            searchList={counteragentsAsClients}
        />
    );
}

export default CounteragentField;