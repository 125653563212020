import { FormikProps } from "formik";
import React, { FC } from "react";
import ModalForm from "../../../../components/dataEntry/forms/ModalForm/ModalForm";
import Field from "../../../../components/dataEntry/inputs/Field/Field";
import Button from "../../../../components/general/buttons/Button/Button";
import Row from "../../../../components/layout/Row/Row";
import Wrp from "../../../../components/layout/Wrp/Wrp";
import ExchangeFormValue from "../../models/ExchangeFormValue";
import PursesDropdown from "../AddForm/components/PursesDropdown/PursesDropdown";

interface Props {
    formik: FormikProps<ExchangeFormValue>;
    visiable: boolean;
    closeModalHandler: () => void;
}

const ExchangeForm: FC<Props> = ({ visiable, closeModalHandler, formik }) => {
    const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched } = formik;

    const submitButtonHandler = () => {
        handleSubmit();
    }

    const toPurseId = values.toPurseId;
    const fromPurseId = values.fromPurseId;

    return (
        <ModalForm
            title="Додати переказ"
            visiable={visiable}
            maxWidth={480}
            handleClick={closeModalHandler}
        >
            <Wrp flexDirection="column" alignItems="center">
                <Row marginBottom={20}>
                    <Field
                        label="Сума"
                        type="number"
                        maxWidth={210}
                        marginBottom={0}
                        labelLeftShift={0}
                        paddingLeft={35}
                        topPositionIcon={29.2}
                        icon={<span style={{ fontSize: 14, fontWeight: "500" }}>₴</span>}
                        value={values.sum}
                        handleBlur={handleBlur("sum")}
                        handleChange={handleChange("sum")}
                        touched={touched.sum}
                        error={errors.sum}
                    />

                    <Field
                        label="Дата"
                        type="date"
                        maxWidth={210}
                        marginBottom={0}
                        marginRight={0}
                        labelLeftShift={0}
                        value={values.date}
                        handleBlur={handleBlur("date")}
                        handleChange={handleChange("date")}
                        touched={touched.date}
                        error={errors.date}
                    />
                </Row>
                <Row marginBottom={20}>
                    <PursesDropdown
                        lable="З рахунку"
                        nameField="fromPurseId"
                        purseId={fromPurseId}
                        error={errors.fromPurseId}
                        touched={touched.fromPurseId}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                    />

                    <PursesDropdown
                        lable="На рахунок"
                        nameField="toPurseId"
                        purseId={toPurseId}
                        error={errors.toPurseId}
                        touched={touched.toPurseId}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                    />
                </Row>

                <Field
                    maxWidth={440}
                    label="Коментар"
                    marginBottom={20}
                    labelLeftShift={0}
                    marginRight={0}
                    value={values.comment}
                    handleBlur={handleBlur("comment")}
                    handleChange={handleChange("comment")}
                    touched={touched.comment}
                    error={errors.comment}
                />

                <Button
                    disabled={!isValid}
                    maxWidth={160}
                    handleClick={submitButtonHandler}
                    backgroundColor="#4775FE"
                    title="Додати"
                    marginBottom={0}
                    marginRight={15}
                />
            </Wrp>
        </ModalForm>
    );
}

export default ExchangeForm;