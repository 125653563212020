import { PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { Services } from "../../../../services/reddyApi/Services/Services";
import { GetServicesListResponse } from "../../../../services/reddyApi/Services/Services.response";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { GetServicesListPayload } from "../../services/service/payload/GetServicesList/GetServicesListPayload";
import { setPageDataServices } from "../../services/service/service.slice";
import { getActiveServicesListFetch, getActiveServicesListSuccess } from "../dashboard.slice";

function* getServicesListWorker(action: PayloadAction<GetServicesListPayload>) {
    const { vendorId, filter } = action.payload;

    try {
        const { data }: AxiosResponse<GetServicesListResponse> = yield call(Services.getServicesList, { vendorId, filter });
        const { totalCount, items } = data;

        yield put(getActiveServicesListSuccess({ totalCount, items, filter }));
        yield put(setPageDataServices({ services: [...items] }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getActiveServicesListFetch(action.payload);
            
            yield handleServerError(error, actionCreator);
        }
    }
}

export default getServicesListWorker;