import moment from "moment";
import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import RectProgress from "../../../../../components/dataDisplay/progress/Rect/RectProgress";
import EmptyView from "../../../../../components/dataDisplay/table/views/EmptyView/EmptyView";
import NameView from "../../../../../components/dataDisplay/table/views/NameView/NameView";
import PriceView from "../../../../../components/dataDisplay/table/views/PriceView/PriceView";
import StatusView from "../../../../../components/dataDisplay/table/views/StatusView/StatusView";
import TableTagInput from "../../../../../components/dataEntry/inputs/TableTagInput/TableTagInput";
import ButtonIcon from "../../../../../components/general/buttons/ButtonIcon/ButtonIcon";
import ButtonInfo from "../../../../../components/general/buttons/ButtonInfo/ButtonInfo";
import IconFont from "../../../../../components/general/icons/IconFont/IconFont";
import Row from "../../../../../components/layout/Row/Row";
import { useAppDispatch } from "../../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../../store/hooks/selector.hook";
import { setFonVisiable } from "../../../../../store/state/all/all.slice";
import { Config } from "../../../../../store/state/configuration/models/Config";
import { 
    deleteStudentForGroupFetch, 
    getStudentsListForGroupFetch, 
    showStudentAttendance, 
    showStudentStatusForm, 
    updateStudentForGroupFetch 
} from "../../../../../store/state/groups/groups.slice";
import { StudentGroup } from "../../../../../store/state/groups/models/Group";
import { Upsell } from "../../../../../store/state/services/upsells/models/Upsell";
import { convertStringToArray } from "../../../../../utl/helpers/convertStringToArray";
import { getRelationship } from "../../../../../utl/helpers/getRelationship";
import { Filter } from "../../../../../utl/models/Filters";
import GroupTableClients from "./GroupTableActiveClients";
import { TableClientsColumn } from "./models/TableClientsColumn";
import StudentAttendanceModalContainer from "./StudentsModals/StudentAttendanceModal/StudentAttendanceModal.container";
import StudentStatusForm from "./StudentsModals/StudentStatusForm/StudentStatusForm";

const GroupTableActiveClientsContainer: FC = () => {
    let { id } = useParams();
    const groupId = id ? Number(id) : -1;
    const dispatch = useAppDispatch();
    const groups = useAppSelector(state => state.groups.pageData, shallowEqual);
    const indexGroup = groups.findIndex(group => group.id === groupId);
    const group = groups[indexGroup];
    const students = useAppSelector(state => state.groups.pageData[indexGroup].students);

    const upsellsIds = group.upsellsIds
    const lessonsAmount = group.lessons.length;
    const studentsConfirmed = students?.filter(student => student.status === 3 || student.status === 1);
    const studentsNotConfirmed = students?.filter(student => student.status === 2);
    const relationshipStatuses = useAppSelector(state => state.config.userConfig?.user.relationshipStatuses, shallowEqual);
    let upsells = useAppSelector(state => state.upsellsServices.upsells, shallowEqual);
    upsells = upsells.filter(upsell => upsell.active)
        .filter(upsell => upsellsIds.findIndex(upsellId => upsellId === upsell.id) > -1);
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const clickStatusButtonHandler = (studentId: number) => () => {
        dispatch(showStudentStatusForm({ groupId, studentId, show: true }));
        dispatch(setFonVisiable({ visiable: true }));
    }

    const clickAttendanceButtonHandler = (studentId: number) => () => {
        dispatch(showStudentAttendance({ groupId, studentId, show: true }));
        dispatch(setFonVisiable({ visiable: true }));
    }

    const changeHandler = (studentId: number) => (upsellsValues: string) => {
        let upsellsIds = convertStringToArray(upsellsValues);

        dispatch(updateStudentForGroupFetch({ groupId, vendorId, studentId, studentData: { upsellsIds } }));
    }

    const deleteSudentButtonHandler = (studentId: number) => () => {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        const filter: Filter = {
            period: {
                from: moment(firstDay).format("YYYY-MM-DD"),
                to: moment(lastDay).format("YYYY-MM-DD"),
            }
        }

        let clientId = -1;
        if(students) {
            const studentIndex = students.findIndex(student => student.student.id === studentId);
            const student = students[studentIndex];
            clientId = student?.client.id || -1;
        }
        
        const serviceId = group?.serviceId || -1;

        dispatch(deleteStudentForGroupFetch({ vendorId, groupId, studentId, filter, serviceId, clientId }));
    }

    const dataConfirmed: TableClientsColumn[] = getDataStudentTable(
        studentsConfirmed,
        upsells,
        lessonsAmount,
        relationshipStatuses,
        clickStatusButtonHandler,
        changeHandler,
        deleteSudentButtonHandler,
        clickAttendanceButtonHandler
    );

    const dataNotConfirmed: TableClientsColumn[] = getDataStudentTable(
        studentsNotConfirmed,
        upsells,
        lessonsAmount,
        relationshipStatuses,
        clickStatusButtonHandler,
        changeHandler,
        deleteSudentButtonHandler,
        clickAttendanceButtonHandler
    );

    const modalsDataConfirmed = studentsConfirmed ? studentsConfirmed.map(student => (
        <StudentStatusForm
            status={student.status}
            vendorId={vendorId}
            groupId={groupId}
            studentId={student.student.id}
        />
    )) : [];

    const modalsNotConfirmed = studentsNotConfirmed ? studentsNotConfirmed.map(student => (
        <StudentStatusForm
            status={student.status}
            vendorId={vendorId}
            groupId={groupId}
            studentId={student.student.id}
        />
    )) : [];

    const modalsDataConfirmedVisit = studentsConfirmed ? studentsConfirmed.map(student => (
        <StudentAttendanceModalContainer
            vendorId={vendorId}
            groupId={groupId}
            studentId={student.student.id}
        />
    )) : [];

    const modalsNotConfirmedVisit = studentsNotConfirmed ? studentsNotConfirmed.map(student => (
        <StudentAttendanceModalContainer
            vendorId={vendorId}
            groupId={groupId}
            studentId={student.student.id}
        />
    )) : [];

    useEffect(() => {
        if (!students) {
            dispatch(getStudentsListForGroupFetch({ vendorId, groupId }));
        }
    }, [vendorId, groupId]);

    return (
        <GroupTableClients
            dataConfirmed={dataConfirmed}
            dataNotConfirmed={dataNotConfirmed}
            modalsDataConfirmed={modalsDataConfirmed}
            modalsNotConfirmed={modalsNotConfirmed}
            modalsDataConfirmedVisit={modalsDataConfirmedVisit}
            modalsNotConfirmedVisit={modalsNotConfirmedVisit}
        />
    )
}

const getDataStudentTable = (students: StudentGroup[] | undefined, upsellsGroup: Upsell[], lessonsAmount: number,
    relationshipStatuses: Config | undefined,
    clickStatusButtonHandler: (studentId: number) => () => void,
    changeHandler: (studentId: number) => (upsellsValues: string) => void,
    deleteSudentButtonHandler: (studentId: number) => () => void,
    clickAttendanceButtonHandler: (studentId: number) => () => void) => {

    const FIRST_USER = 0;

    return students ? students.map(student => {
        const studentId = student.student.id;
        const fullnameStudent = `${student.student.surname} ${student.student.name}`;
        const client = student.client;
        const user = client.users[FIRST_USER];
        const relationship = getRelationship(relationshipStatuses, client.users[0].relationshipStatus);;
        const fullnameParent = `${relationship}: ${user.surname} ${user.name}`
        const price = student.debt;
        const status = student.status;
        const discount = student.discount > 0 ? `-${student.discount}%` : <EmptyView />
        const email = user.email;
        const phone = user.phone;
        const selectedUpsellsIds = student.upsellsIds

        const upsells = upsellsGroup.map(upsell => ({ id: upsell.id, name: upsell.name }));
        const selectedUpsells = selectedUpsellsIds ? selectedUpsellsIds
            .map(selectedUpsell => (
                {
                    id: Number(selectedUpsell),
                    name:
                        upsells[upsells.findIndex(upsell => upsell.id === Number(selectedUpsell))]?.name || "Name"
                }
            )) : [];

        let lessonsAttended = 0;
        student.lessons.forEach(lesson => { if (lesson.attended) lessonsAttended++ })
        const attendance = Math.floor(lessonsAttended * 100 / lessonsAmount);

        return {
            clients: <NameView name={fullnameStudent} subname={fullnameParent} />,
            debt: <PriceView price={price} debt={true} />,
            upsells:
                <TableTagInput
                    selectedTags={selectedUpsells}
                    tagData={upsells}
                    handleChange={changeHandler(studentId)}
                />,
            status:
                <StatusView
                    clicked={true}
                    handleClick={clickStatusButtonHandler(studentId)}
                    isStudentsStatus={true}
                    status={status}
                    statusExp="Не підтвержено"
                    statusOn="Активний"
                    statusOff="Додати"
                />
            ,
            discount,
            attendance:
                <Row>
                    <ButtonIcon
                        width={25}
                        height={25}
                        icon={<IconFont color="#4775FE" iconClass="icon-date" fontSize={15} />}
                        handleClick={clickAttendanceButtonHandler(studentId)}
                        marginRight={20}
                    />
                    <RectProgress progress={attendance} />
                </Row>,
            phone:
                <ButtonInfo
                    icon={<IconFont color="#A6AEC5" iconClass="icon-tel" fontSize={13} />}
                    info={phone}
                />,
            email:
                <ButtonInfo
                    icon={<IconFont color="#A6AEC5" iconClass="icon-email" fontSize={13} />}
                    info={email}
                />,
            delete:
                <ButtonIcon
                    width={25}
                    height={25}
                    icon={<IconFont color="#A6AEC5" iconClass="icon-delete" fontSize={12} />}
                    handleClick={deleteSudentButtonHandler(studentId)}
                />
        }
    }) : []
}

export default React.memo(GroupTableActiveClientsContainer);