import React, { FC } from "react";
import styles from "./TabPanel.module.css";

interface Props {
    children: JSX.Element | JSX.Element[];
    marginBottom?: number;
    marginRight?: number;
}

const TabPanel: FC<Props> = ({ children, marginBottom, marginRight }) => {
    return (
        <div style={{ marginBottom, marginRight }} className={styles.tabsBlock}>
            {children}
        </div>
    );
}

export default TabPanel;