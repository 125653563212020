import { PayloadAction } from "@reduxjs/toolkit";
import { LocationsState } from "../models/LocationsState";
import { DeleteLocationImagesPayload } from "../payload/DeleteLocationImages/DeleteLocationImagesPayload";

type Payload = Pick<DeleteLocationImagesPayload, "links" | "locationId">;

const deleteLocationImages = (state: LocationsState, action: PayloadAction<Payload>) => {
    const { locationId, links } = action.payload;

    const locations = state.locationsArray;

    const indexLocation = locations.findIndex(location => location.id === locationId);

    if(indexLocation > -1) {
        const location = locations[indexLocation];
        location.images = location.images?.filter(image => links.findIndex(link => link.localeCompare(image) === 0) === -1);
    }
}

export default deleteLocationImages;