import React, { FC } from "react";
import Button from "../../../../components/general/buttons/Button/Button";
import IconFont from "../../../../components/general/icons/IconFont/IconFont";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { setFonVisiable } from "../../../../store/state/all/all.slice";
import { setVisiablePaymentForm } from "../../../../store/state/finance/finance.slice";

interface Props {
    
}

const ButtonPaymentForm: FC<Props> = ({}) => {
    const dispatch = useAppDispatch();
    const clickButtonHandler = () => {
        dispatch(setVisiablePaymentForm({visiable: true}));
        dispatch(setFonVisiable({visiable: true}));
    }

    return (
        <Button 
            maxWidth={160}
            handleClick={clickButtonHandler}
            // icon={<IconFont iconClass="icon-plus" fontSize={15} color="#FFF" />} 
            backgroundColor="#FA5D54" 
            title="Витрата"  
            marginBottom={0}
            marginRight={15}
        />
    );
}

export default ButtonPaymentForm;