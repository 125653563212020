import React, { ChangeEvent, FC, FocusEvent } from "react";
import { shallowEqual } from "react-redux";
import Field from "../../../../../components/dataEntry/inputs/Field/Field";
import { useAppDispatch } from "../../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../../store/hooks/selector.hook";
import { setErrorEmailEmployee } from "../../../../../store/state/employees/employees.slice";

interface Props {
    email: string;
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): 
        T extends React.ChangeEvent<any> ? void : 
        (e: string | React.ChangeEvent<any>) => void;
    };
    handleBlur: (event: FocusEvent<HTMLElement>) => void;
    error: string | undefined;
    touched: boolean | undefined;
}

const EmailField: FC<Props> = ({ email, handleChange, handleBlur, error, touched }) => {
    const emailError = useAppSelector(state => state.employees.emailError, shallowEqual);

    const dispatch = useAppDispatch();

    const changeEmailHandler = (event: ChangeEvent<HTMLInputElement>) => {
        handleChange("email")(event);
        dispatch(setErrorEmailEmployee({ error: null }));
    }

    return (
        <Field
            label="Email"
            maxWidth={240}
            marginBottom={0}
            labelLeftShift={0}
            value={email}
            handleChange={changeEmailHandler}
            handleBlur={handleBlur}
            error={error || emailError || undefined}
            touched={touched}
        />
    )
}

export default EmailField;