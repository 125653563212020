import { PayloadAction } from "@reduxjs/toolkit";
import { UpsellState } from "../../models/UpsellsState";
import { ChangeUpsellActivitySuccessPayload } from "../../payload/ChangeUpsellActivity/ChangeUpsellActivitySuccessPayload";

const changeUpsellActivitySuccess = (state: UpsellState, action: PayloadAction<ChangeUpsellActivitySuccessPayload>) => {
    const { upsellId, active } = action.payload;

    const upsells = state.upsells;

    const indexUpsell = upsells.findIndex(upsell => upsell.id === upsellId);

    if(indexUpsell > -1) {
        const upsell = upsells[indexUpsell];

        upsell.active = active;
    }
}

export default changeUpsellActivitySuccess;