import React, { FC } from "react";
import { PagePagination } from "../../../../../components/dataDisplay/table/models/PagePagination";
import Table from "../../../../../components/dataDisplay/table/Table";
import Wrp from "../../../../../components/layout/Wrp/Wrp";
import { DATA_INDEX_FINANCE_CLIENT } from "../../../../../utl/constants/tables";
import { TransactionsColumn } from "../../../models/TransactionsColumn";

interface Props {
    data: TransactionsColumn[];
    page: number;
    pages: PagePagination[];
    handleLeftButtonPagination: () => void;
    handleRightButtonPagination: () => void;
} 

const Transactions: FC<Props> = ({data, page, pages, handleLeftButtonPagination, handleRightButtonPagination}) => {
    return (
        <Wrp paddingRight={20}>
            <Table 
                data={data} 
                columns={DATA_INDEX_FINANCE_CLIENT} 
                heightRow={40}
                leftShiftContentRow={15}
                page={page}
                pages={pages}
                handleLeftButtonPagination={handleLeftButtonPagination}
                handleRightButtonPagination={handleRightButtonPagination}
            />
        </Wrp>
    );
}

export default Transactions;