import React, { FC } from "react";
import Button from "../../../../../../components/general/buttons/Button/Button";
import IconFont from "../../../../../../components/general/icons/IconFont/IconFont";
import { useAppDispatch } from "../../../../../../store/hooks/dispatch.hook";
import { setFonVisiable } from "../../../../../../store/state/all/all.slice";
import { showStudentVisitForm } from "../../../../../../store/state/groups/groups.slice";

interface Props {
    
}

const ButtonSetVistStudentForLesson: FC<Props> = ({}) => {
    const dispatch = useAppDispatch();

    const clickButtonHandler = () => {
        dispatch(showStudentVisitForm({visiable: true}));
        dispatch(setFonVisiable({visiable: true}));
    }

    return (
        <Button
            maxWidth={160}
            handleClick={clickButtonHandler}
            backgroundColor="#4775FE" 
            title="Відмітити"  
            marginBottom={0}
            marginRight={15}
        />
    );
}

export default ButtonSetVistStudentForLesson;