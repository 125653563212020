import React, { FC } from "react";
import Column from "../../../layout/Column/Column";
import Row from "../../../layout/Row/Row";
import styles from "./DataList.module.css";
import classNames from "classnames";

const cx = classNames.bind(styles);

interface Props {
    status: boolean;
    date: string;
    groupName: string;
    serviceName: string;
}

const DataItem: FC<Props> = ({status, date, groupName, serviceName}) => {
    return (
        <li className={styles.dataItem}>
            <Column maxWidth={30} justifyContent="flex-start" alignItems="flex-start">
                <div className={cx(styles.circel, !status && styles.archive)}></div>
            </Column>
            <Column>
                <Row justifyContent="space-between" marginBottom={5}>
                    <p className={styles.groupName}>
                        {groupName}
                    </p>
                    <p className={cx(styles.status, !status && styles.archive)}>
                        {status ? "Активна" : "Архів"}
                    </p>
                </Row>
                <Row justifyContent="space-between">
                    <p className={styles.srviceName}>
                        {serviceName}
                    </p>
                    <p className={styles.date}>
                        {`${status ? "з" : "по"} ${date}`}
                    </p>
                </Row>
            </Column>
        </li>
    );
}

export default DataItem; 