import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Money } from "../../../../services/reddyApi/Money/Money";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { getStatisticsDashboard, getStatisticsFinance } from "../../statistics/statistics.saga";
import { createMoneyTransferFetch, createMoneyTransferSuccess } from "../finance.slice";
import { CreateMoneyTransferPayload } from "../payload/CreateMoneyTransfer/CreateMoneyTransferPayload";

function* createMoneyTransferWorker(action: PayloadAction<CreateMoneyTransferPayload>) {
    const { vendorId, transfer, filter } = action.payload;
    const { responsibleUser, sum, fromPurseId, toPurseId, date, comment } = transfer;

    try {
        const { data } = yield call(Money.createMoneyTransfer, 
            { 
                vendorId, 
                transfer: { 
                    sum, 
                    fromPurseId, 
                    toPurseId, 
                    date, 
                    comment 
                } 
            });
        const { expenseTransactionId, incomeTransactionId } = data

        yield put(createMoneyTransferSuccess({
            id: Number(expenseTransactionId),
            purseId: fromPurseId,
            sum: sum * -1,
            comment: comment,
            createdAt: date,
            responsibleUser: responsibleUser,
        }));

        yield put(createMoneyTransferSuccess({
            id: Number(incomeTransactionId),
            purseId: toPurseId,
            sum: sum,
            comment: comment,
            createdAt: date,
            responsibleUser: responsibleUser,
        }));

        yield getStatisticsDashboard({ vendorId, filter });

        yield getStatisticsFinance({ vendorId, filter });

        yield getNotifications(vendorId);

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => createMoneyTransferFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default createMoneyTransferWorker;