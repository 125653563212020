import React, { ChangeEvent, FC, MouseEvent, useState } from "react";
import ButtonIcon from "../../general/buttons/ButtonIcon/ButtonIcon";
import IconFont from "../../general/icons/IconFont/IconFont";
import Wrp from "../../layout/Wrp/Wrp";
import Search from "../../dataEntry/inputs/Search/Search";
import styles from "./Table.module.css";
import { Column } from "./models/Column";
import EmptyIcon from "../../general/icons/EmptyIcon/EmptyIcon";
import classNames from "classnames";
import { PagePagination } from "./models/PagePagination";

const cx = classNames.bind(styles);

interface Props {
    columns: Column[];
    data: any[];
    leftShiftContentRow?: number;
    heightRow?: number;
    isSearch?: boolean;
    searchParam?: Array<string>;
    handleClickRow?: (item: any, event: MouseEvent<HTMLTableRowElement>) => void;
    paginated?: boolean;
    paginationKey?: string;
    pages?: PagePagination[];
    page?: number;
    handleRightButtonPagination?: () => void;
    handleLeftButtonPagination?: () => void;
    isWhiteRow?: boolean;
}

interface Sort {
    sort: boolean;
    dataIndex: string;
}

const Table: FC<Props> = ({ pages, page, data, columns, leftShiftContentRow = 0, heightRow,
    isSearch = false, searchParam, handleClickRow, paginated = false,
    paginationKey = "PAGINATION_KEY_", handleRightButtonPagination, handleLeftButtonPagination, isWhiteRow }) => {
    const [search, setSearch] = useState("");
    const [sorts, setSorts] = useState<Sort[]>([]);

    const changeValueHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    let dataTable = data;

    if (isSearch && searchParam) {
        dataTable = dataTable.filter(item => searchParam.some((param) => {
            let value = item[param];
            if (Array.isArray(value)) {
                value = value.join(" ");
            }

            return String(value).toUpperCase().includes(search.toUpperCase());
        }));
    }

    sorts.forEach(sort => {
        dataTable = dataTable.sort((item1, item2) => {
            if ((!sort.sort && item1[sort.dataIndex] > item2[sort.dataIndex])
                || (sort.sort && item1[sort.dataIndex] < item2[sort.dataIndex])) {
                return 1;
            }

            if ((!sort.sort && item1[sort.dataIndex] < item2[sort.dataIndex])
                || (sort.sort && item1[sort.dataIndex] > item2[sort.dataIndex])) {
                return -1;
            }

            return 0;
        });
    });

    // Left Shift Content
    const getLeftShiftInTable = (inx: number) => {
        return inx === 0 ? { paddingLeft: leftShiftContentRow } : undefined;
    }

    //Height Row
    const getHeightRowInTable = (height: number | undefined) => {
        return height ? { height } : undefined;
    }

    //Title
    const heads = columns.map((item, inx) => {
        let index = sorts.findIndex(sort => sort.dataIndex === item.dataIndex);

        if (item.sorted) {
            if (index === -1) {
                setSorts(prev => [...prev, { dataIndex: item.dataIndex, sort: false }]);
            }
        }

        const clickSortButtonHandler = () => {
            setSorts(prev => {
                const inx = prev.findIndex(sort => sort.dataIndex === item.dataIndex);
                const newSorts = [...prev];
                newSorts[inx].sort = !newSorts[index].sort

                return newSorts;
            })
        }

        const iconClass = index !== -1 && sorts[index].sort ? "icon-sort-1" : "icon-sort-2";

        return (
            <th style={getLeftShiftInTable(inx)} key={item.key}>
                <div className={styles.headItem}>
                    <p>
                        {item.title}
                    </p>
                    {item.sorted && <ButtonIcon icon={<IconFont iconClass={iconClass} fontSize={9} />} handleClick={clickSortButtonHandler} />}
                </div>
            </th>
        );
    });

    //Content
    const trsData = dataTable.map((item, _) =>
        (<><tr onClick={(event) => { if (handleClickRow) handleClickRow(item, event) }}>{columns.map((elem, index) => <td style={{ ...getLeftShiftInTable(index), ...getHeightRowInTable(heightRow) }}>{item[elem.dataIndex]}</td>)}</tr><tr className={styles.spacer}></tr></>));
    return (
        <div className={styles.tableWrp}>
            {isSearch && searchParam && <Wrp maxWidth={440} marginBottom={30}>
                <Search
                    placeholder="Введіть ім’я або номер телефону клієнта"
                    changeValueHandler={changeValueHandler}
                    value={search}
                />
            </Wrp>}
            <table className={cx(styles.table, isWhiteRow && styles.whiteRow)}>
                <thead>
                    <tr>
                        {heads}
                    </tr>
                </thead>
                <tbody>
                    {trsData}
                </tbody>
            </table>
            {data.length < 1 && <EmptyIcon />}
            {pages && pages.length > 1 && paginated &&
                <Pagination
                    page={page}
                    pages={pages}
                    paginationKey={paginationKey}
                    handleLeftButtonPagination={handleLeftButtonPagination}
                    handleRightButtonPagination={handleRightButtonPagination}
                />
            }
        </div>
    );
}

type PropsP = Pick<Props, "page" | "pages" | "paginationKey" | "handleLeftButtonPagination" | "handleRightButtonPagination">;

export const Pagination: FC<PropsP> = ({ page, pages, paginationKey = "", handleLeftButtonPagination, handleRightButtonPagination }) => {
    const pageFirst = 1;
    const visiableButton = 5;
    const pageLast = pages && pages[pages.length - 1];
    let pageStart = page && visiableButton > page ? 0 : (page && page - visiableButton) || 0;
    
    let pageEnd = pageStart + visiableButton;
    const visiableLastButton = pages && pageEnd + 1 < pages.length;

    const buttons = pages?.slice(pageStart, pageEnd + 1).map((item, index) => (
        <button
            disabled={page === item.name}
            key={paginationKey + index}
            onClick={item.handleClick}
            type="button"
            className={cx(styles.buttonNumber)}
        >
            <span className={styles.pagNumber}>
                {item.name}
            </span>
        </button>
    ));

    return (
        <Wrp
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            marginTop={30}
        >
            <button
                disabled={pageFirst === page}
                type="button"
                className={cx(styles.buttonleft, styles.buttonPagination)}
                onClick={handleLeftButtonPagination}
            >
                <IconFont iconClass="icon-arrow" fontSize={7} />
            </button>
            <>{buttons}</>
            <>{visiableLastButton &&
                <button
                    type="button"
                    className={cx(styles.buttonNumber, styles.separator)}
                >
                    <span className={styles.pagNumber}>
                        ...
                    </span>
                </button>
            }</>
            <>{pageLast && visiableLastButton &&
                <button
                    disabled={pages && page === pages.length}
                    onClick={pageLast.handleClick}
                    type="button"
                    className={cx(styles.buttonNumber, styles.separator)}
                >
                    <span className={styles.pagNumber}>
                        {pageLast.name}
                    </span>
                </button>
            }
            </>
            <button
                disabled={pages && page === pages.length}
                type="button"
                className={cx(styles.buttonRight, styles.buttonPagination)}
                onClick={handleRightButtonPagination}
            >
                <IconFont iconClass="icon-arrow" fontSize={7} />
            </button>
        </Wrp>
    );
}

export default Table;