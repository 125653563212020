import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Services } from "../../../../../services/reddyApi/Services/Services";
import handleServerError from "../../../all/saga/handleServerError";
import setServerErrorDefault from "../../../all/saga/setServerErrorDefault";
import getNotifications from "../../../notifications/saga/getNotifications";
import { UpdateServicePayload } from "../payload/UpdateService/UpdateServicePayload";
import { updateServicesFetch, updateServicesSuccess } from "../service.slice";
import getShortServices from "./getShortServices";

function* updateServiceWorker(action: PayloadAction<UpdateServicePayload>) {
    const { vendorId, service, serviceId } = action.payload;

    try {
        const { status } = yield call(Services.update, { vendorId, service, serviceId });
        if (status === 200) {
            yield put(updateServicesSuccess({ ...service, id: serviceId }));

            yield getShortServices({ vendorId });

            yield getNotifications(vendorId);

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => updateServicesFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default updateServiceWorker;