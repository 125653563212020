import React, { FC } from "react";
import Column from "../../components/layout/Column/Column";
import Row from "../../components/layout/Row/Row";
import Wrp from "../../components/layout/Wrp/Wrp";
import { Notification as NotificationType } from "../../store/state/notifications/models/Notification";
import NotificationsActive from "./components/NotificationsActive/NotificationsActive";
import NotificationsArchive from "./components/NotificationsArchive/NotificationsArchive";
import NotificationsSelected from "./components/NotificationsSelected/NotificationsSelected";
import TypeFilter from "./components/TypeFilter";

interface Props {
    active: NotificationType[];
    archive: NotificationType[];
    selected: NotificationType[];
    vendorId: number;
}

const Notifications: FC<Props> = ({ active, archive, selected, vendorId }) => {
    return (
        <Wrp flexDirection="column">
            <Row marginBottom={15}>
                <TypeFilter />
            </Row>
            <Row alignItems="flex-start">
                <Column maxWidth={775} paddingRight={30}>
                    <NotificationsActive
                        vendorId={vendorId}
                        active={active}
                    />

                    <NotificationsArchive
                        vendorId={vendorId}
                        archive={archive}
                    />
                </Column>

                <NotificationsSelected
                    vendorId={vendorId}
                    selected={selected}
                />
            </Row>
        </Wrp>
    );
}

export default Notifications;