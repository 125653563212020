import React, { FC, useState } from "react";
import { CSSTransition } from "react-transition-group";
import IconFont from "../../general/icons/IconFont/IconFont";
import styles from "./PriceCategoryList.module.css";
import "./transition.css";
import classNames from "classnames";
import AnimateHeight from 'react-animate-height';
const cx = classNames.bind(styles);

interface Props {
    price: string;
    name: string;
    marginBottom?: number;
}

const PriceCategoryList: FC<Props> = ({children, price, name, marginBottom = 15}) => {
    const [toggle, setToggle] = useState<boolean>(false);
    const clickToggleButtonHandler = () => {
        setToggle(prev => !prev);
    }

    return (
        <div className={styles.priceBlock} style={{marginBottom}}>
            <div className={styles.priceInfoBlock}>
                <div onClick={clickToggleButtonHandler} className={cx(styles.nameBlock, toggle && styles.active)}>
                    <p className={styles.name}>{name}</p>
                    <div className={styles.arrowBlock}>
                        <IconFont iconClass="icon-arrow" color="#A6AEC5" fontSize={7} />
                    </div>
                </div>
                <p className={styles.price}>{price} ₴</p>
            </div>
            <AnimateHeight
                duration={500}
                height={toggle ? "auto" : "0"}
            >
                <ul className={styles.priceList}>
                    {children}
                </ul>
            </AnimateHeight>
        </div>
    )
}

export default PriceCategoryList;