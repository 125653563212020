import React, { FC } from "react";
import { PagePagination } from "../../../../../components/dataDisplay/table/models/PagePagination";
import Table from "../../../../../components/dataDisplay/table/Table";
import Title from "../../../../../components/general/typography/Title/Title";
import Wrp from "../../../../../components/layout/Wrp/Wrp";
import { DATA_INDEX_CLIENTS, DATA_INDEX_GROUP_CLIENTS } from "../../../../../utl/constants/tables";
import { TableClientsColumn } from "./models/TableClientsColumn";
import StudentAttendanceModal from "./StudentsModals/StudentAttendanceModal/StudentAttendanceModal";

interface Props {
    dataConfirmed: TableClientsColumn[];
    dataNotConfirmed: TableClientsColumn[];
    modalsDataConfirmed: JSX.Element[];
    modalsNotConfirmed: JSX.Element[];
    modalsDataConfirmedVisit: JSX.Element[];
    modalsNotConfirmedVisit: JSX.Element[];
}

const GroupTableClients: FC<Props> = ({ dataConfirmed, dataNotConfirmed, modalsDataConfirmed, modalsNotConfirmed, 
    modalsDataConfirmedVisit, modalsNotConfirmedVisit }) => {
    return (
        <Wrp marginTop={30} flexDirection="column">
            <>{dataNotConfirmed.length > 0 &&
                <>
                    <Title
                        title="Пробне заняття"
                        color="#A6AEC5"
                        level={2}
                        marginBottom={20}
                    />
                    <Table
                        data={dataNotConfirmed}
                        columns={DATA_INDEX_GROUP_CLIENTS}
                        isWhiteRow={true}
                        heightRow={50}
                        leftShiftContentRow={20}
                    />
                    {modalsNotConfirmed}
                    {modalsNotConfirmedVisit}
                </>
            }</>

            <Title
                title="Основний потік"
                color="#A6AEC5"
                level={2}
                marginBottom={20}
            />
            <Table
                data={dataConfirmed}
                columns={DATA_INDEX_GROUP_CLIENTS}
                isWhiteRow={true}
                heightRow={50}
                leftShiftContentRow={20}
            />
            <>{modalsDataConfirmed}</>
            <>{modalsDataConfirmedVisit}</>
        </Wrp>
    );
}

export default GroupTableClients;