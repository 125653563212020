import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Row from "../../components/layout/Row/Row";
import Wrp from "../../components/layout/Wrp/Wrp";
import TabPanel from "../../components/navigation/TabPanel/TabPanel";
import TabPanelItem from "../../components/navigation/TabPanel/TabPaneltem";
import withMatch from "../../components/other/hoc/withMatch";
import ServiceCategoryFilter from "./Basics/components/CategoryFilter";
import ServiceStatusFilter from "./Basics/components/StatusFilter";
import ServiceTypeFilter from "./Basics/components/TypeFilter";
import UpsellsStatusFilter from "./Upsells/components/StatusFilter";

interface PanelItems {
    name: string;
    active: boolean;
    url: string;
}

const Services = () => {
    const navigate = useNavigate();
    let location = useLocation();
    const locations = location.pathname.split("/");
    locations.shift();

    const [panel, setState] = useState<Array<PanelItems>>([
        {
            name: "Основні",
            active: locations.length > 1 && locations[1] === "basics",
            url: "basics"
        },
        {
            name: "Додаткові",
            active: locations.length > 1 && locations[1] === "upsells",
            url: "upsells"
        },
    ]);

    const panelItems = panel.map((item, index) => {
        const clickTabHandler = () => {
            navigate(`/services/${item.url}`);
        }

        return (
            <TabPanelItem name={item.name} active={item.active} handleClick={clickTabHandler} />
        )
    });

    useEffect(() => {
        setState([
            {
                name: "Основні",
                active: locations.length > 1 && locations[1] === "basics",
                url: "basics"
            },
            {
                name: "Додаткові",
                active: locations.length > 1 && locations[1] === "upsells",
                url: "upsells"
            },
        ]);
    }, [location]);

    const filter = locations[1] === "basics" ?
        <>
            <ServiceStatusFilter />

            <ServiceCategoryFilter />

            <ServiceTypeFilter />
        </>
        :
        <>
            <UpsellsStatusFilter />
        </>;

    return (
        <Wrp flexDirection="column" >
            <Row marginBottom={20}>
                <>
                    {locations.length < 3 &&
                        <TabPanel marginRight={20}>
                            {panelItems}
                        </TabPanel>
                    }
                </>

                {filter}
            </Row>
            <Outlet />
        </Wrp>
    );
}

export default withMatch(React.memo(Services), [2, 3, 4]);