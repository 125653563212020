import { takeLatest } from "redux-saga/effects";
import changeServiceActivityWorker from "./changeServiceActivityWorker";
import createServiceWorker from "./createServiceWorker";
import deleteServiceWorker from "./deleteServiceWorker";
import getAttendanceStatisticWorker from "./getAttendanceStatisticWorker";
import getServicesListWorker from "./getServicesListWorker";
import getServiceWorker from "./getServiceWorker";
import updateServiceWorker from "./updateServiceWorker";
import uploadServiceImagesWorker from "./uploadServiceImagesWorker";

export function* servicesWatcher() {
    yield takeLatest("services/getServicesListFetch", getServicesListWorker);
    yield takeLatest("services/getShortServicesListFetch", getServicesListWorker);
    yield takeLatest("services/getServiceFetch", getServiceWorker);
    yield takeLatest("services/createServicesFetch", createServiceWorker);
    yield takeLatest("services/updateServicesFetch", updateServiceWorker);
    yield takeLatest("services/deleteServiceFetch", deleteServiceWorker);
    yield takeLatest("services/getAttendanceStatisticFetch", getAttendanceStatisticWorker);
    yield takeLatest("services/changeServiceActivityFetch", changeServiceActivityWorker);
    yield takeLatest("services/uploadServiceImagesFetch", uploadServiceImagesWorker);
}