import { PayloadAction } from "@reduxjs/toolkit";
import { ClientsState } from "../../models/ClientsState";
import { UpdateClientDiscountSuccessPayload } from "../../payload/UpdateClientDiscount/UpdateClientDiscountSuccessPayload";

const updateClientDiscountSuccess = (state: ClientsState, action: PayloadAction<UpdateClientDiscountSuccessPayload>) => {
    const { clientId, discountId } = action.payload;

    const clients = state.pageData;
    const indexClient = clients.findIndex(client => client.clientId === clientId);
    if (indexClient > -1) {
        const client = clients[indexClient];

        client.discountId = discountId;
        client.updated = true;
    }

    state.isLoading = false;
    state.isError = null;
}

export default updateClientDiscountSuccess;