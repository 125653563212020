import React, { FC } from "react";
import ParametersBlock from "../../../../../components/dataDisplay/ParametersBlock/ParametersBlock";
import ParameterDisplayer from "../../../../../components/layout/ParameterDisplayer/ParameterDisplayer";
import Wrp from "../../../../../components/layout/Wrp/Wrp";
import { GeneralData } from "../../../../../store/state/groups/models/Group";

interface Props {
    statistics: GeneralData;
}

const GroupStatistics: FC<Props> = ({ statistics }) => {
    const plannedMoney = statistics.money?.planned || 0;
    const actualMoney = statistics.money?.actual || 0;
    const indebtednessMoney = plannedMoney - actualMoney;
    const total = statistics.lessons?.total || 0;
    const completed = statistics.lessons?.completed || 0;
    const completedLessons = total - completed;
    
    return (
        <ParametersBlock flexDirection="row" marginBottom={30} maxWidth={1280}>
            <ParameterDisplayer
                title={"Запланований прихід"}
                param={`₴ ${plannedMoney.toFixed(1)}`}
                marginRight={20}
            />
            <ParameterDisplayer
                title={"Фактичні оплати"}
                param={`₴ ${actualMoney.toFixed(1)}`}
                marginRight={20}
            />
            <ParameterDisplayer
                title={"Заборгованість"}
                param={`₴ ${indebtednessMoney.toFixed(1)}`}
                marginRight={20}
            />
            <ParameterDisplayer
                title={"Залишилось занять"}
                param={`${completedLessons}`}
            />
        </ParametersBlock>
    )
}

export default GroupStatistics;