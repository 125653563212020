import { PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { Locations } from "../../../../services/reddyApi/Locations/Locations";
import { UploadLocationImagesResponse } from "../../../../services/reddyApi/Locations/Locations.response";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { deleteLocationImages, uploadLocationImagesFetch, uploadLocationImagesSuccess } from "../locations.slice";
import { DeleteLocationImagesPayload } from "../payload/DeleteLocationImages/DeleteLocationImagesPayload";
import { UploadLocationImagesPayload } from "../payload/UploadLocationImages/UploadLocationImagesPayload";

type Payload = UploadLocationImagesPayload & DeleteLocationImagesPayload;

function* uploadLocationImagesWorker(action: PayloadAction<Payload>) {
    const { files, links, locationId, vendorId } = action.payload;

    try {
        if (files.length > 0) {
            const { status, data }: AxiosResponse<UploadLocationImagesResponse> =
                yield call(Locations.uploadLocationImages, { files, locationId, vendorId });

            if (status === 200) {
                const linksImeges = data.links;

                yield put(uploadLocationImagesSuccess({ links: linksImeges, locationId }));
            }
        }

        if (links.length > 0) {
            const { status } = yield call(Locations.deleteLocationImages, { links, locationId, vendorId });

            if (status === 200) {
                yield put(deleteLocationImages({ locationId, links }));
            }
        }

        yield getNotifications(vendorId);

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => uploadLocationImagesFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default uploadLocationImagesWorker;