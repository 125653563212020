import { Config } from "../../store/state/configuration/models/Config";

export const configAdapter = (configTypes: Config | undefined, configTypeId: number): string | null => {
    if(!configTypes) {
        return null;
    }

    const keys = Object.keys(configTypes);

    for (let key of keys) {
        const configType = configTypes[Number(key)]
        if(configType.id === configTypeId)
            return configType.localeName.ua;
    }

    return null;
}