import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Money } from "../../../../services/reddyApi/Money/Money";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { searchCounteragentsFetch, searchCounteragentsSuccess } from "../finance.slice";
import { SearchCounteragentsPayload } from "../payload/SearchCounteragents/SearchCounteragentsPayload";

function* searchCounteragentsWorker(action: PayloadAction<SearchCounteragentsPayload>) {
    const { vendorId, value } = action.payload;
    const dataParams = {
        vendorId,
        value
    }

    try {
        const { data } = yield call(Money.searchCounteragents, dataParams);

        yield put(searchCounteragentsSuccess(data));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => searchCounteragentsFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}
export default searchCounteragentsWorker;