import React, { FC, MouseEvent } from "react";
import { FormikProps } from "formik";
import ModalForm from "../../../../components/dataEntry/forms/ModalForm/ModalForm";
import Row from "../../../../components/layout/Row/Row";
import Column from "../../../../components/layout/Column/Column";
import Field from "../../../../components/dataEntry/inputs/Field/Field";
import Button from "../../../../components/general/buttons/Button/Button";
import { VendorsValues } from "../../models/VendorsValues";

interface Props {
    formik: FormikProps<VendorsValues>
    active: boolean;
    closeModalHandler: () => void;
}

const VendorsModal: FC<Props> = ({ formik, active, closeModalHandler }) => {
    const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit } = formik;

    const clickSubmitButtonHandler = (event: MouseEvent<HTMLFormElement>) => {
        handleSubmit(event);
    }

    return (
        <ModalForm
            title="Надати доступ до кабінету"
            visiable={active}
            maxWidth={490}
            handleClick={closeModalHandler}
        >
            <Row alignItems="flex-end">
                <Column width={"50%"}>
                    <Field
                        value={values.confirmedFrom}
                        handleChange={handleChange("confirmedFrom")}
                        handleBlur={handleBlur("confirmedFrom")}
                        error={errors.confirmedFrom}
                        touched={touched.confirmedFrom}
                        label="Оберіть період"
                        maxWidth={215}
                        marginLeft={0}
                        marginBottom={30}
                        type="date"
                    />
                </Column>
                <Column width={"50%"} alignItems="flex-end">
                    <Field
                        value={values.confirmedTo}
                        handleChange={handleChange("confirmedTo")}
                        handleBlur={handleBlur("confirmedTo")}
                        error={errors.confirmedTo}
                        touched={touched.confirmedTo}
                        maxWidth={215}
                        marginRight={0}
                        marginBottom={30}
                        type="date"
                    />
                </Column>
            </Row>
            <Row justifyContent="center">
                <Button
                    disabled={!isValid}
                    title="Зберегти"
                    backgroundColor="#FA5D54"
                    handleClick={clickSubmitButtonHandler}
                />
            </Row>
        </ModalForm>
    )
}

export default VendorsModal;