import React, { FC } from "react";
import Row from "../../../../components/layout/Row/Row";
import Wrp from "../../../../components/layout/Wrp/Wrp";
import CreateClientBlock from "./CreateClientBlock/CreateClientBlock";
import ButtonSetVistStudentForLesson from "./GroupClientsForms/SetStudentsVisitForLesson/Button";
import SetStudentsVisitForLessonContainer from "./GroupClientsForms/SetStudentsVisitForLesson/SetStudentsVisitForLesson.container";
import GroupStatisticsContainer from "./GroupStatistics/GroupStatistics.container";
import GroupTableActiveClientsContainer from "./GroupTables/GroupTableActiveClients.container";

interface Props {
    amountStudents: number;
}

const GroupClients: FC<Props> = ({ amountStudents }) => {
    return (
        <Wrp maxWidth={1280} flexDirection="column">
            <>
                {amountStudents > 0 && <GroupStatisticsContainer />}
                <Row>
                    <>
                        {amountStudents > 0 && <ButtonSetVistStudentForLesson />}
                        <CreateClientBlock />
                    </>
                </Row>
                {amountStudents > 0 && <GroupTableActiveClientsContainer />}
                {amountStudents > 0 && <SetStudentsVisitForLessonContainer />}
            </>
        </Wrp>
    )
}

export default GroupClients;