import { takeLatest } from "redux-saga/effects";
import addStudentToGroupWorker from "./addStudentToGroupWorker";
import changeGroupActivityWorker from "./changeGroupActivityWorker";
import createGroupWorker from "./createGroupWorker";
import deleteGroupWorker from "./deleteGroupWorker";
import deleteStudentForGroupWorker from "./deleteStudentForGroupWorker";
import getGroupGeneralDataWorker from "./getGroupGeneralDataWorker";
import getGroupsListForVendorWorker from "./getGroupsListForVendorWorker";
import getGroupWorker from "./getGroupWorker";
import getSelectedServiceWorker from "./getSelectedServiceWorker";
import getStudentsListForGroupWorker from "./getStudentsListForGroupWorker";
import searchClientsWorker from "./searchClientsWorker";
import searchGroupsByFilterWorker from "./searchGroupsByFilterWorker";
import setStudentsVisitForLessonWorker from "./setStudentsVisitForLessonWorker";
import updateGroupWorker from "./updateGroupWorker";
import updateStudentForGroupWorker from "./updateStudentForGroupWorker";

export function* groupsWatcher() {
    yield takeLatest("groups/searchGroupsByFilterFetch", searchGroupsByFilterWorker);
    yield takeLatest("groups/getGroupsListForVendorFetch", getGroupsListForVendorWorker);
    yield takeLatest("groups/createGroupFetch", createGroupWorker);
    yield takeLatest("groups/updateGroupFetch", updateGroupWorker);
    yield takeLatest("groups/getSelectedServiceFetch", getSelectedServiceWorker);
    yield takeLatest("groups/searchClientsFetch", searchClientsWorker);
    yield takeLatest("groups/getGroupGeneralDataFetch", getGroupGeneralDataWorker);
    yield takeLatest("groups/getStudentsListForGroupFetch", getStudentsListForGroupWorker);
    yield takeLatest("groups/addStudentToGroupFetch", addStudentToGroupWorker);
    yield takeLatest("groups/updateStudentForGroupFetch", updateStudentForGroupWorker);
    yield takeLatest("groups/getGroupFetch", getGroupWorker);
    yield takeLatest("groups/setStudentsVisitForLessonFetch", setStudentsVisitForLessonWorker);
    yield takeLatest("groups/deleteGroupFetch", deleteGroupWorker);
    yield takeLatest("groups/deleteStudentForGroupFetch", deleteStudentForGroupWorker);
    yield takeLatest("groups/changeGroupActivityFetch", changeGroupActivityWorker);
}