import React, { FC } from "react";
import { CSSTransition } from "react-transition-group";
import ButtonIcon from "../../../general/buttons/ButtonIcon/ButtonIcon";
import CloseIcon from "../../../general/icons/CloseIcon/CloseIcon";
import Title from "../../../general/typography/Title/Title";
import styles from "./ErrorMessage.module.css";
import "./ErrorMessage.css";
import Paragraph from "../../../general/typography/Paragraph/Paragraph";
import Row from "../../../layout/Row/Row";

interface Props {
    title?: string;
    maxWidth?: number;
    width?: number | string;
    visiable: boolean;
    handleClick: () => void;
    top?: number | string;
    content: string;
}

const ErrorMessage: FC<Props> = ({ visiable, maxWidth, width, handleClick, title, top, content }) => {
    return (
        <CSSTransition
            in={visiable}
            timeout={200}
            classNames={"error-message"}
            unmountOnExit
        >
            <div style={{top, maxWidth, width}} className={styles.errorMessage}>
                <Row 
                    justifyContent="space-between" 
                    alignItems="center" 
                    marginBottom={20}
                >
                    <>
                        {title && <Title title={title} level={5} />}
                        <ButtonIcon icon={<CloseIcon />} handleClick={handleClick} />
                    </>
                </Row>

                <Paragraph content={content} />
            </div>
        </CSSTransition>
    );
}

export default ErrorMessage;