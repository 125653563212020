import { Location } from "../../store/state/locations/models/Location";

export const locationNameAdapter = (locations: Location[], locationsId: number): string => {
    let locationName = "none"
    for(let location of locations) {
        if(location.id === locationsId) {
            locationName = location.name;
            break;
        }
    }

    return locationName;
}