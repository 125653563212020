import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { DateApi } from "../../../../services/Date/Date";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import ParametersMoneyBlock from "./ParametersMoneyBlock";

const ParametersMoneyBlockContainer: FC = () => {
    const planned = useAppSelector(state => state.finance.finance.planned, shallowEqual);
    const today = new Date();
    const period = `за ${DateApi.getMothUAName(today.getMonth())} ${today.getFullYear()}р.`;

    return (
        <ParametersMoneyBlock
            period={period}
            planned={planned}
        />
    )
}

export default React.memo(ParametersMoneyBlockContainer);