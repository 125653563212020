import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import { getNotificationsFetch, NotificationStatuses, setNotifications, setNotificationsFilter } from "../../../store/state/notifications/notifications.slice";
import { configListAdapter } from "../../../utl/adapters/ConfigListAdapter";
import { Filter } from "../../../utl/models/Filters";
import TabPanelFilter, { PanelItem } from "./TabPanelFilter";

const TypeFilter: FC = () => {
    const types = useAppSelector(state => state.config.vendorConfig?.notification.types, shallowEqual);
    const typesList = configListAdapter(types);
    
    const panelList: PanelItem[] = typesList.map(type => ({
        name: type.name,
        value: String(type.id),
    }));
    panelList.unshift({
        name: "Всі",
        value: "undefined"
    });

    const typeId = useAppSelector(state => state.notifications.filter.typeId, shallowEqual);
    const value = String(typeId);

    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const dispatch = useAppDispatch();

    const handleClick = (typeId: string) => {
        let filter: Filter = {};

        if (typeId.localeCompare('undefined') !== 0) {
            const typeIdValue = parseInt(typeId);

            filter.typeId = typeIdValue;

            dispatch(setNotificationsFilter({ typeId: typeIdValue }));
        } else {
            dispatch(setNotificationsFilter({ typeId: undefined }));
        }

        dispatch(setNotifications({ active: [], archive: [], selected: [] }));
        dispatch(getNotificationsFetch({ vendorId, filters: {...filter, statusId: NotificationStatuses.ACTIVE} }));
        dispatch(getNotificationsFetch({ vendorId, filters: {...filter, statusId: NotificationStatuses.ARCHIVE} }));
        dispatch(getNotificationsFetch({ vendorId, filters: {...filter, statusId: NotificationStatuses.SELECTED} }));
    }

    return (
        <TabPanelFilter
            panelList={panelList}
            value={value}
            handleClick={handleClick}
        />
    );
}

export default TypeFilter;