import { PayloadAction } from "@reduxjs/toolkit";
import { GroupsState } from "../../models/GroupsState";
import { GetStudentsListForGroupSuccessPayload } from "../../payload/GetStudentsListForGroup/GetStudentsListForGroupSuccessPayload";

const getStudentsListForGroupSuccess = (state: GroupsState, action: PayloadAction<GetStudentsListForGroupSuccessPayload>) => {
    const { groupId, students, filter } = action.payload;

    const groups = state.pageData;
    const groupIndex = groups.findIndex(group => group.id === groupId);
    if (groupIndex > -1) {
        const group = groups[groupIndex];

        group.students = [...students];
    }

    state.isLoading = false;
    state.isError = null;
}

export default getStudentsListForGroupSuccess;