import React, { FC } from "react";
import styles from "./Header.module.css";

interface Props {
    left?: JSX.Element[] | JSX.Element;
    right?: JSX.Element[] | JSX.Element
    widthLeft?: number | string;
    widthRight?: number | string;
}

const Header: FC<Props> = ({left = <></>, right = <></>, widthLeft = "50%", widthRight = "50%"}) => {
    return (
        <header className={styles.header}>
            <div className={styles.leftCol} style={{width: widthLeft}}>
                {left}
            </div>
            <div className={styles.rightCol} style={{width: widthRight}}>
                {right}
            </div>
        </header>
    );
}

export default Header;