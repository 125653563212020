import { useFormik } from "formik";
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import { createGroupFetch, setNewItemId } from "../../../../store/state/groups/groups.slice";
import { SUBMIT_ACTIONS } from "../../../../utl/constants/submitActions";
import { convertStringToArray } from "../../../../utl/helpers/convertStringToArray";
import useRedirectToNewItemPage from "../../../../utl/hooks/useRedirectToNewItemPage";
import useSubmitAction from "../../../../utl/hooks/useSubmitAction";
import { GroupsValue } from "../../models/GroupsValue";
import validationSchema from "./Group.scheme";
import GroupSettings from "./GroupSettings";

const GroupContainer: FC = () => {
    const dispatch = useAppDispatch();
    const newItemId = useAppSelector(state => state.groups.newItemId, shallowEqual);
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const payload = {
        newItemId,
        setNewItemId: (id: number | null) => {
            dispatch(setNewItemId({ id }));
        }
    }

    useRedirectToNewItemPage(payload);

    const initialValues: GroupsValue = {
        serviceId: -1,
        employeesIds: "",
        name: "",
        locationId: -1,
        studentsMin: 0,
        studentsMax: 0,
        studentsAmount: 0,
        activeStudentsAmount: 0,
        price: 0,
        paymentParts: [{ date: "", sum: 0 }],
        notificable: false,
        notificationBeforeLesson: {
            days: 0,
            time: ""
        },
        lessonWeekDays: "",
        startDate: "",
        endDate: "",
        lessons: [],
        upsellsIds: "",
        active: true,
        online: false,
        confirmed: false,
        submitAction: SUBMIT_ACTIONS.SAVE,
    }

    const submitActionHandler = useSubmitAction();

    const submitFormHandler = (values: GroupsValue) => {
        const {
            submitAction, name, serviceId, employeesIds, startDate, locationId, studentsMin,
            studentsMax, price, paymentParts, notificable, notificationBeforeLesson, lessonWeekDays,
            lessons, upsellsIds, active, online, endDate, confirmed, activeStudentsAmount, studentsAmount,
        } = values;

        const upsellsIdsArray = convertStringToArray(upsellsIds);

        const lessonWeekDaysArray = convertStringToArray(lessonWeekDays);

        const employeesIdsArray = convertStringToArray(employeesIds);

        const lessonsDatimeFormat = lessons.map(lesson => ({
            id: lesson.id,
            name: lesson.name,
            nameForTeacher: lesson.nameForTeacher,
            datetime: `${lesson.date} ${lesson.time}`,
            durationInHours: lesson.durationInHours,
        }));

        submitActionHandler(
            submitAction, () => createGroupFetch(
                {
                    vendorId,
                    group: {
                        name,
                        serviceId,
                        employeesIds: employeesIdsArray,
                        startDate,
                        endDate,
                        locationId,
                        studentsMin,
                        studentsMax,
                        studentsAmount,
                        activeStudentsAmount,
                        price,
                        paymentParts,
                        notificable,
                        notificationBeforeLesson,
                        lessonWeekDays: lessonWeekDaysArray,
                        lessons: lessonsDatimeFormat,
                        upsellsIds: upsellsIdsArray,
                        active,
                        confirmed,
                        online,
                    }
                }
            )
        );
    }

    const formik = useFormik<GroupsValue>({
        initialValues,
        validationSchema,
        onSubmit: submitFormHandler
    });

    return (
        <GroupSettings
            formik={formik}
            isNewItemForm={true}
        />
    );
}

export default GroupContainer;