import { put } from "redux-saga/effects";
import { getNotificationsFetch, NotificationStatuses } from "../notifications.slice";

function* getNotifications(vendorId: number) {
    yield put(getNotificationsFetch({
        vendorId, filters: {
            statusId: NotificationStatuses.ACTIVE
        }
    }));
}

export default getNotifications