import React, { FC, MouseEvent } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { getGroupsListForVendorFetch, setPageGroups } from "../../store/state/groups/groups.slice";
import culcPageTable from "../../utl/helpers/culcPageTable";
import usePagination from "../../utl/hooks/usePagination";
import { Filter } from "../../utl/models/Filters";
import Groups from "./Gropups";
import { GroupsColumn } from "./models/GroupsColumn";

interface Props {
    data: GroupsColumn[];
    handleClickRow: (item: any, event: MouseEvent<HTMLTableRowElement>) => void;
    vendorId: number;
}

const GroupsPagination: FC<Props> = ({ vendorId, handleClickRow, data }) => {
    const dispatch = useAppDispatch();

    const typeId = useAppSelector(state => state.groups.filter.typeId, shallowEqual);
    const categoryId = useAppSelector(state => state.groups.filter.categoryId, shallowEqual);
    const confirmed = useAppSelector(state => state.groups.filter.confirmed, shallowEqual);
    const active = useAppSelector(state => state.groups.filter.active, shallowEqual);

    const page = useAppSelector(state => state.groups.page, shallowEqual);
    const totalCount = useAppSelector(state => state.groups.totalCount, shallowEqual);
    const pageAll = useAppSelector(state => state.groups.pageAll, shallowEqual);
    const pageSize = useAppSelector(state => state.groups.pageSize, shallowEqual);
    const getAllItems = useAppSelector(state => state.groups.getAllItems, shallowEqual);

    const setPage = (filter: Filter | undefined, pageNumber: number) => {
        dispatch(getGroupsListForVendorFetch({ vendorId, filter }));
        dispatch(setPageGroups({ page: pageNumber }));
    };

    const pagination = usePagination({
        getAllItems,
        pageAll,
        page,
        totalCount,
        pageSize,
        setPage,
        typeId,
        categoryId,
        confirmed,
        active,
    });

    const pagesRange = pagination.pagesRange;

    const pageTable = culcPageTable<GroupsColumn>(data, pagesRange.from, pagesRange.to);

    return (
        <Groups
            handleClickRow={handleClickRow}
            data={pageTable}
            page={page}
            pages={pagination.pages}
            handleRightButtonPagination={pagination.handleRightButtonPagination}
            handleLeftButtonPagination={pagination.handleLeftButtonPagination}
        />
    );
}

export default React.memo(GroupsPagination);