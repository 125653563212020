import { PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { Services } from "../../../../../services/reddyApi/Services/Services";
import { GetServicesListResponse } from "../../../../../services/reddyApi/Services/Services.response";
import handleServerError from "../../../all/saga/handleServerError";
import setServerErrorDefault from "../../../all/saga/setServerErrorDefault";
import { GetServicesListPayload } from "../payload/GetServicesList/GetServicesListPayload";
import {
    getServicesListFetch,
    getServicesListSuccess,
    getShortServicesListSuccess,
    setPageDataServices
} from "../service.slice";

function* getServicesListWorker(action: PayloadAction<GetServicesListPayload>) {
    const { vendorId, filter } = action.payload;

    try {
        const { data }: AxiosResponse<GetServicesListResponse> = yield call(Services.getServicesList, { vendorId, filter });
        if (filter?.short) {
            yield put(getShortServicesListSuccess({ ...data }));
        } else {
            const { totalCount, items } = data;

            yield put(getServicesListSuccess({ totalCount, items, filter }));

            yield put(setPageDataServices({ services: [...items] }));
        }

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getServicesListFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getServicesListWorker;