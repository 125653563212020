import { PayloadAction } from "@reduxjs/toolkit";
import getItems from "../../../../helpers/getItems";
import { Service } from "../../../services/service/models/Service";
import { GetServicesListSuccessPayload } from "../../../services/service/payload/GetServicesList/GetServicesListSuccessPayload";
import { DashboardState } from "../../models/DashboardState";

const defaultServiceItem: Service = {
    id: -1,
    upsellsIds: [],
    typeId: -1,
    categoryId: -1,
    subCategoryId: -1,
    durationTypeId: -1,
    duration: 0,
    active: false,
    name: "Service",
    description: "",
    locationsIds: [],
    lessons: [],
    fullness: 0,
    confirmed: false,
}

const getActiveServicesListSuccess = (state: DashboardState, action: PayloadAction<GetServicesListSuccessPayload>) => {
    const { items, totalCount, filter } = action.payload;

    const services = state.activeSrvicePage.activeSrvices;

    state.activeSrvicePage.activeSrvices = getItems(
        state.activeSrvicePage,
        services,
        items,
        totalCount,
        defaultServiceItem,
        filter
    );

    state.isLoading = false;
    state.isError = null;
}

export default getActiveServicesListSuccess;