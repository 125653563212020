interface Item {
    id?: number;
    clientId?: number;
}

function updateItem<T extends Item>(items: T[], data: T) {
    const index = items.findIndex(item => {
        if (item.id !== undefined && data.id !== undefined) {
            return item.id === data.id;
        } else if (item.clientId !== undefined && data.clientId !== undefined) {
            return item.clientId === data.clientId;
        }

        return false;
    });

    if (index > -1) {
        items[index] = { ...items[index], ...data };
    }
}

export default updateItem;