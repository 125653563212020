import { Teacher } from "../../store/state/employees/models/Teacher";

export const teacherNameAdapter = (teachers: Teacher[], teacherId: number): string => {
    let teacherName = "none"
    for(let teacher of teachers) {
        if(teacher.employeeId === teacherId) {
            teacherName = teacher.fullname;
            break;
        }
    }

    return teacherName;
}