interface IFile {
    getExtension: (filename: string) => string;
    isImage: (filename: string) => boolean
}

export const File: IFile = {
    getExtension: (filename) => {
        let parts = filename.split('.');
        return parts[parts.length - 1];
    },
    isImage: (filename) => {
        var ext = File.getExtension(filename);
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'png':
                return true;
            default :
                return false 
        }
    }
}