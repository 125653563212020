import { ResponseUserData, ResSearchUsersForVendor } from './User.response';
import instance from "../instance"
import { AxiosResponse } from 'axios';
import { RegistretionVendorRequest, SearchUsersForVendorRequest } from './User.request';

interface User {
    getStatusUser: () => Promise<AxiosResponse<ResponseUserData, any>>;
    registerVendor: (vendor: RegistretionVendorRequest) => Promise<AxiosResponse>;
    searchUsersForVendor: (data: SearchUsersForVendorRequest) => Promise<AxiosResponse<ResSearchUsersForVendor>>
}

export const User: User = {
    getStatusUser: () => {
        return instance.get("/auth/user/status");
    },
    registerVendor: (vendor) => {
        const {email, tel, vendorName, name, password} = vendor;
        return instance.post("/vendors", {
            company: vendorName, 
            fullname: name, 
            email,
            phone: tel, 
            password
        });
    },
    searchUsersForVendor: (data) => {
        const {vendorId, value, roleId} = data;
        
        return instance.post(`/vendors/${vendorId}/users/search`, {value, roleId});
    }
}