import Token from '../../Token/token';
import { ResponseLoginUser } from './Authorization.response';
import instance from '../instance';
import { AxiosResponse } from 'axios';

interface Auth {
    loginUser: (email: string, password: string) => Promise<AxiosResponse<ResponseLoginUser, any>>;
    exitUser: () => void;
    setRefreshToken: () => Promise<AxiosResponse<ResponseLoginUser, any>>;
    forgotPassword: (email: string) => Promise<AxiosResponse<any>>;
    resetPassword: (email: string, password: string, resetToken: string) => Promise<AxiosResponse<ResponseLoginUser, any>>;
}

export const Auth: Auth = {
    loginUser: async (email, password) => {
        return instance.post("/auth/login", {email, password});
    },
    exitUser: () => {
        localStorage.clear();
    },
    setRefreshToken: async () => {
        const refreshToken = Token.getRefreshToken();
        
        return instance.post("/auth/refresh", {refreshToken});
    },
    forgotPassword: async (email) => {
        return instance.post("/auth/password/forgot", {email});
    },
    resetPassword: async (email, password, resetToken) => {
        return instance.post("/auth/password/reset", {email, password, resetToken});
    }
}