import { PayloadAction } from "@reduxjs/toolkit";
import createItem from "../../../../../helpers/createItem";
import { UpsellState } from "../../models/UpsellsState";
import { CreateUpsellSuccessPayload } from "../../payload/CreateUpsell/CreateUpsellSuccessPayload";

const createAdditionalSuccess = (state: UpsellState, action: PayloadAction<CreateUpsellSuccessPayload>) => {
    const upsell = action.payload;

    const upsells = state.upsells;
    createItem(state, upsells, upsell);

    state.message = "Додаткову послугу створено"
    state.messageVisiable = true;
    state.isLoading = false;
    state.isError = null;
    state.newItemId = upsell.id;
}

export default createAdditionalSuccess;