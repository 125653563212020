import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Groups } from "../../../../services/reddyApi/Groups/Groups";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { 
    getStatisticsClient, 
    getStatisticsDashboard, 
    getStatisticsFinance, 
    getStatisticsGroup 
} from "../../statistics/statistics.saga";
import { 
    getStudentsListForGroupFetch, 
    setStudentsVisitForLessonFetch, 
    setStudentsVisitForLessonSuccess 
} from "../groups.slice";
import { SetStudentsVisitForLessonPayload } from "../payload/SetStudentsVisitForLesson/SetStudentsVisitForLessonPayload";

function* setStudentsVisitForLessonWorker(action: PayloadAction<SetStudentsVisitForLessonPayload>) {
    const { vendorId, groupId, lessonId, students, filter, clientsIds, employeesIds } = action.payload;

    try {
        const { data } = yield call(Groups.setStudentsVisitForLesson, { vendorId, groupId, lessonId, students, employeesIds });

        yield put(setStudentsVisitForLessonSuccess({ groupId, lessonId, students }));

        yield getStatisticsGroup({ vendorId, groupId });

        yield getStatisticsDashboard({ vendorId, filter });

        yield getStatisticsFinance({ vendorId, filter });

        yield put(getStudentsListForGroupFetch({ vendorId, groupId, filter }));

        for (let clientId of clientsIds) {
            yield getStatisticsClient({ vendorId, clientId });
        }

        yield getNotifications(vendorId);

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => setStudentsVisitForLessonFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default setStudentsVisitForLessonWorker;