import React, { ChangeEvent, FC, useState } from "react";
import { File } from "../../../../services/File/File";
import Title from "../../../general/typography/Title/Title";
import Wrp from "../../../layout/Wrp/Wrp";
import FileFinder from "../FileFinder/FileFinder";

interface Props {
    imgs: (string | undefined)[];
    title?: string;
    titleVisiable?: boolean;
    acceptFile?: string;
    firstSize?: number;
    allSize?: number;
    borderRadius?: number;
    maxWidth?: number;
    iconSmall?: boolean;
    disabled?: boolean;
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    files?: (File | undefined)[];
    links?: (string | undefined)[];
    fieldFile?: string;
    fieldImage?: string;
    fieldLink?: string;
    withDoubleButton?: boolean;
}

const Gallery: FC<Props> = ({ 
    imgs, 
    title = "Галерея", 
    titleVisiable = true, 
    acceptFile, 
    firstSize = 250,
    allSize = 100, 
    borderRadius, 
    maxWidth = 235, 
    iconSmall, 
    disabled = true, 
    setFieldValue, 
    files, 
    links,
    fieldFile = "files",
    fieldImage = "images",
    fieldLink = "links",
    withDoubleButton
}) => {

    const galleryItems = imgs.map((item, index) => {
        let isImage = false;

        const changeFileHandler = (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target && event.target.files && event.target.files[0]) {
                const file = event.target.files[0];
                isImage = File.isImage(file.name);
                const url = String(URL.createObjectURL(file));
                
                if (setFieldValue && files) {
                    const filesGallery = [...files];
                    filesGallery[index] = file;
                    setFieldValue(fieldFile, filesGallery);

                    if (links) {
                        const linksResetImage = [...links];
                        linksResetImage.push(typeof item === "string" ? item : undefined);
                        setFieldValue(fieldLink, linksResetImage);
                    }

                    const imagesGallery = [...imgs];
                    imagesGallery[index] = url;
                    setFieldValue(fieldImage, imagesGallery);
                }
            }
        }

        const remoneFileHandler = () => {
            if (setFieldValue && files) {
                const filesGallery = [...files];
                filesGallery[index] = undefined;
                setFieldValue("files", filesGallery);

                if (links) {
                    const linksResetImage = [...links];
                    linksResetImage.push(typeof item === "string" ? item : undefined);
                    setFieldValue("links", linksResetImage);
                }

                const imagesGallery = [...imgs];
                imagesGallery[index] = undefined;
                setFieldValue("images", imagesGallery);
            }
        }

        return (
            <Wrp marginRight={10} maxWidth={index === 0 ? firstSize : allSize}>
                <FileFinder
                    borderRadius={borderRadius}
                    iconSmall={iconSmall}
                    acceptFile={acceptFile}
                    isFile={isImage}
                    changeFileHandler={changeFileHandler}
                    isDisabled={disabled && index !== 0 && !imgs[index - 1] && !imgs[index]}
                    width={index === 0 ? firstSize : allSize}
                    height={index === 0 ? firstSize : allSize}
                    img={item}
                    handleRemove={remoneFileHandler}
                    withDoubleButton={withDoubleButton}
                />
            </Wrp>);
    });

    return (
        <Wrp maxWidth={maxWidth} flexDirection="column">
            <>{titleVisiable && <Title level={6} marginBottom={5} title={title} color="#A6AEC5" fontWeight="500" />}</>
            <Wrp alignItems="flex-start" justifyContent="space-between">
                {galleryItems}
            </Wrp>
        </Wrp>
    );
}

export default Gallery;