import classNames from "classnames";
import { FormikErrors, FormikTouched } from "formik";
import React, { FC } from "react";
import Field from "../../../../../components/dataEntry/inputs/Field/Field";
import ArrayFields from "../../../../../components/dataEntry/inputs/ListFields/ArrayFields";
import { ContextValue } from "../../../../../components/dataEntry/inputs/ListFields/models/ContextValue";
import ButtonIcon from "../../../../../components/general/buttons/ButtonIcon/ButtonIcon";
import IconFont from "../../../../../components/general/icons/IconFont/IconFont";
import { SalaryForServices } from "../../../../../store/state/employees/models/SalaryForServices";
import ServicesDropdown from "../../../../Groups/Group/GroupSettings/components/ServicesDropdown/ServicesDropdown";

interface Props {
    salaryForServices: SalaryForServices[];
    error: string | string[] | FormikErrors<SalaryForServices>[] | undefined;
    touched: FormikTouched<SalaryForServices>[] | undefined;
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    handleBlur: {
        (e: React.FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    }
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
}

const SalaryForServicesList: FC<Props> = ({
    salaryForServices,
    error,
    touched,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue
}) => {
    const name = "salaryForServices";

    const salaryForServicesItems = (context: ContextValue) => salaryForServices && salaryForServices.map((salaryForService, index) => {
        const clickButtonIconHandler = () => {
            if (index === 0) {
                context.push({ date: "", sum: 0 });
            } else {
                context.remove(index);
            }
        }

        const cx = classNames.bind(context.styles);

        const touchedSalaryForService = touched;
        const errorsSalaryForService = error;

        let serviceIdError = errorsSalaryForService && Array.isArray(errorsSalaryForService) && errorsSalaryForService[index];
        if (typeof serviceIdError === "object") {
            serviceIdError = serviceIdError?.serviceId;
        }

        let salaryError = errorsSalaryForService && Array.isArray(errorsSalaryForService) && errorsSalaryForService[index];
        if (typeof salaryError === "object") {
            salaryError = salaryError?.salary;
        }

        const serviceId = salaryForService.serviceId;
       
        const price = salaryForService.salary;

        return (
            <li key={"SALARY_FOR_SERVICES_" + index} className={context?.styles.listItem}>
                <ServicesDropdown
                    fieldName={`${name}[${index}].serviceId`}
                    serviceId={serviceId}
                    error={(serviceIdError && String(serviceIdError)) || undefined}
                    touched={(touchedSalaryForService && touchedSalaryForService[index]?.serviceId) || undefined}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    maxWidth={400}
                />

                <Field
                    handleChange={handleChange(`${name}[${index}].salary`)}
                    handleBlur={handleBlur(`${name}[${index}].salary`)}
                    error={(salaryError && String(salaryError)) || undefined}
                    touched={(touchedSalaryForService && touchedSalaryForService[index]?.salary) || undefined}
                    maxWidth={160}
                    marginBottom={0}
                    marginRight={0}
                    value={price}
                    topPositionIcon={10}
                    paddingLeft={35}
                    icon={<span style={{ fontSize: 14, fontWeight: "500" }}>₴</span>}
                    button={
                        <ButtonIcon
                            width={25}
                            height={25}
                            icon={
                                <div className={cx(index > 0 && context?.styles.rotate)}>
                                    <IconFont color="#FFF" iconClass="icon-plus" fontSize={12} />
                                </div>
                            }
                            handleClick={clickButtonIconHandler}
                            backgroundColor="#4775FE"
                            borderRadius="50%"
                        />
                    }
                />
            </li>
        );
    });

    return (
        <ArrayFields
            name={name}
            fields={salaryForServicesItems}
            paginated={true}
            paginationKey="EMPLOYEES_"
        />
    )
}

export default SalaryForServicesList;