import React, { FC } from "react";
import { Link } from "react-router-dom";
import styles from "./LinkText.module.css";

interface Props {
    type: "link" | "email";
    content: string;
    link: string;
}

const LinkText: FC<Props> = ({type, content, link}) => {
    switch (type) {
        case "email" :
        return (
            <a href={`mailto:${link}`} className={styles.link}>
                {content}
            </a>
        );
        default : return (
            <Link to={link} className={styles.link}>
                {content}
            </Link>
        );
    }
}

export default LinkText;