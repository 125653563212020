import React, {FC} from "react";
import styles from "./MessageInfo.module.css";
import {CSSTransition} from "react-transition-group";
import "./transition.css";
import Info from "../../../general/typography/Info/Info";


interface Props {
    text: string;
    visiable: boolean;
    minWidth?: number;
    type?: "string" | "email" | "tel";
    borderTopLeftRadius?: number;
    borderBottomLeftRadius?: number;
    borderBottomRightRadius?: number;
    borderTopRightRadius?: number;
}

const MessageInfo: FC<Props> = ({text, visiable, minWidth, type = "string", borderTopLeftRadius, borderBottomLeftRadius, borderBottomRightRadius, borderTopRightRadius}) => {
    return (
        <CSSTransition in={visiable} timeout={400} unmountOnExit classNames="message">
            <div 
                className={styles.messageBlock} 
                style={{minWidth, borderTopLeftRadius, borderBottomLeftRadius, borderBottomRightRadius, borderTopRightRadius}}
            >
                <Info type={type} text={text} />
            </div>
        </CSSTransition>
    );
}

export default MessageInfo;