import React, { FC } from "react";
import ButtonIcon from "../../general/buttons/ButtonIcon/ButtonIcon";
import IconFont from "../../general/icons/IconFont/IconFont";
import styles from "./InfoList.module.css";

interface Props {
    name: string;
    onClick: () => void;
    width?: number;
    marginBottom?: number; 
}

const NameEdit: FC<Props> = ({ name, onClick, width, marginBottom }) => {
    return (
        <div className={styles.nameBlock} style={{
            width,
            marginBottom
        }}>
            <span>{name}</span>
            <ButtonIcon 
                handleClick={onClick} 
                icon={
                    <IconFont 
                        iconClass="icon-edit" 
                        fontSize={10} 
                        color="#A6AEC5" 
                    />
                } 
            />
        </div>
    );
}

export default NameEdit;