import { PayloadAction } from "@reduxjs/toolkit";
import { ResGetMoneyPurses } from "../../../../../services/reddyApi/Money/Money.response";
import { FinanceState } from "../../models/FinanceState";
import { GetMoneyPursesSuccessPayload } from "../../payload/GetMoneyPurses/GetMoneyPursesSuccessPayload";

const getMoneyPursesSuccess = (state: FinanceState, action: PayloadAction<GetMoneyPursesSuccessPayload>) => {
    const {vendorId, purses} = action.payload;

    state.finance.purses = purses;
    state.isLoading = true;
    state.isError = null;
}

export default getMoneyPursesSuccess;