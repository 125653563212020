import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import Location from "./Location";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { setMessageVisiable } from "../../../../store/state/locations/locations.slice";
import Message from "../../../../components/feedback/messages/Message/Message";
import { useMessageActions } from "../../../../utl/hooks/useMessageActions";
import { FormikProps } from "formik";
import { LocationValues } from "../models/LocationValues";

interface Props {
    formik: FormikProps<LocationValues>;
    searchListCity: string[];
    active: boolean;
} 

const LocationMessage: FC<Props> = ({ formik, searchListCity, active }) => {
    const dispatch = useAppDispatch();

    const message = useAppSelector(state => state.locations.message, shallowEqual);
    const visiable = useAppSelector(state => state.locations.messageVisiable, shallowEqual);

    const setMessage = () => {
        dispatch(setMessageVisiable({visiable: false}));
    }
    
    const visiableActions = useMessageActions(visiable, setMessage);

    return (
        <>
            <Message 
                content={message || ""} 
                visiable={visiableActions} 
                position="top"
            />

            <Location 
                formik={formik} 
                searchListCity={searchListCity}
                active={active}
            />
        </>
    );
}

export default React.memo(LocationMessage);