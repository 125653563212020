import { createSlice } from '@reduxjs/toolkit';
import { ConfigState } from './models/ConfigState';
import setErrorReducer from './reducer/setError';
import getUserConfigFetchReducer from './reducer/getUserConfig/getUserConfigFetch';
import getUserConfigSuccessReducer from './reducer/getUserConfig/getUserConfigSuccess';
import getVendorConfigFetchReducer from './reducer/getVendorConfig/getVendorConfigFetch';
import getVendorConfigSuccessReducer from './reducer/getVendorConfig/getVendorConfigSuccess';
import resetConfigReducer from './reducer/resetConfig';

export const initialState: ConfigState = {
    userConfig: null,
    vendorConfig: null,
    isLoading: false,
    isError: null
}

export const ConfigSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setError: setErrorReducer,
        getUserConfigFetch: getUserConfigFetchReducer,
        getUserConfigSuccess: getUserConfigSuccessReducer,
        getVendorConfigFetch: getVendorConfigFetchReducer,
        getVendorConfigSuccess: getVendorConfigSuccessReducer,
        resetConfig: resetConfigReducer,
    },
});

export const { 
    getUserConfigFetch,
    getVendorConfigFetch,
    getUserConfigSuccess,
    getVendorConfigSuccess,
    setError,
    resetConfig,
} = ConfigSlice.actions;

export default ConfigSlice.reducer;