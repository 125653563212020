import React, { FC } from "react";
import LessonView from "../../../../../../../components/dataDisplay/LessonView/LessonView";
import NameView from "../../../../../../../components/dataDisplay/table/views/NameView/NameView";
import ModalForm from "../../../../../../../components/dataEntry/forms/ModalForm/ModalForm";
import Wrp from "../../../../../../../components/layout/Wrp/Wrp";
import { DateApi } from "../../../../../../../services/Date/Date";
import { LessonsStudent } from "../../../../../../../store/state/groups/models/Group";

interface Props {
    lessons: LessonsStudent[];
    studentName: string;
    parentName: string;
    reletionshipStatus: string;
    handleCloseButton: () => void;
    visiable: boolean;

}

const StudentAttendanceModal: FC<Props> = ({ lessons, studentName, parentName, reletionshipStatus, 
    handleCloseButton, visiable }) => {
    const lessonsItems = lessons.map(lesson => {
        const datetime = DateApi.parseDateTime(lesson.date);
        const date = datetime[0];

        return (
            <LessonView 
                date={DateApi.parseDateStringPrint(date)}
                nameLesson={lesson.name}
                attended={lesson.attended}
            />
        );
    });

    return (
        <ModalForm
            title="Відвідуваність"
            width="auto"
            handleClick={handleCloseButton}
            visiable={visiable}
        >
            <Wrp flexDirection="column">
                <NameView
                    name={studentName}
                    subname={`${reletionshipStatus}: ${parentName}`}
                    marginBottom={20}
                />

                <>{lessonsItems}</>
            </Wrp>
        </ModalForm>
    )
}

export default StudentAttendanceModal;