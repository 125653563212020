import React, { FC } from "react";
import logo from "../../../../assets/svg/logoMini.svg"; 
import styles from "./Logo.module.css";

const Logo: FC = () => {
    return (
        <img src={logo} alt="Logo" className={styles.logo} />
    );
}

export default Logo;