import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../../utl/constants/app';
import { prepareHeaders } from '../../utl/helpers/prepareHeaders';

export interface ITimeRanges {
    from: string;
    to: string;
}

export interface IDaysOfWeek {
    [key: number]: string;
}

export interface ICalendar {
    id: number
    daysOfWeek: IDaysOfWeek;
    allDay: boolean;
    timeRanges: ITimeRanges[];
    serviceId: number;
}

export type TCalendarId = Pick<ICalendar, "id">;

export type TCreateCalendar = Omit<ICalendar, "id">;

export const CalendarTag = "Calendar";

export interface IGetCalendarsListArg {
    vendorId: number;
}

export interface IGetCalendarArg {
    vendorId: number;
    calendarId: number;
}

export interface ICreateCalendarArg {
    vendorId: number;
    body: TCreateCalendar;
}

export interface IEditCalendarArg {
    vendorId: number;
    calendarId: number;
    body: Omit<ICalendar, "id">;
}

export const calendarApi = createApi({
    reducerPath: 'calendarApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/vendors/`,
        prepareHeaders,
        credentials: 'include',
    }),
    tagTypes: [CalendarTag],
    endpoints: (build) => ({
        getCalendarsList: build.query<ICalendar[], IGetCalendarsListArg>({
            query: ({ vendorId }) => `${vendorId}/calendars`,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: CalendarTag, id } as const)),
                        { type: CalendarTag, id: 'LIST' },
                    ]
                    :
                    [{ type: CalendarTag, id: 'LIST' }],
            transformResponse: (response: { calendars: ICalendar[] }) => response.calendars,
        }),
        getCalendar: build.query<ICalendar, IGetCalendarArg>({
            query: ({ vendorId, calendarId }) => `${vendorId}/calendars/${calendarId}`,
            providesTags: (result, error, { vendorId, calendarId }) => [{ type: CalendarTag, vendorId, calendarId }],
            transformResponse: (response: ICalendar) => response,
        }),
        createCalendar: build.mutation<TCalendarId, ICreateCalendarArg>({
            query: ({ body, vendorId }) => {
                return {
                    url: `${vendorId}/calendars`,
                    method: "POST",
                    body,
                }
            },
            invalidatesTags: [{ type: CalendarTag, id: 'LIST' }],
        }),
        editCalendar: build.mutation<ICalendar, IEditCalendarArg>({
            query: ({ body, vendorId, calendarId }) => {
                return {
                    url: `${vendorId}/calendars/${calendarId}`,
                    method: "PUT",
                    body,
                }
            },
            invalidatesTags: (result, error, { vendorId, calendarId }) =>
                result
                    ? [
                        { type: CalendarTag, vendorId },
                        { type: CalendarTag, calendarId },
                        { type: CalendarTag, id: 'LIST' },
                    ]
                    :
                    [{ type: CalendarTag, id: 'LIST' }],
        }),
    }),
});

export const {
    useCreateCalendarMutation,
    useEditCalendarMutation,
    useGetCalendarQuery,
    useGetCalendarsListQuery,
} = calendarApi;