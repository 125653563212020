import { PayloadAction } from "@reduxjs/toolkit";
import updateItem from "../../../../helpers/updateItem";
import { EmployeesState } from "../../models/EmployeesState";
import { UpdateEmployeeSuccessPayload } from "../../payload/UpdateEmployee/UpdateEmployeeSuccessPayload";

const updateEmployeeSuccess = (state: EmployeesState, action: PayloadAction<UpdateEmployeeSuccessPayload>) => {
    const employee = action.payload.employee;

    const employees = state.employeesArray;
    updateItem(employees, employee);

    state.isLoading = false;
    state.isError = null;
    state.message = "Працівника успішно оновлено";
    state.messageVisiable = true;
}

export default updateEmployeeSuccess;