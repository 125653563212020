import { PayloadAction } from "@reduxjs/toolkit";
import { UpsellState } from "../../models/UpsellsState";
import { GetUpsellSuccessPayload } from "../../payload/GetUpsell/GetUpsellSuccessPayload";

const getAdditionalSuccess = (state: UpsellState, action: PayloadAction<GetUpsellSuccessPayload>) => {
    const upsell = action.payload;
    state.upsells.push(upsell);
    state.isLoading = false;
    state.isError = null;
}

export default getAdditionalSuccess;