import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Employees } from "../../../../services/reddyApi/Employees/Employees";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getEmployeeFetch, getEmployeeSuccess } from "../employees.slice";
import { GetEmployeePayload } from "../payload/GetEmployee/GetEmployeePayload";

function* getEmployeeWorker(action: PayloadAction<GetEmployeePayload>) {
    const { vendorId, employeeId } = action.payload;

    try {
        const { data } = yield call(Employees.getEmployee, { vendorId, employeeId });

        yield put(getEmployeeSuccess(data));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getEmployeeFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getEmployeeWorker;