import { FormikErrors, FormikTouched } from "formik";
import React, { FC } from "react";
import Field from "../../../../../../components/dataEntry/inputs/Field/Field";
import ArrayFields from "../../../../../../components/dataEntry/inputs/ListFields/ArrayFields";
import { ContextValue } from "../../../../../../components/dataEntry/inputs/ListFields/models/ContextValue";
import { roundNumber } from "../../../../../../utl/helpers/roundNumber";
import { Lessons as GroupLessons } from "../../../../models/GroupsValue";

interface Props {
    lessons: GroupLessons[];
    error: string | string[] | FormikErrors<GroupLessons>[] | undefined;
    touched: FormikTouched<GroupLessons>[] | undefined;
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    handleBlur: {
        (e: React.FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    }
}

const Lessons: FC<Props> = ({ lessons, error, touched, handleBlur, handleChange }) => {
    const name = "lessons";

    const lessonsItems = (context: ContextValue) => lessons && lessons.map((lesson, index) => {
        const touchedLessons = touched;
        const errorsLessons = error;
        const marginBottom = 0;

        let nameError = errorsLessons && Array.isArray(errorsLessons) && errorsLessons[index];
        if (typeof nameError === "object") {
            nameError = nameError?.name;
        }

        let nameForTeacherError = errorsLessons && Array.isArray(errorsLessons) && errorsLessons[index];
        if (typeof nameForTeacherError === "object") {
            nameForTeacherError = nameForTeacherError?.nameForTeacher;
        }


        let dateError = errorsLessons && Array.isArray(errorsLessons) && errorsLessons[index];
        if (typeof dateError === "object") {
            dateError = dateError?.date
        }

        let timeError = errorsLessons && Array.isArray(errorsLessons) && errorsLessons[index];
        if (typeof timeError === "object") {
            timeError = timeError?.time
        }

        let durationInHoursError = errorsLessons && Array.isArray(errorsLessons) && errorsLessons[index];
        if (typeof durationInHoursError === "object") {
            durationInHoursError = durationInHoursError?.durationInHours;
        }

        const nameLesson = lesson.name;
        const nameLessonForTeacher = lesson.nameForTeacher;
        const startDateLesson = lesson.date;
        const startTimeLesson = lesson.time;
        const durationInHours = lesson.durationInHours;

        return (
            <li key={"LESSONS_ITEM_" + index} className={context?.styles.listItem}>
                <Field
                    handleChange={handleChange(`${name}[${index}].name`)}
                    handleBlur={handleBlur(`${name}[${index}].name`)}
                    error={(nameError && String(nameError)) || undefined}
                    touched={(touchedLessons && touchedLessons[index]?.name) || undefined}
                    maxWidth={260}
                    marginBottom={marginBottom}
                    value={nameLesson}
                />

                <Field
                    handleChange={handleChange(`${name}[${index}].nameForTeacher`)}
                    handleBlur={handleBlur(`${name}[${index}].nameForTeacher`)}
                    error={(nameForTeacherError && String(nameForTeacherError)) || undefined}
                    touched={(touchedLessons && touchedLessons[index]?.nameForTeacher) || undefined}
                    maxWidth={260}
                    marginBottom={marginBottom}
                    value={nameLessonForTeacher}
                />

                <Field
                    type={"date"}
                    marginRight={20}
                    marginBottom={marginBottom}
                    handleChange={handleChange(`${name}[${index}].date`)}
                    handleBlur={handleBlur(`${name}[${index}].date`)}
                    error={(dateError && String(dateError)) || undefined}
                    touched={(touchedLessons && touchedLessons[index]?.date) || undefined}
                    maxWidth={150}
                    value={startDateLesson}
                    topPositionIcon={8}
                />

                <Field
                    type={"time"}
                    marginRight={20}
                    marginBottom={marginBottom}
                    handleChange={handleChange(`${name}[${index}].time`)}
                    handleBlur={handleBlur(`${name}[${index}].time`)}
                    error={(timeError && String(timeError)) || undefined}
                    touched={(touchedLessons && touchedLessons[index]?.time) || undefined}
                    maxWidth={110}
                    value={startTimeLesson}
                    topPositionIcon={8}
                />

                <Field
                    type="number"
                    maxWidth={80}
                    marginRight={0}
                    marginBottom={marginBottom}
                    handleChange={handleChange(`${name}[${index}].durationInHours`)}
                    value={durationInHours}
                    error={(durationInHoursError && String(durationInHoursError)) || undefined}
                    touched={(touchedLessons && touchedLessons[index]?.durationInHours) || undefined}
                    roundNumber={roundNumber}
                    step={0.25}
                />
            </li>
        );
    });

    return (
        <ArrayFields
            name={name}
            fields={lessonsItems}
            paginated={true}
            paginationKey="GROUP_LESSONS_"
        />
    )
}

export default Lessons;