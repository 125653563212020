import * as Yup from 'yup';

export default Yup.object().shape({
    lessonId: Yup.number().required('Required'),
    students: Yup.array(Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string(),
        upsellsIds: Yup.string(),
        attended: Yup.boolean(),
    })).required('Required'),
    employeesIds: Yup.string().required('Required')
});