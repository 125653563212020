import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import Dropdown from "../../../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { useAppSelector } from "../../../../../../../../store/hooks/selector.hook";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../../../../utl/constants/defaultValue";

interface Props {
    discountId: number | null;
    error: string | undefined;
    touched: boolean | undefined;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void
}

const DiscountsDropdown: FC<Props> = ({ discountId, error, touched, setFieldValue, setFieldTouched }) => {
    const discounts = useAppSelector(state => state.money.discounts, shallowEqual);

    const discountsWithEmptyValue = [...discounts, { id: null, name: "Без Знижки" }];

    const indexDiscounts = discountsWithEmptyValue.findIndex(
        discount => discount.id === discountId
    );

    const defaultDiscounts = discountsWithEmptyValue.length > 0 && discountsWithEmptyValue[indexDiscounts];
    const defaultValueDiscounts: DefaultValue = {
        valueInput: (defaultDiscounts && String(defaultDiscounts.id)) || DEFAULT_VALUES_DROPDOWN.valueInput,
        valueText: defaultDiscounts && defaultDiscounts.name || DEFAULT_VALUES_DROPDOWN.valueText,
    }
    const discountsItems = discountsWithEmptyValue.map((discount, index) => (
        <DropdownItem
            key={"DISCOUNT_ITEM_" + index}
            item={discount.name}
            valueInput={String(discount.id)}
            valueText={discount.name}
        />
    ));

    const changeHandler = (discountId: string) => {
        setFieldValue("discountId", parseInt(discountId));
    }

    const blurHandler = (touched: boolean) => {
        setFieldTouched("discountId", touched)
    }

    return (
        <Dropdown
            label="Знижка"
            maxWidth={240}
            height={40}
            marginRight={20}
            defaultValue={defaultValueDiscounts}
            doubleClickDropdown={false}
            error={error}
            touched={touched}
            handleBlur={blurHandler}
            handleChange={changeHandler}
        >
            {discountsItems}
        </Dropdown>
    )
}

export default DiscountsDropdown;