import React, { FC, useState } from "react";
import styles from "./TableTagInput.module.css";
import classNames from "classnames";
import "./transition.css";
import { CSSTransition } from "react-transition-group";
import ButtonIcon from "../../../general/buttons/ButtonIcon/ButtonIcon";
import IconFont from "../../../general/icons/IconFont/IconFont";

const cx = classNames.bind(styles);
const TAGS_KEY = "TAGS_"
const ENTER_KEY = "Enter";
const SPACE_KEY = " ";

interface Tag {
    id: number;
    name: string;
}

interface Props {
    tagData: Array<Tag>;
    maxWidth?: number;
    selectedTags: Array<Tag>;
    keyIndex?: string;
    handleChange: (value: string) => void;
    values?: string;
}

const TableTagInput: FC<Props> = ({ tagData, selectedTags, maxWidth, keyIndex, handleChange, values }) => {
    const [toggleOfSelectedTags, setToggleOfSelectedTags] = useState<boolean>(false);
    const [toggleOfTags, setToggleOfTags] = useState<boolean>(false);
    const [zIndexValues, setZIndexValues] = useState({
        zIndexBlock: 5,
        zIndexList: 4,
    });

    const selectedTagsItems = selectedTags.map(selectedTag => {
        const clickCloseButtonHendler = () => {
            if (handleChange) {
                let tags = selectedTags.filter(tag => tag.id !== selectedTag.id)
                    .map(tag => tag.id)
                    .join(",");
                console.log(tags, selectedTags.filter(tag => tag.id !== selectedTag.id), selectedTags.filter(tag => tag.id !== selectedTag.id)
                .map(tag => tag.id));
                handleChange(tags);
            }
        }

        return (
            <div key={TAGS_KEY + selectedTag.id + "_" + keyIndex} className={styles.tagItem} >
                {selectedTag.name}
                <div className={cx(styles.btnBlock, styles.closeBtnBlock)}>
                    <ButtonIcon
                        icon={<IconFont fontSize={11} iconClass="icon-plus" color="#FFF" />}
                        handleClick={clickCloseButtonHendler}
                    />
                </div>
            </div>
        )
    });

    const inactiveTagData = tagData.filter(tag => selectedTags.findIndex(selectedTag => selectedTag.id === tag.id) === -1);
    const tagDataItems = inactiveTagData.map(tag => {
        const clickPlusButtonHendler = () => {
            if (handleChange) {
                handleChange([...selectedTags, { ...tag }]
                    .map(item => item.id)
                    .sort((dayWeek, nextDayWeek) => dayWeek - nextDayWeek)
                    .join(","))
            }
        }

        return (
            <div key={TAGS_KEY + tag.id + "_" + keyIndex} className={styles.tagItem} >
                {tag.name}
                <div className={styles.btnBlock}>
                    <ButtonIcon
                        icon={<IconFont fontSize={11} iconClass="icon-plus" color="#FFF" />}
                        handleClick={clickPlusButtonHendler}
                    />
                </div>
            </div>
        )
    });

    const clickButtonHandler = () => {
        setToggleOfSelectedTags(prev => !prev)
    }

    const blurButtonHandler = () => {
        setToggleOfSelectedTags(false);
    }

    const clickButtonCheckboxHandler = () => {
        setToggleOfTags(prev => !prev)
    }

    const blurButtonCheckboxHandler = () => {
        setToggleOfTags(false);
    }

    const amountSelectedTagsItems = selectedTagsItems.length;
    const amountTagData = tagDataItems.length;

    return (
        <div
            className={cx(styles.tagInputBlock)}
            style={{ maxWidth }}
        >
            <button
                onClick={clickButtonCheckboxHandler}
                onBlur={blurButtonCheckboxHandler}
                className={cx(styles.radio, amountSelectedTagsItems > 0 && styles.checked)}
            ></button>

            {amountSelectedTagsItems > 0 && selectedTagsItems[0]}

            {amountSelectedTagsItems > 0 &&
                <button
                    className={styles.btnInfo}
                    onClick={clickButtonHandler}
                    onBlur={blurButtonHandler}
                >
                    {amountSelectedTagsItems}
                </button>
            }

            <input type="hidden" value={values} />

            <CSSTransition
                in={toggleOfSelectedTags && amountSelectedTagsItems > 0}
                timeout={400}
                unmountOnExit
                classNames="tags-list"
            >
                <div style={{ zIndex: zIndexValues.zIndexList }} className={styles.listTags}>
                    {selectedTagsItems}
                </div>
            </CSSTransition>

            <CSSTransition
                in={toggleOfTags && amountTagData > 0}
                timeout={400}
                unmountOnExit
                classNames="tags-list"
            >
                <div style={{ zIndex: zIndexValues.zIndexList }} className={styles.listTags}>
                    {tagDataItems}
                </div>
            </CSSTransition>
        </div>
    );
}

export default TableTagInput