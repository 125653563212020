import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Upsells } from "../../../../../services/reddyApi/Upsells/Upsells";
import handleServerError from "../../../all/saga/handleServerError";
import setServerErrorDefault from "../../../all/saga/setServerErrorDefault";
import { GetUpsellPayload } from "../payload/GetUpsell/GetUpsellPayload";
import { getAdditionalFetch, getAdditionalSuccess } from "../upsells.slice";

function* getUpsellWorker(action: PayloadAction<GetUpsellPayload>) {
    const { vendorId, upsellId } = action.payload;

    try {
        const { data } = yield call(Upsells.getUpsell, { vendorId, upsellId });

        yield put(getAdditionalSuccess(data));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getAdditionalFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getUpsellWorker;