import { FormikProps } from "formik";
import React, { ChangeEvent, FC } from "react";
import Button from "../../../components/general/buttons/Button/Button";
import AuthForm from "../../../components/dataEntry/forms/AuthForm/AuthForm";
import Field from "../../../components/dataEntry/inputs/Field/Field";
import Paragraph from "../../../components/general/typography/Paragraph/Paragraph";
import LinkText from "../../../components/general/typography/LinkText/LinkText";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { disabledError } from "../../../store/state/user/user.slice";
import { CSSTransition } from "react-transition-group";
import "../../../components/feedback/messages/MessageInfo/transition.css";
import { PassRecoveryValues } from "./models/PassRecoveryValues";

interface Props {
    formik: FormikProps<PassRecoveryValues>;
    isSubmit: boolean;
    error: string | null;
}

const PassRecovery: FC<Props> = ({formik, isSubmit, error}) => {
    const {isValid, values, errors, touched, handleBlur, handleChange, handleSubmit} = formik;
    const dispatch = useAppDispatch();

    const clickButtonSubmitHandler = () => {
        handleSubmit();
    }

    const changePasswordHandler = (event: ChangeEvent<HTMLInputElement>) => {
        handleChange("password")(event);
        dispatch(disabledError());
    }

    return (
        <AuthForm title={!isSubmit ? "Вкажіть новий пароль" : ""} fontSize={14} lineHeight="16px">
            {!isSubmit ? <>
                <Field 
                    marginRight={0}
                    type="password"
                    label="Пароль" 
                    value={values.password} 
                    handleChange={changePasswordHandler} 
                    handleBlur={handleBlur("password")}
                    error={errors.password || error || undefined}
                    touched={touched.password}
                />
                <Field 
                    marginRight={0}
                    type="password"
                    label="Підтвердження паролю" 
                    value={values.passwordCopy} 
                    handleChange={handleChange("passwordCopy")} 
                    handleBlur={handleBlur("passwordCopy")}
                    error={errors.passwordCopy || error || undefined}
                    touched={touched.passwordCopy}
                />

                <Button 
                    disabled={!isValid}
                    title="Надіслати" 
                    backgroundColor="#4775FE"
                    handleClick={clickButtonSubmitHandler} 
                />
            </> 
                : 
                <>
                    <Paragraph 
                        textAlign="center" 
                        marginBottom={10} 
                        content="Пароль змінено спробуйте увійти" 
                    />
                    <LinkText type="link" link="/signin" content="Увійти" />
                </>
            }
        </AuthForm>
    );
}

export default PassRecovery;