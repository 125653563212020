import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Groups } from "../../../../services/reddyApi/Groups/Groups";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getGroupGeneralDataFetch, getGroupGeneralDataSuccess } from "../groups.slice";
import { GetGroupGeneralDataPayload } from "../payload/GetGroupGeneralData/GetGroupGeneralDataPayload";

function* getGroupGeneralDataWorker(action: PayloadAction<GetGroupGeneralDataPayload>) {
    const { vendorId, groupId } = action.payload;
    
    try {
        const { data } = yield call(Groups.getGroupGeneralData, { vendorId, groupId });
       
        yield put(getGroupGeneralDataSuccess({ generalData: { ...data }, groupId }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getGroupGeneralDataFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getGroupGeneralDataWorker;