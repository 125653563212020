import React, { FC } from "react";
import Dropdown from "../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { Student } from "../../../../../../store/state/finance/models/CounteragentsAsClients";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../../utl/constants/defaultValue";

interface Props {
    label: string;
    student: Student;
    students: Student[];
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
    error: string | undefined;
    touched: boolean | undefined;
}

const StudentsDropdown: FC<Props> = ({ student, students, setFieldTouched, setFieldValue, error, touched, label }) => {
    const defaultValueStudent: DefaultValue = {
        valueInput: (student && String(student.id)) || DEFAULT_VALUES_DROPDOWN.valueInput,
        valueText: (student && student.fullname) || DEFAULT_VALUES_DROPDOWN.valueText,
    }

    const studentItems = students.map(student => (
        <DropdownItem
            item={student.fullname}
            valueInput={String(student.id)}
            valueText={student.fullname}
        />
    ));

    const blurStudentsHandler = (touched: boolean) => {
        setFieldTouched("studentId", touched)
    }

    const changeStudentHandler = (studentId: string) => {
        setFieldValue("studentId", parseInt(studentId))
    }

    return (
        <Dropdown
            maxWidth={440}
            marginBottom={20}
            label={label}
            defaultValue={defaultValueStudent}
            doubleClickDropdown={false}
            handleBlur={blurStudentsHandler}
            error={error}
            touched={touched}
            handleChange={changeStudentHandler}
        >
            {studentItems}
        </Dropdown>
    );
}

export default StudentsDropdown;