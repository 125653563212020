import { PayloadAction } from "@reduxjs/toolkit";
import { LocationsState } from "../../models/LocationsState";
import { ChangeLocationActivitySuccessPayload } from "../../payload/ChangeLocationActivity/ChangeLocationActivitySuccessPayload";

const changeLocationActivitySuccess = (state: LocationsState, action: PayloadAction<ChangeLocationActivitySuccessPayload>) => {
    const { locationId, active } = action.payload;

    const locations = state.locationsArray;

    const indexLocation = locations.findIndex(location => location.id === locationId);

    if(indexLocation > -1) {
        const location = locations[indexLocation];

        location.active = active;
    }
}

export default changeLocationActivitySuccess;