import { PayloadAction } from "@reduxjs/toolkit";
import { EmployeesState } from "../../models/EmployeesState";
import { ChangeEmployeeActivitySuccessPayload } from "../../payload/ChangeEmployeeActivity/ChangeEmployeeActivitySuccessPayload";

const changeEmployeeActivitySuccess = (state: EmployeesState, action: PayloadAction<ChangeEmployeeActivitySuccessPayload>) => {
    const { employeeId, active } = action.payload;

    const employees = state.employeesArray;

    const indexEmployee = employees.findIndex(employee => employee.id === employeeId);

    if(indexEmployee > -1) {
        const employee = employees[indexEmployee];

        employee.active = active;
    }
}

export default changeEmployeeActivitySuccess;