import { PayloadAction } from "@reduxjs/toolkit";
import { LocationsState } from "../../models/LocationsState";
import { DeleteLocationImagesPayload } from "../../payload/DeleteLocationImages/DeleteLocationImagesPayload";
import { UploadLocationImagesPayload } from "../../payload/UploadLocationImages/UploadLocationImagesPayload";

export type Payload = UploadLocationImagesPayload & DeleteLocationImagesPayload;

const uploadLocationImagesFetch = (state: LocationsState, action: PayloadAction<Payload>) => {
    state.isLoading = true;
}

export default uploadLocationImagesFetch;