import { PayloadAction } from "@reduxjs/toolkit";
import getItems from "../../../../../helpers/getItems";
import { Service } from "../../models/Service";
import { ServiceState } from "../../models/ServiceState";
import { GetServicesListSuccessPayload } from "../../payload/GetServicesList/GetServicesListSuccessPayload";

const defaultSrviceItem: Service = {
    id: -1,
    upsellsIds: [],
    typeId: -1,
    categoryId: -1,
    subCategoryId: -1,
    durationTypeId: -1,
    duration: 0,
    active: true,
    name: "Service",
    description: "",
    locationsIds: [],
    lessons: [],
    fullness: 0,
    confirmed: false
}

const getServicesListSuccess = (state: ServiceState, action: PayloadAction<GetServicesListSuccessPayload>) => {
    const { items, totalCount, filter } = action.payload;

    const services = state.basics;

    state.basics = getItems(
        state,
        services,
        items,
        totalCount,
        defaultSrviceItem,
        filter,
    );
    
    state.isLoading = false;
    state.isError = null;
}

export default getServicesListSuccess;