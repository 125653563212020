import React, { FC } from "react";
import Paragraph from "../../general/typography/Paragraph/Paragraph";
import Title from "../../general/typography/Title/Title";
import styles from "./InfoView.module.css";

interface Props {
    title: string;
    value: string;
    marginLeft?: number;
    marginRight?: number;
}

const InfoView: FC<Props> = ({title, value, marginLeft, marginRight}) => {
    return (
        <div
            style={{marginLeft, marginRight}} 
            className={styles.blockInfoView}
        >
            <Title 
                level={3} 
                title={title} 
                marginBottom={15} 
                color="#A6AEC5"
            />

            <Paragraph 
                color="#131313"
                fontSize={14}
                fontWeight="500"
                lineHeight={16}  
                content={value}
            />
        </div>
    );
}

export default InfoView;
