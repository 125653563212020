import React, { FC } from "react";
import ButtonIcon from "../../../general/buttons/ButtonIcon/ButtonIcon";
import IconFont from "../../../general/icons/IconFont/IconFont";
import Title from "../../../general/typography/Title/Title";
import Field from "../Field/Field";
import { Value } from "./models/Value";
import styles from "./ListFields.module.css";
import classNames from "classnames";
import { ArrayHelpers, FieldArray, FormikErrors, FormikTouched } from "formik";

const cx = classNames.bind(styles);
const KEY_LIST_FIELDS = "LIST_FIELDS_"

interface Props {
    name: string;
    title?: string;
    values: Array<Value>;
    hasName?: boolean;
    keyIndex: string;
    maxWidth?: number | string;
    handleChange?: any;
    handleBlur?: any;
    handleFocus?: any;
    errors?: string | string[] | FormikErrors<Value[]> | undefined;
    touched?: FormikTouched<Value[]> | undefined;
    typeInputFirst?: string;
    typeInputSecond?: string;
} 

const ListFields: FC<Props> = ({title, values, hasName = false, keyIndex, maxWidth, name, handleChange, handleBlur, errors, touched, typeInputFirst = "text", typeInputSecond = "number"}) => {
    return (
        <div className={styles.listFieldBlock} style={{maxWidth}}>
            {title && <Title level={2} title={title} marginBottom={20} />}
            <ul className={styles.listField}>
                <Fields 
                    name={name} 
                    values={values} 
                    hasName={hasName} 
                    keyIndex={keyIndex} 
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors}
                    touched={touched}
                    typeInputFirst={typeInputFirst}
                    typeInputSecond={typeInputSecond}
                />
            </ul>
        </div>
    );
}

const Fields: FC<Omit<Props, "maxWidth">> = ({values, hasName, keyIndex, name, handleChange, handleBlur, errors, touched, typeInputFirst, typeInputSecond}) => {
    return (
        <FieldArray
            name={name}
            render={({push, remove}: ArrayHelpers) => (
                values && values.map((item, index) => {
                    const clickButtonIconHandler = () => {
                        if(index === 0) {
                            if(hasName) {
                                push({name: "", value: "0"})
                            } else if(item.income !== undefined) {
                                push({name: "", income: item.income});
                            } else {
                                push({name: ""});
                            }
                        } else {
                            remove(index);
                        }
                    }

                    let nameError = errors && Array.isArray(errors) && errors[index];
                    if(typeof nameError === "object") {
                        nameError = nameError?.name
                    }
                    
                    let valueError = errors && Array.isArray(errors) && errors[index];
                    if(errors && Array.isArray(errors) && typeof valueError === "object") {
                        valueError = valueError?.value
                    }

                    return (
                        <li style={{marginBottom: 20}} key={KEY_LIST_FIELDS + keyIndex + index } className={styles.listItem}>
                            <Field 
                                type={typeInputFirst}
                                handleChange={handleChange(`${name}[${index}].name`)}
                                handleBlur={handleBlur(`${name}[${index}].name`)}
                                error={(nameError && String(nameError)) || undefined}
                                touched={(touched && touched[index]?.name) || undefined}
                                topPositionIcon={8}
                                marginBottom={0}
                                maxWidth={!hasName ? 360 : 220}
                                button={
                                    <>
                                    {!hasName &&
                                        <ButtonIcon 
                                            width={25}
                                            height={25}
                                            icon={<div className={cx(index > 0 && styles.rotate)}><IconFont color="#FFF" iconClass="icon-plus" fontSize={12} /></div>} 
                                            handleClick={clickButtonIconHandler}
                                            backgroundColor="#4775FE"
                                            borderRadius="50%"
                                        />}
                                    </>
                                }
                                value={item.name}
                            />
                            {hasName && 
                                <Field
                                    type={typeInputSecond}
                                    handleChange={handleChange(`${name}[${index}].value`)}
                                    handleBlur={handleBlur(`${name}[${index}].value`)}
                                    error={(valueError && String(valueError)) || undefined}
                                    touched={(touched && touched[index]?.value) || undefined} 
                                    maxWidth={120} 
                                    marginBottom={0}
                                    value={item.value || ""} 
                                    topPositionIcon={8}
                                    button={
                                        <ButtonIcon 
                                            width={25}
                                            height={25}
                                            icon={<div className={cx(index > 0 && styles.rotate)}><IconFont color="#FFF" iconClass="icon-plus" fontSize={12} /></div>} 
                                            handleClick={clickButtonIconHandler}
                                            backgroundColor="#4775FE"
                                            borderRadius="50%"
                                        />
                                    }
                                />
                            }
                        </li>
                    );
                })
            )} 
        />
    )
}

export default ListFields;