import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { User } from "../../../../services/reddyApi/User/User";
import { RegistretionVendorPayload } from "../payload/RegistretionVendorPayload";
import { 
    registerVendorFailure, 
    registerVendorSuccess, 
    setErrorEmailVendor, 
    setErrorNameVendor, 
    setErrorTelVendor 
} from "../user.slice";

function* registerVendorWorker(action: PayloadAction<RegistretionVendorPayload>) {
    try {
        const {status} = yield call(User.registerVendor, {...action.payload});
        if(status === 200) {
            console.log(200)
            yield put(registerVendorSuccess());
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const statusCode = String(error.response?.status);
            const message = String(error.response?.data.error.message);

            if(statusCode === "471") {
                yield put(setErrorNameVendor({error: "Компанія з такою назвою вже існує"}));
            } else if(statusCode === "472") {
                yield put(setErrorEmailVendor({error: "Така пошта вже використовується"}));
            } else if(statusCode === "473") {
                yield put(setErrorTelVendor({error: "Такий телефон вже використовується"}));
            } else if(statusCode === "400") {
                yield put(registerVendorFailure({error: "Дані поля не вірні"}));
            } else {
                yield put(registerVendorFailure({error: "Невідома помилка"}));
            }
        }
    }
}

export default registerVendorWorker;