import axios, { Axios, AxiosRequestHeaders } from "axios";
import { BASE_URL } from "../../utl/constants/app";
import Token from "../Token/token";

const instance = axios.create({
    baseURL: BASE_URL,
    withCredentials: false,
    headers: { "Content-Type": "application/json", }
});

instance.interceptors.request.use(function (config) {
        if(config && config.headers) {
            let token = Token.getAccessToken();

            if(!token) {
                token = Token.getRefreshToken();
            }

            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, function (error) {
        return Promise.reject(error);
    }
);

export default instance;