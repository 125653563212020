import React, { FC } from "react";

interface Props {
    alignItems?: "center" | "flex-start" | "flex-end";
    justifyContent?: "center" | "flex-start" | "flex-end" | "space-between" | "space-around";
    flexDirection?: "column" | "row";
    maxWidth?: number;
    maxHeight?: number;
    height?: string | number;
    paddingTop?: number;
    paddingRight?: number;
    paddingBottom?: number;
    paddingLeft?: number;
    marginTop?: number | "auto";
    marginRight?: number | "auto";
    marginBottom?: number | "auto";
    marginLeft?: number | "auto";
    children: JSX.Element | JSX.Element[];
    isOverflowY?: boolean; 
}

const Wrp: FC<Props> = ({maxWidth, maxHeight, paddingTop, paddingBottom, paddingLeft, paddingRight,
    marginBottom, marginTop, marginLeft, marginRight, children, alignItems = "flex-start", 
    justifyContent, flexDirection = "row", height, isOverflowY
    }) => {

    return (
        <div style={{
            display: "flex", flexDirection, alignItems, 
            justifyContent, maxWidth, maxHeight, width: "100%", height, paddingTop, 
            paddingBottom, paddingLeft, paddingRight,
            marginBottom, marginTop, marginLeft, marginRight,
            overflowY: isOverflowY ? "auto" : undefined,
        }}>
            {children}
        </div>
    );
}

export default Wrp;