import { PayloadAction } from "@reduxjs/toolkit";
import { ResSearchUsersForVendor } from "../../../../../services/reddyApi/User/User.response";
import { GroupsState } from "../../models/GroupsState";
import { SearchClientsSuccessPayload } from "../../payload/SearchClients/SearchClientsSuccessPayload";

const searchClientsSuccess = (state: GroupsState, action: PayloadAction<SearchClientsSuccessPayload>) => {
    const searchClients = action.payload;

    state.searchClients = searchClients;
    state.isLoading = true;
}

export default searchClientsSuccess;