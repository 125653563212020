import { AxiosResponse } from 'axios';
import instance from '../instance';
import { GetCategoriesResponse, ResCreateMoneyTransaction, ResCreateMoneyTransfer, ResGetClientMoneyTransactionsByVendor, ResGetFuturePurses, ResGetMoneyPurses, ResGetPlannedPurses, ResGetVendorMoneyTransactions, SearchCounteragentsAsClientsResponse, SearchCounteragentsResponse } from './Money.response';
import { GetCategoriesRequest, ReqCreateMoneyTransaction, ReqCreateMoneyTransfer, ReqGetClientMoneyTransactionsByVendor, ReqGetFutureMoney, ReqGetMoneyPurses, ReqGetPlannedMoney, ReqGetVendorMoneyTransactions, SearchCounteragentsAsClientsRequest, SearchCounteragentsRequest, UpdateCategoriesRequest } from './Money.request';

interface Money {
    getCategories: (data: GetCategoriesRequest) =>
        Promise<AxiosResponse<GetCategoriesResponse>>;
    updateCategories: (data: UpdateCategoriesRequest) =>
        Promise<AxiosResponse>;
    getClientMoneyTransactionsByVendor: (data: ReqGetClientMoneyTransactionsByVendor) =>
        Promise<AxiosResponse<ResGetClientMoneyTransactionsByVendor>>;
    createMoneyTransaction: (data: ReqCreateMoneyTransaction) =>
        Promise<AxiosResponse<ResCreateMoneyTransaction>>;
    createMoneyTransfer: (data: ReqCreateMoneyTransfer) =>
        Promise<AxiosResponse<ResCreateMoneyTransfer>>;
    getVendorMoneyTransactions: (data: ReqGetVendorMoneyTransactions) =>
        Promise<AxiosResponse<ResGetVendorMoneyTransactions>>;
    getMoneyPurses: (data: ReqGetMoneyPurses) =>
        Promise<AxiosResponse<ResGetMoneyPurses>>;
    getFutureMoney: (data: ReqGetFutureMoney) =>
        Promise<AxiosResponse<ResGetFuturePurses>>;
    getPlannedMoney: (data: ReqGetPlannedMoney) =>
        Promise<AxiosResponse<ResGetPlannedPurses>>;
    searchCounteragents: (data: SearchCounteragentsRequest) =>
        Promise<AxiosResponse<SearchCounteragentsResponse>>;
    searchCounteragentsAsClients: (data: SearchCounteragentsAsClientsRequest) =>
        Promise<AxiosResponse<SearchCounteragentsAsClientsResponse>>;
}

export const Money: Money = {
    getCategories: async (data) => {
        const { vendorId } = data;

        return instance.get(`/vendors/${vendorId}/money/categories`);
    },
    updateCategories: (data) => {
        const { vendorId, purses, operations, discounts } = data;

        return instance.put(`/vendors/${vendorId}/money/categories`, { purses, operations, discounts });
    },
    getClientMoneyTransactionsByVendor: (data) => {
        const { vendorId, clientId, filter } = data;

        return instance.get(`/vendors/${vendorId}/clients/${clientId}/transactions`, {
            params: {
                rangeFrom: filter?.range?.from,
                rangeTo: filter?.range?.to,
                type: filter?.type,
            }
        });
    },
    createMoneyTransaction: (data) => {
        const { vendorId, transaction } = data;

        return instance.post(`/vendors/${vendorId}/money/transactions`, transaction);
    },
    createMoneyTransfer: (data) => {
        const { vendorId, transfer } = data;

        return instance.post(`/vendors/${vendorId}/money/transfers`, transfer);
    },
    getVendorMoneyTransactions: (data) => {
        const { vendorId, filter } = data;

        return instance.get(`/vendors/${vendorId}/money/transactions`, {
            params: {
                rangeFrom: filter?.range?.from,
                rangeTo: filter?.range?.to,
                type: filter?.type,
            }
        });
    },
    getMoneyPurses: (data) => {
        const { vendorId } = data;

        return instance.get(`/vendors/${vendorId}/money/purses`);
    },
    getFutureMoney: (data) => {
        const { vendorId, filter } = data;

        return instance.get(`/vendors/${vendorId}/money/future`, {
            params: {
                periodFrom: filter?.period?.from,
                periodTo: filter?.period?.to,
            }
        });
    },
    getPlannedMoney: (data) => {
        const { vendorId, filter } = data;

        return instance.get(`/vendors/${vendorId}/money/planned`, {
            params: {
                periodFrom: filter?.period?.from,
                periodTo: filter?.period?.to,
            }
        });
    },
    searchCounteragents: (data) => {
        const { vendorId, value } = data;

        return instance.post(`/vendors/${vendorId}/counteragents/search`, { value });
    },
    searchCounteragentsAsClients: (data) => {
        const { vendorId, value } = data;

        return instance.post(`/vendors/${vendorId}/counteragents/clients/search`, { value });
    }
}