import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Employees } from "../../../../services/reddyApi/Employees/Employees";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getStatisticsDashboard, getStatisticsFinance } from "../../statistics/statistics.saga";
import { deleteEmployeeFetch, deleteEmployeeSuccess } from "../employees.slice";
import { DeleteEmployeePayload } from "../payload/DeleteEmployee/DeleteEmployeePayload";

function* deleteWorker(action: PayloadAction<DeleteEmployeePayload>) {
    const { vendorId, employeeId, filter } = action.payload;

    try {
        const { data, status } = yield call(Employees.delete, { employeeId, vendorId });

        if (status === 200) {
            yield put(deleteEmployeeSuccess({ employeeId }));

            yield getStatisticsDashboard({ vendorId, filter });

            yield getStatisticsFinance({ vendorId, filter });

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => deleteEmployeeFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default deleteWorker;