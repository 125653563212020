import React, { FC } from "react";

const HandIcon: FC = () => {
    return (
        <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_636_14164)">
                <path d="M72.4145 16.7911C69.612 12.2372 65.0082 11.6272 62.8306 11.7299C60.7908 4.38622 53.8691 3.91368 52.6067 3.91368C50.1541 3.92141 47.7786 4.77152 45.8779 6.32167C43.9772 7.87182 42.6668 10.0279 42.1661 12.4289L41.4945 15.704L39.5168 8.01391C38.2022 2.90168 32.8088 -1.24343 26.5581 0.343311C24.0857 0.979215 21.9228 2.47969 20.4613 4.57288C18.9999 6.66607 18.3365 9.2135 18.5914 11.7537C17.0095 11.8783 15.4754 12.3549 14.1013 13.1486C12.7272 13.9423 11.5478 15.0331 10.6494 16.3411C9.75103 17.6492 9.15632 19.1415 8.90877 20.7089C8.66122 22.2764 8.76709 23.8793 9.21864 25.4006L12.7393 37.2806C7.21243 36.1901 3.66323 39.6196 2.89456 40.4383C1.93557 41.4572 1.18678 42.655 0.690973 43.9634C0.195168 45.2719 -0.0379252 46.6651 0.00501648 48.0637C0.0479581 49.4622 0.366093 50.8386 0.941236 52.1141C1.51638 53.3897 2.33726 54.5393 3.35695 55.4975L16.5621 67.9238C20.7056 71.8339 26.1896 74.0083 31.8869 74H47.4174C51.5729 74.0113 55.6098 72.6155 58.8709 70.04C62.132 67.4645 64.4252 63.8609 65.3771 59.8159L73.7054 24.8447C74.0339 23.4837 74.0889 22.071 73.8673 20.6885C73.6457 19.306 73.1519 17.9813 72.4145 16.7911ZM66.9571 23.2379L58.6288 58.2097C58.0345 60.7347 56.6029 62.9841 54.5672 64.5918C52.5314 66.1995 50.0114 67.0707 47.4174 67.0636H31.8869C27.9571 67.0693 24.1745 65.5695 21.3163 62.8725L8.11066 50.4456C4.53013 47.0777 9.62739 41.6439 13.2096 45.0271L20.5985 51.9802C21.3827 52.7186 22.5471 52.0838 22.5471 51.1381C22.5472 47.7052 22.0518 44.2902 21.0761 40.9989L15.871 23.4327C14.4644 18.6801 21.6088 16.6005 23.0089 21.3189L27.5391 36.6073C28.3475 39.3396 32.4215 38.1557 31.7254 35.45L25.5913 11.5967C24.3622 6.8122 31.5794 4.99935 32.7984 9.7415L39.6385 36.3403C40.2784 38.8323 43.8533 38.7256 44.3662 36.224L48.961 13.8259C49.9453 9.04086 57.2741 10.3416 56.2048 15.5549L52.0786 35.6687C51.5405 38.2861 55.4993 39.2287 56.1336 36.5673L59.7181 21.5149C60.8693 16.6951 68.0944 18.4707 66.9571 23.2379Z" fill="#4775FE"/>
            </g>
            <defs>
                <clipPath id="clip0_636_14164">
                    <rect width="74" height="74" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default HandIcon;