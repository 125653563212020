import { useFormik } from "formik";
import moment from "moment";
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { DateApi } from "../../../../services/Date/Date";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import {
    changeGroupActivityFetch,
    updateGroupFetch
} from "../../../../store/state/groups/groups.slice";
import { Service } from "../../../../store/state/services/service/models/Service";
import { SUBMIT_ACTIONS } from "../../../../utl/constants/submitActions";
import { convertStringToArray } from "../../../../utl/helpers/convertStringToArray";
import { useDeleteRedirect } from "../../../../utl/hooks/useDeleteRedirect";
import useSubmitAction from "../../../../utl/hooks/useSubmitAction";
import { Filter } from "../../../../utl/models/Filters";
import { GroupsValue } from "../../models/GroupsValue";
import GroupMessage from "./Group.message";
import validationSchema from "./Group.scheme";
import useGetSelectedService from "./hook/useGetSelectedService";
import useGetStudentsList from "./hook/useGetStudentsList";

const GroupContainer: FC = () => {
    let { id } = useParams();
    const groupId = Number(id);

    const groups = useAppSelector(state => state.groups.pageData, shallowEqual);
    const deleteRedirect = useDeleteRedirect();
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const services = useAppSelector(state => state.services.pageData, shallowEqual);
    const indexGroup = groups.findIndex(item => item.id === groupId);
    const group = groups[indexGroup];
    const students = useAppSelector(state => state.groups.pageData[indexGroup].students);
    let endDate = "";
    let service: Service | null = null;
    let active = false;

    if (group) {
        const serviceIndex = services.findIndex(item => item.id === group.id)
        service = services[serviceIndex];

        endDate = group.endDate;
        active = group.active;
    }

    const serviceId = (group && group.serviceId) || -1;

    useGetSelectedService({ serviceId, service, group, vendorId });

    useGetStudentsList({ students, vendorId, groupId });

    const initialValues: GroupsValue = group ? {
        serviceId: group.serviceId,
        employeesIds: group.employeesIds.join(","),
        name: group.name,
        locationId: group.locationId,
        studentsMin: group.studentsMin,
        studentsMax: group.studentsMax,
        studentsAmount: group.studentsAmount,
        activeStudentsAmount: group.activeStudentsAmount,
        price: group.price,
        paymentParts: group.paymentParts,
        notificable: group.notificable,
        notificationBeforeLesson: group.notificationBeforeLesson,
        lessonWeekDays: group.lessonWeekDays.join(","),
        startDate: group.startDate,
        endDate,
        lessons: group.lessons.map(lesson => {
            const datetime = DateApi.parseDateTime(lesson.datetime);

            return {
                id: lesson.id,
                name: lesson.name,
                nameForTeacher:
                    lesson.nameForTeacher,
                date: datetime[0],
                time: datetime[1],
                durationInHours: lesson.durationInHours,
            }
        }),
        upsellsIds: group.upsellsIds.join(","),
        active,
        online: group.online,
        confirmed: group.confirmed,
        submitAction: SUBMIT_ACTIONS.SAVE,
    } : {
        serviceId: -1,
        employeesIds: "",
        name: "",
        locationId: -1,
        studentsMin: 0,
        studentsMax: 0,
        studentsAmount: 0,
        activeStudentsAmount: 0,
        price: 0,
        paymentParts: [],
        notificable: false,
        notificationBeforeLesson: {
            days: 0,
            time: ""
        },
        lessonWeekDays: "",
        startDate: "",
        endDate: "",
        lessons: [],
        upsellsIds: "",
        active: true,
        online: false,
        confirmed: false,
        submitAction: SUBMIT_ACTIONS.SAVE,
    }

    const submitActionHandler = useSubmitAction(active ? deleteRedirect : undefined);

    const submitFormHandler = (values: GroupsValue): void => {
        const {
            submitAction, name, serviceId, employeesIds, startDate, locationId, studentsMin,
            studentsMax, price, paymentParts, notificable, notificationBeforeLesson, lessonWeekDays,
            lessons, upsellsIds, studentsAmount, active, online, endDate, confirmed, activeStudentsAmount
        } = values;

        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        const filter: Filter = {
            period: {
                from: moment(firstDay).format("YYYY-MM-DD"),
                to: moment(lastDay).format("YYYY-MM-DD"),
            }
        }

        const upsellsIdsArray = convertStringToArray(upsellsIds);

        const lessonWeekDaysArray = convertStringToArray(lessonWeekDays);

        const employeesIdsArray = convertStringToArray(employeesIds);

        const lessonsDatimeFormat = lessons.map(lesson => {
            return ({
                id: lesson.id,
                name: lesson.name,
                nameForTeacher: lesson.nameForTeacher,
                datetime: `${lesson.date} ${lesson.time}`,
                durationInHours: lesson.durationInHours,
            })
        });

        const clientsIds = students ? students.map(student => student.client.id) : [];

        submitActionHandler(
            submitAction,
            () => updateGroupFetch(
                {
                    vendorId,
                    groupId,
                    clientsIds,
                    group: {
                        name,
                        serviceId,
                        employeesIds: employeesIdsArray,
                        startDate,
                        endDate,
                        locationId,
                        studentsMin,
                        studentsMax,
                        studentsAmount,
                        activeStudentsAmount,
                        price,
                        paymentParts,
                        notificable,
                        notificationBeforeLesson,
                        lessonWeekDays: lessonWeekDaysArray,
                        lessons: lessonsDatimeFormat,
                        upsellsIds: upsellsIdsArray,
                        active,
                        confirmed,
                        online,
                    },
                    filter
                }
            ),
            () => changeGroupActivityFetch(
                { vendorId, groupId, filter, serviceId, clientsIds, active: !active }
            ),
        );
    }

    const formik = useFormik<GroupsValue>({
        initialValues,
        validationSchema,
        onSubmit: submitFormHandler
    });

    return (
        <GroupMessage
            formik={formik}
            active={active}
        />
    );
}

export default GroupContainer;