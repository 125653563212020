import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Upsells } from "../../../../../services/reddyApi/Upsells/Upsells";
import handleServerError from "../../../all/saga/handleServerError";
import setServerErrorDefault from "../../../all/saga/setServerErrorDefault";
import { DeleteUpsellPayload } from "../payload/DeleteUpsell/DeleteUpsellPayload";
import { deleteAdditionalFetch, deleteAdditionalSuccess } from "../upsells.slice";

function* deleteUpsellWorker(action: PayloadAction<DeleteUpsellPayload>) {
    const { vendorId, upsellId } = action.payload;

    try {
        const { status } = yield call(Upsells.delete, { vendorId, upsellId });
        if (status === 200) {
            yield put(deleteAdditionalSuccess({ upsellId }));

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => deleteAdditionalFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default deleteUpsellWorker;