import { PayloadAction } from "@reduxjs/toolkit";
import { GroupsState } from "../../models/GroupsState";
import { ChangeGroupActivityPayload } from "../../payload/ChangeGroupActivity/ChangeGroupActivityPayload";
import { DeleteGroupPayload } from "../../payload/DeleteGroup/DeleteGroupPayload";

export type ChangeGroupActivityFetchType = ChangeGroupActivityPayload & DeleteGroupPayload;

const changeGroupActivityFetch = (state: GroupsState, action: PayloadAction<ChangeGroupActivityFetchType>) => {
    state.isLoading = true;
}

export default changeGroupActivityFetch;