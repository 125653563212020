import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../../../../store/hooks/selector.hook";
import { setFonVisiable } from "../../../../../../../store/state/all/all.slice";
import { showStudentAttendance } from "../../../../../../../store/state/groups/groups.slice";
import { LessonsStudent } from "../../../../../../../store/state/groups/models/Group";
import { configAdapter } from "../../../../../../../utl/adapters/ConfigAdapter";
import StudentAttendanceModal from "./StudentAttendanceModal";

interface Props {
    vendorId: number;
    groupId: number;
    studentId: number;
}

const StudentAttendanceModalContainer: FC<Props> = ({ vendorId, groupId, studentId }) => {
    const groups = useAppSelector(state => state.groups.pageData, shallowEqual);
    const indexGroup = groups.findIndex(group => group.id === groupId);
    const students = useAppSelector(state => state.groups.pageData[indexGroup].students, shallowEqual);
    const relationshipStatuses = useAppSelector(state => state.config.userConfig?.user.relationshipStatuses, shallowEqual);

    let lessons: LessonsStudent[] = [];
    let studentName = "";
    let parentName = "";
    let reletionshipStatus = "";
    let visiable = false;

    if (students) {
        const indexStudent = students.findIndex(student => student.student.id === studentId);
        const student = students[indexStudent];
        if (student) {
            lessons = student.lessons;
            studentName = `${student.student.surname} ${student.student.name}`;

            const user = student.client.users[0];
            parentName = `${user.surname} ${user.name}`;

            reletionshipStatus = configAdapter(relationshipStatuses, user.relationshipStatus) || "";

            visiable = Boolean(student.attendanceVisiable);
        }
    }


    const dispatch = useAppDispatch();

    const closeModalHandler = () => {
        dispatch(setFonVisiable({ visiable: false }));
        dispatch(showStudentAttendance({ groupId, studentId, show: false }));
    }


    return (
        <StudentAttendanceModal
            lessons={lessons}
            studentName={studentName}
            parentName={parentName}
            reletionshipStatus={reletionshipStatus}
            handleCloseButton={closeModalHandler}
            visiable={visiable}
        />
    );
}

export default StudentAttendanceModalContainer;