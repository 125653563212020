import React, { FC } from "react";
import "./Title.css";

interface Props {
    title: string;
    level?: number;
    fontWeight?: string;
    color?: string;
    marginBottom?: number;
    marginRight?: number;
    textAlign?: "center" | "start" | "end";
    width?: string | number; 
}

const Title: FC<Props> = ({level = 1, title, fontWeight = "bold", color = "#131313", marginBottom, marginRight, textAlign, width}) => {
    switch(level) {
        case 2 :
            return (<h2 style={{fontWeight, color, marginBottom, marginRight, textAlign, width}}>{title}</h2>); 
        case 3 :
            return (<h3 style={{fontWeight, color, marginBottom, marginRight, textAlign, width}}>{title}</h3>); 
        case 4 :
            return (<h4 style={{fontWeight, color, marginBottom, marginRight, textAlign, width}}>{title}</h4>); 
        case 5 :
            return (<h5 style={{fontWeight, color, marginBottom, marginRight, textAlign, width}}>{title}</h5>); 
        case 6 :
            return (<h6 style={{fontWeight, color, marginBottom, marginRight, textAlign, width}}>{title}</h6>);
        default :
            return (<h1 style={{fontWeight, color, marginBottom, marginRight, textAlign, width}}>{title}</h1>);     
    }
}

export default Title;