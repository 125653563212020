import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Configuration } from "../../../../services/reddyApi/Configuration/Configuration";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getUserConfigFetch, getUserConfigSuccess } from "../configuration.slice";

function* getUserConfigWorker() {
    try {
        const { data } = yield call(Configuration.getUserConfig);
        const { user } = data;

        yield put(getUserConfigSuccess({ userConfig: { user } }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getUserConfigFetch();

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getUserConfigWorker;