import { FormikProps } from "formik";
import React, { ChangeEvent, FC } from "react";
import Button from "../../components/general/buttons/Button/Button";
import AuthForm from "../../components/dataEntry/forms/AuthForm/AuthForm";
import Field from "../../components/dataEntry/inputs/Field/Field";
import CenterLayout from "../../components/layout/CenterLayout/CenterLayout";
import Position from "../../components/layout/position/Position";
import Paragraph from "../../components/general/typography/Paragraph/Paragraph";
import LinkText from "../../components/general/typography/LinkText/LinkText";
import { SignUpValues } from "./models/SignUpValues";
import { SUPPORT_ADMIN_EMAIL } from "../../utl/constants/app";
import { Error } from "../../utl/models/Error";

interface Props {
    formik: FormikProps<SignUpValues>;
    clickNavButtonHandler: () => void;
    emailError: string | null;
    telError: string | null;
    vendorNameError: string | null;
    isSubmit: boolean;
    setErrorEmailVendor: (payload: Error) => void;
    setErrorTelVendor: (payload: Error) => void;
    setErrorNameVendor: (payload: Error) => void;
}

const SignUp: FC<Props> =
    ({
        formik, 
        clickNavButtonHandler, 
        emailError, 
        telError, 
        vendorNameError, 
        isSubmit,
        setErrorEmailVendor,
        setErrorNameVendor,
        setErrorTelVendor
    }) => {
        const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit } = formik;
       
        const changeEmailHandler = (event: ChangeEvent<HTMLInputElement>) => {
            handleChange("email")(event);
            setErrorEmailVendor({ error: null });
        }

        const changeTelHandler = (event: ChangeEvent<HTMLInputElement>) => {
            handleChange("tel")(event);
            setErrorTelVendor({ error: null });
        }

        const changeVendorNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
            handleChange("vendorName")(event);
            setErrorNameVendor({ error: null });
        }

        const clickButtonSubmitHandler = () => {
            handleSubmit();
        }

        return (
            <CenterLayout>
                <AuthForm title={!isSubmit ? "Реєстрація" : ""}>
                    {!isSubmit ? <>
                        <Field
                            marginRight={0}
                            label="Email"
                            value={values.email}
                            handleChange={changeEmailHandler}
                            handleBlur={handleBlur("email")}
                            error={errors.email || emailError || undefined}
                            touched={touched.email}
                        />
                        <Field
                            marginRight={0}
                            label="Назва компанії"
                            value={values.vendorName}
                            handleChange={changeVendorNameHandler}
                            handleBlur={handleBlur("vendorName")}
                            error={errors.vendorName || vendorNameError || undefined}
                            touched={touched.vendorName}
                        />
                        <Field
                            marginRight={0}
                            label="ПІБ"
                            value={values.name}
                            handleChange={handleChange("name")}
                            handleBlur={handleBlur("name")}
                            error={errors.name}
                            touched={touched.name}
                        />
                        <Field
                            marginRight={0}
                            type="tel"
                            label="Мобільний"
                            value={values.tel}
                            handleChange={changeTelHandler}
                            handleBlur={handleBlur("tel")}
                            error={errors.tel || telError || undefined}
                            touched={touched.tel}
                            mask={"+38 (___) ___-__-__"}
                        />
                        <Field
                            marginRight={0}
                            type="password"
                            label="Пароль"
                            value={values.password}
                            handleChange={handleChange("password")}
                            handleBlur={handleBlur("password")}
                            error={errors.password}
                            touched={touched.password}
                        />
                        <Field
                            marginRight={0}
                            type="password"
                            label="Підтвердження паролю"
                            value={values.passwordCopy}
                            handleChange={handleChange("passwordCopy")}
                            handleBlur={handleBlur("passwordCopy")}
                            error={errors.passwordCopy}
                            touched={touched.passwordCopy}
                        />
                        <Button
                            disabled={!isValid}
                            title="Зареєструватись"
                            backgroundColor="#4775FE"
                            handleClick={clickButtonSubmitHandler}
                        />
                    </> :
                        <>
                            <Paragraph
                                textAlign="center"
                                content="Дякуємо за реєстрацію! Найближчим часом ми перевіремо ваші дані та надамо доступ до кабінету"
                                marginBottom={15}
                            />
                            <Paragraph
                                textAlign="center"
                                content="Якщо у вас виникли питання, напишіть нам"
                                marginBottom={5}
                            />
                            <LinkText
                                type="email"
                                content={SUPPORT_ADMIN_EMAIL}
                                link={SUPPORT_ADMIN_EMAIL}
                            />
                        </>
                    }
                </AuthForm>
                <Position width="100%" maxWidth={160} type="fixed" top={30} right={30}>
                    <Button
                        maxWidth={160}
                        backgroundColor="#FA5D54"
                        title="Увійти"
                        handleClick={clickNavButtonHandler}
                    />
                </Position>
            </CenterLayout>
        )
    }

export default SignUp;
