import { PayloadAction } from "@reduxjs/toolkit";
import { ClientsState } from "../models/ClientsState";
import { SetPageDataClientsPayload } from "../payload/SetPageDataClientsPayload";

const setPageDataClients = (state: ClientsState, action: PayloadAction<SetPageDataClientsPayload>) => {
    const { clinets } = action.payload;

    const pageClientsData = state.pageData;
    const newPageClientsData = [...pageClientsData];

    clinets.forEach(function (clinet) {
        const indexClient = newPageClientsData.findIndex(pageData => pageData.clientId === clinet.clientId);

        if (indexClient === -1) {
            newPageClientsData.push(clinet);
        } else {
            newPageClientsData[indexClient] = { ...newPageClientsData[indexClient], ...clinet };
        }
    });

    state.pageData = newPageClientsData;
}

export default setPageDataClients;