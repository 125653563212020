import { Location } from "../../store/state/locations/models/Location";

export const locationAdapter = (locationsIds: number[], locations: Location[]): string[] => {
    const citis: string[] = [];

    locationsIds.forEach((locationId, _) => {
        const index = locations.findIndex(location => location.id === locationId);
        const location = locations[index];

        const cityIndex = citis.findIndex(item => item === location.city) === -1;
        if(location && location.city && cityIndex) {
            citis.push(location.city);
        }
    });

    return citis;
}