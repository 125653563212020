import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Clients } from "../../../../services/reddyApi/Clients/Clients";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { downloadClientsTemplateFetch, downloadClientsTemplateSuccess } from "../clients.slice";

function* downloadClientsTemplateWorker(action: PayloadAction) {
    try {
        const { data } = yield call(Clients.downloadClientsTemplate);

        yield put(downloadClientsTemplateSuccess({ template: data }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => downloadClientsTemplateFetch();
            
            yield handleServerError(error, actionCreator);
        }
    }
}

export default downloadClientsTemplateWorker;