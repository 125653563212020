import React, { ChangeEvent, FC, FocusEvent } from "react";
import { shallowEqual } from "react-redux";
import Field from "../../../../../components/dataEntry/inputs/Field/Field";
import { useAppDispatch } from "../../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../../store/hooks/selector.hook";
import { setErrorTelEmployee } from "../../../../../store/state/employees/employees.slice";

interface Props {
    phone: string;
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T):
            T extends React.ChangeEvent<any> ? void :
            (e: string | React.ChangeEvent<any>) => void;
    };
    handleBlur: (event: FocusEvent<HTMLElement>) => void;
    error: string | undefined;
    touched: boolean | undefined;
}

const PhoneField: FC<Props> = ({ phone, handleChange, handleBlur, error, touched }) => {
    const telError = useAppSelector(state => state.employees.telError, shallowEqual);

    const dispatch = useAppDispatch();

    const changePhoneHandler = (event: ChangeEvent<HTMLInputElement>) => {
        handleChange("phone")(event);
        dispatch(setErrorTelEmployee({ error: null }));
    }

    return (
        <Field
            label="Телефон"
            maxWidth={160}
            marginBottom={0}
            labelLeftShift={0}
            value={phone}
            handleChange={changePhoneHandler}
            handleBlur={handleBlur}
            error={error || telError || undefined}
            touched={touched}
            mask={"+38 (___) ___-__-__"}
        />
    )
}

export default PhoneField;