import React, { FC } from "react";
import CreateClientFormContainer from "../GroupClientsForms/CreateClientForm/CreateClientForm.container";
import ButtonSearchClients from "../GroupClientsForms/SearchClients/Button";
import SearchClientsContainer from "../GroupClientsForms/SearchClients/SearchClients.container";

interface Props {
    buttonName?: string;
    isCreationScript?: boolean;
}

const CreateClientBlock: FC<Props> = ({isCreationScript = false, buttonName = "Додати клієнтів"}) => {
    return (
        <>
            <ButtonSearchClients buttonName={buttonName} />
            <SearchClientsContainer isCreationScript={isCreationScript} />
            <CreateClientFormContainer isCreationScript={isCreationScript} />
        </>
    );
}

export default CreateClientBlock;