import { takeLatest } from "redux-saga/effects";
import changeUpsellActivityWorker from "./changeUpsellActivityWorker";
import createUpsellWorker from "./createUpsellWorker";
import deleteUpsellWorker from "./deleteUpsellWorker";
import getUpsellsListWorker from "./getUpsellsListWorker";
import getUpsellWorker from "./getUpsellWorker";
import updateUpsellWorker from "./updateUpsellWorker";
import uploadUpsellIconWorker from "./uploadUpsellIconWorker";

export function* upsellsWatcher() {
    yield takeLatest("upsellsServices/getAdditionalListFetch", getUpsellsListWorker);
    yield takeLatest("upsellsServices/getAdditionalFetch", getUpsellWorker);
    yield takeLatest("upsellsServices/createAdditionalFetch", createUpsellWorker);
    yield takeLatest("upsellsServices/updateAdditionalFetch", updateUpsellWorker);
    yield takeLatest("upsellsServices/deleteAdditionalFetch", deleteUpsellWorker);
    yield takeLatest("upsellsServices/changeUpsellActivityFetch", changeUpsellActivityWorker);
    yield takeLatest("upsellsServices/uploadUpsellIconFetch", uploadUpsellIconWorker);
}