import { createSlice } from '@reduxjs/toolkit';
import { MoneyState } from './models/MoneyState';
import getCategoriesFetchReducer from './reducer/getCategories/getCategoriesFetch';
import getCategoriesSuccessReducer from './reducer/getCategories/getCategoriesSuccess';
import updateCategoriesFetchReducer from './reducer/updateCategories/updateCategoriesFetch';
import updateCategoriesSuccessReducer from './reducer/updateCategories/updateCategoriesSuccess';
import setMessageReducer from './reducer/setMessage';
import setMessageVisiableReducer from './reducer/setMessageVisiable';
import resetMoneyCategoriesReducer from './reducer/resetMoneyCategories';
import setErrorReducer from './reducer/setError';

export const initialState: MoneyState = {
    operations: [],
    discounts: [],
    purses: [],
    isLoading: false,
    isError: null,
    message: null,
    messageVisiable: false,
}

export const MoneySlice = createSlice({
    name: 'money',
    initialState,
    reducers: {
        getCategoriesFetch: getCategoriesFetchReducer,
        getCategoriesSuccess: getCategoriesSuccessReducer,
        updateCategoriesFetch: updateCategoriesFetchReducer,
        updateCategoriesSuccess: updateCategoriesSuccessReducer,
        setError: setErrorReducer,
        setMessage: setMessageReducer,
        setMessageVisiable: setMessageVisiableReducer,
        resetMoneyCategories: resetMoneyCategoriesReducer,
    },
});

export const { 
    getCategoriesFetch, 
    getCategoriesSuccess,
    updateCategoriesFetch, 
    updateCategoriesSuccess,
    setError,
    setMessage,
    setMessageVisiable,
    resetMoneyCategories,
} = MoneySlice.actions;

export default MoneySlice.reducer;