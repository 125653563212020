import { useEffect } from "react";
import { Service } from "../../../../../store/state/services/service/models/Service";
import { DAY_HOUR, DURATION_TYPE_DAY } from "../../../../../utl/constants/duration";

interface Payload {
    updated: boolean;
    selectedService: Service | null;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const useServiceLoading = (payload: Payload) => {
    const { updated, selectedService, setFieldValue } = payload;

    useEffect(() => {
        if (updated && selectedService) {
            let duration = selectedService.duration;
            const durationTypeId = selectedService.durationTypeId;

            if(DURATION_TYPE_DAY === durationTypeId) {
                duration = duration * DAY_HOUR;
            }

            setFieldValue("upsellsIds", selectedService.upsellsIds.join(","));

            const lessons = selectedService.lessons.map(lesson => ({
                id: null,
                name: lesson,
                nameForTeacher: "Тема уроку",
                date: "",
                time: "",
                durationInHours: duration,
            }));

            setFieldValue("lessons", lessons);
        }
    }, [selectedService]);
}

export default useServiceLoading;