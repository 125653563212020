import React, { FC } from "react";
import DataBlock from "../../../../components/dataDisplay/DataBlock/DataBlock";
import InfoItem from "../../../../components/dataDisplay/InfoList/InfoItem";
import InfoList from "../../../../components/dataDisplay/InfoList/InfoList";
import PriceCategoryItem from "../../../../components/dataDisplay/PriceCategoryList/PriceCategoryItem";
import PriceCategoryList from "../../../../components/dataDisplay/PriceCategoryList/PriceCategoryList";
import Paragraph from "../../../../components/general/typography/Paragraph/Paragraph";
import Title from "../../../../components/general/typography/Title/Title";
import Column from "../../../../components/layout/Column/Column";
import Wrp from "../../../../components/layout/Wrp/Wrp";
import { Operation } from "../../../../store/state/finance/models/Future";
import { Purses } from "../../../../store/state/finance/models/Purses";
import { Operations } from "../../../../store/state/money/models/Operations";
import { allPursesSumMoneyAdapter } from "../../../../utl/adapters/AllPursesSumAdapter";
import { MoneyAdapter } from "../../../../utl/adapters/MoneyAdapter";

interface Props {
    incomes: Operation[];
    expenses: Operation[];
    purses: Purses[];
    operations: Operations[];
}

const StatisticsBlock: FC<Props> = ({incomes, expenses, purses, operations}) => {
    const sumPurses = allPursesSumMoneyAdapter(purses);
    const pursesItems = purses.map(purse => (<InfoItem name={purse.name} price={`${purse.sum}`} />));

    let incomeSum = 0;
    const incomeItems = incomes.map(income => {
        incomeSum += income.sum;
        const name = MoneyAdapter(operations, income.operationId) || "Name";
        return (
            <PriceCategoryItem name={name} price={`${income.sum}`} />
        )
    });

    let expensesSum = 0;
    const expensesItems = expenses.map(expense => {
        expensesSum += expense.sum;
        const name = MoneyAdapter(operations, expense.operationId) || "Name";
        return (
            <PriceCategoryItem name={name} price={`${expense.sum}`} />
        )
    });

    return (
        <Column maxWidth={330}>
            <DataBlock>
                <Wrp flexDirection="column" marginBottom={40}>
                    <Title 
                        level={3} 
                        color={"#A6AEC5"} 
                        fontWeight={"500"} 
                        title={"Всього на рахунках"} 
                        marginBottom={5}
                    />
                    <Paragraph 
                        content={`₴ ${sumPurses}`} 
                        fontSize={32}
                        lineHeight={38}
                        fontWeight="normal" 
                        color="#4775FE" 
                    />
                </Wrp>
                <Wrp flexDirection="column" marginBottom={40}>
                    <InfoList name="Мої рахунки" linkTo="/settings/money">
                        {pursesItems}
                    </InfoList>
                </Wrp>
                <Wrp flexDirection="column" marginBottom={40}>
                    <Title 
                        level={3} 
                        color={"#131313"} 
                        fontWeight={"700"} 
                        title={"Всього на рахунках"} 
                        marginBottom={20}
                    />
                    <PriceCategoryList name="Майбутні доходи" price={`${incomeSum}`} >
                        {incomeItems}
                    </PriceCategoryList>
                    <PriceCategoryList name="Майбутні витрати" price={`${expensesSum}`} >
                        {expensesItems}                        
                    </PriceCategoryList>
                </Wrp>
            </DataBlock>
        </Column>
    )
}

export default StatisticsBlock;