import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Groups } from "../../../../services/reddyApi/Groups/Groups";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { getStatisticsClient, getStatisticsDashboard, getStatisticsFinance, getStatisticsGroup } from "../../statistics/statistics.saga";
import { getStudentsListForGroupFetch, updateGroupFetch, updateGroupSuccess } from "../groups.slice";
import { UpdateGroupPayload } from "../payload/UpdateGroup/UpdateGroupPayload";

function* updateGroupWorker(action: PayloadAction<UpdateGroupPayload>) {
    const { vendorId, group, groupId, filter, clientsIds } = action.payload;
    const dataParams = {
        vendorId,
        group,
        groupId
    }

    try {
        const { status } = yield call(Groups.update, dataParams);

        yield put(updateGroupSuccess({ ...group, id: groupId, }));

        yield getStatisticsGroup({ vendorId, groupId });

        yield put(getStudentsListForGroupFetch({ vendorId, groupId, filter }));

        yield getStatisticsDashboard({ vendorId, filter });

        yield getStatisticsFinance({ vendorId, filter });

        for (let clientId of clientsIds) {
            yield getStatisticsClient({ vendorId, clientId });
        }

        yield getNotifications(vendorId);

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => updateGroupFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default updateGroupWorker;