import { AxiosResponse } from "axios"
import instance from "../instance";
import { ReqGetUserConfig, ReqGetVendorConfig } from "./Configuration.request";
import { ResGetUserConfig, ResGetVendorConfig } from "./Configuration.response";

interface Configuration {
    getUserConfig: () => Promise<AxiosResponse<ResGetUserConfig>>;
    getVendorConfig: () => Promise<AxiosResponse<ResGetVendorConfig>>
}

export const Configuration: Configuration = {
    getUserConfig: () => {
        return instance.get(`/config/user`);
    },
    getVendorConfig: () => {
        return instance.get(`/config/vendor`);
    }
}