import { AxiosResponse } from 'axios';
import instance from '../instance';
import { ChangeNotificationsStatusRequest, GetNotificationsRequest } from './Notifications.request';
import { GetNotificationsResponse } from './Notifications.response';

interface NotificationsInterface {
    getNotifications: (data: GetNotificationsRequest) =>
        Promise<AxiosResponse<GetNotificationsResponse>>;
    changeNotificationsStatus: (data: ChangeNotificationsStatusRequest) => Promise<AxiosResponse>;
}

export class Notifications implements NotificationsInterface {
    constructor() {}
    
    getNotifications(data: GetNotificationsRequest): Promise<AxiosResponse<GetNotificationsResponse>> {
        const { vendorId, filters } = data;
        
        return instance.get(`/vendors/${vendorId}/notifications`, {
            params: {
                rangeFrom: filters?.range?.from,
                rangeTo: filters?.range?.to,
                locationsIds: filters?.locationsIds,
                statusId: filters?.statusId,
                typeId: filters?.typeId,
            }
        });
    }

    changeNotificationsStatus(data: ChangeNotificationsStatusRequest): Promise<AxiosResponse> {
        const { vendorId, statusId, notificationsIds } = data;

        return instance.put(`/vendors/${vendorId}/notifications/status`, { statusId, notificationsIds });
    }
}