import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Groups } from "../../../../services/reddyApi/Groups/Groups";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getStudentsListForGroupFetch, getStudentsListForGroupSuccess } from "../groups.slice";
import { GetStudentsListForGroupPayload } from "../payload/GetStudentsListForGroup/GetStudentsListForGroupPayload";

function* getStudentsListForGroupWorker(action: PayloadAction<GetStudentsListForGroupPayload>) {
    const { vendorId, groupId, filter } = action.payload;

    try {
        const { data } = yield call(Groups.getStudentsListForGroup, { vendorId, groupId, filter });

        yield put(getStudentsListForGroupSuccess({ students: [...data], groupId, filter }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getStudentsListForGroupFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getStudentsListForGroupWorker;