import React, { FC } from "react";
import { PagePagination } from "../../../../components/dataDisplay/table/models/PagePagination";
import Table from "../../../../components/dataDisplay/table/Table";
import Column from "../../../../components/layout/Column/Column";
import Row from "../../../../components/layout/Row/Row";
import { DATA_INDEX_FINANCE } from "../../../../utl/constants/tables";
import AddFormContainer from "../../FinanceForms/AddForm/AddForm.container";
import ExchangeFormContainer from "../../FinanceForms/ExchangeForm/ExchangeForm.container";
import PaymentFormContainer from "../../FinanceForms/PaymentForm/PaymentForm.container";
import { FinanceColumn } from "../../models/FinanceColumn";
import TypeFilter from "../TypeFilter";

interface Props {
    data: FinanceColumn[];
    page: number;
    pages: PagePagination[];
    handleLeftButtonPagination: () => void;
    handleRightButtonPagination: () => void;
}

const DataTableBlock: FC<Props> = ({ data, page, pages, handleLeftButtonPagination, handleRightButtonPagination }) => {
    return (
        <Column paddingRight={25}>
            <Row marginBottom={10}>
                <TypeFilter />
            </Row>

            <Table
                columns={DATA_INDEX_FINANCE}
                data={data}
                paginated={true}
                page={page}
                pages={pages}
                heightRow={40}
                leftShiftContentRow={15}
                handleLeftButtonPagination={handleLeftButtonPagination}
                handleRightButtonPagination={handleRightButtonPagination}
            />

            <AddFormContainer />

            <PaymentFormContainer />

            <ExchangeFormContainer />
        </Column>
    )
}

export default DataTableBlock;