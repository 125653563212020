import React, { FC, useContext } from "react";
import { Context } from "./Dropdown";
import styles from "./Dropdown.module.css";

interface Props {
    item: string | JSX.Element;
    valueText: string;
    valueInput: string;
}

const DropdownItem: FC<Props> = ({item, valueText, valueInput}) => {
    const context = useContext(Context);

    const clickItemHandler = () => {
        context?.setToggle();
        context?.setValue(valueText, valueInput);
    }

    return (
        <li className={styles.dropdownItem} onClick={clickItemHandler}>
            {item}
        </li>
    );
}

export default DropdownItem;