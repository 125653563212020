import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Employees } from "../../../../services/reddyApi/Employees/Employees";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getTeachersListFetch, getTeachersListSuccess } from "../employees.slice";
import { GetTeachersListPayload } from "../payload/GetTeachersList/GetTeachersListPayload";

function* getTeachersListWorker(action: PayloadAction<GetTeachersListPayload>) {
    const { vendorId } = action.payload;

    try {
        const { data } = yield call(Employees.getTeachersList, { vendorId });

        yield put(getTeachersListSuccess(data));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getTeachersListFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getTeachersListWorker;