import { PayloadAction } from "@reduxjs/toolkit";
import { LocationsState } from "../../models/LocationsState";
import { GetLocationSuccessPayload } from "../../payload/GetLocation/GetLocationSuccessPayload";

const getLocationSuccess = (state: LocationsState, action: PayloadAction<GetLocationSuccessPayload>) => {
    const { location } = action.payload;

    const locations = state.locationsArray;
    const locationIndex = locations.findIndex(loc => loc.id === location.id);

    if(locationIndex > -1) {
        locations[locationIndex] = location; 
    } else {
        locations.unshift(location);
    }
}

export default getLocationSuccess;