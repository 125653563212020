import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Clients } from "../../../../services/reddyApi/Clients/Clients";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getFinanceInfoForVendorFetch, getFinanceInfoForVendorSuccess } from "../clients.slice";
import { GetFinanceInfoForVendorPayload } from "../payload/GetFinanceInfoForVendor/GetFinanceInfoForVendorPayload";

function* getFinanceInfoForVendorWorker(action: PayloadAction<GetFinanceInfoForVendorPayload>) {
    const { vendorId, clientId } = action.payload;
    const dataParams = {
        vendorId,
        clientId
    }

    try {
        const { data } = yield call(Clients.getFinanceInfoForVendor, dataParams);

        yield put(getFinanceInfoForVendorSuccess({ clientId, financeInfo: { ...data } }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getFinanceInfoForVendorFetch(action.payload);
            
            yield handleServerError(error, actionCreator);
        }
    }
}

export default getFinanceInfoForVendorWorker;