import { PayloadAction } from "@reduxjs/toolkit";
import { ServiceState } from "../models/ServiceState";
import { SetServiceFilterPayload } from "../payload/SetServiceFilterPayload";

const setServiceFilter = (state: ServiceState, action: PayloadAction<SetServiceFilterPayload>) => {
    const { confirmed, categoryId, typeId, active } = action.payload;

    state.filter.confirmed = confirmed;
    state.filter.categoryId = categoryId;
    state.filter.typeId = typeId;
    state.filter.active = active;
}

export default setServiceFilter;