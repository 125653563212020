import { Filter } from "../models/Filters";
import { culcPage } from "./culcPage";

interface Payload {
    getAllItems: boolean;
    pageRange: ReturnType<typeof culcPage>;
    active?: boolean;
    type?: string;
    typeId?: number;
    categoryId?: number;
    confirmed?: boolean;
}

const getFilterPage = (payload: Payload) => {
    const { getAllItems, pageRange, active, type, typeId, categoryId, confirmed } = payload;

    let filter: Filter = {
        active,
        type,
        typeId,
        categoryId,
        confirmed,
    };

    if (!getAllItems) {
        filter.range = {
            from: pageRange.from,
            to: pageRange.to
        }
    }

    return filter
}

export default getFilterPage;