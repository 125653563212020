import { PayloadAction } from "@reduxjs/toolkit";
import createItem from "../../../../helpers/createItem";
import { EmployeesState } from "../../models/EmployeesState";
import { CreateEmployeeSuccessPayload } from "../../payload/CreateEmployee/CreateEmployeeSuccessPayload";

const createEmployeeSuccess = (state: EmployeesState, action: PayloadAction<CreateEmployeeSuccessPayload>) => {
    const employee = action.payload;

    const emloyees = state.employeesArray;
    createItem(state, emloyees, employee);

    state.isLoading = false;
    state.isError = null;
    state.message = "Працівника успішно створено";
    state.messageVisiable = true;
    state.newItemId = employee.id;
}

export default createEmployeeSuccess