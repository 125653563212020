import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Clients } from "../../../../services/reddyApi/Clients/Clients";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { importClientsByVendorFetch, importClientsByVendorSuccess } from "../clients.slice";
import { ImportClientsByVendorPayload } from "../payload/ImportClientsByVendorPayload";

function* importClientsByVendorWorker(action: PayloadAction<ImportClientsByVendorPayload>) {
    const { vendorId, file } = action.payload;

    try {
        const { data, status } = yield call(Clients.importClientsByVendor, { vendorId, file });

        if (status === 200) {
            yield put(importClientsByVendorSuccess());

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => importClientsByVendorFetch(action.payload);
            
            yield handleServerError(error, actionCreator);
        }
    }
}

export default importClientsByVendorWorker;