import { AxiosResponse } from "axios"
import instance from "../instance";
import { 
    AddCommentRequest, 
    AddSudentRequest, 
    CreateClientByVendorRequest, 
    DeleteClientForVendorRequest, 
    GetClientCommentsByVendorRequest, 
    GetClientsListForVendorRequest, 
    GetClientStatisticsForVendorRequest, 
    GetFinanceInfoForVendorRequest, 
    GetVendorClientDataRequest, 
    ImportClientsByVendorRequest, 
    SearchClientByStudentRequest, 
    SearchClientForVendorRequest, 
    UpdateClientDiscountRequest, 
    UploadDocRequest 
} from "./Clients.request";
import { 
    AddCommentResponse, 
    AddStudentResponse, 
    CreateResponse, 
    GetCommentsByVendorResponse, 
    GetListForVendorResponse, 
    GetVendorClientDataResponse, 
    ResGetClientStatisticsForVendor, 
    ResGetFinanceInfoForVendor, 
    SearchClientForVendorResponse, 
    SearchListClientsResponse 
} from "./Clients.response";

interface Clients {
    createByVendor: (data: CreateClientByVendorRequest) =>
        Promise<AxiosResponse<CreateResponse>>;
    addStudent: (data: AddSudentRequest) =>
        Promise<AxiosResponse<AddStudentResponse>>;
    uploadDoc: (data: UploadDocRequest) =>
        Promise<AxiosResponse>;
    addComment: (data: AddCommentRequest) =>
        Promise<AxiosResponse<AddCommentResponse>>;
    deleteForVendor: (data: DeleteClientForVendorRequest) =>
        Promise<AxiosResponse>;
    getCommentsByVendor: (data: GetClientCommentsByVendorRequest) =>
        Promise<AxiosResponse<GetCommentsByVendorResponse>>;
    getListForVendor: (data: GetClientsListForVendorRequest) =>
        Promise<AxiosResponse<GetListForVendorResponse>>;
    searchClientForVendor: (data: SearchClientForVendorRequest) =>
        Promise<AxiosResponse<SearchClientForVendorResponse>>;
    searchClientByStudent: (data: SearchClientByStudentRequest) =>
        Promise<AxiosResponse<SearchListClientsResponse>>;
    getClientStatisticsForVendor: (data: GetClientStatisticsForVendorRequest) =>
        Promise<AxiosResponse<ResGetClientStatisticsForVendor>>;
    getFinanceInfoForVendor: (data: GetFinanceInfoForVendorRequest) =>
        Promise<AxiosResponse<ResGetFinanceInfoForVendor>>;
    getVendorClientData: (data: GetVendorClientDataRequest) =>
        Promise<AxiosResponse<GetVendorClientDataResponse>>;
    updateClientDiscount: (data: UpdateClientDiscountRequest) =>
        Promise<AxiosResponse>;
    downloadClientsTemplate: () => Promise<AxiosResponse>;
    importClientsByVendor: (data: ImportClientsByVendorRequest) => Promise<AxiosResponse>;
}

export const Clients: Clients = {
    createByVendor: (data) => {
        const { vendorId, user, students, discountId } = data;

        return instance.post(`/vendors/${vendorId}/clients`, { ...user, students, discountId });
    },
    addStudent: (data) => {
        const { vendorId, student, clientId } = data;

        return instance.post(`/vendors/${vendorId}/clients/${clientId}/students`, student);
    },
    uploadDoc: (data) => {
        const { vendorId, clientId, image } = data;

        return instance.post(`/vendors/${vendorId}/clients/${clientId}/docs`, { image });
    },
    addComment: (data) => {
        const { vendorId, clientId, comment } = data;

        return instance.post(`/vendors/${vendorId}/clients/${clientId}/comments`, comment);
    },
    deleteForVendor: (data) => {
        const { vendorId, clientId } = data;

        return instance.delete(`/vendors/${vendorId}/clients/${clientId}`);
    },
    getCommentsByVendor: (data) => {
        const { vendorId, clientId, filter } = data;

        return instance.get(`/vendors/${vendorId}/clients/${clientId}/comments`, {
            params: {
                rangeFrom: filter?.range?.from,
                rangeTo: filter?.range?.to
            }
        });
    },
    getListForVendor: (data) => {
        const { vendorId, filter } = data;

        return instance.get(`/vendors/${vendorId}/clients`, {
            params: {
                rangeFrom: filter?.range?.from,
                rangeTo: filter?.range?.to
            }
        });
    },
    searchClientForVendor: (data) => {
        const { value, vendorId } = data;

        return instance.post(`/vendors/${vendorId}/clients/search`, { value });
    },
    searchClientByStudent: (data) => {
        const { student } = data;

        return instance.post(`/auth/search-clients`, student);
    },
    getClientStatisticsForVendor: (data) => {
        const { vendorId, filter } = data;

        return instance.get(`/vendors/${vendorId}/clients/statistics`, {
            params: {
                periodFrom: filter?.period?.from,
                periodTo: filter?.period?.to,
            }
        });
    },
    getFinanceInfoForVendor: (data) => {
        const { vendorId, clientId } = data;

        return instance.get(`/vendors/${vendorId}/clients/${clientId}/finances`);
    },
    getVendorClientData: (data) => {
        const { vendorId, clientId } = data;

        return instance.get(`/vendors/${vendorId}/clients/${clientId}`);
    },
    updateClientDiscount: (data) => {
        const { vendorId, clientId, discountId } = data;

        return instance.put(`/vendors/${vendorId}/clients/${clientId}/discount`, { discountId });
    },
    downloadClientsTemplate: () => {
        return instance.get(`/files/downloads/vendors/clients.csv`);
    },
    importClientsByVendor: (data) => {
        const { vendorId, file } = data;

        const formData = new FormData();
        formData.append('file', file, "clients.csv");

        return instance.post(`/vendors/${vendorId}/clients/import`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    }
}