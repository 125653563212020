import moment from "moment";
import React from "react";
import { shallowEqual } from "react-redux";
import { DateApi } from "../../../../services/Date/Date";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import ParametersClientsBlock from "./ParametersClientsBlock";

const ParametersClientsBlockContainer = () => {
    const statistics = useAppSelector(state => state.clients.statistics, shallowEqual);
    const today = new Date();
    const period = `за ${DateApi.getMothUAName(today.getMonth())} ${today.getFullYear()}р.`;

    return (
        <ParametersClientsBlock
            period={period}
            statistics={statistics}
        />
    )
}

export default React.memo(ParametersClientsBlockContainer);