import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { PagePagination } from "../../../../../components/dataDisplay/table/models/PagePagination";
import PriceView from "../../../../../components/dataDisplay/table/views/PriceView/PriceView";
import ButtonInfo from "../../../../../components/general/buttons/ButtonInfo/ButtonInfo";
import IconFont from "../../../../../components/general/icons/IconFont/IconFont";
import { DateApi } from "../../../../../services/Date/Date";
import { useAppDispatch } from "../../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../../store/hooks/selector.hook";
import { getClientMoneyTransactionsByVendorFetch, setPageClientsTransactions } from "../../../../../store/state/clients/clients.slice";
import { MoneyAdapter } from "../../../../../utl/adapters/MoneyAdapter";
import { culcPage } from "../../../../../utl/helpers/culcPage";
import { TransactionsColumn } from "../../../models/TransactionsColumn";
import Transactions from "./Transactions";
import TransactionsPagination from "./Transactions.pagination";

const TransactionsContainer: FC = () => {
    let { id } = useParams();
    const clientId = (id && parseInt(id)) || -1

    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const clients = useAppSelector(state => state.clients.pageData, shallowEqual);
    let indexClient = clients.findIndex(client => client.clientId === clientId);
    let client = clients[indexClient];
    
    let transactions = client.transactions || [];

    const purses = useAppSelector(state => state.money.purses, shallowEqual);
    const operations = useAppSelector(state => state.money.operations, shallowEqual);

    const data: TransactionsColumn[] = transactions.map(transaction => {
        return ({
            datetime: DateApi.parseDateTimeStringPrint(transaction.createdAt),
            sum: <PriceView income={transaction.income} price={transaction.sum} />,
            score: `${MoneyAdapter(purses, transaction.purseId)}`,
            category: `${MoneyAdapter(operations, transaction.operationId) || "-"}`,
            responsible: transaction.responsibleUser?.fullname || "-",
            comment:
                <ButtonInfo
                    icon={<IconFont color="#A6AEC5" iconClass="icon-messageicon" fontSize={15} />}
                    info={transaction.comment || "Коментар не додан"}
                />
        })
    });

    return (
        <TransactionsPagination
            data={data}
            vendorId={vendorId}
            clientId={clientId}
        />
    );
}

export default TransactionsContainer;