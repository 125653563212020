import React, { FC, FocusEvent, useState } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./Dropdown.module.css";
import "./transition.css";
import classNames from "classnames";
import IconFont from "../../../general/icons/IconFont/IconFont";
import { DefaultValue } from "./models/DefaultValue";
import { ContextValue } from "./models/ContextValue";

const cx = classNames.bind(styles);

interface Props {
    label?: string;
    children: JSX.Element[] | JSX.Element;
    maxWidth: number | string;
    width?: number | string;
    height?: number;
    borderRadius?: number;
    defaultValue?: DefaultValue;
    icon?: JSX.Element;
    backgroundColorIcon?: string;
    doubleClickDropdown?: boolean;
    marginRight?: number;
    marginBottom?: number;
    paddingLeft?: number;
    paddingRight?: number;
    handleChange?: (value: string) => void;
    handleBlur?: (touched: boolean) => void;
    handleFocus?: (event: FocusEvent<any>) => void;
    error?: string | undefined;
    touched?: boolean | undefined;
    values?: string | number;
    isTransparent?: boolean;
}

export const Context = React.createContext<ContextValue | null>(null);

const Dropdown: FC<Props> = ({ children, maxWidth, width = maxWidth, height, borderRadius = 40,
    defaultValue = { valueInput: "", valueText: "" }, icon, backgroundColorIcon = "#4775FE", doubleClickDropdown = true, label,
    marginRight, values, handleChange, handleBlur, handleFocus, error, touched, marginBottom, paddingLeft, paddingRight,
    isTransparent
}) => {
    const [toggle, setToggle] = useState<boolean>(false);
    const [active, setActive] = useState<boolean>(!doubleClickDropdown);
    const [zIndexInput, setZIndexInput] = useState<number>(3);
    const [zIndexList, setZIndexList] = useState<number>(2);

    const setDropdownValue = (textValue: string, inputValue: string) => {
        if (doubleClickDropdown) {
            setActive(false);
        }

        if (handleChange) {
            handleChange(inputValue);
        }
    }

    const blurDropdownHandler = (event: FocusEvent) => {
        setToggle(false);
        setZIndexInput(3);
        setZIndexList(2);
    }

    const doubleClickHandler = () => {
        setActive(true);
    }

    const setToogleHandler = () => {
        if(handleBlur) {
            handleBlur(true);
        }

        setToggle(prev => {
            if (prev) {
                setTimeout(() => {
                    setZIndexInput(3);
                    setZIndexList(2);
                }, 500);
            } else {
                setZIndexInput(15);
                setZIndexList(10);
            }

            return !prev;
        });
    }

    return (
        <Context.Provider value={{ setValue: setDropdownValue, setToggle: setToogleHandler }}>
            <div
                className={styles.dropdownBlock}
                style={
                    {
                        maxWidth,
                        marginRight,
                        marginBottom,
                        flexDirection: doubleClickDropdown ? "row" : "column",
                        justifyContent: doubleClickDropdown ? "center" : "flex-start",
                        alignItems: doubleClickDropdown ? "center" : "flex-start"
                    }
                }
            >
                {label && !doubleClickDropdown && <p style={{ marginBottom: 5 }} className={styles.infoBlock}>{label}</p>}
                {doubleClickDropdown && label &&
                    <CSSTransition
                        in={!active}
                        timeout={400}
                        unmountOnExit
                        classNames="dropdown-list"
                    >
                        <p onDoubleClick={doubleClickHandler} className={styles.infoBlock}>
                            {label}: <span className={styles.value}>{defaultValue.valueText}</span>
                        </p>
                    </CSSTransition>
                }
                <CSSTransition
                    in={active}
                    timeout={400}
                    unmountOnExit
                    classNames="dropdown-list"
                >
                    <div
                        style={{ width, height, borderRadius, zIndex: zIndexInput, paddingLeft, paddingRight }}
                        className={cx(
                            styles.dropdown,
                            icon && styles.icon,
                            doubleClickDropdown && styles.doubleClick,
                            touched && error && styles.error,
                            isTransparent && styles.transparent,
                        )}
                        onClick={setToogleHandler}
                    >
                        {icon &&
                            <div className={styles.iconBlock} style={{ backgroundColor: backgroundColorIcon }}>
                                {icon}
                            </div>
                        }
                        <span className={styles.name}>
                            {defaultValue.valueText}
                        </span>
                        <div className={cx(styles.iconArrow, toggle && styles.active)}>
                            <IconFont iconClass="icon-arrow" fontSize={7} color="#131313" />
                        </div>
                    </div>
                </CSSTransition>
                <CSSTransition
                    in={toggle}
                    timeout={400}
                    unmountOnExit
                    classNames="dropdown-list"
                >
                    <ul className={cx(styles.dropdownList, isTransparent && styles.transparent,)}
                        style={{
                            borderBottomRightRadius: borderRadius / 2,
                            borderBottomLeftRadius: borderRadius / 2,
                            zIndex: zIndexList,
                            width
                        }}
                    >
                        {children}
                    </ul>
                </CSSTransition>
                <input type="hidden" value={defaultValue.valueInput} />
            </div>
        </Context.Provider>
    );
}

export default Dropdown;