import React, { FC, MouseEvent } from "react";
import { shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import RectProgress from "../../components/dataDisplay/progress/Rect/RectProgress";
import NameView from "../../components/dataDisplay/table/views/NameView/NameView";
import StatusView from "../../components/dataDisplay/table/views/StatusView/StatusView";
import Avatar from "../../components/dataDisplay/user/Avatar/Avatar";
import ButtonIcon from "../../components/general/buttons/ButtonIcon/ButtonIcon";
import IconFont from "../../components/general/icons/IconFont/IconFont";
import withMatch from "../../components/other/hoc/withMatch";
import { DateApi } from "../../services/Date/Date";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { configAdapter } from "../../utl/adapters/ConfigAdapter";
import { locationAdapter } from "../../utl/adapters/LocationAdapter";
import GroupsPagination from "./Groups.pagination";
import { GroupsColumn } from "./models/GroupsColumn";

const GroupsContainer: FC = () => {
    let navigation = useNavigate();
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const groups = useAppSelector(state => state.groups.groups, shallowEqual);
    const groupsData = useAppSelector(state => state.groups.pageData, shallowEqual);
    const shortServices = useAppSelector(state => state.services.shortServices, shallowEqual);
    const locations = useAppSelector(state => state.locations.locationsArray, shallowEqual);
    const types = useAppSelector(state => state.config.vendorConfig?.service.types, shallowEqual) || [];

    const data: GroupsColumn[] = groups.map(group => {
        const groupDataIndex = groupsData.findIndex(groupData => groupData.id === group.id);
        const groupData = groupsData[groupDataIndex];

        const serviceId = groupData?.serviceId || -1;
        const serviceIndex = shortServices.findIndex(shortService => shortService.id === serviceId);
        const service = shortServices[serviceIndex];

        const serviceTypeId = service?.typeId || -1;
        const type = configAdapter(types, serviceTypeId) || "";

        const confirmed = group.confirmed;
        const active = group.active;

        const studentsAmount = groupData?.activeStudentsAmount || 0;
        const studentsMax = groupData?.studentsMax || 0;
        const progress = Math.floor(studentsAmount * 100 / studentsMax);

        const endDate = groupData?.endDate || "";

        const locationId = groupData?.locationId || -1
        const cytis = locationAdapter([locationId], locations);
        const city = cytis[0];

        const name = groupData?.name || "";
        const serviceName = service?.name || "";


        return {
            id: group.id,
            miniature: 
            <Avatar
                name={name}
                width={50}
                height={50}
                background="#A6AEC5" 
                backgroundName="#A6AEC5" 
                fontSize={18}
                lineHeight={21}
                maxHeightImg={"100%"}
                maxWidthImg={"100%"}
            />,
            name: <NameView name={name} subname={serviceName} />,
            location: city,
            type,
            status: <StatusView status={active ? Number(confirmed) : 2} />,
            endDate: DateApi.parseDateStringPrint(endDate),
            countStudents: studentsAmount,
            occupancy: 
            <RectProgress progress={progress} />,
            edit:
            <ButtonIcon 
                icon={<IconFont color="#A6AEC5" iconClass="icon-edit" fontSize={13} />}  
                handleClick={() => navigation(`${group.id}/clients`, {})} 
            />,
        }
    });

    const clickRowTableHandler = (item: any, event: MouseEvent<HTMLTableRowElement>) => {
        navigation(`${item.id}/clients`, {})
    }

    return (
        <GroupsPagination 
            handleClickRow={clickRowTableHandler}
            data={data}
            vendorId={vendorId}
        />
    );
}

export default withMatch(React.memo(GroupsContainer), [2, 3, 4]);