import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Groups } from "../../../../services/reddyApi/Groups/Groups";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { getStatisticsClient, getStatisticsDashboard, getStatisticsFinance, getStatisticsService } from "../../statistics/statistics.saga";
import { changeGroupActivityFetch, changeGroupActivitySuccess, deleteGroupSuccess, getGroupFetch } from "../groups.slice";
import { ChangeGroupActivityFetchType } from "../reducer/changeGroupActivity/changeGroupActivityFetch";

function* changeGroupActivityWorker(action: PayloadAction<ChangeGroupActivityFetchType>) {
    const { vendorId, groupId, active, clientsIds, filter, serviceId } = action.payload;

    try {
        const { status } = yield call(Groups.changeGroupActivity, { vendorId, groupId, active });

        if (status === 200) {
            yield put(changeGroupActivitySuccess({ groupId, active }));

            if (active) {
                yield put(getGroupFetch({ vendorId, groupId }));
            } else {
                // yield put(deleteGroupSuccess({ groupId }));
            }

            yield getStatisticsDashboard({ vendorId, filter });

            yield getStatisticsFinance({ vendorId, filter });
    
            for (let clientId of clientsIds) {
                yield getStatisticsClient({ vendorId, clientId });
            }
    
            yield getStatisticsService({ vendorId, serviceId });

            yield getNotifications(vendorId);

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => changeGroupActivityFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default changeGroupActivityWorker;