import React from "react";
import { Outlet } from "react-router-dom";
import CenterLayout from "../../components/layout/CenterLayout/CenterLayout";

const Recovery = () => {
    return (
        <CenterLayout>
            <Outlet />
        </CenterLayout>
    );
}

export default Recovery;