import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import { 
    getVendorMoneyTransactionsFetch, 
    setPageTransactions, 
    setTransactions, 
    setTransactionsFilter 
} from "../../../store/state/finance/finance.slice";
import { TYPE } from "../../../utl/constants/transactions";
import { culcPage } from "../../../utl/helpers/culcPage";
import { Filter } from "../../../utl/models/Filters";
import DropdownFilter, { DropdownFilterItem } from "../../Services/Basics/components/DropdownFilter/DropdownFilter";


const TypeFilter: FC = () => {
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const type = useAppSelector(state => state.finance.finance.filter.type, shallowEqual);
    const value = String(type);

    const page = 1;
    const totalCount = 0;
    const pageAll = 0;
    const pageSize = useAppSelector(state => state.finance.finance.pageSize, shallowEqual);
    const getAllItems = useAppSelector(state => state.finance.finance.getAllItems, shallowEqual);
    const pagesRange = culcPage(page, pageAll, totalCount, pageSize);

    const types: DropdownFilterItem[] = [
        {
            name: "Дохід",
            value: TYPE.INCOME,
        },
        {
            name: "Витрата",
            value: TYPE.EXPENSE,
        },
        {
            name: "Переказ",
            value: TYPE.TRANSFER,
        },
        {
            name: "Всі",
            value: "undefined",
        }
    ];

    const dispatch = useAppDispatch();

    const changeHandler = (value: string) => {
        let filter: Filter = {};

        if (!getAllItems) {
            filter.range = {
                from: pagesRange.from,
                to: pagesRange.to
            }
        }

        if (value.localeCompare('undefined') !== 0) {
            const typeValue = value;
            filter.type = typeValue;

            dispatch(setTransactionsFilter({ type: typeValue }));
        } else {
            dispatch(setTransactionsFilter({ type: undefined }));
        }

        dispatch(setTransactions({ transactions: [] }));
        dispatch(getVendorMoneyTransactionsFetch({ vendorId, filter }));
        dispatch(setPageTransactions({ page }));
    }

    return (
        <DropdownFilter
            label="Тип"
            changeHandler={changeHandler}
            dropdownList={types}
            value={value}
            maxWidth={200}
        />
    )
}

export default TypeFilter;