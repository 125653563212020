import { useFormik } from "formik";
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../../../store/hooks/selector.hook";
import { setFonVisiable } from "../../../../../../store/state/all/all.slice";
import { searchClientForVendorFetch } from "../../../../../../store/state/clients/clients.slice";
import { createClientFormVisiable, setSearchClientsFormVisiable } from "../../../../../../store/state/groups/groups.slice";
import { SearchClientsForm } from "../../../../models/SearchClientsForm";
import SearchClients from "./SearchClients";
import validationSchema from "./SearchClients.scheme";

interface Props {
    isCreationScript: boolean;
}

const SearchClientsContainer: FC<Props> = ({ isCreationScript }) => {
    const dispatch = useAppDispatch();
    let { id } = useParams();
    const groupId = id ? Number(id) : -1;
    const groups = useAppSelector(state => state.groups.pageData, shallowEqual);
    const indexGroup = groups.findIndex(group => group.id === groupId);
    const group = groups[indexGroup];
    const visiable = useAppSelector(state => state.groups.searchClientsFormVisiable, shallowEqual)
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const searchListUsers = useAppSelector(state => state.clients.searchedClients, shallowEqual);
    const showSkeletonStudent = useAppSelector(state => state.groups.showSkeletonStudent, shallowEqual);

    const initialValues: SearchClientsForm = {
        value: "",
    }

    const formik = useFormik<SearchClientsForm>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            dispatch(setSearchClientsFormVisiable({ visiable: false }));
            dispatch(createClientFormVisiable({ visiable: true }));
        },
    });

    const closeModalHandler = () => {
        formik.resetForm();
        dispatch(setSearchClientsFormVisiable({ visiable: false }));
        dispatch(setFonVisiable({ visiable: false }));
    }

    const handleSearchUser = (value: string) => {
        dispatch(searchClientForVendorFetch({ vendorId, value }));
    }

    let isMaxNumberOfStudents = false;
    if(group && group.students) {
        isMaxNumberOfStudents = group.students.length > group.studentsMax;
    }

    return (
        <SearchClients
            formik={formik}
            visiable={visiable}
            closeModalHandler={closeModalHandler}
            handleSearchUser={handleSearchUser}
            searchListUsers={searchListUsers}
            visiableStudentSkeleton={showSkeletonStudent && searchListUsers.length === 0}
            isCreationScript={isCreationScript}
            isMaxNumberOfStudents={isMaxNumberOfStudents}
        />
    );
}

export default SearchClientsContainer;