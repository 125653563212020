import { PayloadAction } from "@reduxjs/toolkit";
import { ServiceState } from "../models/ServiceState";
import { SetServicesPayload } from "../payload/SetServicesPayload";

const setServices = (state: ServiceState, action: PayloadAction<SetServicesPayload>) => {
    const { services } = action.payload;

    state.basics = services;
}

export default setServices;