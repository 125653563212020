import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Clients } from "../../../../services/reddyApi/Clients/Clients";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getClientStatisticsForVendorFetch, getClientStatisticsForVendorSuccess } from "../clients.slice";
import { GetClientStatisticsForVendorPayload } from "../payload/GetClientStatisticsForVendor/GetClientStatisticsForVendorPayload";

function* getClientStatisticsForVendorWorker(action: PayloadAction<GetClientStatisticsForVendorPayload>) {
    const { vendorId, filter } = action.payload;
    const dataParams = {
        vendorId,
        filter
    }

    try {
        const { data } = yield call(Clients.getClientStatisticsForVendor, dataParams);

        yield put(getClientStatisticsForVendorSuccess(data));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getClientStatisticsForVendorFetch(action.payload);
            
            yield handleServerError(error, actionCreator);
        }
    }
}

export default getClientStatisticsForVendorWorker;