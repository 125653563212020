import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Vendors } from "../../../../services/reddyApi/Vendors/Vendors";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { GetVendorsPayload } from "../payload/GetVendors/GetVendorsPayload";
import { getVendorsFetch, getVendorsSuccess } from "../vendors.slice";

function* getVendorsWorker(action: PayloadAction<GetVendorsPayload>) {
    const filter = action.payload.filter;
    try {
        const { data } = yield call(Vendors.getVendors, { filter });
        const { items, totalCount } = data;

        yield put(getVendorsSuccess({ items, totalCount, filter }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getVendorsFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getVendorsWorker;