import React, { FC } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./Fon.module.css"; 
import "./Fon.css";

interface Props {
    visiable: boolean;
}

const Fon: FC<Props> = ({visiable}) => {
    return (
        <CSSTransition 
            in={visiable}
            timeout={200}
            classNames={"fade-fon"}
            unmountOnExit
        >
            <div className={styles.fon}></div>
        </CSSTransition>
    );
}

export default Fon;