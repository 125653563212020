import { PagePagination } from "../../components/dataDisplay/table/models/PagePagination";
import { culcPage } from "../helpers/culcPage";
import getFilterPage from "../helpers/getFilterPage";
import { Filter } from "../models/Filters";

interface Payload {
    getAllItems: boolean;
    pageAll: number;
    page: number;
    totalCount: number;
    pageSize: number;
    setPage: (filter: Filter | undefined, page: number) => void;
    active?: boolean;
    type?: string;
    typeId?: number;
    categoryId?: number;
    confirmed?: boolean;
}

interface Pagination {
    pagesRange: ReturnType<typeof culcPage>;
    pages: PagePagination[];
    handleRightButtonPagination: () => void;
    handleLeftButtonPagination: () => void;
}

const usePagination = (payload: Payload): Pagination => {
    const { 
        getAllItems, 
        pageAll, 
        page, 
        pageSize, 
        totalCount, 
        setPage, 
        active,
        type,
        typeId,
        categoryId,
        confirmed,
    } = payload;

    const pagesRange = culcPage(page, pageAll, totalCount, pageSize);

    const pages: PagePagination[] = [];

    for (let i = 0; i < pageAll; i++) {
        const pageNumber = i + 1;

        const handleClick = () => {
            const filter = getFilterPage({
                getAllItems,
                pageRange: culcPage(pageNumber, pageAll, totalCount, pageSize),
                active,
                type,
                typeId,
                categoryId,
                confirmed,
            });

            setPage(filter, pageNumber);
        }

        pages.push({
            name: pageNumber,
            handleClick,
        });
    }

    const handleRightButtonPagination = () => {
        const pageNumber = page + 1;

        const filter = getFilterPage({
            getAllItems,
            pageRange: culcPage(pageNumber, pageAll, totalCount, pageSize),
            active,
            type,
            typeId,
            categoryId,
            confirmed,
        });

        setPage(filter, pageNumber);
    }

    const handleLeftButtonPagination = () => {
        const pageNumber = page - 1;

        const filter = getFilterPage({
            getAllItems,
            pageRange: culcPage(pageNumber, pageAll, totalCount, pageSize),
            active,
            type,
            typeId,
            categoryId,
            confirmed,
        });

        setPage(filter, pageNumber);
    }

    return {
        pagesRange,
        pages,
        handleLeftButtonPagination,
        handleRightButtonPagination
    }
}

export default usePagination;