import React, { FC } from "react";
import HandIcon from "../../../../components/general/icons/HandIcon/HandIcon";
import Paragraph from "../../../../components/general/typography/Paragraph/Paragraph";
import Title from "../../../../components/general/typography/Title/Title";
import Column from "../../../../components/layout/Column/Column";
import Row from "../../../../components/layout/Row/Row";
import Wrp from "../../../../components/layout/Wrp/Wrp";

const EmptyDashboard: FC = () => {
    const message = "Нажаль у Вас поки замало даних щоб ми змогли відобразити аналітику";
    const title = "Вітаємо!"
    
    return (
        <Wrp maxWidth={360}>
            <Row justifyContent="space-between">
                <HandIcon />
                <Column maxWidth={255}>
                    <Title 
                        title={title} 
                        marginBottom={20} 
                        level={2} 
                    />
                    <Paragraph 
                        color="#A6AEC5"
                        content={message} 
                    />
                </Column>
            </Row>
        </Wrp>
    )
}

export default EmptyDashboard;