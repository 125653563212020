import { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { ClientsState } from "../../models/ClientsState";
import { Comment } from "../../models/Comment";
import { GetClientCommentsByVendorSuccessPayload } from "../../payload/GetClientCommentsByVendor/GetClientCommentsByVendorSuccessPayload";

const defaultCommentsItem: Comment = {
    id: -1,
    categoryId: 1,
    message: "",
    createdAt: moment().format('YYYY-MM-DD'),
    responsibleUser: {
        id: -1,
        fullname: "Система",
        roleId: -1,
    }
}

const getCommentsByVendorSuccess = (state: ClientsState, actions: PayloadAction<GetClientCommentsByVendorSuccessPayload>) => {
    const { clientId, totalCount, items, filter } = actions.payload;

    const clients = state.pageData;

    const clientIndex = clients.findIndex(client => client.clientId === clientId);

    if (clientIndex > -1) {
        const comments = state.pageData[clientIndex].comments;
        let newComments: Comment[] = [];

        if (filter?.range) {
            for (let i = 0; i < totalCount; i++) {
                if (comments && comments[i]) {
                    newComments[i] = { ...comments[i] };
                    continue;
                }

                newComments[i] = { ...defaultCommentsItem };
            }

            let j = 0;
            for (let i = filter.range.from; i <= filter.range.to; i++) {
                if (items[j]) {
                    newComments[i] = { ...items[j] };
                }
                j++;
            }
        } else {
            newComments = [];

            items.forEach(function (item) {
                newComments.push({ ...item });
            });
        }

        state.pageData[clientIndex].comments = newComments;
    }
    state.isLoading = false;
    state.isError = null;
}

export default getCommentsByVendorSuccess