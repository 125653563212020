import { PayloadAction } from "@reduxjs/toolkit";
import { Visiable } from "../../../../utl/models/Visiable";
import { GroupsState } from "../models/GroupsState";

const showSkeletonStudent = (state: GroupsState, action: PayloadAction<Visiable>) => {
    const { visiable } = action.payload;

    state.showSkeletonStudent = visiable;
}

export default showSkeletonStudent;