import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Auth } from "../../../../services/reddyApi/Authorization/Authorization";
import { EmailSendPayload } from "../payload/EmailSendPayload";
import { forgotPasswordFailure, forgotPasswordSuccess, resetPasswordFailure } from "../user.slice";

function* sendEmailWorker(action: PayloadAction<EmailSendPayload>) {
    const { email } = action.payload;

    try {
        const {status} = yield call(Auth.forgotPassword, email);
        if(status === 200) {
            yield put(forgotPasswordSuccess());
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const statusCode = String(error.response?.status);
            const message = String(error.response?.data.error.message);
            
            if(statusCode === "404") {
                yield put(forgotPasswordFailure({error: "Ви ввели неправильний email або пароль"}));
            } else if(statusCode === "400") {
                yield put(forgotPasswordFailure({error: "Ви ввели неправильний email або пароль"}));
            } else {
                yield put(resetPasswordFailure({error: "Невідома помилка"}));
            }
        }
    }
}

export default sendEmailWorker;