import { PayloadAction } from "@reduxjs/toolkit";
import { GroupsState } from "../../models/GroupsState";
import { GetGroupGeneralDataSuccessPayload } from "../../payload/GetGroupGeneralData/GetGroupGeneralDataSuccessPayload";

const getGroupGeneralDataSuccess = (state: GroupsState, action: PayloadAction<GetGroupGeneralDataSuccessPayload>) => {
    const { generalData, groupId } = action.payload;
    const groups = state.pageData;
    const groupIndex = groups.findIndex(group => group.id === groupId);

    if (groupIndex > -1) {
        const group = groups[groupIndex];
        group.generalData = { ...generalData };
    }

    state.isLoading = false;
    state.isError = null;
}

export default getGroupGeneralDataSuccess;