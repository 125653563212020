import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Clients } from "../../../../services/reddyApi/Clients/Clients";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { addCommentFecth, addCommentSuccess } from "../clients.slice";
import { AddCommentPayload } from "../payload/AddComment/AddCommentPayload";

function* addCommentWorker(action: PayloadAction<AddCommentPayload>) {
    const { vendorId, clientId, comment } = action.payload;
    const dataParams = {
        vendorId,
        clientId,
        comment,
    }

    try {
        const { data } = yield call(Clients.addComment, dataParams);
        const { commentId } = data;

        yield put(addCommentSuccess({ comment: { ...comment, id: commentId }, clientId }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => addCommentFecth(action.payload);
            
            yield handleServerError(error, actionCreator);
        }
    }
}

export default addCommentWorker;