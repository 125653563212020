import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import {
    getServicesListFetch,
    setPageServices,
    setServices,
    setServiceFilter
} from "../../../../store/state/services/service/service.slice";
import { ARCHIVE_ITEMS } from "../../../../utl/constants/app";
import { culcPage } from "../../../../utl/helpers/culcPage";
import { Filter } from "../../../../utl/models/Filters";
import DropdownFilter, { DropdownFilterItem } from "./DropdownFilter/DropdownFilter";


const StatusFilter: FC = () => {
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const confirmed = useAppSelector(state => state.services.filter.confirmed, shallowEqual);
    const active = useAppSelector(state => state.services.filter.active, shallowEqual);
    const value = active !== undefined && !active ? ARCHIVE_ITEMS : String(confirmed);

    const typeId = useAppSelector(state => state.services.filter.typeId, shallowEqual);

    const categoryId = useAppSelector(state => state.services.filter.categoryId, shallowEqual);

    const page = 1;
    const totalCount = 0;
    const pageAll = 0;
    const pageSize = useAppSelector(state => state.services.pageSize, shallowEqual);
    const getAllItems = useAppSelector(state => state.services.getAllItems, shallowEqual);
    const pagesRange = culcPage(page, pageAll, totalCount, pageSize);

    const statuses: DropdownFilterItem[] = [
        {
            name: "Активні",
            value: "true",
        },
        {
            name: "Чекає підтвердження",
            value: "false",
        },
        {
            name: "Архівні",
            value: ARCHIVE_ITEMS,
        },
        {
            name: "Всі",
            value: "undefined",
        }
    ];

    const dispatch = useAppDispatch();

    const changeHandler = (value: string) => {
        let filter: Filter = {
            typeId,
            categoryId,
        };

        if (!getAllItems) {
            filter.range = {
                from: pagesRange.from,
                to: pagesRange.to
            }
        }

        if (value.localeCompare('undefined') !== 0 && value.localeCompare(ARCHIVE_ITEMS) !== 0) {
            const status = value === "true" ? true : false;
            const active = true;
        
            filter.confirmed = status;
            filter.active = active;

            dispatch(setServiceFilter({ confirmed: status, typeId, categoryId, active }));
        } else if (value.localeCompare(ARCHIVE_ITEMS) === 0) {
            const active = false;

            filter.active = active;

            dispatch(setServiceFilter({ confirmed: undefined, active, typeId, categoryId }));
        } else {
            const active = undefined;

            filter.active = active;

            dispatch(setServiceFilter({ confirmed: undefined, typeId, categoryId, active }));
        }

        dispatch(setServices({ services: [] }));
        dispatch(getServicesListFetch({ vendorId, filter }));
        dispatch(setPageServices({ page }));
    }

    return (
        <DropdownFilter
            label="Статус"
            changeHandler={changeHandler}
            dropdownList={statuses}
            value={value}
            maxWidth={200}
        />
    )
}

export default StatusFilter;