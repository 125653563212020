import React, { FC, useState } from "react";
import { shallowEqual } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import { setFonVisiable, setServerError } from "../../../store/state/all/all.slice";
import Fon from "../../other/fon/Fon";
import HeaderContainer from "../../navigation/header/Header.container";
import Wrp from "../Wrp/Wrp";
import styles from "./MainLayout.module.css";
import { setDefaultValueClients } from "../../../store/state/clients/clients.slice";
import ErrorMessage from "../../feedback/messages/ErrorMessage/ErrorMessage";
import SidebarContainer from "./Sidebar.conatiner";

const MainLayout: FC = () => {
    const dispatch = useAppDispatch()
    const isFonVisiable = useAppSelector(state => state.all.isFonVisiable, shallowEqual);
    const importedClients = useAppSelector(state => state.clients.importedClients, shallowEqual);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const serverError = useAppSelector(state => state.all.serverError, shallowEqual);

    let location = useLocation();
    const locations = location.pathname.split("/");
    locations.shift();

    const closeModalHandler = () => {
        dispatch(setFonVisiable({ visiable: false }));
        dispatch(setDefaultValueClients({
            importedClients: true,
        }));
    }

    const closeModalErrorMessage = () => {
        dispatch(setFonVisiable({ visiable: false }));
        dispatch(setServerError({ error: null, status: null, message: null }))
    }

    const visiableServerError = !!serverError.message;

    return (
        <div className={styles.wrp}>
            <HeaderContainer />
            <main className={styles.main}>
                <SidebarContainer
                    toggleSidebar={toggleSidebar}
                    setToggleSidebar={setToggleSidebar}
                />

                <Wrp paddingLeft={30} paddingRight={30} marginTop={30}>
                    <Outlet />
                </Wrp>
            </main>

            <ErrorMessage
                top={"20%"}
                title="Повідомлення"
                maxWidth={440}
                visiable={importedClients}
                handleClick={closeModalHandler}
                content={"Файл з клієнтами відправлено в обробку"}
            />

            <ErrorMessage
                top={"20%"}
                title="Помилка"
                maxWidth={440}
                width={440}
                visiable={visiableServerError}
                handleClick={closeModalErrorMessage}
                content={"Oooops... " + serverError.message}
            />

            <Fon visiable={isFonVisiable || toggleSidebar || importedClients || visiableServerError} />
        </div>
    )
}

export default React.memo(MainLayout);