import { useFormik } from "formik";
import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import withMatch from "../../../../components/other/hoc/withMatch";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import {
    changeUpsellActivityFetch,
    deleteAdditionalFetch,
    updateAdditionalFetch,
    uploadUpsellIconFetch
} from "../../../../store/state/services/upsells/upsells.slice";
import { SUBMIT_ACTIONS } from "../../../../utl/constants/submitActions";
import { useDeleteRedirect } from "../../../../utl/hooks/useDeleteRedirect";
import useNoMatch from "../../../../utl/hooks/useNoMatch";
import useSubmitAction from "../../../../utl/hooks/useSubmitAction";
import { UpsellValue } from "../models/UpsellValue";
import UpsellMessage from "./Upsell.message";
import validationSchema from "./Upsell.scheme";

const UpsellContainer: FC = () => {
    let { id } = useParams();
    const upsellId = Number(id);
    const deleteRedirect = useDeleteRedirect();
    const upsells = useAppSelector(state => state.upsellsServices.upsells, shallowEqual);

    const indexUpsells = upsells.findIndex(upsell => upsell.id === upsellId);
    const upsell = upsells[indexUpsells];

    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const noMatch = useNoMatch(upsell);

    const active = upsell?.active;

    const initialValues: UpsellValue = upsell ? {
        name: upsell.name,
        confirmed: upsell.confirmed,
        price: upsell.price,
        description: upsell.description,
        image: upsell.image || undefined,
        active,
        submitAction: SUBMIT_ACTIONS.SAVE,
    } : {
        name: "",
        confirmed: false,
        price: 0,
        description: "",
        active: false,
        submitAction: SUBMIT_ACTIONS.DELETE,
    }

    const submitActionHandler = useSubmitAction(active ? deleteRedirect : undefined);

    const formik = useFormik<UpsellValue>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const { submitAction, name, confirmed, price, description, files, active } = values;

            submitActionHandler(
                submitAction,
                () => updateAdditionalFetch(
                    {
                        vendorId,
                        upsell: {
                            id: upsellId,
                            name,
                            confirmed,
                            price,
                            description,
                            active
                        }
                    }
                ),
                () => changeUpsellActivityFetch({
                    vendorId, upsellId, active: !active,
                }),
                files ? () => uploadUpsellIconFetch({
                    upsellId,
                    vendorId,
                    file: files[0],
                }) : undefined
            );

            setFieldValue("files", undefined);
        },
    });

    const { setFieldValue } = formik;

    useEffect(() => {
        setFieldValue("image", upsell.image);
    }, [upsell.image]);

    if (noMatch.isNoMatch) {
        return noMatch.noMatchComponent
    }

    return (
        <UpsellMessage
            formik={formik}
            active={active}
        />
    )
}

export default React.memo(withMatch(UpsellContainer, [2, 3, 4]));