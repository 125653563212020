import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Groups } from "../../../../services/reddyApi/Groups/Groups";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { 
    getStatisticsClient, 
    getStatisticsDashboard, 
    getStatisticsFinance, 
    getStatisticsGroup, 
    getStatisticsService 
} from "../../statistics/statistics.saga";
import { addStudentToGroupFetch, addStudentToGroupSuccess } from "../groups.slice";
import { AddStudentToGroupPayload } from "../payload/AddStudentToGroup/AddStudentToGroupPayload";

function* addStudentToGroupWorker(action: PayloadAction<AddStudentToGroupPayload>) {
    const { vendorId, groupId, student, studentData, filter, clientId, serviceId } = action.payload;

    try {
        const { data } = yield call(Groups.addStudentToGroup, { vendorId, groupId, student });

        if (studentData) {
            yield put(addStudentToGroupSuccess({ student: studentData, groupId }));

            yield getStatisticsGroup({ vendorId, groupId })

            yield getStatisticsDashboard({ vendorId, filter });

            yield getStatisticsFinance({ vendorId, filter });

            yield getStatisticsClient({ vendorId, clientId });

            yield getStatisticsService({ vendorId, serviceId });

            yield getNotifications(vendorId);

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => addStudentToGroupFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default addStudentToGroupWorker;