import React, { FC } from "react";
import styles from "./InfoList.module.css";

interface Props {
    name: string;
    price: string
}

const InfoItem: FC<Props> = ({name, price}) => {
    return (
        <li className={styles.infoItem}>
            <span>{name}</span>
            <span>{price} ₴</span>
        </li>
    );
}

export default InfoItem;