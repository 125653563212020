import { PayloadAction } from "@reduxjs/toolkit";
import createItem from "../../../../helpers/createItem";
import { ClientsState } from "../../models/ClientsState";
import { CreateClientByVendorSuccessPayload } from "../../payload/CreateClientByVendor/CreateClientByVendorSuccessPayload";

const createByVendorSuccess = (state: ClientsState, actions: PayloadAction<CreateClientByVendorSuccessPayload>) => {
    const client = actions.payload;

    const clients = state.clients;
    createItem(state.clientsPages, clients, client);

    const clientsPage = state.pageData;
    createItem(null, clientsPage, client);

    state.searchedClients = [{ ...client }];

    state.clientsPages.totalCount = state.clientsPages.totalCount + 1;
    state.clientsPages.pageAll = Math.ceil(state.clientsPages.totalCount / state.clientsPages.pageSize);

    state.isLoading = false;
    state.isError = null;
}

export default createByVendorSuccess;