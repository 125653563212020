import { PayloadAction } from "@reduxjs/toolkit";
import deleteItem from "../../../../helpers/deleteItem";
import { EmployeesState } from "../../models/EmployeesState";
import { DeleteEmployeeSuccessPayload } from "../../payload/DeleteEmployee/DeleteEmployeeSuccessPayload";

const deleteEmployeeSuccess = (state: EmployeesState, action: PayloadAction<DeleteEmployeeSuccessPayload>) => {
    const { employeeId } = action.payload;

    const employees = state.employeesArray;
    state.employeesArray = deleteItem(state, employees, employeeId);

    state.isLoading = false;
    state.isError = null;
}

export default deleteEmployeeSuccess;