import { PayloadAction } from "@reduxjs/toolkit";
import createItem from "../../../../helpers/createItem";
import { FinanceState } from "../../models/FinanceState";
import { CreateTransferSuccessPayload } from "../../payload/CreateMoneyTransfer/CreateTransferSuccessPayload";

const createMoneyTransferSuccess = (state: FinanceState, action: PayloadAction<CreateTransferSuccessPayload>) => {
    const transfer = action.payload;

    const transactions = state.finance.transactions;
    createItem(state.finance, transactions, transfer);

    state.finance.message = "Транзакцію було створено";
    state.finance.messageVisiable = true;
    state.isLoading = true;
    state.isError = null;
}

export default createMoneyTransferSuccess;