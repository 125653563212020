import { createSlice } from '@reduxjs/toolkit';
import { AllState } from './models/AllState';
import setFonVisiableReducer from './reducer/setFonVisiable';
import setLocationFilterActiveReducer from './reducer/setLocationFilterActive';
import setLoadingDataReducer from './reducer/setLoadingData';
import resetOtherDataReducer from './reducer/resetOtherData';
import setServerErrorReducer from './reducer/setServerError';

export const initialState: AllState = {
  hasMessage: true,
  hasNotification: true,
  isFonVisiable: false,
  isLocationsFilterActive: false,
  isLoadingData: false,
  serverError: {
    status: null,
    error: null,
    message: null,
  }
}

export const allSlice = createSlice({
  name: 'all',
  initialState,
  reducers: {
    setFonVisiable: setFonVisiableReducer,
    setLocationFilterActive: setLocationFilterActiveReducer,
    setLoadingData: setLoadingDataReducer,
    resetOtherData: resetOtherDataReducer,
    setServerError: setServerErrorReducer
  },
});

export const {
  setFonVisiable,
  setLoadingData,
  setLocationFilterActive,
  resetOtherData,
  setServerError,
} = allSlice.actions

export default allSlice.reducer