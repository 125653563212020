import * as Yup from 'yup';

export default Yup.object().shape({
    purses: Yup.array().of(Yup.object().shape({
        id: Yup.number(),
        name: Yup.string().required('Required'),
    })),
    operationsIncome: Yup.array().of(Yup.object().shape({
        id: Yup.number(),
        name: Yup.string().required('Required'),
        income: Yup.boolean().required('required'),
    })),
    operationsExpense: Yup.array().of(Yup.object().shape({
        id: Yup.number(),
        name: Yup.string().required('Required'),
        income: Yup.boolean().required('required'),
    })),
    discounts: Yup.array().of(Yup.object().shape({
        id: Yup.number(),
        name: Yup.string().required('Required'),
        value: Yup.number().required('Required'),
    })),
});