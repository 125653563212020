import * as Yup from 'yup';

export default Yup.object().shape({
    name: Yup.string().required(),
    confirmed: Yup.boolean().required(),
    categoryId: Yup.number().min(0, "value not valid").required(),
    // subCategoryId: Yup.number(),
    description: Yup.string().required(),
    duration: Yup.number().required(),
    durationTypeId: Yup.number().required(),
    typeId: Yup.number().required(),
    locationsIds: Yup.string().required(),
    lessons: Yup.string().matches(/^([A-Za-zА-Яа-я0-9_!?\- ]+((\[\/\][A-Za-zА-Яа-я0-9_!?\- ]+)+)?)$/i, "Fail").required(),
    upsellsIds: Yup.string(),
});