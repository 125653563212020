import { FormikProps, useFormik } from "formik";
import moment from "moment";
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import { setFonVisiable } from "../../../../store/state/all/all.slice";
import { addCommentFecth, setVisiableCommentForm } from "../../../../store/state/clients/clients.slice";
import { AddCommentFormValue } from "../../models/AddCommentFormValue";
import AddCommentForm from "./AddCommentForm";
import validationSchema from "./AddCommentForm.scheme";

const AddCommentFormContainer: FC = () => {
    let { id } = useParams();
    const clientId = (id && parseInt(id)) || -1;
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const dispatch = useAppDispatch();
    const visiable = useAppSelector(state => state.clients.visiableCommentForm, shallowEqual);
    const userId = useAppSelector(state => state.user.id, shallowEqual) || -1;
    const fullname = useAppSelector(state => state.user.name, shallowEqual);
    const roleId = useAppSelector(state => state.user.roleId, shallowEqual) || -1;

    const initialValues: AddCommentFormValue = {
        categoryId: 1,
        message: "",
    }

    const formik = useFormik<AddCommentFormValue>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const { message, categoryId } = values;
            dispatch(addCommentFecth({
                comment: {
                    categoryId,
                    message,
                    responsibleUser: {
                        id: userId,
                        fullname: fullname,
                        roleId: roleId,
                    },
                    createdAt: moment().format('YYYY-MM-DD'),
                },
                vendorId,
                clientId
            }));
            
            closeModalHandler();
        },
    });

    const closeModalHandler = () => {
        formik.resetForm();
        dispatch(setVisiableCommentForm({ visiable: false }));
        dispatch(setFonVisiable({ visiable: false }));
    }

    return (
        <AddCommentForm
            closeModalHandler={closeModalHandler}
            visiable={visiable}
            formik={formik}
        />
    );
}

export default AddCommentFormContainer;