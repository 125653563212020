import { PayloadAction } from "@reduxjs/toolkit";
import { ClientsState } from "../../models/ClientsState";
import { DownloadClientsTemplatePayload } from "../../payload/DownloadClientsTemplatePayload";

const downloadClientsTemplateSuccess = (state: ClientsState, action: PayloadAction<DownloadClientsTemplatePayload>) => {
    const { template } = action.payload;

    state.templateCSV = template;
    state.isLoading = false;
    state.isError = null;
}

export default downloadClientsTemplateSuccess;