import React, { FC } from "react"
import TabPanel from "../../../components/navigation/TabPanel/TabPanel";
import TabPanelItem from "../../../components/navigation/TabPanel/TabPaneltem";

export interface PanelItem {
    name: string;
    value: string;
}

interface Props {
    panelList: PanelItem[];
    handleClick: (value: string) => void;
    value: string;
}

const TabPanelFilter: FC<Props> = ({ panelList, handleClick, value }) => {
    const panelItems = panelList.map((item, index) => {
        const clickTabHandler = () => {
            handleClick(item.value);
        }

        const active = item.value.localeCompare(value) === 0

        return (
            <TabPanelItem
                name={item.name}
                active={active}
                handleClick={clickTabHandler}
            />
        )
    });

    return (
        <TabPanel>
            {panelItems}
        </TabPanel>
    );
}

export default TabPanelFilter;