import { PayloadAction } from "@reduxjs/toolkit";
import updateItem from "../../../../helpers/updateItem";
import { LocationsState } from "../../models/LocationsState";
import { UpdateLocationSuccessPayload } from "../../payload/UpdateLocation/UpdateLocationSuccessPayload";

const updateLocationSuccess = (state: LocationsState, action: PayloadAction<UpdateLocationSuccessPayload>) => {
    const { location } = action.payload;

    const locations = state.locationsArray;
    updateItem(locations, location);

    state.isLoading = true;
    state.isError = null;
    state.message = "Локацію успішно оновлено";
    state.messageVisiable = true;
}

export default updateLocationSuccess;