import React, { FC } from "react";
import ParametersBlock from "../../../../components/dataDisplay/ParametersBlock/ParametersBlock";
import ParameterDisplayer from "../../../../components/layout/ParameterDisplayer/ParameterDisplayer";
import { Planned } from "../../../../store/state/finance/models/Planned";

interface Props {
    period: string;
    planned: Planned | null;
}

const ParametersMoneyBlock: FC<Props> = ({ period, planned }) => {
    const title = "Гроші";

    const plannedIncomes = planned?.plannedIncomes || 0;
    const plannedExpenses = planned?.plannedExpenses || 0;
    const accountsReceivable = plannedIncomes - plannedExpenses;

    return (
        <ParametersBlock
            title={title}
            briefInfo={period}
        >
            <>
                {planned && <ParameterDisplayer
                    title={"Дебеторська заборгованість"}
                    param={`₴ ${plannedExpenses.toFixed(1)}`}
                    marginBottom={30}
                />}
                {planned && <ParameterDisplayer
                    title={"Запланований прихід"}
                    param={`₴ ${plannedIncomes.toFixed(1)}`}
                    marginBottom={30}
                />}
                {planned && <ParameterDisplayer
                    title={"Запланований прибуток"}
                    param={`₴ ${accountsReceivable.toFixed(1)}`}
                />}
            </>
        </ParametersBlock>
    )
}

export default ParametersMoneyBlock;