import { PayloadAction } from "@reduxjs/toolkit";
import { NotificationsState } from "../models/NotificationsState";
import { SetNotificationsFilterPayload } from "../payload/SetNotificationsFilterPayload";

const setNotificationsFilter = (state: NotificationsState, action: PayloadAction<SetNotificationsFilterPayload>) => {
    const { statusId, typeId } = action.payload;

    state.filter.statusId = statusId;
    state.filter.typeId = typeId;
}

export default setNotificationsFilter;