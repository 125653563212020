import { useLocation, useNavigate } from "react-router-dom";

type ReturnType = () => NodeJS.Timeout;

export function useDeleteRedirect<T>(redirectUrl = "", timeout = 500): ReturnType {
    const navigate = useNavigate();
    const location = useLocation();
    const locations = location.pathname.split("/");
    locations.shift();
    
    let path = locations.length > 2 ? `${locations[0]}/${locations[1]}` : locations[0];
    
    return () => setTimeout(() => {
            redirectUrl === "" ? navigate(`/${path}`) : navigate(`/${redirectUrl}`);
        }, timeout);
}