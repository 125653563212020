import { PayloadAction } from "@reduxjs/toolkit";
import { VendorsState } from "../models/VendorsState";
import { SetActiveDateFormPayload } from "../payload/SetActiveDateFormPayload";

const setActiveDateForm = (state: VendorsState, action: PayloadAction<SetActiveDateFormPayload>) => {
    const {vendorId, active} = action.payload;
    const index = state.vendorsArray.findIndex(vendor => vendor.id === vendorId);
    if(index > -1) {
        state.vendorsArray[index].activeDateForm = active;
    }
}

export default setActiveDateForm;