import { PayloadAction } from "@reduxjs/toolkit";
import getItems from "../../../../helpers/getItems";
import { Client } from "../../../clients/models/Client";
import { GetClientsListForVendorSuccessPayload } from "../../../clients/payload/GetClientsListForVendor/GetClientsListForVendorSuccessPayload";
import { DashboardState } from "../../models/DashboardState";

const defaultClientsItem: Client = {
    clientId: -1,
    users: [{
        id: -1,
        name: "Name",
        surname: "Surname",
        phone: "+380*********",
        email: "***@gmail.com",
        relationshipStatus: -1,
    }],
    students: [],
    services: [],
    discountId: null,
    debt: null,
}

const getClientsListForVendorSuccess = (state: DashboardState, actions: PayloadAction<GetClientsListForVendorSuccessPayload>) => {
    const { totalCount, items, filter } = actions.payload;

    const clients = state.clientsPage.clients;

    state.clientsPage.clients = getItems(
        state.activeSrvicePage,
        clients,
        items,
        totalCount,
        defaultClientsItem,
        filter
    );
    
    state.isLoading = false;
    state.isError = null;
}

export default getClientsListForVendorSuccess;