import React, { ChangeEvent, FC, FocusEvent, MouseEvent, useEffect, useRef, useState } from "react";
import Position from "../../../layout/position/Position";
import classNames from "classnames";
import "./transition.css";
import { CSSTransition } from "react-transition-group";
import useDebounce from "../../../../utl/hooks/useDebounce";
import styles from "./SearchField.module.css";
import { isValid } from "../../../../utl/helpers/isValid";
import "./transition.css";
import ButtonIcon from "../../../general/buttons/ButtonIcon/ButtonIcon";
import IconFont from "../../../general/icons/IconFont/IconFont";

const cx = classNames.bind(styles);

interface Props {
    type?: string;
    label?: string;
    icon?: JSX.Element;
    handleBlur?: (touched: boolean) => void;
    backgroundColor?: string;
    colorText?: string;
    error?: string | undefined;
    touched?: boolean | undefined;
    maxWidth?: number | string;
    marginLeft?: number;
    marginRight?: number;
    marginBottom?: number;
    marginTop?: number;
    labelLeftShift?: number;
    info?: JSX.Element | JSX.Element[];
    topPositionIcon?: number | string;
    paddingLeft?: number;
    positionTopMessage?: number;
    searchList?: any[];
    handleSearch?: (value: string) => void;
    keyIndex?: string;
    setFieldValue?: (value: any) => void;
    placeholder?: string;
    showSearchList?: boolean;
    valuelangth?: number;
    createField?: boolean;
    defaultFields?: any;
    fieldForEnteredInformation?: string;
}

const SearchField: FC<Props> = ({
    type = "text", handleBlur, label, error, touched,
    backgroundColor = "#FFF", colorText = "#131313", maxWidth, icon,
    marginLeft = 0, marginBottom = 20, marginTop = 0, marginRight = 20, labelLeftShift = 15,
    topPositionIcon = "55%", paddingLeft = 30, searchList, keyIndex, setFieldValue, handleSearch, placeholder,
    showSearchList = true, valuelangth = 3, createField, defaultFields, fieldForEnteredInformation
}) => {
    const [value, setValue] = useState("");
    const valid = isValid(error, touched);
    const isHidden = type === "hidden";
    const [toggle, setToggle] = useState<boolean>(false);
    const [visiableButton, setVisiableButton] = useState(false);

    const [zIndexValues, setZIndexValues] = useState({
        zIndexInput: 3,
        zIndexList: 2,
    });

    const debouncedValue = useDebounce(value, 500);

    const blurInputHandler = (event: FocusEvent<HTMLInputElement>) => {
        setToggle(false);
        if (handleBlur)
            handleBlur(true);
    }

    const changeInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const targetValue = event.target.value;
        setValue(targetValue);
        if (!showSearchList) {
            if (setFieldValue) {
                setFieldValue(targetValue);
            }
        }

        if(createField && targetValue.length >= valuelangth) {
            setVisiableButton(true);
        }
    }

    const clickFieldHandler = (event: MouseEvent<HTMLElement>) => {
        setToggle(true);
        setZIndexValues({ zIndexInput: 10, zIndexList: 9 });
    }

    useEffect(() => {
        if (handleSearch && value.length >= valuelangth) {
            handleSearch(value);
        }

    }, [debouncedValue]);

    const searchItems = searchList && searchList.map((item, index) => {
        const clickSearchListItemHandler = (event: MouseEvent<HTMLElement>) => {
            setToggle(false);
            
            if (setFieldValue) {
                setFieldValue(item);

                setValue(item.name || item.fullname || (item.groupName + " / " + item.serviceName));
            }

            setZIndexValues({ zIndexInput: 1, zIndexList: 0 });

            setVisiableButton(false);
        }

        return (
            <li
                key={index + "_" + "SEARCH_ITEM_" + keyIndex}
                className={styles.serchListItem}
                onClick={clickSearchListItemHandler}
            >
                {item.name || item.fullname || (item.groupName + " / " + item.serviceName)}
            </li>
        );
    });

    const clickButtonIconHandler = () => {
        if (defaultFields && fieldForEnteredInformation && setFieldValue) {
            const generatedField = {
                ...defaultFields,
            }

            generatedField[fieldForEnteredInformation] = value;

            setFieldValue(generatedField);

            setVisiableButton(false);
        }
    }

    const visiableSearchList = toggle && !!searchItems && searchItems.length > 0 && showSearchList;
    const visiableCreateButton = visiableButton && createField;

    return (
        <label
            onClick={clickFieldHandler}
            className={styles.label}
            style={{
                maxWidth, marginLeft, marginBottom, marginTop, marginRight,
                visibility: isHidden ? "hidden" : undefined,
                position: isHidden ? "absolute" : "relative",
                zIndex: zIndexValues.zIndexList,
            }}
        >
            {label && <p
                style={{ marginLeft: labelLeftShift }}
                className={cx(styles.name)}
            >
                {label}
            </p>}
            {icon && <Position zIndex={zIndexValues.zIndexInput + 1} type="absolute" top={topPositionIcon} left={20}>
                {icon}
            </Position>
            }
            <input
                min={0}
                type={type}
                className={cx(styles.field, !valid && styles.error, icon && styles.icon)}
                style={{
                    backgroundColor,
                    color: colorText,
                    paddingLeft: icon ? paddingLeft : undefined,
                    zIndex: zIndexValues.zIndexInput
                }}
                value={value}
                onClick={clickFieldHandler}
                onChange={changeInputHandler}
                onBlur={blurInputHandler}
                placeholder={placeholder}
            />

            <CSSTransition
                in={visiableCreateButton}
                timeout={400}
                unmountOnExit
                classNames="search-list"
            >
                <Position zIndex={zIndexValues.zIndexInput + 1} type="absolute" top={28} right={9}>
                    <ButtonIcon
                        width={25}
                        height={25}
                        icon={<IconFont color="#FFF" iconClass="icon-plus" fontSize={12} />}
                        handleClick={clickButtonIconHandler}
                        backgroundColor="#4775FE"
                        borderRadius="50%"
                    />
                </Position>
            </CSSTransition>

            <CSSTransition
                in={visiableSearchList}
                timeout={400}
                unmountOnExit
                classNames="search-list"
            >
                <ul
                    style={{ zIndex: zIndexValues.zIndexList }}
                    className={styles.serchList}
                >
                    {searchItems}
                </ul>
            </CSSTransition>
        </label>
    );
}

export default SearchField;