import React, {FC} from "react";
import styles from "./Message.module.css";
import {CSSTransition} from "react-transition-group";
import "./transition.css";
import Info from "../../../general/typography/Info/Info";
import classNames from "classnames";
import CheckedIcon from "../../../general/icons/CheckedIcon/CheckedIcon";

const cx = classNames.bind(styles);

interface Props {
   content: string;
   visiable: boolean;
   position: "top" | "left" | "right" | "bottom";
   type?: "success" | "error" | "warning";
   maxWidth?: number; 
}

const Message: FC<Props> = ({content, visiable, position, maxWidth = 280}) => {
    return (
        <CSSTransition 
            in={visiable} 
            timeout={400} 
            unmountOnExit 
            classNames="message"
        >
            <div 
                className={cx(
                    styles.message, 
                    position === "top" && styles.top, 
                    position === "bottom" && styles.bottom,
                    position === "left" && styles.left,
                    position === "right" && styles.right,
                )} 
                style={{maxWidth}}
            >
                <div className={styles.iconBlock}>
                    <CheckedIcon width="15" height="15" />
                </div>
                <Info text={content} />
            </div>
        </CSSTransition>
    );
}

export default Message;