import { PayloadAction } from "@reduxjs/toolkit";
import { MoneyState } from "../../models/MoneyState";
import { GetCategoriesSuccessPayload } from "../../payload/GetCategories/GetCategoriesSuccessPayload";

const getCategoriesSuccess = (state: MoneyState, action: PayloadAction<GetCategoriesSuccessPayload>) => {
    const {operations, purses, discounts} = action.payload;

    state.discounts = discounts;
    state.operations = operations;
    state.purses = purses;
    
    state.isLoading = false;
    state.isError = null;
    state.message = null;
    state.messageVisiable = false;
}

export default getCategoriesSuccess;