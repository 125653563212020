import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Upsells } from "../../../../../services/reddyApi/Upsells/Upsells";
import handleServerError from "../../../all/saga/handleServerError";
import setServerErrorDefault from "../../../all/saga/setServerErrorDefault";
import getNotifications from "../../../notifications/saga/getNotifications";
import { UpdateUpsellPayload } from "../payload/UpdateUpsell/UpdateUpsellPayload";
import { updateAdditionalFetch, updateAdditionalSuccess } from "../upsells.slice";

function* updateUpsellWorker(action: PayloadAction<UpdateUpsellPayload>) {
    const { vendorId, upsell } = action.payload;

    try {
        const { status } = yield call(Upsells.update, { vendorId, upsell });
        if (status === 200) {
            yield put(updateAdditionalSuccess({ ...upsell }));

            yield getNotifications(vendorId);

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => updateAdditionalFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default updateUpsellWorker; 