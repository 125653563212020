import React, { FC } from "react";
import classNames from "classnames";
import styles from "./IconFont.module.css"; 

const cx = classNames.bind(styles);

interface Props {
    iconClass: string;
    fontSize?: number;
    color?: string;
    marginTop?: number;
} 

const IconFont: FC<Props> = ({iconClass, fontSize = 25, color, marginTop}) => {
    return (
        <span style={{fontSize, color, marginTop}} className={cx(iconClass, styles.icon)}></span>
    );
}

export default IconFont;