import { PayloadAction } from "@reduxjs/toolkit";
import { UpsellState } from "../models/UpsellsState";
import { SetUpsellsFilterPayload } from "../payload/SetUpsellsFilterPayload";

const setUpsellsFilter = (state: UpsellState, action: PayloadAction<SetUpsellsFilterPayload>) => {
    const { confirmed, active } = action.payload;

    state.filter.confirmed = confirmed;
    state.filter.active = active;
}

export default setUpsellsFilter;

