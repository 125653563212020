import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Groups } from "../../../../services/reddyApi/Groups/Groups";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { searchGroupsByFilterFetch, searchGroupsByFilterSuccess } from "../groups.slice";
import { SearchGroupsByFilterPayload } from "../payload/SearchGroupsByFilter/SearchGroupsByFilterPayload";

function* searchGroupsByFilterWorker(action: PayloadAction<SearchGroupsByFilterPayload>) {
    const { vendorId, name } = action.payload;
    const dataParams = {
        vendorId,
        name
    }

    try {
        const { data } = yield call(Groups.searchGroupsByFilter, dataParams);

        yield put(searchGroupsByFilterSuccess(data));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => searchGroupsByFilterFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default searchGroupsByFilterWorker;