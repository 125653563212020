import { PayloadAction } from "@reduxjs/toolkit";
import getItems from "../../../../helpers/getItems";
import { Employee } from "../../models/Employee";
import { EmployeesState } from "../../models/EmployeesState";
import { GetEmployeesListSuccessPayload } from "../../payload/GetEmployeesList/GetEmployeesListSuccessPayload";

const defaultEmloyeeItem: Employee = {
    id: -1,
    image: "",
    roleId: -1,
    fullname: "Name Surname",
    email: "***@gmail.com",
    phone: "+380*********",
    startDate: "",
    salary: 0,
    locationsIds: [],
    salaryForServices: [],
    active: true,
}

const getEmployeesListSuccess = (state: EmployeesState, action: PayloadAction<GetEmployeesListSuccessPayload>) => {
    const { totalCount, items, filter } = action.payload;

    const employess = state.employeesArray;

    state.employeesArray = getItems(
        state,
        employess,
        items,
        totalCount,
        defaultEmloyeeItem,
        filter,
    );

    state.isLoading = false;
    state.isError = null;
}

export default getEmployeesListSuccess;