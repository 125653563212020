import { createSlice } from '@reduxjs/toolkit';
import { GroupsState } from './models/GroupsState';
import searchGroupsByFilterFetchReducer from './reducer/searchGroupsByFilter/searchGroupsByFilterFetch';
import searchGroupsByFilterSuccessReducer from './reducer/searchGroupsByFilter/searchGroupsByFilterSuccess';
import setErrorReducer from './reducer/setError';
import createGroupFetchReducer from './reducer/createGroup/createGroupFetch';
import createGroupSuccessReducer from './reducer/createGroup/createGroupSuccess';
import updateGroupFetchReducer from './reducer/updateGroup/updateGroupFetch';
import updateGroupSuccessReducer from './reducer/updateGroup/updateGroupSuccess';
import addStudentToGroupFetchReducer from './reducer/addStudentToGroup/addStudentToGroupFetch';
import addStudentToGroupSuccessReducer from './reducer/addStudentToGroup/addStudentToGroupSuccess';
import changeStudentStatusForGroupFetchReducer from './reducer/changeStudentStatusForGroup/changeStudentStatusForGroupFetch';
import changeStudentStatusForGroupSuccessReducer from './reducer/changeStudentStatusForGroup/changeStudentStatusForGroupSuccess';
import setStudentsVisitForLessonFetchReducer from './reducer/setStudentsVisitForLesson/setStudentsVisitForLessonFetch';
import setStudentsVisitForLessonSuccessReducer from './reducer/setStudentsVisitForLesson/setStudentsVisitForLessonSuccess';
import getStudentDataForGroupFetchReducer from './reducer/getStudentDataForGroup/getStudentDataForGroupFetch';
import getStudentDataForGroupSuccessReducer from './reducer/getStudentDataForGroup/getStudentDataForGroupSuccess';
import getStudentsListForGroupFetchReducer from './reducer/getStudentsListForGroup/getStudentsListForGroupFetch';
import getStudentsListForGroupSuccessReducer from './reducer/getStudentsListForGroup/getStudentsListForGroupSuccess';
import getGroupGeneralDataFetchReducer from './reducer/getGroupGeneralData/getGroupGeneralDataFetch';
import getGroupGeneralDataSuccessReducer from './reducer/getGroupGeneralData/getGroupGeneralDataSuccess';
import getGroupsListForVendorFetchReducer from './reducer/getGroupsListForVendor/getGroupsListForVendorFetch';
import getGroupsListForVendorSuccessReducer from './reducer/getGroupsListForVendor/getGroupsListForVendorSuccess';
import setPageGroupsReducer from './reducer/setPageGroups';
import setMessageReducer from './reducer/setMessage';
import setMessageVisiableReducer from './reducer/setMessageVisiable';
import setNewItemIdReducer from './reducer/setNewItemId';
import getSelectedServiceFetchReducer from './reducer/getSelectedService/getSelectedServiceFetch';
import getSelectedServiceSuccessReducer from './reducer/getSelectedService/getSelectedServiceSuccess';
import setSelectedServiceReducer from './reducer/setSelectedService';
import setSearchClientsFormVisiableReducer from './reducer/setSearchClientsFormVisiable';
import createClientFormVisiableReducer from './reducer/createClientFormVisiable';
import searchClientsFetchReducer from './reducer/searchClients/searchClientsFetch';
import searchClientsSuccessReducer from './reducer/searchClients/searchClientsSuccess';
import setDefaultValueGroupsReducer from './reducer/setDefaultValueGroups';
import setAddStudentToGroupFormVisiableReducer from './reducer/setAddStudentToGroupFormVisiable';
import updateStudentForGroupFetchReducer from './reducer/updateStudentForGroup/updateStudentForGroupFetch';
import updateStudentForGroupSuccessReducer from './reducer/updateStudentForGroup/updateStudentForGroupSuccess';
import getGroupFetchReducer from './reducer/getGroup/getGroupFetch';
import getGroupSuccessReducer from './reducer/getGroup/getGroupSuccess';
import showStudentStatusFormReducer from './reducer/showStudentStatusForm';
import showStudentAttendanceReducer from './reducer/showStudentAttendance';
import deleteGroupFetchReducer from './reducer/deleteGroup/deleteGroupFetch';
import deleteGroupSuccessReducer from './reducer/deleteGroup/deleteGroupSuccess';
import deleteStudentForGroupFetchReducer from './reducer/deleteStudentForGroup/deleteStudentForGroupFetch';
import deleteStudentForGroupSuccessReducer from './reducer/deleteStudentForGroup/deleteStudentForGroupSuccess';
import showStudentVisitFormReducer from './reducer/showStudentVisitForm';
import showSkeletonStudentReducer from './reducer/showSkeletonStudent';
import setPageDataGroupsReducer from './reducer/setPageDataGroups';
import resetGroupsReducer from './reducer/resetGroups';
import { Filter } from '../../../utl/models/Filters';
import setGroupsReducer from './reducer/setGroups';
import setGroupsFilterReducer from './reducer/setGroupsFilter';
import changeGroupActivityFetchReducer from './reducer/changeGroupActivity/changeGroupActivityFetch';
import changeGroupActivitySuccessReducer from './reducer/changeGroupActivity/changeGroupActivitySuccess';

const filter: Filter = {
    typeId: undefined,
    categoryId: undefined,
    confirmed: undefined,
}

export const initialState: GroupsState = {
    groups: [],
    pageData: [],
    serchedGroups: [],
    searchClients: [],
    filter,
    selectedService: null,
    isLoading: false,
    isError: null,
    message: null,
    messageVisiable: false,
    getAllItems: false,
    pageAll: 0,
    pageSize: 15,
    page: 1,
    totalCount: 0,
    newItemId: null,
    searchClientsFormVisiable: false,
    createClientFormVisiable: false,
    addStudentToGroupFormVisiable: false,
    showStudentVisitForm: false,
    showSkeletonStudent: false,
}

export const GroupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        searchGroupsByFilterFetch: searchGroupsByFilterFetchReducer,
        searchGroupsByFilterSuccess: searchGroupsByFilterSuccessReducer,
        setError: setErrorReducer,
        createGroupFetch: createGroupFetchReducer,
        createGroupSuccess: createGroupSuccessReducer,
        updateGroupFetch: updateGroupFetchReducer,
        updateGroupSuccess: updateGroupSuccessReducer,
        addStudentToGroupFetch: addStudentToGroupFetchReducer,
        addStudentToGroupSuccess: addStudentToGroupSuccessReducer,
        changeStudentStatusForGroupFetch: changeStudentStatusForGroupFetchReducer,
        changeStudentStatusForGroupSuccess: changeStudentStatusForGroupSuccessReducer,
        setStudentsVisitForLessonFetch: setStudentsVisitForLessonFetchReducer,
        setStudentsVisitForLessonSuccess: setStudentsVisitForLessonSuccessReducer,
        getStudentDataForGroupFetch: getStudentDataForGroupFetchReducer,
        getStudentDataForGroupSuccess: getStudentDataForGroupSuccessReducer,
        getStudentsListForGroupFetch: getStudentsListForGroupFetchReducer,
        getStudentsListForGroupSuccess: getStudentsListForGroupSuccessReducer,
        getGroupGeneralDataFetch: getGroupGeneralDataFetchReducer,
        getGroupGeneralDataSuccess: getGroupGeneralDataSuccessReducer,
        getGroupsListForVendorFetch: getGroupsListForVendorFetchReducer,
        getGroupsListForVendorSuccess: getGroupsListForVendorSuccessReducer,
        setPageGroups: setPageGroupsReducer,
        setMessage: setMessageReducer,
        setMessageVisiable: setMessageVisiableReducer,
        setNewItemId: setNewItemIdReducer,
        getSelectedServiceFetch: getSelectedServiceFetchReducer,
        getSelectedServiceSuccess: getSelectedServiceSuccessReducer,
        setSelectedService: setSelectedServiceReducer,
        setSearchClientsFormVisiable: setSearchClientsFormVisiableReducer,
        createClientFormVisiable: createClientFormVisiableReducer,
        searchClientsFetch: searchClientsFetchReducer,
        searchClientsSuccess: searchClientsSuccessReducer,
        setDefaultValueGroups: setDefaultValueGroupsReducer,
        setAddStudentToGroupFormVisiable: setAddStudentToGroupFormVisiableReducer,
        updateStudentForGroupFetch: updateStudentForGroupFetchReducer,
        updateStudentForGroupSuccess: updateStudentForGroupSuccessReducer,
        getGroupFetch: getGroupFetchReducer,
        getGroupSuccess: getGroupSuccessReducer,
        showStudentStatusForm: showStudentStatusFormReducer,
        showStudentAttendance: showStudentAttendanceReducer,
        deleteGroupFetch: deleteGroupFetchReducer,
        deleteGroupSuccess: deleteGroupSuccessReducer,
        deleteStudentForGroupFetch: deleteStudentForGroupFetchReducer,
        deleteStudentForGroupSuccess: deleteStudentForGroupSuccessReducer,
        showStudentVisitForm: showStudentVisitFormReducer,
        showSkeletonStudent: showSkeletonStudentReducer,
        setPageDataGroups: setPageDataGroupsReducer,
        resetGroups: resetGroupsReducer,
        setGroups: setGroupsReducer,
        setGroupsFilter: setGroupsFilterReducer,
        changeGroupActivityFetch: changeGroupActivityFetchReducer,
        changeGroupActivitySuccess: changeGroupActivitySuccessReducer,
    },
});

export const {
    searchGroupsByFilterFetch,
    searchGroupsByFilterSuccess,
    setError,
    createGroupFetch,
    createGroupSuccess,
    updateGroupFetch,
    updateGroupSuccess,
    addStudentToGroupFetch,
    addStudentToGroupSuccess,
    changeStudentStatusForGroupFetch,
    changeStudentStatusForGroupSuccess,
    setStudentsVisitForLessonFetch,
    setStudentsVisitForLessonSuccess,
    getStudentDataForGroupFetch,
    getStudentDataForGroupSuccess,
    getGroupGeneralDataFetch,
    getGroupGeneralDataSuccess,
    getGroupsListForVendorFetch,
    getGroupsListForVendorSuccess,
    getStudentsListForGroupFetch,
    getStudentsListForGroupSuccess,
    setMessage,
    setMessageVisiable,
    setNewItemId,
    setPageGroups,
    setSelectedService,
    getSelectedServiceFetch,
    getSelectedServiceSuccess,
    setSearchClientsFormVisiable,
    searchClientsFetch,
    searchClientsSuccess,
    createClientFormVisiable,
    setDefaultValueGroups,
    setAddStudentToGroupFormVisiable,
    updateStudentForGroupFetch,
    updateStudentForGroupSuccess,
    getGroupSuccess,
    getGroupFetch,
    showStudentStatusForm,
    showStudentAttendance,
    deleteGroupFetch,
    deleteGroupSuccess,
    deleteStudentForGroupFetch,
    deleteStudentForGroupSuccess,
    showStudentVisitForm,
    showSkeletonStudent,
    setPageDataGroups,
    resetGroups,
    setGroups,
    setGroupsFilter,
    changeGroupActivityFetch,
    changeGroupActivitySuccess,
} = GroupsSlice.actions

export default GroupsSlice.reducer