import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { getLocationsDataFetch } from "../../store/state/locations/locations.slice";
import { SYSTEM_ADMIN_ID } from "../../utl/constants/role";
import { culcPage } from "../../utl/helpers/culcPage";
import { Filter } from "../../utl/models/Filters";

function withLoadingLocations(Component: React.ComponentType) {
    const ComponentWithLoadingLocations: FC = () => {
        const dispatch = useAppDispatch();
        const page = useAppSelector(state => state.locations.page, shallowEqual);
        const totalCount = useAppSelector(state => state.locations.totalCount, shallowEqual);
        const pageAll = useAppSelector(state => state.locations.pageAll, shallowEqual);
        const pageSize = useAppSelector(state => state.locations.pageSize, shallowEqual);
        const getAllItems = useAppSelector(state => state.locations.getAllItems, shallowEqual);
        const pagesRange = culcPage(page, pageAll, totalCount, pageSize);
        const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
        const roleId = useAppSelector(state => state.user.roleId, shallowEqual) || -1;

        useEffect(() => {
            if (roleId > SYSTEM_ADMIN_ID) {
                let filter: Filter = {};

                if (!getAllItems) {
                    filter.range = {
                        from: pagesRange.from,
                        to: pagesRange.to
                    }
                }

                dispatch(getLocationsDataFetch({ vendorId, filter }));
            }
        }, [vendorId, roleId]);

        return (
            <Component />
        );
    }

    return ComponentWithLoadingLocations;
}

export default withLoadingLocations;