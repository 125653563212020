import { PayloadAction } from "@reduxjs/toolkit";
import deleteItem from "../../../../../helpers/deleteItem";
import { ServiceState } from "../../models/ServiceState";
import { DeleteServiceSuccessPayload } from "../../payload/DeleteService/DeleteServiceSuccessPayload";

const deleteServiceSuccess = (state: ServiceState, action: PayloadAction<DeleteServiceSuccessPayload>) => {
    const { serviceId } = action.payload;

    let services = state.basics;
    state.basics = deleteItem(state, services, serviceId);

    state.isLoading = false;
    state.isError = null;
}

export default deleteServiceSuccess;