import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { Navigate } from "react-router-dom";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import { getUserDataFetch } from "../../../store/state/user/user.slice";

function withAuthCheckStatus(Component: React.ComponentType) {
    const ComponentAuthCheckStatus: FC = () => {
        const dispatch = useAppDispatch();
        const tokenStatus = useAppSelector(state => state.user.tokenStatus, shallowEqual);

        // useEffect(() => {
        //     const handler = setInterval(() => {
        //         dispatch(getUserDataFetch({}));
        //     }, 1000 * 60 * 60);

        //     return () => {
        //         clearInterval(handler);
        //     }
        // }, []);

        if(tokenStatus) {
            dispatch(getUserDataFetch({}));
        } else {
            return (
                <Navigate to="/signin" />
            );
        }

        return (
            <Component />
        );
    }

    return ComponentAuthCheckStatus;
}

export default withAuthCheckStatus;