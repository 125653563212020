import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { setFonVisiable } from "../../../store/state/all/all.slice";
import { setDefaultValueClients } from "../../../store/state/clients/clients.slice";
import { setDefaultValueFinance } from "../../../store/state/finance/finance.slice";
import { setDefaultValueGroups } from "../../../store/state/groups/groups.slice";
import Button from "../../general/buttons/Button/Button";
import IconFont from "../../general/icons/IconFont/IconFont";
import styles from "./Header.module.css";

const ButtonGoBack = () => {
    let navigation = useNavigate();
    const dispatch = useAppDispatch();
    let location = useLocation();
    
    const locations = location.pathname.split("/");
    locations.shift();
    if(locations.length === 3 && locations[0].localeCompare("settings") !== 0 && locations[0].localeCompare("services") !== 0) {
        locations.pop();
    }
    locations.pop();

    const clickButtonHeaderHendler = () => {
        // let path = "";
        // locations.forEach((location, index) => path += `${index > 0 ? "/" : ""}${location}`);
        navigation(-1);
        
        dispatch(setFonVisiable({visiable: false}));
        dispatch(setDefaultValueGroups({
            searchCleints: true, 
            searchGroups: true, 
            selectedService: true,
            searchClientsFormVisiable: true,
            createClientFormVisiable: true,
            addStudentToGroupFormVisiable: true,
            showStudentVisitForm: true
        }));
        dispatch(setDefaultValueClients({
            visiableCommentForm: true, 
            visiableTransactionForm: true, 
            clientsPages: false,
            transactionsPages: true
        }));
        dispatch(setDefaultValueFinance({
            visiableAddForm: true,
            visiableExchangeForm: true,
            visiablePaymentForm: true,
            counterpartis: true,
            counteragents: true,
        }));
    }

    return (
        <Button
            handleClick={clickButtonHeaderHendler} 
            icon={
                <div className={styles.goBackArrow}>
                    <IconFont 
                        iconClass="icon-arrow" 
                        color="#FFF" 
                        fontSize={10}
                    />
                </div>
            } 
            title="Назад" 
            backgroundColor="#A6AEC5"
        /> 
    );
}

export default ButtonGoBack;