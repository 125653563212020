import React, { FC } from "react";
import ParametersBlock from "../../../../../components/dataDisplay/ParametersBlock/ParametersBlock";
import ParameterDisplayer from "../../../../../components/layout/ParameterDisplayer/ParameterDisplayer";

interface Props {
    balance: number;
    costPerOrder: number;
    totalSpent: number;
    numberOfPurchases: number;
}

const Parameters: FC<Props> = ({ balance, costPerOrder, totalSpent, numberOfPurchases }) => {
    console.log(balance);
    return (
        <ParametersBlock
            maxWidth={845}
            flexDirection="row"
        >
            <ParameterDisplayer
                title={"Баланс"}
                param={`₴ ${balance}`}
                mode={balance < 0 ? "failure" : "success"}
                status={balance > 0}
                marginRight={20}
            />
            <ParameterDisplayer
                title={"Cost Per Order"}
                param={`₴ ${costPerOrder}`}
                marginRight={20}
            />
            <ParameterDisplayer
                title={"Всього потрачено / К-ть покупок"}
                param={`₴ ${totalSpent}  / ${numberOfPurchases}`}
                marginRight={20}
            />
        </ParametersBlock>
    );
}

export default Parameters;