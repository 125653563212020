import { PayloadAction } from "@reduxjs/toolkit";
import { GroupsState } from "../../models/GroupsState";
import { UpdateStudentForGroupSuccessPayload } from "../../payload/UpdateStudentForGroup/UpdateStudentForGroupSuccessPayload";

const updateStudentForGroupSuccess = (state: GroupsState, action: PayloadAction<UpdateStudentForGroupSuccessPayload>) => {
    const { groupId, studentId, studentData } = action.payload;
    const { status, upsellsIds } = studentData;

    const groups = state.pageData;
    const groupIndex = groups.findIndex(group => group.id === groupId);
    if (groupIndex > -1) {
        const group = groups[groupIndex];
        const students = group.students;
        const studentIndex = students?.findIndex(student => student.student.id === studentId);

        if (students && studentIndex !== undefined && studentIndex > -1) {
            const student = students[studentIndex];

            if (status !== undefined) {
                const studentStatus = student.status;
                student.status = status;
                if((status === 2 || status === 3) && (studentStatus !== 2 && studentStatus !== 3)) {
                    group.studentsAmount += 1;
                } else if(status === 1 && studentStatus !== 1) {
                    group.studentsAmount -= 1;
                }
            }

            if (upsellsIds) {
                student.upsellsIds = upsellsIds;
            }
        }
    }

    state.isLoading = false;
    state.isError = null;
}

export default updateStudentForGroupSuccess;