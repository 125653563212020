import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Money } from "../../../../services/reddyApi/Money/Money";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getMoneyPursesFetch, getMoneyPursesSuccess } from "../finance.slice";
import { GetMoneyPursesPayload } from "../payload/GetMoneyPurses/GetMoneyPursesPayload";

function* getMoneyPursesWorker(action: PayloadAction<GetMoneyPursesPayload>) {
    const { vendorId } = action.payload;
    const dataParams = {
        vendorId
    }

    try {
        const { data } = yield call(Money.getMoneyPurses, dataParams);
        const { vendorId, purses } = data;

        yield put(getMoneyPursesSuccess({ vendorId, purses }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getMoneyPursesFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getMoneyPursesWorker;