import { PayloadAction } from "@reduxjs/toolkit";
import { DeleteResponse } from "../../../../../../services/reddyApi/Upsells/Upsells.response";
import deleteItem from "../../../../../helpers/deleteItem";
import { UpsellState } from "../../models/UpsellsState";
import { DeleteUpsellSuccessPayload } from "../../payload/DeleteUpsell/DeleteUpsellSuccessPayload";

const deleteAdditionalSuccess = (state: UpsellState, action: PayloadAction<DeleteUpsellSuccessPayload>) => {
    const { upsellId } = action.payload;

    const upsells = state.upsells;
    state.upsells = deleteItem(state, upsells, upsellId);

    state.isLoading = false;
    state.isError = null;
}

export default deleteAdditionalSuccess;