import { put } from 'redux-saga/effects';
import { 
    getClientStatisticsForVendorFetch, 
    getFinanceInfoForVendorFetch, 
    getVendorClientDataFetch
} from '../clients/clients.slice';
import { 
    getFutureMoneyFetch, 
    getMoneyPursesFetch, 
    getPlannedMoneyFetch 
} from '../finance/finance.slice';
import { getGroupGeneralDataFetch } from '../groups/groups.slice';
import {
    getAttendanceStatisticFetch, 
    getServiceFetch 
} from '../services/service/service.slice';
import { 
    GetStatisticsClientPayload, 
    GetStatisticsGroupPayload, 
    GetStatisticsPayload, 
    GetStatisticsServicePayload 
} from './statistics.payload';

export function* getStatisticsDashboard(payload: GetStatisticsPayload) {
    const { vendorId, filter } = payload;

    yield put(getPlannedMoneyFetch({ vendorId, filter }));

    yield put(getClientStatisticsForVendorFetch({ vendorId, filter }));

    yield put(getAttendanceStatisticFetch({ vendorId, filter }));
}

export function* getStatisticsFinance(payload: GetStatisticsPayload) {
    const { vendorId, filter } = payload;

    yield put(getMoneyPursesFetch({ vendorId }));

    yield put(getFutureMoneyFetch({ vendorId, filter }));
}

export function* getStatisticsGroup(payload: GetStatisticsGroupPayload) {
    const { vendorId, groupId } = payload;

    yield put(getGroupGeneralDataFetch({ vendorId, groupId }));
}

export function* getStatisticsClient(payload: GetStatisticsClientPayload) {
    const { vendorId, clientId } = payload;

    yield put(getVendorClientDataFetch({ vendorId, clientId }));

    yield put(getFinanceInfoForVendorFetch({ vendorId, clientId }));
}

export function* getStatisticsService(payload: GetStatisticsServicePayload) {
    const { vendorId, serviceId } = payload;

    yield put(getServiceFetch({ vendorId, serviceId }));
}