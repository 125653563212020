import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../../../store/hooks/selector.hook";
import { setFonVisiable } from "../../../../../../store/state/all/all.slice";
import { setStudentsVisitForLessonFetch, showStudentVisitForm } from "../../../../../../store/state/groups/groups.slice";
import { Lessons, StudentGroup } from "../../../../../../store/state/groups/models/Group";
import { SudentVisit } from "../../../../../../store/state/groups/models/SudentVisit";
import { convertStringToArray } from "../../../../../../utl/helpers/convertStringToArray";
import { Filter } from "../../../../../../utl/models/Filters";
import { SetStudentsVisitForLessonValues } from "../../../../models/SetStudentsVisitForLessonValues";
import SetStudentsVisitForLesson from "./SetStudentsVisitForLesson";
import validationSchema from "./SetStudentsVisitForLesson.schema";

const SetStudentsVisitForLessonContainer = () => {
    let { id } = useParams();
    const groupId = id ? Number(id) : -1;
    const dispatch = useAppDispatch();
    const visiable = useAppSelector(state => state.groups.showStudentVisitForm, shallowEqual);
    const groups = useAppSelector(state => state.groups.pageData, shallowEqual);
    const indexGroup = groups.findIndex(group => group.id === groupId);
    let group;
    let studentsGroup: StudentGroup[] | undefined;
    let lessons: Lessons[] = [];
    let upsellsIds: number[] = [];
    let employeesIds: number[] = [];

    if (indexGroup > -1) {
        group = groups[indexGroup];
        lessons = group.lessons.filter(lesson => !lesson.completed);
        studentsGroup = group.students;
        upsellsIds = group.upsellsIds;
        employeesIds = group.employeesIds;
    }

    const closeModalHandler = () => {
        dispatch(showStudentVisitForm({ visiable: false }));
        dispatch(setFonVisiable({ visiable: false }));
    }

    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const studentsVisit = studentsGroup ? studentsGroup.map(student => {
        const upsellsIds = student.upsellsIds ? student.upsellsIds?.join(",") : "";

        return ({
            id: student.student.id,
            name: `${student.student.surname} ${student.student.name}`,
            upsellsIds,
            attended: false,
        });
    }) : [];

    const initialValues: SetStudentsVisitForLessonValues = {
        lessonId: lessons.length > 0 && lessons[0].id || -1,
        students: studentsVisit,
        employeesIds: "",
    };

    const formik = useFormik<SetStudentsVisitForLessonValues>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const { students, lessonId, employeesIds } = values;

            const employeesIdsArray = convertStringToArray(employeesIds);

            const studentsVisit: SudentVisit[] = students.map(student => ({
                id: student.id,
                attended: student.attended,
                upsellsIds: convertStringToArray(student.upsellsIds),
            }));

            const today = new Date();
            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const filter: Filter = {
                period: {
                    from: moment(firstDay).format("YYYY-MM-DD"),
                    to: moment(lastDay).format("YYYY-MM-DD"),
                }
            }

            const clientsIds = studentsGroup ? studentsGroup.map(student => student.client.id) : []

            dispatch(setStudentsVisitForLessonFetch({ 
                groupId, 
                vendorId, 
                lessonId, 
                students: studentsVisit, 
                filter, 
                clientsIds,
                employeesIds: employeesIdsArray,
            }));
            closeModalHandler();
        },
    });

    const { setFieldValue } = formik;

    useEffect(() => {
        setFieldValue("students", studentsVisit);
    }, [studentsGroup]);

    const visiableForm = lessons.length > 0;

    return (
        <SetStudentsVisitForLesson
            formik={formik}
            lessons={lessons}
            closeModalHandler={closeModalHandler}
            visiable={visiable}
            visiableForm={visiableForm}
            groupUpsellsIds={upsellsIds}
            groupEmployeesIds={employeesIds}
        />
    )
}

export default SetStudentsVisitForLessonContainer;