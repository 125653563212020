interface Item {
    id?: number;
    clientId?: number;
}

interface State {
    totalCount: number;
    pageAll: number;
    pageSize: number;
    page: number;
}

function deleteItem<T extends Item, A extends State>(state: A | null, items: T[], id: number) {
    if (state) {
        state.totalCount = state.totalCount - 1;
        state.pageAll = Math.ceil(state.totalCount / state.pageSize);

        if (state.pageAll < state.page) {
            state.page = state.pageAll;
        }
    }

    return items.filter(item => {
        if (item.id !== undefined) {
            return item.id !== id;
        } else if (item.clientId !== undefined) {
            return item.clientId !== id;
        }
    });
}

export default deleteItem;