import React, { FC } from "react";

interface Props {
    width?: string;
    height?: string;
    fill?: string;
}

const CheckedIcon: FC<Props> = ({width = "26", height = "26", fill="#4775FE"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M25.9629 13.3134C25.9629 20.217 20.3665 25.8134 13.4629 25.8134C6.55931 25.8134 0.962891 20.217 0.962891 13.3134C0.962891 6.40987 6.55931 0.813446 13.4629 0.813446C20.3665 0.813446 25.9629 6.40987 25.9629 13.3134ZM12.017 19.9321L21.2912 10.6579C21.6061 10.343 21.6061 9.83235 21.2912 9.51743L20.1507 8.37695C19.8358 8.06198 19.3252 8.06198 19.0102 8.37695L11.4468 15.9404L7.91556 12.4092C7.60064 12.0942 7.09001 12.0942 6.77504 12.4092L5.63456 13.5496C5.31964 13.8646 5.31964 14.3752 5.63456 14.6901L10.8765 19.932C11.1915 20.247 11.7021 20.247 12.017 19.9321Z" 
                fill={fill}
            />
        </svg>
    );
}

export default CheckedIcon;