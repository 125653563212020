interface Pages {
    from: number;
    to: number;
}

export const culcPage = (page: number, pageAll: number, total: number, pageSize: number): Pages => {
    return {
        from: (page - 1) * pageSize,
        to: page === pageAll ? total - 1 : (page * pageSize) - 1,
    }
}