import React, { FC, MouseEvent } from "react";
import { shallowEqual } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import StatusView from "../../../components/dataDisplay/table/views/StatusView/StatusView";
import ButtonIcon from "../../../components/general/buttons/ButtonIcon/ButtonIcon";
import ButtonInfo from "../../../components/general/buttons/ButtonInfo/ButtonInfo";
import IconFont from "../../../components/general/icons/IconFont/IconFont";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import { deleteLocationFetch } from "../../../store/state/locations/locations.slice";
import LocationsPagination from "./Locations.pagination";
import { LocationsColumns } from "./models/LocationsColumns";

const LocationsContainer: FC = () => {
    let location = useLocation();
    let navigation = useNavigate();
    const path = location.pathname;
    const dispatch = useAppDispatch();
    const locations = useAppSelector(state => state.locations.locationsArray, shallowEqual)

    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const data: LocationsColumns[] = locations.map(location => {
        const editClickButton = () => {
            navigation(`${path}/${location.id}`);
        }

        const deleteClickButton = () => {
            dispatch(deleteLocationFetch({ id: location.id, vendorId: vendorId || -1 }));
        }

        const confirmed = location?.confirmed !== undefined ?  location.confirmed : true;
        const active = location?.active !== undefined ? location?.active : true;
        const status = active ? Number(confirmed) : 2;

        return {
            id: location.id,
            name: location.name,
            city: location.city,
            address: location.address,
            status: <StatusView status={status} />,
            phone:
                <ButtonInfo
                    icon={<IconFont color="#A6AEC5" iconClass="icon-tel" fontSize={13} />}
                    info={location.phone}
                />,
            edit:
                <ButtonIcon
                    width={25}
                    height={25}
                    icon={<IconFont color="#A6AEC5" iconClass="icon-edit" fontSize={12} />}
                    handleClick={editClickButton}

                />,
            delete:
                <ButtonIcon
                    width={25}
                    height={25}
                    icon={<IconFont color="#A6AEC5" iconClass="icon-delete" fontSize={12} />}
                    handleClick={deleteClickButton}
                />,
        }
    });

    const clickRowTableHandler = (item: any, event: MouseEvent<HTMLTableRowElement>) => {
        navigation(`${item.id}`, {})
    }

    return (
        <LocationsPagination
            data={data}
            handleClickRow={clickRowTableHandler}
            vendorId={vendorId}
        />
    );
}

export default React.memo(LocationsContainer);