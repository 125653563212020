import { PayloadAction } from "@reduxjs/toolkit";
import { ServiceState } from "../../models/ServiceState";
import { ChangeServiceActivitySuccessPayload } from "../../payload/ChangeServiceActivity/ChangeServiceActivitySuccessPayload";

const changeServiceActivitySuccess = (state: ServiceState, action: PayloadAction<ChangeServiceActivitySuccessPayload>) => {
    const { serviceId, active } = action.payload;

    const services = state.basics;

    const indexService = services.findIndex(service => service.id === serviceId);

    if(indexService > -1) {
        const service = services[indexService];

        service.active = active;
    }
}

export default changeServiceActivitySuccess;