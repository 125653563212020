import React, { FC } from "react";
import Button from "../../../../components/general/buttons/Button/Button";
import IconFont from "../../../../components/general/icons/IconFont/IconFont";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { setFonVisiable } from "../../../../store/state/all/all.slice";
import { setVisiableTransactionForm } from "../../../../store/state/clients/clients.slice";

interface Props {

} 

const AddTransactionButton: FC<Props> = () => {
    const dispatch = useAppDispatch();

    const clickButtonHandler = () => {
        dispatch(setVisiableTransactionForm({visiable: true}));
        dispatch(setFonVisiable({visiable: true}));
    }

    return (
        <Button
            backgroundColor="#4775FE" 
            title="Оплата" 
            icon={<IconFont iconClass="icon-plus" color="#FFF" fontSize={14} />} 
            handleClick={clickButtonHandler} 
            marginRight={0}
        />
    );
}

export default AddTransactionButton;