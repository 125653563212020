import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface Payload {
    newItemId: number | null;
    setNewItemId: (id: number | null) => void;
}

const useRedirectToNewItemPage = (payload: Payload) => {
    const { newItemId, setNewItemId } = payload;

    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;

    useEffect(() => {
        if (newItemId) {
            navigate(path.replace("new", String(newItemId)));
            
            setNewItemId(null);
        }
    }, [newItemId !== null]);
}

export default useRedirectToNewItemPage;