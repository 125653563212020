import React, { FC } from "react";
import Button from "../../components/general/buttons/Button/Button";
import Icon404 from "../../components/general/icons/404Icon/404Icon";
import CenterLayout from "../../components/layout/CenterLayout/CenterLayout";
import logo from "../../assets/svg/Logo.svg";
import Paragraph from "../../components/general/typography/Paragraph/Paragraph";

interface Props {
    isAuth: boolean;
    handleClick: () => void;
}

const NoMatch: FC<Props> = ({handleClick, isAuth}) => {
    const Elements404: FC = () => {
        return (
            <>
                <img style={{marginBottom: 20}} src={logo} alt="logo" />
                <Paragraph 
                    content="Ууупс, щос пішло не так" 
                    marginBottom={5} 
                    textAlign="center" 
                    color="#A6AEC5" 
                />
                <Paragraph 
                    content="Нажаль цієї сторінки не існує" 
                    marginBottom={20} 
                    textAlign="center" 
                    fontSize={18}
                    fontWeight="bold"
                    lineHeight={21}
                />
                <Button 
                    maxWidth={180}
                    marginBottom={80}
                    handleClick={handleClick} 
                    backgroundColor="#FA5D54" 
                    title="Повернусь на головну" 
                />
                <Icon404 />
            </>
        );
    } 

    return (
        <CenterLayout flexDirection="column">
            <Elements404 />
        </CenterLayout> 
    );
}

export default NoMatch;