import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../../../store/hooks/selector.hook";
import { setFonVisiable } from "../../../../../../store/state/all/all.slice";
import { setDefaultValueClients } from "../../../../../../store/state/clients/clients.slice";
import { addStudentToGroupFetch, setSearchClientsFormVisiable } from "../../../../../../store/state/groups/groups.slice";
import { Group } from "../../../../../../store/state/groups/models/Group";
import { getNotificationsFetch, NotificationStatuses } from "../../../../../../store/state/notifications/notifications.slice";
import { Filter } from "../../../../../../utl/models/Filters";
import { AddClientToGroupFormValues } from "../../../../models/AddClientToGroupFormValues";
import AddClientToGroupForm from "./AddClientToGroupForm";
import validationSchema from "./AddClientToGroupForm.schema";

const AddClientToGroupFormContainer = () => {
    let { id } = useParams();
    const groupId = id ? Number(id) : -1;
    const dispatch = useAppDispatch();
    const groups = useAppSelector(state => state.groups.pageData, shallowEqual);
    const indexGroup = groups.findIndex(group => group.id === groupId);
    let group: Group | undefined;
    let coursePrice = 0;
    let amountLessons = 0;
    if (indexGroup > -1) {
        group = groups[indexGroup];
        coursePrice = group.price;
        amountLessons = group.lessons.length;
    }

    const closeModalHandler = () => {
        dispatch(setSearchClientsFormVisiable({ visiable: false }));
        dispatch(setFonVisiable({ visiable: false }));
    }

    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const searchListClients = useAppSelector(state => state.clients.searchedClients, shallowEqual);

    const initialValues: AddClientToGroupFormValues = {
        studentId: -1,
        status: 2,
        studentData: null,
    }

    const formik = useFormik<AddClientToGroupFormValues>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const { status, studentId, studentData } = values;

            const today = new Date();

            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const filter: Filter = {
                period: {
                    from: moment(firstDay).format("YYYY-MM-DD"),
                    to: moment(lastDay).format("YYYY-MM-DD"),
                }
            }

            const clientIndex = searchListClients.findIndex(client =>
                client.students.findIndex(student => student.id === studentId) > -1
            );
            const client = searchListClients[clientIndex];
            
            const clientId = client?.clientId || -1;
            const serviceId = group?.serviceId || -1;

            dispatch(addStudentToGroupFetch({
                student: {
                    studentId,
                    status,
                },
                vendorId,
                groupId,
                clientId,
                serviceId,
                studentData: studentData ? { ...studentData, status } : null,
                filter,
            }));

            closeModalHandler();
            dispatch(setDefaultValueClients({ searchedClients: true }));
        },
    });
    return (
        <AddClientToGroupForm
            formik={formik}
            closeModalHandler={closeModalHandler}
            searchListUsers={searchListClients}
            groupId={groupId}
            coursePrice={coursePrice}
            amountLessons={amountLessons}
        />
    )
}

export default AddClientToGroupFormContainer;