import { PayloadAction } from "@reduxjs/toolkit";
import getItems from "../../../../helpers/getItems";
import { Location } from "../../models/Location";
import { LocationsState } from "../../models/LocationsState";
import { GetLocationsDataSuccessPayload } from "../../payload/GetLocationsData/GetLocationsDataSuccessPayload";

const defaultLocationItem: Location = {
    id: -1,
    name: "Location",
    city: "City",
    address: "Address",
    description: "",
    coordinates: [0, 0],
    phone: "",
    active: true,
    confirmed: false,
}

const getLocationsDataSuccess = (state: LocationsState, action: PayloadAction<GetLocationsDataSuccessPayload>) => {
    const { items, totalCount, filter } = action.payload;

    const locations = state.locationsArray;

    state.locationsArray = getItems<Location, LocationsState>(
        state, locations, items, totalCount, defaultLocationItem, filter
    );

    state.isLoading = false;
    state.isError = null;
}

export default getLocationsDataSuccess;