import { PayloadAction } from "@reduxjs/toolkit";
import { FinanceState } from "../../models/FinanceState";
import { GetPlannedMoneySuccessPayload } from "../../payload/GetPlannedMoney/GetPlannedMoneySuccessPayload";

const getPlannedMoneySuccess = (state: FinanceState, action: PayloadAction<GetPlannedMoneySuccessPayload>) => {
    const planned = action.payload;
   
    state.finance.planned = planned;
    
    state.isLoading = true;
    state.isError = null;
}

export default getPlannedMoneySuccess;