import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./InfoList.module.css";
import NameEdit from "./NameEdit";

interface Props {
    name: string;
    linkTo: string;
}

const InfoList: FC<Props> = ({children, name, linkTo}) => {
    const navigate = useNavigate();

    const clickButtonLinkHandler = () => {
        navigate(linkTo)
    }

    return (
        <div className={styles.infoListBlock}>
            <NameEdit 
                name={name}
                onClick={clickButtonLinkHandler}
            />
            <ul className={styles.infoList}>
                {children}
            </ul>
        </div>
    )
}

export default InfoList;