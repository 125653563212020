import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Locations } from "../../../../services/reddyApi/Locations/Locations";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { updateLocationFetch, updateLocationSuccess } from "../locations.slice";
import { UpdateLocationPayload } from "../payload/UpdateLocation/UpdateLocationPayload";

function* updateLocationWorker(action: PayloadAction<UpdateLocationPayload>) {
    const {location, locationId, vendorId} = action.payload;

    try {
        const {status} = yield call(Locations.update, action.payload);
        if(status === 200) {
            yield put(updateLocationSuccess({location: {...location, id: locationId}}));

            yield getNotifications(vendorId);

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => updateLocationFetch(action.payload);
            
            yield handleServerError(error, actionCreator);
        }
    }
}

export default updateLocationWorker;