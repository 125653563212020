import { PayloadAction } from "@reduxjs/toolkit";
import { ConfigState } from "../../models/ConfigState";
import { GetUserConfigSuccessPayload } from "../../payload/GetUserConfig/GetUserConfigSuccessPayload";

const getUserConfigSuccess = (state: ConfigState, action: PayloadAction<GetUserConfigSuccessPayload>) => {
    const { userConfig } = action.payload;
    
    state.userConfig = userConfig;
    
    state.isError = null;
    state.isLoading = false;
}

export default getUserConfigSuccess;