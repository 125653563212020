import { PayloadAction } from "@reduxjs/toolkit";
import { FinanceState } from "../../models/FinanceState";
import { SearchCounteragentsAsClientsSuccessPayload } from "../../payload/SearchCounteragentsAsClients/SearchCounteragentsAsClientsSuccessPayload";

const searchCounteragentsAsClientSuccess = (state: FinanceState, action: PayloadAction<SearchCounteragentsAsClientsSuccessPayload>) => {
    const counteragentsAsClients = action.payload;
    state.counteragentsAsClients = counteragentsAsClients;
    state.isLoading = true;
    state.isError = null;
}

export default searchCounteragentsAsClientSuccess;