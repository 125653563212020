import React, { FC, MouseEvent } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import { getAdditionalListFetch, setPageUpsells } from "../../../store/state/services/upsells/upsells.slice";
import culcPageTable from "../../../utl/helpers/culcPageTable";
import usePagination from "../../../utl/hooks/usePagination";
import { Filter } from "../../../utl/models/Filters";
import { UpsellsColumn } from "./models/UpsellsColumn";
import Upsells from "./Upsells";

interface Props {
    vendorId: number;
    data: UpsellsColumn[];
    handleClickRow: (item: any, event: MouseEvent<HTMLTableRowElement>) => void;
}

const UpsellsPagination: FC<Props> = ({ vendorId, data, handleClickRow }) => {
    const dispatch = useAppDispatch();

    const confirmed = useAppSelector(state => state.upsellsServices.filter.confirmed, shallowEqual);
    const active = useAppSelector(state => state.upsellsServices.filter.active, shallowEqual);

    const page = useAppSelector(state => state.upsellsServices.page, shallowEqual);
    const totalCount = useAppSelector(state => state.upsellsServices.totalCount, shallowEqual);
    const pageAll = useAppSelector(state => state.upsellsServices.pageAll, shallowEqual);
    const pageSize = useAppSelector(state => state.upsellsServices.pageSize, shallowEqual);
    const getAllItems = useAppSelector(state => state.upsellsServices.getAllItems, shallowEqual);

    const setPage = (filter: Filter | undefined, pageNumber: number) => {
        dispatch(getAdditionalListFetch({ vendorId, filter }));
        dispatch(setPageUpsells({ page: pageNumber }));
    };

    const pagination = usePagination({
        getAllItems,
        pageAll,
        page,
        totalCount,
        pageSize,
        setPage,
        confirmed,
        active,
    });

    const pagesRange = pagination.pagesRange;

    const pageTable = culcPageTable<UpsellsColumn>(data, pagesRange.from, pagesRange.to);

    return (
        <Upsells
            handleClickRow={handleClickRow}
            data={pageTable}
            page={page}
            pages={pagination.pages}
            handleLeftButtonPagination={pagination.handleLeftButtonPagination}
            handleRightButtonPagination={pagination.handleRightButtonPagination}
        />
    );
}

export default UpsellsPagination;