import { parse, isDate } from "date-fns";
import moment from "moment";

enum DurationType {
    HOUR = 1,
    WEEK = 2,
    MONTH = 3,
}

interface IDateApi {
    parseDateString: (value: string, originalValue: string) => string | Date;
    parseDateStringPrint: (originalValue: string) => string;
    parseDateTimeStringPrint: (datetime: string) => string;
    parseDateTime: (datetime: string) => [string, string];
    setDate: (dateStart: string, number: number, type?: number, operationType?: "plus" | "minus") => string;
    calculateSchedule: (startDate: string, daysWeek: number[], duration: number, lessons: number) => string[];
    getDayOfDate: (date: string) => number;
    padTo2Digits: (number: number) => string;
    formatDate: (date: Date, separator?: string) => string;
    getMothUAName: (month: number) => string;
}

export const DateApi: IDateApi = {
    parseDateString: (value: string, originalValue: string) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, "yyyy-MM-dd", new Date());

        return parsedDate;
    },
    parseDateStringPrint: (originalValue) => {
        const parsedDate = parse(originalValue, "yyyy-MM-dd", new Date());

        return moment(parsedDate).format('DD.MM.YYYY');
    },
    parseDateTimeStringPrint: (datetime) => {
        return moment(datetime).format('DD.MM.YYYY');
    },
    parseDateTime: (datetime) => {
        const date = new Date(datetime);

        return [
            moment(date).format('YYYY-MM-DD'),
            moment(date).format('HH:mm'),
        ];
    },
    padTo2Digits: (num) => {
        return num.toString().padStart(2, '0');
    },
    formatDate: (date, separator = ".") => {
        return [
            DateApi.padTo2Digits(date.getDate()),
            DateApi.padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join(separator);
    },
    setDate: (dateStart, number, type = 3, operationType = "plus") => {
        const date = parse(dateStart, "yyyy-MM-dd", new Date());
        if (operationType === "plus") {
            switch (type) {
                case 3:
                    date.setMonth(date.getMonth() + number);
                    break;
                case 2:
                    date.setDate(date.getDate() + number);
                    break;
                case 1:
                    date.setHours(date.getHours() + number);
                    break;
            }
        } else {
            switch (type) {
                case 3:
                    date.setMonth(date.getMonth() - number);
                    break;
                case 2:
                    date.setDate(date.getDate() - number);
                    break;
                case 1:
                    date.setHours(date.getHours() - number);
                    break;
            }
        }

        return moment(date).format('YYYY-MM-DD');
    },
    calculateSchedule: (startDate, weekdays, duration, lessons) => {
        let weekdaysSchedule = [...weekdays];
        const date = new Date(startDate);
        let weekday = date.getDay();
        const WEEKDAYS_SCHEDULE_LENHTH = weekdaysSchedule.length; 
        
        if (duration === DurationType.MONTH || duration === DurationType.WEEK) {
            const MOVING_FORWARD_ON_SCHEDULE = 1;

            const schedule: string[] = [];
            let nextWeekdayIndex = 0;
            let nextWeekday = 0;
            let weekCounter = 0;
            for(let i = 0; i < lessons; i++) {
                if(nextWeekdayIndex >= WEEKDAYS_SCHEDULE_LENHTH) {
                    nextWeekdayIndex = 0;
                    weekCounter = 7;
                }

                nextWeekday = weekdaysSchedule[nextWeekdayIndex] + weekCounter;
                
                if(i === 0) {
                    schedule.push(startDate);
                    nextWeekdayIndex = weekdaysSchedule.findIndex(weekdaySchedule => weekdaySchedule === weekday) + MOVING_FORWARD_ON_SCHEDULE;
                    if(nextWeekdayIndex < 1) {
                        nextWeekdayIndex = WEEKDAYS_SCHEDULE_LENHTH;
                    }
                    continue;
                }
 
                let differenceInDays = nextWeekday - weekday;

                date.setDate(date.getDate() + differenceInDays);
                weekday = date.getDay();
                nextWeekdayIndex = weekdaysSchedule.findIndex(weekdaySchedule => weekdaySchedule === weekday) + MOVING_FORWARD_ON_SCHEDULE;
                weekCounter = 0;
                const formatdate = moment(date).format('YYYY-MM-DD');
                schedule.push(formatdate);
            }

            return schedule;
        }

        const schedule: string[] = [];
        for (let i = 0; i < lessons; i++) {
            schedule.push(startDate);
        }

        return schedule;
    },
    getDayOfDate: (date: string) => {
        return new Date(date).getDay();
    },
    getMothUAName: (month: number) => {
        switch(month) {
            case 0 : return "cічень";
            case 1 : return "лютий";
            case 2 : return "березень";
            case 3 : return "квітень";
            case 4 : return "травень";
            case 5 : return "червень";
            case 6 : return "липень";
            case 7 : return "серпень";
            case 8 : return "вересень";
            case 9 : return "жовтень";
            case 10 : return "листопад";
            case 11 : return "грудень";
            default : return ""
        }
    }
}