import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Employees } from "../../../../services/reddyApi/Employees/Employees";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getStatisticsDashboard, getStatisticsFinance } from "../../statistics/statistics.saga";
import { updateEmployeeFetch, updateEmployeeSuccess } from "../employees.slice";
import { UpdateEmployeePayload } from "../payload/UpdateEmployee/UpdateEmployeePayload";
import handleError from "./handleError";

function* updateWorker(action: PayloadAction<UpdateEmployeePayload>) {
    const { vendorId, employee, employeeId, filter } = action.payload;
    try {
        const { status } = yield call(Employees.update, action.payload);

        if (status === 200) {
            yield put(updateEmployeeSuccess({ employee: { ...employee, id: employeeId } }));

            yield getStatisticsDashboard({ vendorId, filter });

            yield getStatisticsFinance({ vendorId, filter });

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const statusCode = error.response?.status;

            if (statusCode === 472 || statusCode === 473) {
                handleError(statusCode);
            } else {
                const actionCreator = () => updateEmployeeFetch(action.payload);

                yield handleServerError(error, actionCreator);
            }
        }
    }
}

export default updateWorker;