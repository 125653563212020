import { Filter } from "../../utl/models/Filters";

interface State {
    totalCount: number;
    pageAll: number;
    pageSize: number;
}

interface Item {
    id?: number;
    clientId?: number;
}

function getItems<T extends Item, S extends State>(
    state: S,
    oldItems: T[],
    items: T[],
    totalCount: number,
    defaultItem: T,
    filter: Filter | undefined
): T[] {
    state.totalCount = totalCount;
    state.pageAll = Math.ceil(totalCount / state.pageSize);

    let newItems: T[] = [];
    if (filter?.range) {
        for (let i = 0; i < totalCount; i++) {
            if (oldItems[i]) {
                newItems[i] = { ...oldItems[i] };
                continue;
            }

            newItems[i] = { ...defaultItem };
        }

        let j = 0;
        for (let i = filter.range.from; i <= filter.range.to; i++) {
            if (items[j]) {
                newItems[i] = { ...items[j] };
            }

            j++;
        }
    } else {
        newItems = [...oldItems];
        items.forEach(function (item) {
            if (newItems.findIndex(elem => {
                if (item.id && elem.id) {
                    return item.id === elem.id;
                } else if (item.clientId && elem.clientId) {
                    return item.clientId === elem.clientId;
                }

                return false;
            }) === -1) {
                newItems.push(item);
            }
        });
    }

    return newItems;
}

export default getItems;