import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Wrp from "../../components/layout/Wrp/Wrp";
import TabPanel from "../../components/navigation/TabPanel/TabPanel";
import TabPanelItem from "../../components/navigation/TabPanel/TabPaneltem";
import withMatch from "../../components/other/hoc/withMatch";

interface State {
    name: string;
    active: boolean;
    url: string;
}

const Settings = () => {
    const navigate = useNavigate();
    let location = useLocation();
    const locations = location.pathname.split("/");
    locations.shift();

    const [state, setState] = useState<Array<State>>([
        {
            name: "Локації",
            active: locations.length > 1 && locations[1] === "locations",
            url: "locations"
        },
        {
            name: "Гроші",
            active: locations.length > 1 && locations[1] === "money",
            url: "money"
        },
    ]);

    useEffect(() => {
        setState([
            {
                name: "Локації",
                active: locations.length > 1 && locations[1] === "locations",
                url: "locations"
            },
            {
                name: "Гроші",
                active: locations.length > 1 && locations[1] === "money",
                url: "money"
            },
        ]);
    }, [location]);

    const panelItems = state.map((item, index) => {
        const clickTabHandler = () => {
            navigate(`/settings/${item.url}`);
        }

        return (
            <TabPanelItem 
                key={"PANEL_ITEM_SETTINGS_" + index}
                name={item.name} 
                active={item.active} 
                handleClick={clickTabHandler} 
            />
        )
    });

    return (
        <Wrp flexDirection="column" >
            <>{locations.length < 3 && <TabPanel marginBottom={20}>
                {panelItems}
            </TabPanel>}</>
            <Outlet />
        </Wrp>
    );
}

export default withMatch(React.memo(Settings), [2, 3, 4]);