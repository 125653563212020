import React, { FC } from "react";
import logo from "../../../../assets/svg/Name.svg";
import styles from "./LogoName.module.css";

const LogoName: FC = () => {
    return (
        <img src={logo} alt="Name Project" className={styles.logo} />
    )
}

export default LogoName;