import classNames from "classnames";
import { FormikErrors, FormikTouched } from "formik";
import React, { FC, useState } from "react";
import Field from "../../../../../../components/dataEntry/inputs/Field/Field";
import ArrayFields from "../../../../../../components/dataEntry/inputs/ListFields/ArrayFields";
import { ContextValue } from "../../../../../../components/dataEntry/inputs/ListFields/models/ContextValue";
import ButtonIcon from "../../../../../../components/general/buttons/ButtonIcon/ButtonIcon";
import IconFont from "../../../../../../components/general/icons/IconFont/IconFont";
import Wrp from "../../../../../../components/layout/Wrp/Wrp";
import { PaymentParts as PaymentPartsInterface } from "../../../../../../store/state/groups/models/Group";
import { PriceTypes } from "../../../../models/PriceTypes";
import PriceTypeDropdown from "../PriceTypeDropdown/PriceTypeDropdown";

interface Props {
    paymentParts: PaymentPartsInterface[];
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    error: string | string[] | FormikErrors<PaymentPartsInterface>[] | undefined;
    touched: FormikTouched<PaymentPartsInterface>[] | undefined;
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    handleBlur: {
        (e: React.FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    }
}

const PaymentParts: FC<Props> = ({ setFieldValue, paymentParts, error, touched, handleBlur, handleChange }) => {
    const priceTypes: PriceTypes[] = [{ id: 1, name: "Одразу" }, { id: 2, name: "Частинами" }];
    const [priceTypeValue, setPriceTypeValue] = useState("1");

    const priceTypeId = parseInt(priceTypeValue);

    const name = "paymentParts";
    const paymentPartsItems = (context: ContextValue) => paymentParts && paymentParts.map((paymentPart, index) => {
        const clickButtonIconHandler = () => {
            if (index === 0) {
                context.push({ date: "", sum: 0 });
            } else {
                context.remove(index);
            }
        }

        const cx = classNames.bind(context.styles);

        const touchedPaymentParts = touched;
        const errorsPaymentParts = error;
        const marginBottom = 0;

        let sumError = errorsPaymentParts && Array.isArray(errorsPaymentParts) && errorsPaymentParts[index];
        if (typeof sumError === "object") {
            sumError = sumError.sum;
        }

        let dateError = errorsPaymentParts && Array.isArray(errorsPaymentParts) && errorsPaymentParts[index];
        if (typeof dateError === "object") {
            dateError = dateError?.date
        }

        return (
            <li key={"PAYMENT_PARTS_ITEM_" + index} className={context?.styles.listItem}>
                <Field
                    type={"number"}
                    handleChange={handleChange(`${name}[${index}].sum`)}
                    handleBlur={handleBlur(`${name}[${index}].sum`)}
                    error={(sumError && String(sumError)) || undefined}
                    touched={(touchedPaymentParts && touchedPaymentParts[index]?.sum) || undefined}
                    maxWidth={140}
                    paddingLeft={35}
                    topPositionIcon={10}
                    marginBottom={marginBottom}
                    icon={<span style={{ fontSize: 14, fontWeight: "500" }}>₴</span>}
                    value={paymentPart.sum}
                />

                <Field
                    type={"date"}
                    marginRight={0}
                    marginBottom={marginBottom}
                    handleChange={handleChange(`${name}[${index}].date`)}
                    handleBlur={handleBlur(`${name}[${index}].date`)}
                    error={(dateError && String(dateError)) || undefined}
                    touched={(touchedPaymentParts && touchedPaymentParts[index]?.date) || undefined}
                    maxWidth={180}
                    value={paymentPart.date}
                    topPositionIcon={8}
                    button={priceTypeId === 2 ?
                        <ButtonIcon
                            width={25}
                            height={25}
                            icon={
                                <div className={cx(index > 0 && context?.styles.rotate)}>
                                    <IconFont color="#FFF" iconClass="icon-plus" fontSize={12} />
                                </div>
                            }
                            handleClick={clickButtonIconHandler}
                            backgroundColor="#4775FE"
                            borderRadius="50%"
                        />
                        :
                        <></>
                    }
                />
            </li>
        );
    });

    const multiline = paymentParts.length > 1;

    return (
        <>
           <PriceTypeDropdown
                priceTypes={priceTypes}
                priceTypeId={priceTypeId}
                multiline={multiline}
                setFieldValue={setFieldValue}
                setPriceTypeValue={setPriceTypeValue}
                paymentParts={paymentParts}
           />

            <Wrp marginTop={20} maxWidth={330}>
                <ArrayFields
                    name={name}
                    fields={paymentPartsItems}
                    marginRight={20}
                />
            </Wrp>
        </>
    );
}

export default PaymentParts;

