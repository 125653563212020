import { PayloadAction } from "@reduxjs/toolkit";
import { ClientsState } from "../../models/ClientsState";
import { GetFinanceInfoForVendorSuccessPayload } from "../../payload/GetFinanceInfoForVendor/GetFinanceInfoForVendorSuccessPayload";

const getFinanceInfoForVendorSuccess = (state: ClientsState, actions: PayloadAction<GetFinanceInfoForVendorSuccessPayload>) => {
    const { clientId, financeInfo } = actions.payload;

    const clients = state.pageData;

    const clientIndex = clients.findIndex(client => client.clientId === clientId);

    if (clientIndex > -1) {
        clients[clientIndex].financeInfo = { ...financeInfo };
    }
    
    state.isLoading = false;
    state.isError = null;
}

export default getFinanceInfoForVendorSuccess;