import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Employees } from "../../../../services/reddyApi/Employees/Employees";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { sendPasswordFetch, sendPasswordSuccess } from "../employees.slice";
import { SendEmployeePasswordPayload } from "../payload/SendEmployeePassword/SendEmployeePasswordPayload";

function* sendPasswordWorker(action: PayloadAction<SendEmployeePasswordPayload>) {
    const { vendorId, employeeId } = action.payload;

    try {
        const { data, status } = yield call(Employees.sendPassword, { employeeId, vendorId });

        if (status === 200) {
            yield put(sendPasswordSuccess());

            yield getNotifications(vendorId);

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => sendPasswordFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default sendPasswordWorker;