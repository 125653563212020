import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import Dropdown from "../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { useAppSelector } from "../../../../../../store/hooks/selector.hook";
import { configListAdapter } from "../../../../../../utl/adapters/ConfigListAdapter";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../../utl/constants/defaultValue";

interface Props {
    typeId: number;
    error: string | undefined;
    touched: boolean | undefined;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
}

const TypeDropdown: FC<Props> = ({ typeId, error, touched, setFieldValue, setFieldTouched }) => {
    let types = useAppSelector(state => state.config.vendorConfig?.service.types, shallowEqual);
    const typesList = configListAdapter(types);

    const indexType = typesList.findIndex(type => type.id === typeId);

    const defaultType = typesList[indexType];

    const defaultName = defaultType?.name;
    const valueInput = (defaultType && String(defaultType.id)) || DEFAULT_VALUES_DROPDOWN.valueInput;
    const valueText = defaultName || DEFAULT_VALUES_DROPDOWN.valueText;

    const defaultValueType: DefaultValue = {
        valueInput,
        valueText,
    }

    const typeItems = typesList.map(type => {
        const name = type.name;
        const id = String(type.id);

        return (
            <DropdownItem
                item={name}
                valueInput={id}
                valueText={name}
            />
        )
    });

    const changeHandler = (typeId: string) => {
        setFieldValue("typeId", parseInt(typeId));
    }

    const blurHandler = (touched: boolean) => {
        setFieldTouched("typeId", touched)
    }

    return (
        <Dropdown
            label="Тип"
            maxWidth={170}
            marginRight={20}
            defaultValue={defaultValueType}
            handleChange={changeHandler}
            handleBlur={blurHandler}
            error={error}
            touched={touched}
            doubleClickDropdown={false}
        >
            {typeItems}
        </Dropdown>
    )
}

export default TypeDropdown;