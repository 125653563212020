import * as Yup from 'yup';
import { DateApi } from '../../../../services/Date/Date';

export default Yup.object().shape({
    serviceId: Yup.number()
        .min(0, "Invalid Value")
        .required('Required'),
    employeesIds: Yup.string()
        .required('Required'),
    name: Yup.string()
        .required('Required'),
    locationId: Yup.number()
        .min(0, "Invalid Value")
        .required('Required'),
    studentsMin: Yup.number()
        .required('Required')
        .positive()
        .integer()
        .min(0, 'This string not valid'),
    studentsMax: Yup.number()
        .positive()
        .integer()
        .min(Yup.ref('studentsMin'), 'This string not valid')
        .required('Required'),
    price: Yup.number()
        .required('Required'),
    paymentParts: Yup.array(Yup.object().shape({
        sum: Yup.number()
            .required('Required'),
        date: Yup.date()
            .transform(DateApi.parseDateString)
            .required('Required')
    })).required('Required'),
    notificable: Yup.boolean()
        .required('Required'),
    notificationBeforeLesson: Yup.object().shape({
        days: Yup.number()
            .required('Required'),
        time: Yup.string().required('Required')
    }),
    lessonWeekDays: Yup.string()
        .required('Required'),
    startDate: Yup.date()
        .transform(DateApi.parseDateString)
        .required('Required'),
    endDate: Yup.date()
        .transform(DateApi.parseDateString)
        .required('Required'),
    lessons: Yup.array(Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string(),
        nameForTeacher: Yup.string(),
        date: Yup.date()
            .transform(DateApi.parseDateString)
            .required('Required'),
        time: Yup.string().required('Required'),
        durationInHours: Yup.number().default(0).required('Required')
    }))
        .required('Required'),
    upsellsIds: Yup.string(),
    confirmed: Yup.boolean().required('Required'),
    online: Yup.boolean().required('Required')
});