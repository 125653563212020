import React, { FC, MouseEvent } from "react";
import styles from "./ButtonIcon.module.css";

interface Props {
    icon: JSX.Element;
    handleClick: () => void;
    width?: number;
    height?: number; 
    backgroundColor?: string;
    borderRadius?: number | string;
    marginLeft?: number;
    marginRight?: number;
}

const ButtonIcon: FC<Props> = ({icon, handleClick, backgroundColor, borderRadius, width, height, marginLeft, marginRight}) => {
    const clickButtonIconHandler = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        handleClick();
    }

    return (
        <button 
            style={{width, height, backgroundColor, borderRadius, marginLeft, marginRight}} 
            type="button" 
            onClick={clickButtonIconHandler} 
            className={styles.btnIcon}
        >
            {icon}
        </button>
    );
}

export default ButtonIcon;