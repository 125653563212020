import { PayloadAction } from "@reduxjs/toolkit";
import { ServiceState } from "../../models/ServiceState";
import { DeleteServicesImagesPayload } from "../../payload/DeleteServicesImages/DeleteServicesImagesPayload";
import { UploadServicesImagesPayload } from "../../payload/UploadServicesImages/UploadServicesImagesPayload";

export type Payload = UploadServicesImagesPayload & DeleteServicesImagesPayload;

const uploadServiceImagesFetch = (state: ServiceState, action: PayloadAction<Payload>) => {
    state.isLoading = true;
}

export default uploadServiceImagesFetch;