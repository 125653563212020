import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import Dropdown from "../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { useAppSelector } from "../../../../../../store/hooks/selector.hook";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../../utl/constants/defaultValue";

interface Props {
    income?: boolean;
    operationId: number;
    error: string | undefined;
    touched: boolean | undefined;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void
}

const OperationsDropdown: FC<Props> = ({ operationId, error, touched, setFieldValue, setFieldTouched, income }) => {
    let operations = useAppSelector(state => state.money.operations, shallowEqual);
        
    if(income) {
        operations = operations.filter(operetion => operetion.income);
    } else {
        operations = operations.filter(operation => !operation.income);
    }

    const indexOperation = operations.findIndex(item => item.id === operationId);

    const defaultOperation = operations[indexOperation];
    const defaultValueOperation: DefaultValue = {
        valueInput: defaultOperation && String(defaultOperation.id) || DEFAULT_VALUES_DROPDOWN.valueInput,
        valueText: defaultOperation && defaultOperation.name || DEFAULT_VALUES_DROPDOWN.valueText,
    }

    const operationItems = operations.map(operation => (
        <DropdownItem
            item={operation.name}
            valueInput={String(operation.id)}
            valueText={operation.name}
        />
    ));

    const changeHandler = (operationId: string) => {
        setFieldValue("operationId", parseInt(operationId));
    }

    const blurHandler = (touched: boolean) => {
        setFieldTouched("operationId", touched)
    }

    return (
        <Dropdown
            marginRight={20}
            maxWidth={210}
            label="Категорія"
            defaultValue={defaultValueOperation}
            doubleClickDropdown={false}
            handleBlur={blurHandler}
            error={error}
            touched={touched}
            handleChange={changeHandler}
        >
            {operationItems}
        </Dropdown>
    )
}

export default OperationsDropdown;