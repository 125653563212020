import { PayloadAction } from "@reduxjs/toolkit";
import { ClientsState } from "../../models/ClientsState";
import { SearchClientForVendorSuccessPayload } from "../../payload/SearchClientForVendor/SearchClientForVendorSuccessPayload";

const searchClientForVendorSuccess = (state: ClientsState, action: PayloadAction<SearchClientForVendorSuccessPayload>) => {
    const { searchedClients } = action.payload;

    state.searchedClients = [...searchedClients];
}

export default searchClientForVendorSuccess;