import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Clients } from "../../../../services/reddyApi/Clients/Clients";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getCommentsByVendorFetch, getCommentsByVendorSuccess } from "../clients.slice";
import { GetClientCommentsByVendorPayload } from "../payload/GetClientCommentsByVendor/GetClientCommentsByVendorPayload";

function* getCommentsByVendorWorker(action: PayloadAction<GetClientCommentsByVendorPayload>) {
    const { vendorId, clientId, filter } = action.payload;
    const dataParams = {
        vendorId,
        clientId,
        filter
    }

    try {
        const { data } = yield call(Clients.getCommentsByVendor, dataParams);

        yield put(getCommentsByVendorSuccess({ ...data, clientId, filter }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getCommentsByVendorFetch(action.payload);
            
            yield handleServerError(error, actionCreator);
        }
    }
}

export default getCommentsByVendorWorker;