import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../../store/hooks/selector.hook";
import useGetCommentsByVendor from "../../hook/useGetCommentsByVendor";
import Comments from "./Comments";

const CommentsContainer: FC = () => {
    let { id } = useParams();
    const clientId = (id && parseInt(id)) || -1;
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const clients = useAppSelector(state => state.clients.pageData, shallowEqual);
    const indexClient = clients.findIndex(client => client.clientId === clientId);
    const client = clients[indexClient];
    let comments = client.comments || [];
    let updated = client.updated;
    
    const commentsLength = comments.length;

    useGetCommentsByVendor({ commentsLength, clientId, vendorId, updated });

    return (
        <Comments
            comments={comments}
        />
    );
}

export default CommentsContainer;