import React, { FC, MouseEvent } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { getClientsListForVendorFetch, setPageClients } from "../../store/state/clients/clients.slice";
import culcPageTable from "../../utl/helpers/culcPageTable";
import usePagination from "../../utl/hooks/usePagination";
import { Filter } from "../../utl/models/Filters";
import Clients from "./Clients";
import { ClientsColumn } from "./models/ClientsColumn";

interface Props {
    data: ClientsColumn[];
    handleClickRow: (item: any, event: MouseEvent<HTMLTableRowElement>) => void;
    vendorId: number;
}

const ClientsPagination: FC<Props> = ({ data, handleClickRow, vendorId }) => {
    const dispatch = useAppDispatch();
   
    const page = useAppSelector(state => state.clients.clientsPages.page, shallowEqual);
    const totalCount = useAppSelector(state => state.clients.clientsPages.totalCount, shallowEqual);
    const pageAll = useAppSelector(state => state.clients.clientsPages.pageAll, shallowEqual);
    const pageSize = useAppSelector(state => state.clients.clientsPages.pageSize, shallowEqual);
    const getAllItems = useAppSelector(state => state.clients.clientsPages.getAllItems, shallowEqual);

    const setPage = (filter: Filter | undefined, pageNumber: number) => {
        dispatch(getClientsListForVendorFetch({ vendorId, filter }));
        dispatch(setPageClients({ page: pageNumber }));
    };

    const pagination = usePagination({
        getAllItems,
        pageAll,
        page,
        totalCount,
        pageSize,
        setPage,
    });

    const pagesRange = pagination.pagesRange;

    const pageTable = culcPageTable<ClientsColumn>(data, pagesRange.from, pagesRange.to);

    return (
        <Clients
            handleClickRow={handleClickRow}
            data={pageTable}
            page={page}
            pages={pagination.pages}
            handleRightButtonPagination={pagination.handleRightButtonPagination}
            handleLeftButtonPagination={pagination.handleLeftButtonPagination}
        />
    );
}

export default ClientsPagination;