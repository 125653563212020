import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import styles from "./FileFinder.module.css";
import classNames from "classnames";
import IconFont from "../../../general/icons/IconFont/IconFont";
import { CSSTransition } from "react-transition-group";
import "./transition.css";
import CheckedIcon from "../../../general/icons/CheckedIcon/CheckedIcon";
import Position from "../../../layout/position/Position";
import ModalForm from "../../forms/ModalForm/ModalForm";
import Row from "../../../layout/Row/Row";
import Button from "../../../general/buttons/Button/Button";
import { useDispatch } from "react-redux";
import { setFonVisiable } from "../../../../store/state/all/all.slice";
import DoubleButton from "../../../general/buttons/DoubleButton/DoubleButton";

const cx = classNames.bind(styles);

interface Props {
    img?: string | undefined;
    width: number;
    height: number;
    isDisabled?: boolean;
    acceptFile?: string;
    changeFileHandler: (event: ChangeEvent<HTMLInputElement>) => void;
    isFile: boolean;
    handleRemove?: () => void;
    borderRadius?: number;
    iconSmall?: boolean;
    withDoubleButton?: boolean;
}

const FileFinder: FC<Props> = ({ width, height, isDisabled = false, acceptFile, img,
    changeFileHandler, isFile, handleRemove, borderRadius, iconSmall, withDoubleButton = true }) => {
    const dispatch = useDispatch();
    const inputFile = useRef<HTMLInputElement | null>(null);
    const inputDocument = useRef<HTMLAnchorElement | null>(null);
    const [toggle, setToggle] = useState<boolean>(false);

    const setVisiable = (visiable: boolean): void => {
        dispatch(setFonVisiable({ visiable }));
        setToggle(visiable);
    }

    const clickButtonHandler = async () => {
        if (isDisabled) {
            return;
        }

        if (!img) {
            inputFile.current?.click();
        } else {
            setVisiable(true);
        }
    };

    const downloadFileHandler = () => {
        inputDocument.current?.click();
        setVisiable(false);
    }

    const resetFileHandler = () => {
        inputFile.current?.click();
        setVisiable(false);
    }

    const removeFileHandler = () => {
        if (handleRemove) {
            handleRemove();
        }
        setVisiable(false);
    }

    const clickCloseButton = () => {
        setVisiable(false);
    }

    return (
        <>
            <div
                style={{ width, height, borderRadius }}
                className={cx(styles.fileFinder, isDisabled && styles.disabled)}
                onClick={() => clickButtonHandler()}
            >
                <CSSTransition
                    in={Boolean(img) && !isFile}
                    timeout={400}
                    unmountOnExit
                    classNames="file-finder"
                >
                    {img && !isFile ? <img src={img} className={styles.fileFinderImg} /> : <></>}
                </CSSTransition>

                <CSSTransition
                    in={isFile}
                    timeout={400}
                    unmountOnExit
                    classNames="file-finder"
                >
                    <Position
                        type="absolute"
                    >
                        <CheckedIcon
                            width="45"
                            height="45"
                            fill="#fff"
                        />
                    </Position>
                </CSSTransition>

                <CSSTransition
                    in={!isDisabled && !isFile}
                    timeout={400}
                    unmountOnExit
                    classNames="file-finder"
                >
                    <IconFont iconClass="icon-plus" color="#FFF" fontSize={iconSmall ? 15 : 30} />
                </CSSTransition>

                <a
                    ref={inputDocument}
                    href={String(img)}
                    download
                    style={{ display: "none" }}
                />

                <input
                    accept={acceptFile}
                    onChange={changeFileHandler}
                    type='file'
                    ref={inputFile}
                    style={{ display: "none" }}
                />
            </div>
            <ModalForm
                top="25%"
                title="Файл"
                maxWidth={480}
                visiable={toggle}
                handleClick={clickCloseButton}
            >
                <Row justifyContent="space-between" alignItems="center">
                    <Button
                        title="Завантажити"
                        handleClick={downloadFileHandler}
                        backgroundColor={"#4775FE"}
                    />

                    {withDoubleButton ?
                        <DoubleButton
                            handleFirst={resetFileHandler}
                            nameFirst="Змінити"
                            handleSecond={removeFileHandler}
                            nameSecond="Видалити"
                            maxWidth={250}
                        />
                        :
                        <Button
                            title="Змінити"
                            handleClick={resetFileHandler}
                            backgroundColor={"#A6AEC5"}
                        />
                    }
                </Row>
            </ModalForm>
        </>
    );
}

export default FileFinder;