import { PayloadAction } from "@reduxjs/toolkit";
import { ServiceState } from "../models/ServiceState";
import { SetPageDataServicesPayload } from "../payload/SetPageDataServicesPayload";

const setPageDataServices = (state: ServiceState, action: PayloadAction<SetPageDataServicesPayload>) => {
    const { services } = action.payload;

    const pageServicesData = state.pageData;
    const newPageClientsData = [...pageServicesData];

    services.forEach(function (service) {
        const indexService = newPageClientsData.findIndex(pageData => pageData.id === service.id);

        if (indexService === -1) {
            newPageClientsData.push(service);
        } else {
            newPageClientsData[indexService] = { ...newPageClientsData[indexService], ...service };
        }
    });

    state.pageData = newPageClientsData;
}

export default setPageDataServices;