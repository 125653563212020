import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Locations } from "../../../../services/reddyApi/Locations/Locations";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { createLocationFetch, createLocationSuccess } from "../locations.slice";
import { CreateLocationPayload } from "../payload/CreateLocation/CreateLocationPayload";

function* createLocationWorker(action: PayloadAction<CreateLocationPayload>) {
    const {vendorId, location} = action.payload;

    try {
        const {status, data} = yield call(Locations.create, action.payload);
        const {locationId} = data;

        yield put(createLocationSuccess({location: {...location, id: locationId}}));

        yield getNotifications(vendorId);

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => createLocationFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default createLocationWorker;