import { useEffect } from "react";

export const useMessageActions = (visiable: boolean, setMessage: () => void, timeout = 2000): boolean => {
    useEffect(() => {
        if (visiable) {
            const handler = setTimeout(() => {
                setMessage();
            }, timeout);

            return () => {
                clearTimeout(handler);
            };
        }
    }, [visiable]);

    return visiable;
}