import React, { FC, FocusEvent } from "react";
import { shallowEqual } from "react-redux";
import TagInput from "../../../../../../components/dataEntry/inputs/TagInput/TagInput";
import { useAppSelector } from "../../../../../../store/hooks/selector.hook";

interface Props {
    upsellsIdsValues: string;
    error: string | undefined;
    touched: boolean | undefined;
    handleBlur: (event: FocusEvent<HTMLElement>) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const UpsellsTagInput: FC<Props> = ({ upsellsIdsValues, error, touched, handleBlur, setFieldValue }) => {
    let upsellsIds = useAppSelector(state => state.upsellsServices.upsells, shallowEqual);
    const upsellsList = upsellsIds
        .filter(upsell => upsell.active)
        .map(upsell => ({ id: upsell.id, name: upsell.name }));

    const activeUpsells = upsellsIdsValues.length > 0 ? upsellsIdsValues.split(',')
        .map(upsellId => Number(upsellId))
        .map(upsellId => (
            {
                id: upsellId,
                name:
                    upsellsList[upsellsList.findIndex(upsell => upsell.id === upsellId)]?.name || "Name"
            }
        )) : [];

    const changeHandler = (upsellsIds: string) => {
        setFieldValue("upsellsIds", upsellsIds);
    }

    return (
        <TagInput
            label="Додаткові послуги"
            tagsArray={upsellsList}
            maxWidth={550}
            activeTagsArray={activeUpsells}
            handleBlur={handleBlur}
            handleChange={changeHandler}
            error={error}
            touched={touched}
            values={upsellsIdsValues}
        />
    )
}

export default UpsellsTagInput;