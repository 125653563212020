import { PayloadAction } from "@reduxjs/toolkit";
import createItem from "../../../../helpers/createItem";
import { GroupsState } from "../../models/GroupsState";
import { CreateGroupSuccessPayload } from "../../payload/CreateGroup/CreateGroupSuccessPayload";

const createGroupSuccess = (state: GroupsState, action: PayloadAction<CreateGroupSuccessPayload>) => {
    const group = action.payload;

    const groups = state.groups;
    createItem(state, groups, group);

    const groupsPage = state.pageData;
    createItem(null, groupsPage, group);

    state.isLoading = false;
    state.isError = null;
    state.message = "Групу успішно створено";
    state.messageVisiable = true;
    state.newItemId = group.id;
}

export default createGroupSuccess;