import { PayloadAction } from "@reduxjs/toolkit";
import { MoneyState } from "../../models/MoneyState";
import { UpdateCategoriesSuccessPayload } from "../../payload/UpdateCategories.ts/UpdateCategoriesSuccessPayload";

const updateCategoriesSuccess = (state: MoneyState, action: PayloadAction<UpdateCategoriesSuccessPayload>) => {
    const {operations, purses, discounts} = action.payload;

    state.discounts = discounts;
    state.operations = operations;
    state.purses = purses;

    state.isLoading = false;
    state.isError = null;
    state.message = "Гроші успішно оновлено";
    state.messageVisiable = true;
}

export default updateCategoriesSuccess;