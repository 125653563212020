import { createSlice } from '@reduxjs/toolkit';
import { DashboardState } from './models/DashboardState';
import getActiveServicesListFetchReducer from './reducer/getActiveServicesList/getActiveServicesListFetch';
import getActiveServicesListSuccessReducer from './reducer/getActiveServicesList/getActiveServicesListSuccess';
import getClientsListForVendorFetchReducer from './reducer/getClientsListForVendor/getClientsListForVendorFetch';
import getClientsListForVendorSuccessReducer from './reducer/getClientsListForVendor/getClientsListForVendorSuccess';
import setErrorReducer from './reducer/setError';
import setPageClientsReducer from './reducer/setPageClients';
import setPageActiveSrvicesReducer from './reducer/setPageActiveSrvices';
import resetDashboardReducer from './reducer/resetDashboard';

const Pages = {
    getAllItems: true,
    pageAll: 0,
    pageSize: 15,
    page: 1,
    totalCount: 0,
}

export const initialState: DashboardState = {
    activeSrvicePage: {
        activeSrvices: [],
        ...Pages
    },
    clientsPage: {
        clients: [],
        ...Pages
    },
    isLoading: false,
    isError: null
}

export const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        getActiveServicesListFetch: getActiveServicesListFetchReducer,
        getActiveServicesListSuccess: getActiveServicesListSuccessReducer,
        getClientsListForVendorFetch: getClientsListForVendorFetchReducer,
        getClientsListForVendorSuccess: getClientsListForVendorSuccessReducer,
        setError: setErrorReducer,
        setPageClients: setPageClientsReducer,
        setPageActiveSrvices: setPageActiveSrvicesReducer,
        resetDashboard: resetDashboardReducer,
    },
});

export const {
    getActiveServicesListFetch,
    getActiveServicesListSuccess,
    getClientsListForVendorFetch,
    getClientsListForVendorSuccess,
    setError,
    setPageActiveSrvices,
    setPageClients,
    resetDashboard,
} = DashboardSlice.actions

export default DashboardSlice.reducer