import { PayloadAction } from "@reduxjs/toolkit";
import { NotificationsState } from "../models/NotificationsState";
import { SetNotificationsPayload } from "../payload/SetNotificationsPayload";

const setNotifications = (state: NotificationsState, action: PayloadAction<SetNotificationsPayload>) => {
    const { active, archive, selected } = action.payload;

    state.active = active;
    state.archive = archive;
    state.selected = selected;
}

export default setNotifications;