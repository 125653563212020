import { FormikProps } from "formik";
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import Dropdown from "../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { useAppSelector } from "../../../../../store/hooks/selector.hook";
import { DiscountValue } from "./DiscountValue";

interface Props {
    formik: FormikProps<DiscountValue>;
}

const Discounts: FC<Props> = ({formik}) => {
    const {values, setFieldValue, handleSubmit, errors, touched, setFieldTouched} = formik 

    const discounts = useAppSelector(state => state.money.discounts, shallowEqual);
    const discountId = values.discountId ? Number(values.discountId) : null;
    const discountsWithEmptyValue = [...discounts, { id: null, name: "Без Знижки" }];

    const indexDiscounts = discountsWithEmptyValue.findIndex(
        discount => discount.id === discountId
    );

    const submitButtonHandler = () => {
        handleSubmit();
    }

    const defaultValues: DefaultValue = {
        valueInput: "",
        valueText: "Оберіть зі списку",
    }

    const defaultDiscounts = discountsWithEmptyValue.length > 0 && discountsWithEmptyValue[indexDiscounts];
    const defaultValueDiscounts: DefaultValue = {
        valueInput: (defaultDiscounts && String(defaultDiscounts.id)) || defaultValues.valueInput,
        valueText: defaultDiscounts && defaultDiscounts.name || defaultValues.valueText,
    }
    const discountsItems = discountsWithEmptyValue.map((discount, index) => (
        <DropdownItem
            key={"DISCOUNT_ITEM_" + index}
            item={discount.name}
            valueInput={String(discount.id)}
            valueText={discount.name}
        />
    ));

    const changeDiscountHandler = (value: string) => {
        setFieldValue("discountId", parseInt(value));
        handleSubmit();
    }

    return (
        <Dropdown
            maxWidth={240}
            height={40}
            marginRight={20}
            defaultValue={defaultValueDiscounts}
            doubleClickDropdown={false}
            error={errors.discountId}
            touched={touched.discountId}
            handleBlur={(touched) => setFieldTouched("discountId", touched)}
            handleChange={changeDiscountHandler}
        >
            {discountsItems}
        </Dropdown>
    );
}

export default Discounts;