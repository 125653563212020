import { PayloadAction } from "@reduxjs/toolkit";
import { FinanceState } from "../models/FinanceState";
import { SetDefaultValuePayload } from "../payload/SetDefaultValue";

const setDefaultValueFinance = (state: FinanceState, action: PayloadAction<SetDefaultValuePayload>) => {
    const {
        counterpartis,
        counteragents,
        visiableAddForm,
        visiableExchangeForm,
        visiablePaymentForm,
    } = action.payload;

    if(counterpartis) {
        state.counterpartis = [];
    }

    if(counteragents) {
        state.counteragents = [];
    }

    if(visiableAddForm) {
        state.visiableAddForm = false;
    }

    if(visiableExchangeForm) {
        state.visiableExchangeForm = false;
    }

    if(visiablePaymentForm) {
        state.visiablePaymentForm = false;
    }
}

export default setDefaultValueFinance;