import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import { getGroupsListForVendorFetch, setGroups, setGroupsFilter, setPageGroups } from "../../../store/state/groups/groups.slice";
import { configListAdapter } from "../../../utl/adapters/ConfigListAdapter";
import { culcPage } from "../../../utl/helpers/culcPage";
import { Filter } from "../../../utl/models/Filters";
import DropdownFilter, { DropdownFilterItem } from "../../Services/Basics/components/DropdownFilter/DropdownFilter";

const CategoryFilter: FC = () => {
    const categories = useAppSelector(state => state.config.vendorConfig?.service.categories, shallowEqual);
    const categoriesList = configListAdapter(categories);

    const dropdownList: DropdownFilterItem[] = categoriesList.map(category =>
    ({
        name: category.name, value: String(category.id)
    }));
    dropdownList.push({
        name: "Всі",
        value: "undefined"
    });

    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const confirmed = useAppSelector(state => state.groups.filter.confirmed, shallowEqual);
    const active = useAppSelector(state => state.groups.filter.active, shallowEqual);

    const typeId = useAppSelector(state => state.groups.filter.typeId, shallowEqual);

    const categoryId = useAppSelector(state => state.groups.filter.categoryId, shallowEqual);
    const value = String(categoryId);

    const page = 1;
    const totalCount = 0;
    const pageAll = 0;
    const pageSize = useAppSelector(state => state.groups.pageSize, shallowEqual);
    const getAllItems = useAppSelector(state => state.groups.getAllItems, shallowEqual);
    const pagesRange = culcPage(page, pageAll, totalCount, pageSize);

    const dispatch = useAppDispatch();

    const changeHandler = (value: string) => {
        let filter: Filter = {
            confirmed,
            typeId,
            active,
        };

        if (!getAllItems) {
            filter.range = {
                from: pagesRange.from,
                to: pagesRange.to
            }
        }

        if (value.localeCompare('undefined') !== 0) {
            const categoryIdValue = parseInt(value);

            filter.categoryId = categoryIdValue;

            dispatch(setGroupsFilter({ typeId, confirmed, categoryId: categoryIdValue }));
        } else {
            dispatch(setGroupsFilter({ typeId, confirmed, categoryId: undefined }));
        }

        dispatch(setGroups({ groups: [] }));
        dispatch(getGroupsListForVendorFetch({ vendorId, filter }));
        dispatch(setPageGroups({ page }));
    }

    return (
        <DropdownFilter
            label="Категорії"
            changeHandler={changeHandler}
            dropdownList={dropdownList}
            value={value}
            maxWidth={200}
        />
    )
}

export default CategoryFilter;