import React, { FC } from "react";
import logo from "../../../../assets/svg/Logo.svg";
import styles from "./AuthForm.module.css";

interface Props {
    title?: string;
    children: JSX.Element[] | JSX.Element;
    fontSize?: number;
    lineHeight?: string; 
} 

const AuthForm: FC<Props> = ({title = "", children, fontSize = 18, lineHeight = '21px'}) => {
    return (
        <form className={styles.form}>
            <img className={styles.logo} src={logo} alt="logo" />
            {title !== "" && <h1 className={styles.title} style={{fontSize, lineHeight}}>{title}</h1>}
            {children}
        </form>
    );
}

export default AuthForm;