import React, { ChangeEvent, FC, MouseEvent, useState } from "react";
import styles from "./Switch.module.css";
import classNames from "classnames";
import Title from "../../../general/typography/Title/Title";

const cx = classNames.bind(styles);

interface Props {
    title?: string;
    value: boolean;
    handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    marginRight?: number;
}

const Switch: FC<Props> = ({value, title, handleChange, marginRight}) => {
    const clickSwitchHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if(handleChange)
            handleChange(event);
    }

    return (
        <div style={{marginRight}}>
            {title && <Title level={6} title={title} color="#A6AEC5" marginBottom={5} fontWeight="500" />}
            <label className={cx(styles.switch, value && styles.active)}>
                <input type="checkbox" checked={value} onChange={clickSwitchHandler} value={String(value)} />
                <span className={styles.slider}></span>
                <div className={styles.status}>
                    <span className={styles.statusOn}>
                        On
                    </span>
                    <span className={styles.statusOff}>
                        Off
                    </span>
                </div>
            </label>
        </div>
    );
}

export default Switch;