import { PayloadAction } from "@reduxjs/toolkit";
import { GetVendorClientDataResponse } from "../../../../../services/reddyApi/Clients/Clients.response";
import { ClientsState } from "../../models/ClientsState";
import { GetVendorClientDataSuccessPayload } from "../../payload/GetVendorClientData/GetVendorClientDataSuccessPayload";

const getVendorClientDataSuccess = (state: ClientsState, action: PayloadAction<GetVendorClientDataSuccessPayload>) => {
    const client = action.payload;

    const pageClientsData = state.pageData;
    const newPageClientsData = [...pageClientsData];

    const indexClient = newPageClientsData.findIndex(pageData => pageData.clientId === client.clientId);
    if (indexClient === -1) {
        newPageClientsData.push(client);
    } else {
        const oldClient = newPageClientsData[indexClient];
        newPageClientsData[indexClient] = { ...oldClient, ...client };
    }

    state.pageData = newPageClientsData;
}

export default getVendorClientDataSuccess;