import { PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { Groups } from "../../../../services/reddyApi/Groups/Groups";
import { getGroupsListForVendorResponse } from "../../../../services/reddyApi/Groups/Groups.response";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getGroupsListForVendorFetch, getGroupsListForVendorSuccess, setPageDataGroups } from "../groups.slice";
import { GetGroupsListForVendorPayload } from "../payload/GetGroupsListForVendor/GetGroupsListForVendorPayload";

function* getGroupsListForVendorWorker(action: PayloadAction<GetGroupsListForVendorPayload>) {
    const { vendorId, filter } = action.payload;
    const dataParams = {
        vendorId,
        filter
    }

    try {
        const { data }: AxiosResponse<getGroupsListForVendorResponse> = yield call(Groups.getGroupsListForVendor, dataParams);
        const {totalCount, items} = data;

        yield put(getGroupsListForVendorSuccess({ totalCount, items, filter }));
        
        yield put(setPageDataGroups({groups: [...items]}));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getGroupsListForVendorFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getGroupsListForVendorWorker;