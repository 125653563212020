import { RootState } from "../../store";
import Token from "../../services/Token/token";

export const prepareHeaders = (headers: Headers, api: { getState: () => unknown; }) => {
    const { getState } = api;

    const user = (getState() as RootState).user;

    if (user && user.tokenStatus) {
        let token = Token.getAccessToken();

        headers.set('Authorization', `Bearer ${token}`)
    }

    return headers;
}
