import { PayloadAction } from "@reduxjs/toolkit";
import { GroupsState } from "../../models/GroupsState";
import { GetSelectedServiceSuccessPayload } from "../../payload/GetSelectedService/GetSelectedServiceSuccessPayload";

const getSelectedServiceSuccess = (state: GroupsState, action: PayloadAction<GetSelectedServiceSuccessPayload>) => {
    const service = action.payload;

    state.selectedService = service;

    state.isLoading = false;
    state.isError = null;
}

export default getSelectedServiceSuccess;