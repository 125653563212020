import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Services } from "../../../../../services/reddyApi/Services/Services";
import handleServerError from "../../../all/saga/handleServerError";
import setServerErrorDefault from "../../../all/saga/setServerErrorDefault";
import { DeleteServicePayload } from "../payload/DeleteService/DeleteServicePayload";
import { deleteServiceFetch, deleteServiceSuccess } from "../service.slice";
import getShortServices from "./getShortServices";

function* deleteServiceWorker(action: PayloadAction<DeleteServicePayload>) {
    const {vendorId, serviceId} = action.payload;

    try {
        const {status} = yield call(Services.delete, {vendorId, serviceId});
        if(status === 200) {
            yield put(deleteServiceSuccess({serviceId}));

            yield getShortServices({ vendorId });

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => deleteServiceFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default deleteServiceWorker;