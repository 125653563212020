import { PayloadAction } from "@reduxjs/toolkit";
import { FinanceState } from "../../models/FinanceState";
import { SearchCounteragentsSuccessPayload } from "../../payload/SearchCounteragents/SearchCounteragentsSuccessPayload";

const searchCounteragentsSuccess = (state: FinanceState, action: PayloadAction<SearchCounteragentsSuccessPayload>) => {
    const counteragents = action.payload;

    state.counteragents = counteragents;

    state.isLoading = true;
    state.isError = null;
}

export default searchCounteragentsSuccess;