import { PayloadAction } from "@reduxjs/toolkit";
import getItems from "../../../../helpers/getItems";
import { FinanceState } from "../../models/FinanceState";
import { MoneyTransaction } from "../../models/MoneyTransaction";
import { GetVendorMoneyTransactionsSuccessPayload } from "../../payload/GetVendorMoneyTransactions/GetVendorMoneyTransactionsSuccessPayload";

const defaultTransactionsItem: MoneyTransaction = {
    id: -1,
    counteragent: {
        id: -1,
        name: "Counteragent",
    },
    purseId: -1,
    operationId: -2,
    sum: 0,
    comment: "",
    responsibleUser: {
        id: -1,
        fullname: "responsibleUser",
        roleId: -1,
    },
    createdAt: "",
}

const getVendorMoneyTransactionsSuccess = (state: FinanceState, action: PayloadAction<GetVendorMoneyTransactionsSuccessPayload>) => {
    const {totalCount, items, filter} = action.payload;

    const transactions = state.finance.transactions;

    state.finance.transactions = getItems(
        state.finance,
        transactions,
        items,
        totalCount,
        defaultTransactionsItem,
        filter,
    );

    state.isLoading = false;
    state.isError = null;
}

export default getVendorMoneyTransactionsSuccess;