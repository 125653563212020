import React, { FC } from "react";
import styles from "./Breadcrumbs.module.css";

const KEY_BREADCRUMBS = "BREADCRUMBS_"

interface Props {
    path: Array<string>;
}

const Breadcrumbs: FC<Props> = ({path}) => {
    return (
        <ul className={styles.breadcrumb}>
            {path.map((item, index) => (
                <li key={KEY_BREADCRUMBS + index}>
                    <span className={styles.name}>{String(item)}</span>
                    {path.length - 1 !== index && <span className={styles.separator}>/</span>}
                </li>
            ))}
        </ul>
    );
}

export default Breadcrumbs;