import { PayloadAction } from "@reduxjs/toolkit";
import { ServiceState } from "../../models/ServiceState";
import { GetAttendanceStatisticSuccessPayload } from "../../payload/GetAttendanceStatistic/GetAttendanceStatisticSuccessPayload";

const getAttendanceStatisticSuccess = (state: ServiceState, action: PayloadAction<GetAttendanceStatisticSuccessPayload>) => {
    const { attendanceStatistic } = action.payload;

    state.attendanceStatistic = attendanceStatistic;
}

export default getAttendanceStatisticSuccess;