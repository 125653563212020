import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Locations } from "../../../../services/reddyApi/Locations/Locations";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getLocationsDataFetch, getLocationsDataSuccess } from "../locations.slice";
import { GetLocationsDataPayload } from "../payload/GetLocationsData/GetLocationsDataPayload";

function* getLocationsWorker(action: PayloadAction<GetLocationsDataPayload>) {
    const {vendorId, filter} = action.payload;

    try {
        const {data} = yield call(Locations.getData, {vendorId, filter});
        const {items, totalCount} = data;

        yield put(getLocationsDataSuccess({items, totalCount, filter}));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getLocationsDataFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getLocationsWorker;