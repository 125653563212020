import { PayloadAction } from "@reduxjs/toolkit";
import getItems from "../../../../helpers/getItems";
import { Client } from "../../models/Client";
import { ClientsState } from "../../models/ClientsState";
import { GetClientsListForVendorSuccessPayload } from "../../payload/GetClientsListForVendor/GetClientsListForVendorSuccessPayload";

const defaultClientsItem: Client = {
    clientId: -1,
    users: [{
        id: -1,
        name: "Name",
        surname: "Surname",
        phone: "+380*********",
        email: "***@gmail.com",
        relationshipStatus: -1,
    }],
    students: [],
    services: [],
    discountId: null,
    debt: null,
}

const getClientsListForVendorSuccess = (state: ClientsState, actions: PayloadAction<GetClientsListForVendorSuccessPayload>) => {
    const { totalCount, items, filter } = actions.payload;

    const clients = state.clients;

    state.clients = getItems(
        state.clientsPages,
        clients,
        items,
        totalCount,
        defaultClientsItem,
        filter,
    );

    state.isLoading = false;
    state.isError = null;
}

export default getClientsListForVendorSuccess;