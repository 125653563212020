import { Config } from "../../store/state/configuration/models/Config";

export interface ConfigValue {
    id: number;
    key: string;
    name: string;
}

export const configListAdapter = (configTypes: Config | undefined): ConfigValue[] => {
    if (!configTypes) {
        return [];
    }

    const keys = Object.keys(configTypes);

    return keys.map((key, _) => {
        const configType = configTypes[Number(key)];

        return ({ 
            id: configType.id, 
            name: configType.localeName.ua, 
            key: configType.name 
        });
    });
}