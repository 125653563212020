import React, { FC } from "react";
import styles from "./Contacts.module.css";

const KEY_CONTACTS = "CONTACTS_";

interface Props {
    values: string[];
    icon: JSX.Element;
    keyIndex: string;
    maxWidth?: number;
    marginBottom?: number;
}

const Contacts: FC<Props> = ({icon, values, keyIndex, maxWidth, marginBottom}) => {

    const items = values.map((item, index) => 
        <p 
            key={KEY_CONTACTS + keyIndex + index} 
            className={styles.value}>{item}
        </p>
    );

    return (
        <div style={{maxWidth, marginBottom}} className={styles.contacts}>
            <div className={styles.icon}>
                {icon}
            </div>
            <div className={styles.info}>
                {items}
            </div>
        </div>
    );
}

export default Contacts;