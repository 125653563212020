import { PayloadAction } from "@reduxjs/toolkit";
import { Visiable } from "../../../../utl/models/Visiable";
import { GroupsState } from "../models/GroupsState";

const setAddStudentToGroupFormVisiable = (state: GroupsState, action: PayloadAction<Visiable>) => {
    const { visiable } = action.payload;

    state.addStudentToGroupFormVisiable = visiable;
}

export default setAddStudentToGroupFormVisiable;