import { PayloadAction } from "@reduxjs/toolkit";
import updateItem from "../../../../helpers/updateItem";
import { GroupsState } from "../../models/GroupsState";
import { UpdateGroupSuccessPayload } from "../../payload/UpdateGroup/UpdateGroupSuccessPayload";

const updateGroupSuccess = (state: GroupsState, action: PayloadAction<UpdateGroupSuccessPayload>) => {
    const group = action.payload;

    const groups = state.groups;
    updateItem(groups, group);

    const groupsPage = state.pageData;
    updateItem(groupsPage, group);

    state.isLoading = false;
    state.isError = null;
    state.message = "Групу успішно оновлено";
    state.messageVisiable = true;
}

export default updateGroupSuccess;