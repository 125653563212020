import { FormikErrors, FormikTouched } from "formik";
import React, { FC } from "react";
import Field from "../../../../../../../../components/dataEntry/inputs/Field/Field";
import ArrayFields from "../../../../../../../../components/dataEntry/inputs/ListFields/ArrayFields";
import { ContextValue } from "../../../../../../../../components/dataEntry/inputs/ListFields/models/ContextValue";
import Button from "../../../../../../../../components/general/buttons/Button/Button";
import IconFont from "../../../../../../../../components/general/icons/IconFont/IconFont";
import Row from "../../../../../../../../components/layout/Row/Row";
import { Student } from "../../../../../../../../store/state/clients/models/Student";

interface Props {
    students: Pick<Student, "name" | "surname" | "birthday">[];
    error: string | string[] | FormikErrors<Pick<Student, "name" | "surname" | "birthday">>[] | undefined;
    touched:FormikTouched<Pick<Student, "name" | "surname" | "birthday">>[] | undefined;
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    handleBlur: {
        (e: React.FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    }
}

const StudentsList: FC<Props> = ({ students, error, touched, handleChange, handleBlur }) => {
    const studentsItems = (context: ContextValue) => students && students.map((student, index) => {
        const name = "students";
        const touchedStudents = touched
        const errorsStudents = error;
        const marginBottom = 20;

        let surnameError = errorsStudents && Array.isArray(errorsStudents) && errorsStudents[index];
        if (typeof surnameError !== "string" && typeof surnameError === "object") {
            surnameError = surnameError?.surname;
        }

        let nameError = errorsStudents && Array.isArray(errorsStudents) && errorsStudents[index];
        if (typeof nameError === "object") {
            nameError = nameError?.name
        }

        let birthdayError = errorsStudents && Array.isArray(errorsStudents) && errorsStudents[index];
        if (typeof birthdayError === "object") {
            birthdayError = birthdayError?.birthday
        }

        const addStudentButtonHandler = () => {
            if (index === 0) {
                context.push({ name: "", surname: "", birthday: "" });
            }
        }

        const surname = student.surname;
        const studentName = student.name;
        const birthday = student.birthday;

        return (
            <li key={"STUDENT_ITEM_" + index} style={{ display: "flex", flexDirection: "column" }}>
                <Row maxWidth={440}>
                    <Field
                        label="Прізвище"
                        handleChange={handleChange(`${name}[${index}].surname`)}
                        handleBlur={handleBlur(`${name}[${index}].surname`)}
                        error={(surnameError && String(surnameError)) || undefined}
                        touched={(touchedStudents && touchedStudents[index]?.surname) || undefined}
                        maxWidth={210}
                        marginRight={20}
                        value={surname}
                    />

                    <Field
                        label="Ім’я"
                        marginRight={0}
                        marginBottom={marginBottom}
                        handleChange={handleChange(`${name}[${index}].name`)}
                        handleBlur={handleBlur(`${name}[${index}].name`)}
                        error={(nameError && String(nameError)) || undefined}
                        touched={(touchedStudents && touchedStudents[index]?.name) || undefined}
                        maxWidth={210}
                        value={studentName}
                    />
                </Row>
                <Row maxWidth={440} marginBottom={30} justifyContent="space-between" alignItems="flex-end">
                    <Field
                        label="Дата народження"
                        type="date"
                        handleChange={handleChange(`${name}[${index}].birthday`)}
                        handleBlur={handleBlur(`${name}[${index}].birthday`)}
                        error={(birthdayError && String(birthdayError)) || undefined}
                        touched={(touchedStudents && touchedStudents[index]?.birthday) || undefined}
                        maxWidth={150}
                        marginRight={0}
                        marginBottom={0}
                        value={birthday}
                    />

                    <Button
                        maxWidth={160}
                        handleClick={addStudentButtonHandler}
                        icon={<IconFont iconClass="icon-plus" fontSize={15} color="#FFF" />}
                        backgroundColor="#4775FE"
                        title="Додати"
                        marginBottom={0}
                        marginRight={0}
                    />
                </Row>
            </li>
        );
    });

    return (
        <ArrayFields
            name="students"
            fields={studentsItems}
        />
    );
}

export default StudentsList;