import { useFormik } from "formik";
import React from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../../store/hooks/selector.hook";
import { DiscountValue } from "./DiscountValue";
import validationSchema from "./Discounts.schema";
import { updateClientDiscountFetch } from "../../../../../store/state/clients/clients.slice";
import { useParams } from "react-router-dom";
import { Filter } from "../../../../../utl/models/Filters";
import moment from "moment";
import Discounts from "./Discounts";

const DiscountsContainer = () => {
    let { id } = useParams();
    const clientId = Number(id);
    const dispatch = useAppDispatch();
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const clients = useAppSelector(state => state.clients.pageData, shallowEqual);
    const indexClient = clients.findIndex(client => client.clientId === clientId);
    const discountId = useAppSelector(state => state.clients.pageData[indexClient].discountId, shallowEqual);

    const initialValues: DiscountValue = {
        discountId,
    }

    const formik = useFormik<DiscountValue>({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: values => {
            const { discountId } = values;

            const today = new Date();
            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const filter: Filter = {
                period: {
                    from: moment(firstDay).format("YYYY-MM-DD"),
                    to: moment(lastDay).format("YYYY-MM-DD"),
                }
            }

            dispatch(updateClientDiscountFetch({ vendorId, discountId, clientId, filter }));
        }
    });

    return (
        <Discounts
            formik={formik}
        />
    );
}

export default DiscountsContainer;