import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../../utl/constants/app';
import { prepareHeaders } from '../../utl/helpers/prepareHeaders';

export interface ICalendarEvent {
    id: number;
    name: string;
    description: string;
    startDate: string;
    endDate: string;
    groupId: number;
    employeesIds: number[];
    studentsIds: number[];
    happened: boolean;
}

export type TCalendarEventId = Pick<ICalendarEvent, "id">;

export type TCreateCalendarEvent = Omit<ICalendarEvent, "id">;

export const CalendarEventsTag = "CalendarEventsTag";

export interface IGetEventsListArg {
    vendorId: number;
    calendarId: number;
}

export interface IGetEventArg {
    vendorId: number;
    calendarId: number;
    eventId: number;
}

export interface ICreateEventArg {
    vendorId: number;
    calendarId: number;
    body: TCreateCalendarEvent;
}

export interface IUpdateEventArg {
    vendorId: number;
    calendarId: number;
    eventId: number;
    body: Omit<ICalendarEvent, "id">;
}

export const calendarEventsApi = createApi({
    reducerPath: 'calendarEventsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/vendors/`,
        prepareHeaders,
        credentials: 'include',
    }),
    tagTypes: [CalendarEventsTag],
    endpoints: (build) => ({
        getEventsList: build.query<ICalendarEvent[], IGetEventsListArg>({
            query: ({ vendorId, calendarId }) => `${vendorId}/calendars/${calendarId}/events`,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: CalendarEventsTag, id } as const)),
                        { type: CalendarEventsTag, id: 'LIST' },
                    ]
                    :
                    [{ type: CalendarEventsTag, id: 'LIST' }],
            transformResponse: (response: { calendars: ICalendarEvent[] }) => response.calendars,
        }),
        getEvent: build.query<ICalendarEvent, IGetEventArg>({
            query: ({ vendorId, calendarId, eventId }) => `${vendorId}/calendars/${calendarId}/events/${eventId}`,
            providesTags: (result, error, { vendorId, calendarId, eventId }) => [{ type: CalendarEventsTag, vendorId, calendarId, eventId }],
            transformResponse: (response: ICalendarEvent) => response,
        }),
        createEvent: build.mutation<TCalendarEventId, ICreateEventArg>({
            query: ({ body, vendorId, calendarId }) => {
                return {
                    url: `${vendorId}/calendars/${calendarId}/events`,
                    method: "POST",
                    body,
                }
            },
            invalidatesTags: [{ type: CalendarEventsTag, id: 'LIST' }],
        }),
        updateEvent: build.mutation<ICalendarEvent, IUpdateEventArg>({
            query: ({ body, vendorId, calendarId, eventId }) => {
                return {
                    url: `${vendorId}/calendars/${calendarId}/events/${eventId}`,
                    method: "PUT",
                    body,
                }
            },
            invalidatesTags: (result, error, { vendorId, calendarId, eventId }) =>
                result
                    ? [
                        { type: CalendarEventsTag, vendorId },
                        { type: CalendarEventsTag, calendarId },
                        { type: CalendarEventsTag, eventId },
                        { type: CalendarEventsTag, id: 'LIST' },
                    ]
                    :
                    [{ type: CalendarEventsTag, id: 'LIST' }],
        }),
    }),
});

export const {
   useCreateEventMutation,
   useUpdateEventMutation,
   useGetEventQuery,
   useGetEventsListQuery,
} = calendarEventsApi;