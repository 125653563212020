import React, { FC } from "react";
import Title from "../../general/typography/Title/Title";
import Wrp from "../Wrp/Wrp";
import styles from "./NotificationsBlock.module.css";

interface Props {
    title?: string;
    children: JSX.Element | JSX.Element[];
    maxWidth?: number;
    maxHeight?: number;
    marginBottom?: number;
    button?: JSX.Element;
} 

const NotificationsBlock: FC<Props> = ({maxWidth = 775, maxHeight = 585, children, title, button, marginBottom = 0}) => {
    return (
        <Wrp isOverflowY={true} marginBottom={marginBottom} maxWidth={maxWidth} maxHeight={maxHeight} flexDirection="column" alignItems="flex-start">
            <>{(title || button) && <div className={styles.titleBlock}>
                    {title && <Title title={title} marginRight={35} level={5} />}
                    {button}
                </div>
            }</>
            <>{children}</>
        </Wrp>
    );
}

export default NotificationsBlock