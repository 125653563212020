import { PayloadAction } from "@reduxjs/toolkit";
import { ConfigState } from "../../models/ConfigState";
import { GetVendorConfigSuccessPayload } from "../../payload/GetVendorConfig/GetVendorConfigSuccessPayload";

const getVendorConfigSuccess = (state: ConfigState, action: PayloadAction<GetVendorConfigSuccessPayload>) => {
    const { vendorConfig } = action.payload;

    state.vendorConfig = vendorConfig;
    state.isError = null;
    state.isLoading = false;
}

export default getVendorConfigSuccess;