import { useFormik } from "formik";
import moment from "moment";
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import { setFonVisiable } from "../../../../store/state/all/all.slice";
import { createMoneyTransactionFetch, searchCounteragentsFetch, searchUsersForVendorFetch, setDefaultValueFinance, setVisiablePaymentForm } from "../../../../store/state/finance/finance.slice";
import { Transaction } from "../../../../store/state/finance/models/Transaction";
import PaymentFormValue from "../../models/PaymentFormValue";
import PaymentForm from "./PaymentForm";
import validationSchema from "./PaymentForm.scheme";

const PaymentFormContainer: FC = () => {
    const dispatch = useAppDispatch();
    
    const visiable = useAppSelector(state => state.finance.visiablePaymentForm, shallowEqual)
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const userId = useAppSelector(state => state.user.id, shallowEqual) || -1;
    const userRoleId = useAppSelector(state => state.user.roleId, shallowEqual) || -1;
    const userFullname = useAppSelector(state => state.user.name, shallowEqual);
    const counteragents = useAppSelector(state => state.finance.counteragents, shallowEqual);

    const initialValues: PaymentFormValue = {
        counteragent: {
            id: null,
            name: "",
            userId: null,
        },
        responsibleUser: {
            id: userId,
            fullname: userFullname,
            roleId: userRoleId,
        },
        sum: 0,
        date: "",
        operationId: -1,
        purseId: -1,
        comment: "",
        scheduled: false,
    }

    const formik = useFormik<PaymentFormValue>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const {counteragent, date, responsibleUser, sum, purseId, operationId, comment, scheduled} = values;

            const today = new Date();

            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const transaction: Transaction = {
                counteragent,
                date,
                responsibleUser,
                sum,
                purseId,
                operationId,
                comment,
                income: false,
                scheduled,
            };

            dispatch(createMoneyTransactionFetch({vendorId, transaction, filter: {
                period: {
                    from: moment(firstDay).format("YYYY-MM-DD"),
                    to: moment(lastDay).format("YYYY-MM-DD"),
                }
            }}));
            closeModalHandler();
        },
    });

    const closeModalHandler =  () => {
        formik.resetForm();
        dispatch(setVisiablePaymentForm({visiable: false}));
        dispatch(setFonVisiable({visiable: false}));
        dispatch(setDefaultValueFinance({counteragents: true}));
    }

    const handleSearchUser = (value: string) => {
        dispatch(searchCounteragentsFetch({vendorId, value}));
    }

    return (
        <PaymentForm
            formik={formik} 
            visiable={visiable} 
            closeModalHandler={closeModalHandler} 
            handleSearchUser={handleSearchUser}
            counteragents={counteragents}
        />
    );
}

export default PaymentFormContainer;