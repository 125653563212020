import { createSlice } from '@reduxjs/toolkit';
import { EmployeesState } from './models/EmployeesState';
import createEmployeeFetchReducer from './reducer/createEmployee/createEmployeeFetch';
import createEmployeeSuccessReducer from './reducer/createEmployee/createEmployeeSuccess';
import updateEmployeeFetchReducer from './reducer/updateEmployee/updateEmployeeFetch';
import updateEmployeeSuccessReducer from './reducer/updateEmployee/updateEmployeeSuccess';
import deleteEmployeeFetchReducer from './reducer/deleteEmployee/deleteEmployeeFetch';
import deleteEmployeeSuccessReducer from './reducer/deleteEmployee/deleteEmployeeSuccess';
import getEmployeesListFetchReducer from './reducer/getEmployeesList/getEmployeesListFetch';
import getEmployeesListSuccessReducer from './reducer/getEmployeesList/getEmployeesListSuccess';
import sendPasswordFetchReducer from './reducer/sendPassword/sendPasswordFetch';
import sendPasswordSuccessReducer from './reducer/sendPassword/sendPasswordSuccess';
import setErrorReducer from './reducer/setError';
import setPageEmployeesReducer from './reducer/setPageEmployees';
import setMessageReducer from './reducer/setMessage';
import setMessageVisiableReducer from './reducer/setMessageVisiable';
import setNewItemIdReducer from './reducer/setNewItemId';
import setErrorEmailEmployeeReducer from './reducer/setErrorEmailEmployee';
import setErrorTelEmployeeReducer from './reducer/setErrorTelEmployee';
import getTeachersListFetchReducer from './reducer/getTeachersList/getTeachersListFetch';
import getTeachersListSuccessReducer from './reducer/getTeachersList/getTeachersListSuccess';
import getEmployeeFetchReducer from './reducer/getEmployee/getEmployeeFetch';
import getEmployeeSuccessReducer from './reducer/getEmployee/getEmployeeSuccess';
import resetEmployeeReducer from './reducer/resetEmployee';
import changeEmployeeActivityFetchReducer from './reducer/changeEmployeeActivity/changeEmployeeActivityFetch';
import changeEmployeeActivitySuccessReducer from './reducer/changeEmployeeActivity/changeEmployeeActivitySuccess';

export const initialState: EmployeesState = {
    employeesArray: [],
    teachers: [],
    isLoading: false,
    isError: null,
    message: null,
    messageVisiable: false,
    newItemId: null,
    getAllItems: false,
    pageAll: 0,
    pageSize: 15,
    page: 1,
    totalCount: 0,
    emailError: null,
    telError: null,
}

export const EmployeesSlice = createSlice({
    name: 'employees',
    initialState,
    reducers: {
        createEmployeeFetch: createEmployeeFetchReducer,
        createEmployeeSuccess: createEmployeeSuccessReducer,
        updateEmployeeFetch: updateEmployeeFetchReducer,
        updateEmployeeSuccess: updateEmployeeSuccessReducer,
        deleteEmployeeFetch: deleteEmployeeFetchReducer,
        deleteEmployeeSuccess: deleteEmployeeSuccessReducer,
        getEmployeesListFetch: getEmployeesListFetchReducer,
        getEmployeesListSuccess: getEmployeesListSuccessReducer,
        sendPasswordFetch: sendPasswordFetchReducer,
        sendPasswordSuccess: sendPasswordSuccessReducer,
        setError: setErrorReducer,
        setPageEmployees: setPageEmployeesReducer,
        setMessage: setMessageReducer,
        setMessageVisiable: setMessageVisiableReducer,
        setNewItemId: setNewItemIdReducer,
        setErrorEmailEmployee: setErrorEmailEmployeeReducer,
        setErrorTelEmployee: setErrorTelEmployeeReducer,
        getTeachersListFetch: getTeachersListFetchReducer,
        getTeachersListSuccess: getTeachersListSuccessReducer,
        getEmployeeFetch: getEmployeeFetchReducer,
        getEmployeeSuccess: getEmployeeSuccessReducer,
        resetEmployee: resetEmployeeReducer,
        changeEmployeeActivityFetch: changeEmployeeActivityFetchReducer,
        changeEmployeeActivitySuccess: changeEmployeeActivitySuccessReducer,
    },
});

export const {
    createEmployeeFetch,
    createEmployeeSuccess,
    getEmployeesListFetch,
    getEmployeesListSuccess,
    updateEmployeeFetch,
    updateEmployeeSuccess,
    deleteEmployeeFetch,
    deleteEmployeeSuccess,
    sendPasswordFetch,
    sendPasswordSuccess,
    setError,
    setPageEmployees,
    setMessage,
    setMessageVisiable,
    setNewItemId,
    setErrorEmailEmployee,
    setErrorTelEmployee,
    getTeachersListFetch,
    getTeachersListSuccess,
    getEmployeeFetch,
    getEmployeeSuccess,
    resetEmployee,
    changeEmployeeActivityFetch,
    changeEmployeeActivitySuccess,
} = EmployeesSlice.actions

export default EmployeesSlice.reducer