import { AxiosInstance } from "axios";

class Token {
    private ACCESS_TOKEN_KEY = "AccessToken";
    private REFRESH_TOKEN_KEY = "RefreshToken";

    getAccessToken = (): string | null => {
        return localStorage.getItem(this.ACCESS_TOKEN_KEY);
    };

    getRefreshToken = (): string | null => {
        return localStorage.getItem(this.REFRESH_TOKEN_KEY);
    };

    setAccessToken = (token: string): boolean => {
        if(token === "") {
            return false;
        }
    
        localStorage.setItem(this.ACCESS_TOKEN_KEY, token);
    
        return true;
    };
    removeAccessToken = (): boolean => {
        localStorage.removeItem(this.ACCESS_TOKEN_KEY);

        return true;
    };

    setRefreshToken = (token: string): boolean => {
        if(token === "") {
            return false;
        }
    
        localStorage.setItem(this.REFRESH_TOKEN_KEY, token)
    
        return true;
    };

    addHeaderToken = (refreshToken: string, accessToken: string): boolean => {
        if(this.setRefreshToken(refreshToken) && this.setAccessToken(accessToken)) {
            return true;
        }

        return false;
    }
    
    clear = (): void => {
        localStorage.clear();
    }
}

export default new Token();