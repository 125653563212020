import moment from "moment";
import React, { FC } from "react";
import Notification from "../../../../../components/feedback/notifications/Notification/Notification";
import IconFont from "../../../../../components/general/icons/IconFont/IconFont";
import Paragraph from "../../../../../components/general/typography/Paragraph/Paragraph";
import Wrp from "../../../../../components/layout/Wrp/Wrp";
import { Comment } from "../../../../../store/state/clients/models/Comment";

interface Props {
    comments: Comment[];
}

const Comments: FC<Props> = ({comments}) => {
    const commentItems = comments.map((commentItem, index) => {
        const origin = commentItem.responsibleUser?.fullname || "";
        const datatime =  moment(commentItem.createdAt).format('DD.MM.YYYY');
        const notification = <Paragraph content={commentItem.message} />;
        const iconType = <IconFont iconClass="icon-group" fontSize={10} color="" />;

        return (
            <Notification 
                key={"COMMUNICAtTION_ITEM_" + index}
                icon={iconType} 
                notification={notification}
                datatime={datatime}
                origin={origin}
            />
        )
    });

    return (
        <Wrp flexDirection="column">
            {commentItems}
        </Wrp>
    );
}

export default Comments;