import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Clients } from "../../../../services/reddyApi/Clients/Clients";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { searchClientForVendorFetch, searchClientForVendorSuccess } from "../clients.slice";
import { SearchClientForVendorPayload } from "../payload/SearchClientForVendor/SearchClientForVendorPayload";

function* searchClientForVendorWorker(action: PayloadAction<SearchClientForVendorPayload>) {
    const { vendorId, value } = action.payload;
    const dataParams = {
        vendorId,
        value
    }

    try {
        const { data } = yield call(Clients.searchClientForVendor, dataParams);

        yield put(searchClientForVendorSuccess({ searchedClients: data }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => searchClientForVendorFetch(action.payload);
            
            yield handleServerError(error, actionCreator);
        }
    }
}

export default searchClientForVendorWorker;