import { PayloadAction } from "@reduxjs/toolkit";
import { NotificationsState } from "../../models/NotificationsState";
import { NotificationStatuses } from "../../notifications.slice";
import { GetNotificationsSuccessPayload } from "../../payload/GetNotifications/GetNotificationsSuccessPayload";

const getNotificationsSuccess = (state: NotificationsState, action: PayloadAction<GetNotificationsSuccessPayload>) => {
    const { filters, totalCount, items } = action.payload;
   
    const status = filters?.statusId;

    if (status) {
        switch (status) {
            case NotificationStatuses.ACTIVE:
                state.active = items;
                break;
            case NotificationStatuses.SELECTED:
                state.selected = items;
                break;
            case NotificationStatuses.ARCHIVE:
                state.archive = items;
                break;
        }
    }
    
    state.isLoading = false;
    state.isError = null;
}

export default getNotificationsSuccess;