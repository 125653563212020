import React, { FC } from "react";
import { CSSTransition } from "react-transition-group";
import "./transition.css";

interface Props {
    justifyContent?: "flex-start" | "flex-end" | "center" | "space-between";
    alignItems?: "center" | "flex-start" | "flex-end";
    width?: number | string;
    children: JSX.Element[] | JSX.Element;
    marginBottom?: number;
    marginTop?: number;
    maxWidth?: number;
    visiable?: boolean;
}

const Row: FC<Props> = ({ width = "100%", alignItems = "center", justifyContent = "flex-start", children, 
    marginBottom, maxWidth, visiable = true, marginTop }) => {
    return (
        <CSSTransition
            in={visiable}
            timeout={400}
            unmountOnExit
            classNames="row"
        >
            <div style={{ display: "flex", justifyContent, alignItems, width, marginBottom, marginTop, maxWidth }}>
                {children}
            </div>
        </CSSTransition>
    )
}

export default Row;