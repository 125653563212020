import React, { FC } from "react";
import styles from "./CircleProgress.module.css";
import classNames from "classnames";
import Wrp from "../../../layout/Wrp/Wrp";

const cx = classNames.bind(styles);

interface Props {
    sqSize: number;
    strokeWidth: number;
    percentage: number;
}

const CircleProgress: FC<Props> = ({sqSize, strokeWidth, percentage}) => {
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (sqSize - strokeWidth) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - dashArray * percentage / 100;

    return (
        <Wrp flexDirection="column" alignItems="center" justifyContent="center">
            <p className={styles.percentageText}>
                {percentage}%
            </p>
            <svg
                width={sqSize}
                height={sqSize}
                viewBox={viewBox}
            >
                <circle
                    className={styles.circleBackground}
                    cx={sqSize / 2}
                    cy={sqSize / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`} 
                />
                <circle
                    className={styles.circleProgress}
                    cx={sqSize / 2}
                    cy={sqSize / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`}
                    // Start progress marker at 12 O'Clock
                    transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset
                    }} 
                />
            </svg>
        </Wrp>
    );
}

export default CircleProgress;