import { PayloadAction } from "@reduxjs/toolkit";
import { GroupsState } from "../../models/GroupsState";
import { AddStudentToGroupSuccessPayload } from "../../payload/AddStudentToGroup/AddStudentToGroupSuccessPayload";

const addStudentToGroupSuccess = (state: GroupsState, action: PayloadAction<AddStudentToGroupSuccessPayload>) => {
    const { groupId, student } = action.payload;
    const groups = state.pageData;

    const groupIndex = groups.findIndex(group => group.id === groupId);

    if (groupIndex > -1) {
        const group = groups[groupIndex];
        const students = group.students;

        if (students) {
            students.push(student);
        } else {
            group.students = [student];
        }

        group.studentsAmount += 1;
    }

    state.isLoading = false;
    state.isError = null;
}

export default addStudentToGroupSuccess;