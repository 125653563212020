import { useFormik } from "formik";
import React, { FC } from "react";
import SignIn from "./SignIn";
import validationSchema from "./SignIn.scheme";
import { shallowEqual, useDispatch } from "react-redux";
import { getUserDataFetch } from "../../store/state/user/user.slice";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { Navigate, useNavigate } from "react-router-dom";
import { SignInValues } from "./models/SignInValues";
import { SYSTEM_ADMIN_ID } from "../../utl/constants/role";
import { disabledError } from "../../store/state/user/user.slice";

const SignInContainer: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tokenStatus = useAppSelector(state => state.user.tokenStatus, shallowEqual);
    const userRoleID = useAppSelector(state => state.user.roleId, shallowEqual);
    const error = useAppSelector(state => state.user.isError, shallowEqual);

    const initialValues: SignInValues = {
        email: '',
        password: '',
    }

    const formik = useFormik<SignInValues>({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            dispatch(getUserDataFetch({ email: values.email, password: values.password }));
        },
    });

    const clickNavButtonHandler = () => {
        navigate("/signup");
    }

    if (tokenStatus && userRoleID && userRoleID > 0) {
        return <Navigate to={userRoleID === SYSTEM_ADMIN_ID ? "/vendors" : "/"} />
    }

    const disabledErrorHandler = () => {
        dispatch(disabledError());
    }

    return (
        <SignIn 
            error={error} 
            clickNavButtonHandler={clickNavButtonHandler} 
            formik={formik} 
            disabledError={disabledErrorHandler}
        />
    );
}

export default React.memo(SignInContainer);