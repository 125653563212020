import { PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { UploadServicesImagesResponse } from "../../../../../services/reddyApi/Services/Services.response";
import { Upsells } from "../../../../../services/reddyApi/Upsells/Upsells";
import { UploadUpsellIconResponse } from "../../../../../services/reddyApi/Upsells/Upsells.response";
import handleServerError from "../../../all/saga/handleServerError";
import setServerErrorDefault from "../../../all/saga/setServerErrorDefault";
import getNotifications from "../../../notifications/saga/getNotifications";
import { UploadUpsellIconPayload } from "../payload/UploadUpsellIcon/UploadUpsellIconPayload";
import { uploadUpsellIconFetch, uploadUpsellIconSuccess } from "../upsells.slice";

function* uploadUpsellIconWorker(action: PayloadAction<UploadUpsellIconPayload>) {
    const { file, upsellId, vendorId } = action.payload;

    try {
        if (file !== undefined) {
            const { status, data }: AxiosResponse<UploadUpsellIconResponse> =
                yield call(Upsells.uploadUpsellIcon, { file, upsellId, vendorId });

            if (status === 200) {
                const linkImeges = data.link;

                yield put(uploadUpsellIconSuccess({ link: linkImeges, upsellId }));

                yield getNotifications(vendorId);

                yield setServerErrorDefault();
            }
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => uploadUpsellIconFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default uploadUpsellIconWorker;