import { Operations } from "../../store/state/money/models/Operations";
import { Purses } from "../../store/state/money/models/Purses";


export const MoneyAdapter = (money: Purses[] | Operations[], moneyId: number | null | undefined): string | null | 0 => {
    if(!moneyId) {
        return 0;
    }

    for (let item of money) {
        if(item.id === moneyId)
            return item.name;
    }

    return null;
}