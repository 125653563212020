import { PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { Notifications } from "../../../../services/reddyApi/Notifications/Notifications";
import { GetNotificationsResponse } from "../../../../services/reddyApi/Notifications/Notifications.response";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getNotificationsFetch, getNotificationsSuccess } from "../notifications.slice";
import { GetNotificationsPayload } from "../payload/GetNotifications/GetNotificationsPayload";

function* getNotificationsWorker(action: PayloadAction<GetNotificationsPayload>) {
    const { vendorId, filters } = action.payload;
    const notifications = new Notifications();

    try {
        const { data }: AxiosResponse<GetNotificationsResponse> = yield call(notifications.getNotifications, { vendorId, filters });
        const { items, totalCount } = data;

        yield put(getNotificationsSuccess({ items, totalCount, filters }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getNotificationsFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getNotificationsWorker;