import React, { FC } from "react";
import ButtonToggle from "../../general/buttons/ButtonToggle/ButtonToggle";
import styles from "./Sidebar.module.css";
import "./Sidebar.css";
import CloseIcon from "../../general/icons/CloseIcon/CloseIcon";
import SettingsIcon from "../../general/icons/SettingsIcon/SettingsIcon";
import {CSSTransition} from "react-transition-group";
import classNames from "classnames";
const cx = classNames.bind(styles);

interface Props {
    toggle: boolean;
    setToggle: () => void;
    mode?: string;
    logo: JSX.Element;
    logoOpacity: JSX.Element;
    children: JSX.Element[] | JSX.Element;
    width?: number;
    activeWidth?: number;
}


const Sidebar: FC<Props> = ({toggle, setToggle, mode = "primary", logo, logoOpacity, children, width = 100, activeWidth = 240}) => {
    return (
        <div 
            className={cx(styles.sidebar, "primary", toggle && styles.active)} 
            style={{width: toggle? activeWidth : width}}
        >
            <div className={styles.logoBlock}>
                <div className={styles.logo}>
                    {logo}
                </div>
                <CSSTransition
                    in={toggle}
                    timeout={200}
                    classNames={"fade"}
                    unmountOnExit
                >
                    <div className={styles.logoOpacity}>
                        {logoOpacity}
                    </div>
                </CSSTransition>
            </div>
            <nav className={styles.nav}>
                <ul className={styles.sidebarList}>
                    {children}
                </ul>
            </nav>
            <div className={styles.btnBlock}>
                <ButtonToggle 
                    active={<CloseIcon />}
                    inactive={<SettingsIcon />}
                    isActive={toggle} 
                    handleClick={() => setToggle()}
                />
            </div>
        </div>
    );
}

export default Sidebar;