import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../../store/hooks/selector.hook";
import UserDataBlock from "./UserDataBlock";

const UserDataBlockContainer: FC = () => {
    let { id } = useParams();
    const clientId = (id && parseInt(id)) || -1;
    const clients = useAppSelector(state => state.clients.pageData, shallowEqual);
    let indexClient = clients.findIndex(client => client.clientId === clientId);
    let client = clients[indexClient];
    let users = client.users;
    let students = client.students;
    let documents = client.documents || [];

    return (
        <UserDataBlock
            users={users}
            students={students}
            documents={documents}
        />
    );
}

export default UserDataBlockContainer;