import React from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import StatisticsBlock from "./StatisticsBlock";

const StatisticsBlockContainer = () => {
    const incomes = useAppSelector(state => state.finance.finance.future?.incomes, shallowEqual) || [];
    const expenses = useAppSelector(state => state.finance.finance.future?.expenses, shallowEqual) || [];
    const purses = useAppSelector(state => state.finance.finance.purses, shallowEqual);
    const operations = useAppSelector(state => state.money.operations, shallowEqual);

    return (
        <StatisticsBlock
            incomes={incomes}
            expenses={expenses}
            purses={purses}
            operations={operations}
        />
    );
}

export default React.memo(StatisticsBlockContainer);