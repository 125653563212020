import React, { FC } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./ModalForm.module.css";
import "./ModalForm.css";
import Title from "../../../general/typography/Title/Title";
import ButtonIcon from "../../../general/buttons/ButtonIcon/ButtonIcon";
import CloseIcon from "../../../general/icons/CloseIcon/CloseIcon";

interface Props {
    title?: string;
    children: JSX.Element[] | JSX.Element;
    maxWidth?: number;
    width?: number | string;
    visiable: boolean;
    handleClick: () => void;
    top?: number | string;
}

const ModalForm: FC<Props> = ({children, visiable, maxWidth, width, handleClick, title, top}) => {
    return (
        <CSSTransition 
            in={visiable}
            timeout={200}
            classNames={"fade-form-modal"}
            unmountOnExit
        >
            <form className={styles.modalForm} style={{maxWidth, width, top}}>
                <div className={styles.row}>
                    {title && <Title title={title} level={5} />}
                    <ButtonIcon icon={<CloseIcon />} handleClick={handleClick}/>
                </div>
                {children}
            </form>
        </CSSTransition>
    )
}

export default ModalForm;