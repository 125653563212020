import { PayloadAction } from "@reduxjs/toolkit";
import { ServiceState } from "../../models/ServiceState";
import { UploadServicesImagesSuccessPayload } from "../../payload/UploadServicesImages/UploadServicesImagesSuccessPayload";

const uploadServiceImagesSuccess = (state: ServiceState, action: PayloadAction<UploadServicesImagesSuccessPayload>) => {
    const { serviceId, links } = action.payload;

    const services = state.basics;

    const indexService = services.findIndex(service => service.id === serviceId);

    if(indexService > -1) {
        const service = services[indexService];
        service.images = links;
    }
}

export default uploadServiceImagesSuccess;