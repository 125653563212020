import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Groups } from "../../../../services/reddyApi/Groups/Groups";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { createGroupFetch, createGroupSuccess } from "../groups.slice";
import { CreateGroupPayload } from "../payload/CreateGroup/CreateGroupPayload";

function* createGroupWorker(action: PayloadAction<CreateGroupPayload>) {
    const { vendorId, group } = action.payload;
    const dataParams = {
        vendorId,
        group,
    }

    try {
        const { data } = yield call(Groups.create, dataParams);
        const { groupId } = data;

        yield put(createGroupSuccess({ ...group, id: groupId }));

        yield getNotifications(vendorId);

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => createGroupFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default createGroupWorker;