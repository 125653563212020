import { takeLatest } from "redux-saga/effects";
import addCommentWorker from "./addCommentWorker";
import createByVendorWorker from "./createByVendorWorker";
import deleteForVendorWorker from "./deleteForVendorWorker";
import downloadClientsTemplateWorker from "./downloadClientsTemplateWorker";
import getClientMoneyTransactionsByVendorWorker from "./getClientMoneyTransactionsByVendorWorker";
import getClientsListForVendorWorker from "./getClientsListForVendorWorker";
import getClientStatisticsForVendorWorker from "./getClientStatisticsForVendorWorker";
import getCommentsByVendorWorker from "./getCommentsByVendorWorker";
import getFinanceInfoForVendorWorker from "./getFinanceInfoForVendorWorker";
import getVendorClientDataWorker from "./getVendorClientDataWorker";
import importClientsByVendorWorker from "./importClientsByVendorWorker";
import searchClientForVendorWorker from "./searchClientForVendorWorker";
import updateClientDiscountWorker from "./updateClientDiscountWorker";

export function* clientsWatcher() {
    yield takeLatest("clients/createByVendorFecth", createByVendorWorker);
    yield takeLatest("clients/addCommentFecth", addCommentWorker);
    yield takeLatest("clients/getCommentsByVendorFetch", getCommentsByVendorWorker);
    yield takeLatest("clients/getClientsListForVendorFetch", getClientsListForVendorWorker);
    yield takeLatest("clients/deleteForVendorFetch", deleteForVendorWorker);
    yield takeLatest("clients/getFinanceInfoForVendorFetch", getFinanceInfoForVendorWorker);
    yield takeLatest("clients/getClientMoneyTransactionsByVendorFetch", getClientMoneyTransactionsByVendorWorker);
    yield takeLatest("clients/getClientStatisticsForVendorFetch", getClientStatisticsForVendorWorker);
    yield takeLatest("clients/searchClientForVendorFetch", searchClientForVendorWorker);
    yield takeLatest("clients/getVendorClientDataFetch", getVendorClientDataWorker);
    yield takeLatest("clients/updateClientDiscountFetch", updateClientDiscountWorker);
    yield takeLatest("clients/downloadClientsTemplateFetch", downloadClientsTemplateWorker);
    yield takeLatest("clients/importClientsByVendorFetch", importClientsByVendorWorker);
}