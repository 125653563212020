import { PayloadAction } from "@reduxjs/toolkit";
import { VendorsState } from "../../models/VendorsState";
import { ConfirmVendorSuccessPayload } from "../../payload/ConfirmVendor/ConfirmVendorSuccessPayload";

const confirmVendorSuccess = (state: VendorsState, action: PayloadAction<ConfirmVendorSuccessPayload>) => {
    const {vendorId} = action.payload;
    state.isLoading = false;

    const indexVendor = state.vendorsArray.findIndex(vendor => vendor.id === vendorId);
    state.vendorsArray[indexVendor].active = action.payload.active;

    state.isError = null;
    state.message = "Вендора успішно підтверджено";
    state.messageVisiable = true;
}

export default confirmVendorSuccess;