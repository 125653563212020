import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../../store/hooks/selector.hook";
import { getFinanceInfoForVendorFetch } from "../../../../../store/state/clients/clients.slice";
import Parameters from "./Parameters";

const ParametersConatiner: FC = () => {
    const dispatch = useAppDispatch();
    let { id } = useParams();
    const clientId = (id && parseInt(id)) || -1;
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const clients = useAppSelector(state => state.clients.pageData, shallowEqual);
    const indexClient = clients.findIndex(client => client.clientId === clientId);
    const financeInfo = useAppSelector(state => state.clients.pageData[indexClient].financeInfo, shallowEqual);
    const balance = financeInfo?.balance || 0;
    const costPerOrder = financeInfo?.costPerOrder || 0;
    const numberOfPurchases = financeInfo?.numberOfPurchases || 0;
    const totalSpent = financeInfo?.totalSpent || 0;
    
    useEffect(() => {
        if(!financeInfo) {
            dispatch(getFinanceInfoForVendorFetch({vendorId, clientId}))
        }
    }, [clientId, vendorId]);

    return (
        <Parameters
            balance={parseFloat(balance.toFixed(1))}
            costPerOrder={parseFloat(costPerOrder.toFixed(1))}
            numberOfPurchases={parseFloat(numberOfPurchases.toFixed(1))}
            totalSpent={parseFloat(totalSpent.toFixed(1))}
        />
    );
}

export default React.memo(ParametersConatiner);