import { useEffect, useState } from "react";

export default function useDebounce<T>(value: T, delay: number = 0): T {
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(
      () => {
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        
        return () => {
          clearTimeout(handler);
        };
      },
      [value]
    );

    return debouncedValue
}