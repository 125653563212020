import { PayloadAction } from "@reduxjs/toolkit";
import { Notification } from "../../models/Notification";
import { NotificationsState } from "../../models/NotificationsState";
import { NotificationStatuses } from "../../notifications.slice";
import { ChangeNotificationsStatusSuccessPayload } from "../../payload/ChangeNotificationsStatus/ChangeNotificationsStatusSuccessPayload";

const changeNotificationsStatusSuccess = (state: NotificationsState, action: PayloadAction<ChangeNotificationsStatusSuccessPayload>) => {
    const { statusId, notificationsIds, prevStatusId } = action.payload;
    let archive = state.archive.map(notification => ({...notification}));
    let active = state.active.map(notification => ({...notification}));
    let selected = state.selected.map(notification => ({...notification}));
    
    notificationsIds.forEach(notificationId => {
        let notification: Notification | null = null;

        switch (prevStatusId) {
            case NotificationStatuses.ACTIVE:
                const indexNotificationActive = active.findIndex(notification => notification.id === notificationId);
                if (indexNotificationActive > -1) {
                    notification = active[indexNotificationActive];
                }
                break;
            case NotificationStatuses.SELECTED:
                const indexNotificationSelected = selected.findIndex(notification => notification.id === notificationId);
                if (indexNotificationSelected > -1) {
                    notification = selected[indexNotificationSelected];
                }
                break;
            case NotificationStatuses.ARCHIVE:
                const indexNotificationArchive = archive.findIndex(notification => notification.id === notificationId);
                if (indexNotificationArchive > -1) {
                    notification = archive[indexNotificationArchive];
                }
                break;
        }

        if (notification) {
            switch (statusId) {
                case NotificationStatuses.ACTIVE:
                    active.unshift({...notification, statusId});
                    archive = archive.filter(notification => notification.id !== notificationId);
                    selected = selected.filter(notification => notification.id !== notificationId);
                    break;
                case NotificationStatuses.SELECTED:
                    selected.unshift({...notification, statusId});
                    archive = archive.filter(notification => notification.id !== notificationId);
                    active = active.filter(notification => notification.id !== notificationId);
                    break;
                case NotificationStatuses.ARCHIVE:
                    archive.unshift({...notification, statusId});
                    selected = selected.filter(notification => notification.id !== notificationId);
                    active = active.filter(notification => notification.id !== notificationId);
                    break;
            }
        }
    });

    state.active = active;
    state.archive = archive;
    state.selected = selected;

    state.isLoading = false;
    state.isError = null;
}

export default changeNotificationsStatusSuccess;