import { FormikProps } from "formik";
import React, { ChangeEvent, FC } from "react";
import Button from "../../../components/general/buttons/Button/Button";
import AuthForm from "../../../components/dataEntry/forms/AuthForm/AuthForm";
import Field from "../../../components/dataEntry/inputs/Field/Field";
import Margin from "../../../components/layout/Margin/Margin";
import LinkText from "../../../components/general/typography/LinkText/LinkText";
import Paragraph from "../../../components/general/typography/Paragraph/Paragraph";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { disabledError } from "../../../store/state/user/user.slice";
import { EmailPassRecoveryValues } from "./models/EmailPassRecoveryValues";

interface Props {
    formik: FormikProps<EmailPassRecoveryValues>;
    isSubmit: boolean;
    error: string | null;
}

const EmailPassRecovery: FC<Props> = ({formik, isSubmit, error}) => {
    const {isValid, values, errors, touched, handleBlur, handleChange, handleSubmit} = formik;
    const dispatch = useAppDispatch();

    const clickButtonSubmitHandler = () => {
        handleSubmit();
    }

    const changeEmailHandler = (event: ChangeEvent<HTMLInputElement>) => {
        handleChange("email")(event);
        dispatch(disabledError());
    }

    return (
        <AuthForm 
            title={!isSubmit ? "Вкажіть свій Email і ми надішлемо вам дані для відновлення паролю" : ""}
            fontSize={14}
            lineHeight="16px"
        >
            {!isSubmit ? <>
                <Field
                    marginRight={0}
                    label="Email" 
                    value={values.email} 
                    handleChange={changeEmailHandler} 
                    handleBlur={handleBlur("email")}
                    error={errors.email || error || undefined}
                    touched={touched.email}
                />
                <Button 
                    disabled={!isValid}
                    title="Надіслати" 
                    backgroundColor="#4775FE"
                    handleClick={clickButtonSubmitHandler} 
                />
                <Margin marginTop={20}>
                    <LinkText type="link" link="/signin" content="Увійти" />
                </Margin></> : <Paragraph textAlign="center" content="Лист був віправлен вам на пошту" />
            }
        </AuthForm>
    );
}

export default EmailPassRecovery;