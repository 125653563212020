import React, { FC } from "react";
import Column from "../../components/layout/Column/Column";
import Wrp from "../../components/layout/Wrp/Wrp";
import ClientsTableBlockContainer from "./components/ClientsTableBlock/ClientsTableBlock.container";
import EmptyDashboard from "./components/EmptyDashboard/EmptyDashboard";
import GraphBlockContainer from "./components/GraphBlock/GraphBlock.container";
import ParametersClientsBlockContainer from "./components/ParametersClientsBlock/ParametersClientsBlock.container";
import ParametersMoneyBlockContainer from "./components/ParametersMoneyBlock/ParametersMoneyBlock.container";
import SrvicesTableBlockContainer from "./components/ServicesTableBlock/ServicesTableBlock.container";

interface Props {
    isEmpty: boolean;
}

const Dashboard: FC<Props> = ({ isEmpty }) => {
    return (
        !isEmpty ? <>
            <Column maxWidth={965}>
                <GraphBlockContainer />
                <Wrp
                    flexDirection="column"
                    paddingRight={20}
                    marginBottom={30}
                >
                    <SrvicesTableBlockContainer />
                </Wrp>
            </Column>
            <Column maxWidth={270}>
                <ParametersMoneyBlockContainer />
                <ParametersClientsBlockContainer />
            </Column>
        </>
            :
            <EmptyDashboard />
    )
}

export default Dashboard;