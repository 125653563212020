import React, { FC } from "react";
import styles from "./EmptyView.module.css";
import classNames from "classnames";

const cx = classNames.bind(styles);

interface Props {
    mode?: "black" | "white";
}

const EmptyView: FC<Props> = ({mode = "white"}) => {
    return (
        <div className={styles.emptyBlock}>
            <div className={cx(styles.empty, mode === "black" && styles.black)}></div>
        </div>
    )
}

export default EmptyView;