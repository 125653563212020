import NoMatchContainer from "../../screens/NoMatch/NoMatch.container"

interface ReturnType {
    isNoMatch: boolean;
    noMatchComponent: JSX.Element;
}

function useNoMatch<T>(data: T | null | undefined): ReturnType {
    return {
        isNoMatch: !data,
        noMatchComponent: <NoMatchContainer />
    }
}

export default useNoMatch;