import { FormikProps, FormikProvider } from "formik";
import React, { FC } from "react";
import Button from "../../../../../../components/general/buttons/Button/Button";
import Wrp from "../../../../../../components/layout/Wrp/Wrp";
import { SetStudentsVisitForLessonValues } from "../../../../models/SetStudentsVisitForLessonValues";
import ModalForm from "../../../../../../components/dataEntry/forms/ModalForm/ModalForm";
import Paragraph from "../../../../../../components/general/typography/Paragraph/Paragraph";
import { Lessons } from "../../../../../../store/state/groups/models/Group";
import Row from "../../../../../../components/layout/Row/Row";
import TeachersTagInput from "../../../GroupSettings/components/TeachersTagInput/TeachersTagInput";
import LessonDateDropdown from "./components/LessonDateDropdown/LessonDateDropdown";
import Students from "./components/Students/Students";

interface Props {
    formik: FormikProps<SetStudentsVisitForLessonValues>;
    closeModalHandler: () => void;
    visiable: boolean;
    visiableForm: boolean;
    lessons: Lessons[];
    groupUpsellsIds: number[];
    groupEmployeesIds: number[];
}

const SetStudentsVisitForLesson: FC<Props> = ({
    closeModalHandler,
    formik,
    visiable,
    visiableForm,
    lessons,
    groupUpsellsIds,
    groupEmployeesIds
}) => {
    const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched } = formik;

    const submitButtonHandler = () => {
        handleSubmit();
    }

    const lessonId = values.lessonId

    const employeesIds = values.employeesIds;

    const students = values.students;

    return (
        <FormikProvider value={formik}>
            <ModalForm
                title="Відмітити відвідуваність"
                visiable={visiable}
                maxWidth={480}
                handleClick={closeModalHandler}
            >
                <Wrp flexDirection="column" alignItems="flex-start">
                    {visiableForm ?
                        <>
                            <LessonDateDropdown
                                lessonId={lessonId}
                                lessons={lessons}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                error={errors.lessonId}
                                touched={touched.lessonId}
                            />

                            <Row marginBottom={20}>
                                <TeachersTagInput
                                    employeesIdsValues={employeesIds}
                                    error={errors.employeesIds}
                                    touched={touched.employeesIds}
                                    handleBlur={handleBlur("employeesIds")}
                                    setFieldValue={setFieldValue}
                                    marginRight={0}
                                    maxWidth={440}
                                    obligatoryEmployeesIds={groupEmployeesIds}
                                />
                            </Row>

                            <Students
                                students={students}
                                groupUpsellsIds={groupUpsellsIds}
                                error={errors.students}
                                touched={touched.students}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                            />

                            <Row justifyContent="center">
                                <Button
                                    disabled={!isValid}
                                    maxWidth={160}
                                    handleClick={submitButtonHandler}
                                    backgroundColor="#4775FE"
                                    title="Зберегти"
                                    marginTop={20}
                                />
                            </Row>
                        </>
                        :
                        <Paragraph
                            content="Усі заняття було відмічено"
                            fontSize={16}
                        />
                    }
                </Wrp>
            </ModalForm>
        </FormikProvider>
    );
}

export default SetStudentsVisitForLesson;