import { FormikProps } from "formik";
import React, { FC } from "react";
import ModalForm from "../../../../components/dataEntry/forms/ModalForm/ModalForm";
import Textarea from "../../../../components/dataEntry/inputs/Textarea/Textarea";
import Button from "../../../../components/general/buttons/Button/Button";
import Wrp from "../../../../components/layout/Wrp/Wrp";
import { AddCommentFormValue } from "../../models/AddCommentFormValue";
import CategoryCommentDropdown from "./components/CategoryCommentDropdown/CategoryCommentDropdown";

interface Props {
    formik: FormikProps<AddCommentFormValue>;
    visiable: boolean;
    closeModalHandler: () => void;
}

const AddCommentForm: FC<Props> = ({ formik, visiable, closeModalHandler }) => {
    const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched } = formik;

    const submitButtonHandler = () => {
        handleSubmit();
    }

    const categoryId = values.categoryId;
    return (
        <ModalForm
            title="Додати дохід"
            visiable={visiable}
            maxWidth={480}
            handleClick={closeModalHandler}
        >
            <Wrp flexDirection="column" alignItems="center">
                <CategoryCommentDropdown
                    categoryId={categoryId}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    error={errors.categoryId}
                    touched={touched.categoryId}
                />

                <Textarea
                    maxWidth={440}
                    maxHeight={120}
                    title="Коментар"
                    marginBottom={20}
                    value={values.message}
                    handleBlur={handleBlur("message")}
                    handleChange={handleChange("message")}
                    touched={touched.message}
                    error={errors.message}
                />
                <Button
                    disabled={!isValid}
                    maxWidth={160}
                    handleClick={submitButtonHandler}
                    backgroundColor="#4775FE"
                    title="Додати"
                    marginBottom={0}
                    marginRight={15}
                />
            </Wrp>
        </ModalForm>
    );
}

export default AddCommentForm;