import { PayloadAction } from "@reduxjs/toolkit";
import getItems from "../../../../helpers/getItems";
import { VendorState } from "../../models/Vendor";
import { VendorsState } from "../../models/VendorsState";
import { GetVendorsSuccessPayload } from "../../payload/GetVendors/GetVendorsSuccessPayload";

const defaultVendorItem: VendorState = {
    id: -1,
    name: "Company",
    active: false,
    confirmedFrom: null,
    confirmedTo: null,
    locations: [],
    owner: {
        name: "Name Surname",
        email: "***@gmail.com",
        phone: "+380*********"
    },
    students: {
        total: 0,
        current: 0,
        fullness: 0
    },
    activeDateForm: false,
}

const getVendorsSuccess = (state: VendorsState, action: PayloadAction<GetVendorsSuccessPayload>) => {
    const {items, totalCount, filter} = action.payload;
    
    state.totalCount = totalCount;
    state.pageAll = Math.ceil(totalCount / state.pageSize);
    const vendors = state.vendorsArray;

    state.vendorsArray = getItems(
        state,
        vendors,
        items,
        totalCount,
        defaultVendorItem,
        filter,
    );

    state.isLoading = false;
    state.isError = null;
}

export default getVendorsSuccess;