import { PayloadAction } from "@reduxjs/toolkit";
import { GroupsState } from "../models/GroupsState";
import { SetDefaultValuePayload } from "../payload/SetDefaultValuePayload";

const setDefaultValueGroups = (state: GroupsState, action: PayloadAction<SetDefaultValuePayload>) => {
    const {
        searchCleints,
        searchGroups,
        selectedService,
        searchClientsFormVisiable,
        addStudentToGroupFormVisiable,
        createClientFormVisiable,
        showStudentVisitForm,
    } = action.payload;

    if (searchCleints) {
        state.searchClients = [];
    }

    if (searchGroups) {
        state.serchedGroups = [];
    }

    if (selectedService) {
        state.selectedService = null;
    }

    if (searchClientsFormVisiable) {
        state.searchClientsFormVisiable = false;
    }

    if (addStudentToGroupFormVisiable) {
        state.addStudentToGroupFormVisiable = false;
    }

    if (createClientFormVisiable) {
        state.createClientFormVisiable = false;
    }

    if (showStudentVisitForm) {
        state.showStudentVisitForm = false;
    }
}

export default setDefaultValueGroups;