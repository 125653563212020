import React, { FC, FormEvent, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useAppDispatch } from "../../../../../../../store/hooks/dispatch.hook";
import ModalForm from "../../../../../../../components/dataEntry/forms/ModalForm/ModalForm";
import { setFonVisiable } from "../../../../../../../store/state/all/all.slice";
import { showStudentStatusForm, updateStudentForGroupFetch } from "../../../../../../../store/state/groups/groups.slice";
import { StudentStatusFormValues } from "../../models/StudentStatusFormValues";
import validationSchema from "./StudentStatusForm.scheme";
import Row from "../../../../../../../components/layout/Row/Row";
import Button from "../../../../../../../components/general/buttons/Button/Button";
import IconFont from "../../../../../../../components/general/icons/IconFont/IconFont";
import { useAppSelector } from "../../../../../../../store/hooks/selector.hook";
import { shallowEqual } from "react-redux";
import Wrp from "../../../../../../../components/layout/Wrp/Wrp";
import { configListAdapter } from "../../../../../../../utl/adapters/ConfigListAdapter";
import DropdownItem from "../../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import Dropdown from "../../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import { Filter } from "../../../../../../../utl/models/Filters";
import moment from "moment";

interface Props {
    vendorId: number;
    groupId: number;
    studentId: number;
    status: number;
}

const StudentStatusForm: FC<Props> = ({ vendorId, studentId, groupId, status }) => {
    const initialValues: StudentStatusFormValues = {
        status
    }

    const submitHandler = (values: StudentStatusFormValues) => {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        const filter: Filter = {
            period: {
                from: moment(firstDay).format("YYYY-MM-DD"),
                to: moment(lastDay).format("YYYY-MM-DD"),
            }
        }

        dispatch(updateStudentForGroupFetch({ groupId, vendorId, studentId, studentData: { status: values.status }, filter }));
        closeModalHandler();
    }

    const formik = useFormik<StudentStatusFormValues>({
        initialValues,
        validationSchema,
        onSubmit: submitHandler
    });

    const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched } = formik;

    const statuses = useAppSelector(state => state.config.vendorConfig?.client.studentStatuses, shallowEqual);
    const groups = useAppSelector(state => state.groups.pageData, shallowEqual);
    const indexGroup = groups.findIndex(group => group.id === groupId);
    const students = useAppSelector(state => state.groups.pageData[indexGroup].students, shallowEqual);

    let visiable = false;
    if (students) {
        const indexStudent = students.findIndex(student => student.student.id === studentId);
        const student = students[indexStudent];
        if (student) {
            visiable = Boolean(student.statusFormVisiable);
        }
    }

    const dispatch = useAppDispatch();

    const closeModalHandler = () => {
        dispatch(setFonVisiable({ visiable: false }));
        dispatch(showStudentStatusForm({ groupId, studentId, show: false }));
    }

    const defaultValues: DefaultValue = {
        valueInput: "",
        valueText: "Оберіть зі списку",
    }

    const statusesArray = configListAdapter(statuses);
    const indexStatus = statusesArray?.findIndex(
        statusItem => statusItem.id === values.status
    );

    const defaultStatus = statusesArray.length > 0 && statusesArray[indexStatus];
    const defaultValueStatus: DefaultValue = {
        valueInput: (defaultStatus && String(defaultStatus.id)) || defaultValues.valueInput,
        valueText: defaultStatus && defaultStatus.name || defaultValues.valueText,
    }
    const statusItems = statusesArray.map((statusItem, index) => (
        <DropdownItem
            key={"STATUS_ITEM_" + index}
            item={statusItem.name}
            valueInput={String(statusItem.id)}
            valueText={statusItem.name}
        />
    ));

    const submitButtonHandler = (event: FormEvent<HTMLFormElement>) => {
        handleSubmit(event);
    }

    const clickCancelButtonHandler = () => {
        closeModalHandler();
    }

    return (
        <ModalForm
            title="Змінити статус студента"
            visiable={visiable}
            maxWidth={490}
            handleClick={closeModalHandler}
        >
            <Wrp flexDirection="column" alignItems="center">
                <Dropdown
                    label="Статус"
                    maxWidth={340}
                    height={40}
                    marginRight={0}
                    marginBottom={30}
                    defaultValue={defaultValueStatus}
                    doubleClickDropdown={false}
                    error={errors.status}
                    touched={touched.status}
                    handleBlur={(touched) => setFieldTouched("status", touched)}
                    handleChange={(value) => {
                        console.log(value)
                        setFieldValue("status", parseInt(value))
                    }}
                >
                    {statusItems}
                </Dropdown>
                <Row maxWidth={340}>
                    <Button
                        disabled={!isValid}
                        maxWidth={160}
                        handleClick={submitButtonHandler}
                        backgroundColor="#4775FE"
                        title="Зберегти"
                        marginBottom={0}
                        marginRight={15}
                    />

                    <Button
                        maxWidth={160}
                        handleClick={clickCancelButtonHandler}
                        backgroundColor="#A6AEC5"
                        title="Скасувати"
                        marginBottom={0}
                        marginRight={0}
                    />
                </Row>
            </Wrp>
        </ModalForm>
    )
}

export default StudentStatusForm;