import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import withMatch from "../../../../components/other/hoc/withMatch";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import GroupClients from "./GroupClients";

const GroupClientsContainer: FC = () => {
    let { id } = useParams();
    const groupId = Number(id);
    const groups = useAppSelector(state => state.groups.pageData, shallowEqual);
    const indexGroup = groups.findIndex(group => group.id === groupId);
    const group = groups[indexGroup];
    const studentsAmount = group?.studentsAmount || 0;
    
    return (
        <GroupClients 
            amountStudents={studentsAmount}
        />
    );
}

export default GroupClientsContainer;