import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks/selector.hook";
import NoMatch from "./NoMatch";

const NoMatchContainer: FC = ({}) => {
    const navigate = useNavigate();
    const userId = useAppSelector(state => state.user.id);
    const isAuth = userId !== null && userId > -1;

    const clickButtonGoHomeHandler = () => {
        navigate("/");
    }

    return (
        <NoMatch 
            isAuth={isAuth}
            handleClick={clickButtonGoHomeHandler} 
        />
    )
}

export default NoMatchContainer;