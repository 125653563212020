import React, { FC, MouseEvent } from "react";
import { PagePagination } from "../../components/dataDisplay/table/models/PagePagination";
import Table from "../../components/dataDisplay/table/Table";
import Row from "../../components/layout/Row/Row";
import Wrp from "../../components/layout/Wrp/Wrp";
import { DATA_INDEX_GROUPS } from "../../utl/constants/tables";
import CategoryFilter from "./components/CategoryFilter";
import StatusFilter from "./components/StatusFilter";
import TypeFilter from "./components/TypeFilter";
import { GroupsColumn } from "./models/GroupsColumn";

interface Props {
    data: Array<GroupsColumn>;
    page: number;
    pages: PagePagination[];
    handleLeftButtonPagination: () => void;
    handleRightButtonPagination: () => void;
    handleClickRow: (item: any, event: MouseEvent<HTMLTableRowElement>) => void;
}

const Groups: FC<Props> = ({data, pages, handleLeftButtonPagination, handleRightButtonPagination, page, handleClickRow}) => {
    return (
        <Wrp maxWidth={1280} flexDirection="column">
            <Row marginBottom={0}>
                <StatusFilter />

                <TypeFilter />
                
                <CategoryFilter />
            </Row>
            <Table 
                handleClickRow={handleClickRow}
                data={data}
                columns={DATA_INDEX_GROUPS} 
                paginated={true}
                page={page}
                pages={pages}
                handleLeftButtonPagination={handleLeftButtonPagination}
                handleRightButtonPagination={handleRightButtonPagination}
            />
        </Wrp>
    );
}

export default Groups;