import { PayloadAction } from "@reduxjs/toolkit";
import getItems from "../../../../helpers/getItems";
import { Group } from "../../models/Group";
import { GroupsState } from "../../models/GroupsState";
import { GetGroupsListForVendorSuccessPayload } from "../../payload/GetGroupsListForVendor/GetGroupsListForVendorSuccessPayload";

const defaultGroupItem: Group = {
    serviceId: -1,
    employeesIds: [],
    name: "",
    locationId: 0,
    studentsMin: 0,
    studentsMax: 0,
    studentsAmount: 0,
    activeStudentsAmount: 0,
    price: 0,
    paymentParts: [],
    notificable: false,
    notificationBeforeLesson: {
        days: 0,
        time: "00:00"
    },
    lessonWeekDays: [],
    startDate: "",
    endDate: "",
    lessons: [],
    upsellsIds: [],
    id: -1,
    active: true,
    confirmed: false,
    online: false,
}

const getGroupsListForVendorSuccess = (state: GroupsState, action: PayloadAction<GetGroupsListForVendorSuccessPayload>) => {
    const { totalCount, items, filter } = action.payload;

    const groups = state.groups;

    state.groups = getItems(
        state,
        groups,
        items,
        totalCount,
        defaultGroupItem,
        filter,
    );

    state.isLoading = false;
    state.isError = null;
}

export default getGroupsListForVendorSuccess;