import moment from "moment";
import React, { FC, MouseEvent } from "react";
import { shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import NameView from "../../components/dataDisplay/table/views/NameView/NameView";
import PriceView from "../../components/dataDisplay/table/views/PriceView/PriceView";
import Avatar from "../../components/dataDisplay/user/Avatar/Avatar";
import ButtonIcon from "../../components/general/buttons/ButtonIcon/ButtonIcon";
import ButtonInfo from "../../components/general/buttons/ButtonInfo/ButtonInfo";
import IconFont from "../../components/general/icons/IconFont/IconFont";
import withMatch from "../../components/other/hoc/withMatch";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { deleteForVendorFetch } from "../../store/state/clients/clients.slice";
import { Filter } from "../../utl/models/Filters";
import ClientsPagination from "./Clients.pagination";
import { ClientsColumn } from "./models/ClientsColumn";

const ClientsContainer: FC = () => {
    let navigation = useNavigate();
    const dispatch = useAppDispatch();
    const clients = useAppSelector(state => state.clients.clients, shallowEqual);
    const clientsData = useAppSelector(state => state.clients.pageData, shallowEqual);
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const data: ClientsColumn[] = clients.map(client => {
        const clientId = client.clientId;

        const clientsDataIndex = clientsData.findIndex(clientData => clientData.clientId === clientId);
        const clientData = clientsData[clientsDataIndex];

        let name = "";
        let email = "";
        let tel = "";

        const users = clientData?.users || [];

        if (users.length > 0) {
            const user = users[0];
            name = `${user.surname} ${user.name}`;
            email = user.email;
            tel = user.phone;
        }

        const services = clientData?.services || [];
        const amountServices = services.length;


        const students = clientData?.students || [];
        const children = students.map(student => student.name).join(", ");
        const countStudents = students.length;

        const price = clientData?.debt || 0;

        const editClickButton = () => {
            navigation(`${clientId}/communication`, {})
        }

        const deleteClickButton = () => {
            const today = new Date();
            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const filter: Filter = {
                period: {
                    from: moment(firstDay).format("YYYY-MM-DD"),
                    to: moment(lastDay).format("YYYY-MM-DD"),
                }
            }

            dispatch(deleteForVendorFetch({ vendorId, clientId, filter }))
        }

        return ({
            id: clientId,
            avatar:
                <Avatar
                    name={name}
                    width={50}
                    height={50}
                    background="#A6AEC5"
                    backgroundName="#A6AEC5"
                    fontSize={18}
                    lineHeight={21}
                    maxHeightImg={"100%"}
                    maxWidthImg={"100%"}
                />,
            clients:
                <NameView
                    name={name}
                    subname={`Учень: ${children}`}
                />,
            countStudents,
            services: amountServices,
            congestion: <PriceView debt={true} price={price} />,
            email:
                <ButtonInfo icon={
                    <IconFont iconClass="icon-email" color="#A6AEC5" fontSize={13} />
                }
                    info={email}
                />,
            tel:
                <ButtonInfo
                    icon={
                        <IconFont iconClass="icon-tel" color="#A6AEC5" fontSize={13} />
                    }
                    info={tel} />,
            edit:
                <ButtonIcon
                    icon={<IconFont color="#A6AEC5" iconClass="icon-edit" fontSize={13} />}
                    handleClick={editClickButton}
                />,
            delete:
                <ButtonIcon
                    width={25}
                    height={25}
                    icon={<IconFont color="#A6AEC5" iconClass="icon-delete" fontSize={12} />}
                    handleClick={deleteClickButton}
                />
        })
    });

    const clickRowTableHandler = (item: any, event: MouseEvent<HTMLTableRowElement>) => {
        event.stopPropagation();
        navigation(`${item.id}/communication`, {})
    }

    return (
        <ClientsPagination
            handleClickRow={clickRowTableHandler}
            data={data}
            vendorId={vendorId}
        />
    );
}

export default withMatch(ClientsContainer, [2, 3, 4]);