import React, { FC, useCallback, useState } from "react";
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import ModalForm from "../../components/dataEntry/forms/ModalForm/ModalForm";
import Wrp from "../../components/layout/Wrp/Wrp";
import CategoryCommentDropdown from "../Clients/ClientForms/AddCommentForm/components/CategoryCommentDropdown/CategoryCommentDropdown";
import Textarea from "../../components/dataEntry/inputs/Textarea/Textarea";
import Button from "../../components/general/buttons/Button/Button";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { setFonVisiable } from "../../store/state/all/all.slice";
import Field from "../../components/dataEntry/inputs/Field/Field";
import Row from "../../components/layout/Row/Row";
import Checkbox from "../../components/dataEntry/inputs/Checkbox/Checkbox";
import Dropdown from "../../components/dataEntry/inputs/Dropdown/Dropdown";
import SearchField from "../../components/dataEntry/inputs/SearchField/SearchField";
import IconFont from "../../components/general/icons/IconFont/IconFont";
import NameEdit from "../../components/dataDisplay/InfoList/NameEdit";

const localizer = momentLocalizer(moment);

interface Props {

} 

const events = [
    {
        title: 'Test 1',
        start: new Date(),
        end: new Date(),
    },
    {
        title: 'Test 1',
        start: new Date(),
        end: new Date(),
    },
    {
        title: 'Test 1',
        start: new Date(),
        end: new Date(),
    }
];

export const Calendar: FC<Props> = () => {
    const dispatch = useAppDispatch()
    const [myEvents, setEvents] = useState(events)
    const [visiable, setVisiable] = useState(false);

    const handleSelectSlot = useCallback(
      ({ start, end }) => {
        setVisiable(true);
        dispatch(setFonVisiable({ visiable: true }));
      },
      [setEvents]
    )
  
    const handleSelectEvent = useCallback(
      (event) => {
        setVisiable(true);
        dispatch(setFonVisiable({ visiable: true }));
      },
      []
    );

    return (
        <div style={{ width: "100%" }}>
            {/* <ModalForm
                title="Створити Подію"
                maxWidth={480}
                visiable={visiable}
                handleClick={() => { 
                    setVisiable(false);
                    dispatch(setFonVisiable({ visiable: false }));
                }}
            >
                <Wrp flexDirection="column" alignItems="center">
                    <Field
                        label="Назва"
                        maxWidth={440}
                        marginRight={0}
                        labelLeftShift={0}
                        value={''}
                        handleBlur={() => console.log("Submit")}
                        handleChange={() => console.log("Submit")}
                        touched={false}
                    />

                    <Textarea
                        maxWidth={440}
                        maxHeight={120}
                        title="Опис"
                        marginBottom={20}
                        value={''}
                        handleBlur={() => console.log("Submit")}
                        handleChange={() => console.log("Submit")}
                        touched={false}
                        error={undefined}
                    />

                    <Button
                        disabled={false}
                        maxWidth={160}
                        handleClick={() => console.log("Submit")}
                        backgroundColor="#4775FE"
                        title="Далі"
                        marginBottom={0}
                        marginRight={15}
                    />
                </Wrp>
            </ModalForm> */}

            {/* <ModalForm
                title="Оберіть Тривалість"
                maxWidth={480}
                visiable={visiable}
                handleClick={() => { 
                    setVisiable(false);
                    dispatch(setFonVisiable({ visiable: false }));
                }}
            >
                <Wrp flexDirection="column" alignItems="center">
                    <Row marginBottom={20} justifyContent="space-between">
                        <Field
                            label="Початок"
                            type="date"
                            maxWidth={210}
                            marginBottom={0}
                            labelLeftShift={0}
                            value={''}
                            handleBlur={() => console.log("Submit")}
                            handleChange={() => console.log("Submit")}
                        />

                        <Field
                            label="Кінець"
                            type="date"
                            maxWidth={210}
                            marginBottom={0}
                            marginRight={0}
                            labelLeftShift={0}
                            value={''}
                            handleBlur={() => console.log("Submit")}
                            handleChange={() => console.log("Submit")}
                        />
                    </Row>

                    <Row marginBottom={20}>
                        <Checkbox
                            handleChange={() => console.log()}
                            text="Зробити повторюваним"
                            marginBottom={20}
                        />
                    </Row>

                    <Row marginBottom={20} justifyContent="center">
                        <Button
                            disabled={false}
                            maxWidth={160}
                            handleClick={() => console.log("Submit")}
                            backgroundColor="#a6aec5"
                            title="Назад"
                            marginBottom={0}
                            marginRight={15}
                        />
                        <Button
                            disabled={false}
                            maxWidth={160}
                            handleClick={() => console.log("Submit")}
                            backgroundColor="#4775FE"
                            title="Далі"
                            marginBottom={0}
                            marginRight={15}
                        />
                    </Row>
                </Wrp>
            </ModalForm> */}

            {/* <ModalForm
                title="Оберіть Викладача"
                maxWidth={480}
                visiable={visiable}
                handleClick={() => { 
                    setVisiable(false);
                    dispatch(setFonVisiable({ visiable: false }));
                }}
            >
                <Wrp flexDirection="column" alignItems="center">
                    <SearchField
                        topPositionIcon={32}
                        paddingLeft={50}
                        icon={<IconFont iconClass="icon-search" fontSize={20} color="#A6AEC5" />}
                        maxWidth={440}
                        label="Викладач"
                        marginBottom={20}
                        labelLeftShift={0}
                        marginRight={0}
                    />

                    <Dropdown
                        maxWidth={440}
                        label="Час"
                        doubleClickDropdown={false}
                        marginBottom={20}
                    >
                        {[]}
                    </Dropdown>

                    <Dropdown
                        label="Курс / Група"
                        maxWidth={440}
                        doubleClickDropdown={false}
                        marginBottom={20}
                    >
                        {[]}
                    </Dropdown>

                    <Row marginBottom={20} justifyContent="center">
                        <Button
                            disabled={false}
                            maxWidth={160}
                            handleClick={() => console.log("Submit")}
                            backgroundColor="#a6aec5"
                            title="Назад"
                            marginBottom={0}
                            marginRight={15}
                        />
                        <Button
                            disabled={false}
                            maxWidth={160}
                            handleClick={() => console.log("Submit")}
                            backgroundColor="#4775FE"
                            title="Далі"
                            marginBottom={0}
                            marginRight={15}
                        />
                    </Row>
                </Wrp>
            </ModalForm> */}

            <ModalForm
                title="Оберіть Студентів"
                maxWidth={480}
                visiable={visiable}
                handleClick={() => { 
                    setVisiable(false);
                    dispatch(setFonVisiable({ visiable: false }));
                }}
            >
                <Wrp flexDirection="column" alignItems="center">
                    <SearchField
                        topPositionIcon={32}
                        paddingLeft={50}
                        icon={<IconFont iconClass="icon-search" fontSize={20} color="#A6AEC5" />}
                        maxWidth={440}
                        label="Клієнт"
                        marginBottom={20}
                        labelLeftShift={0}
                        marginRight={0}
                    />

                    <Row marginBottom={20} justifyContent="center">
                        <Button
                            disabled={false}
                            maxWidth={160}
                            handleClick={() => console.log("Submit")}
                            backgroundColor="#a6aec5"
                            title="Назад"
                            marginBottom={0}
                            marginRight={15}
                        />
                        <Button
                            disabled={false}
                            maxWidth={160}
                            handleClick={() => console.log("Submit")}
                            backgroundColor="#4775FE"
                            title="Створити"
                            marginBottom={0}
                            marginRight={15}
                        />
                    </Row>
                </Wrp>
            </ModalForm>
{/* 
            <ModalForm
                title="Подія: Назва Події"
                maxWidth={480}
                visiable={visiable}
                handleClick={() => { 
                    setVisiable(false);
                    dispatch(setFonVisiable({ visiable: false }));
                }}
            >
                <Wrp flexDirection="column">
                    <NameEdit
                        name={"Налаштувати Подію"}
                        onClick={() => console.log('Налаштувати Подію')}
                        width={200}
                        marginBottom={5}
                    />

                    <NameEdit 
                        name={"Налаштувати Викладача"}
                        onClick={() => console.log('Налаштувати Викладача')}
                        width={200}
                        marginBottom={5}
                    />
                    
                    <NameEdit 
                        name={"Налаштувати Студентів"}
                        onClick={() => console.log('Налаштувати Студентів')}
                        width={200}
                        marginBottom={5}
                    />
                </Wrp>
            </ModalForm> */}

            <BigCalendar
                localizer={localizer}
                events={myEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ 
                    width: "100%", 
                    height: "80vh" 
                }}
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                selectable
                popup
            />
        </div>
    );
}