import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../general/buttons/Button/Button";
import IconFont from "../../general/icons/IconFont/IconFont";

const ButtonHeader = () => {
    let location = useLocation();
    let navigation = useNavigate();
    const path = location.pathname;

    const clickButtonHeaderHendler = () => {
        navigation(`${path}/new`);
    }

    return (
        <Button
            handleClick={clickButtonHeaderHendler} 
            icon={<IconFont iconClass="icon-plus" color="#FFF" fontSize={14} marginTop={3} />} 
            title="Додати" 
            backgroundColor="#4775FE" 
        />
    )
}

export default ButtonHeader;