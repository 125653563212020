import { useEffect } from "react";
import { useAppDispatch } from "../../../../../store/hooks/dispatch.hook";
import { getStudentsListForGroupFetch } from "../../../../../store/state/groups/groups.slice";
import { StudentGroup } from "../../../../../store/state/groups/models/Group";

interface Payload {
    students: StudentGroup[] | undefined;
    vendorId: number;
    groupId: number;
}

const useGetStudentsList = (payload: Payload) => {
    const { students, vendorId, groupId } = payload;

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!students) {
            dispatch(getStudentsListForGroupFetch({ vendorId, groupId }));
        }
    }, [vendorId, groupId]);
}

export default useGetStudentsList;