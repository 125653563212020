import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import NoMatchContainer from "../../../screens/NoMatch/NoMatch.container";
import { useAppSelector } from "../../../store/hooks/selector.hook";

function withMatch(Component: React.ComponentType, roles: number[]) {
    const ComponentWithRedirect: FC = () => {
        const rolesId = useAppSelector(state => state.user.roleId, shallowEqual);
        
        if((rolesId && !roles.includes(rolesId)) || !rolesId) { 
            return (
                <NoMatchContainer />
            );
        }

        return (
            <Component />
        );
    }

    return ComponentWithRedirect;
}

export default withMatch;