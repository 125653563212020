import React, { ChangeEvent, FC } from "react";
import Field from "../../../../../../components/dataEntry/inputs/Field/Field";
import { roundNumber } from "../../../../../../utl/helpers/roundNumber";
import { Lessons } from "../../../../models/GroupsValue";

interface Props {
    lessons: Lessons[];
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const DurationInHoursField: FC<Props> = ({ lessons, setFieldValue }) => {
    const durationInHours = lessons[0].durationInHours;

    const changeDurationInHours = (event: ChangeEvent<HTMLInputElement>) => {
        const durationInHours = event.target.value;

        const lessonsWithDurationInHours = lessons.map((lesson, index) => ({
            ...lesson,
            durationInHours,
        }));

        setFieldValue("lessons", lessonsWithDurationInHours);
    }

    return (
        <Field
            label="Час занять"
            type="number"
            maxWidth={110}
            marginBottom={0}
            labelLeftShift={0}
            value={durationInHours}
            handleChange={changeDurationInHours}
            step={0.25}
            roundNumber={roundNumber}
        />
    );
}

export default DurationInHoursField;