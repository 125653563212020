import { PayloadAction } from "@reduxjs/toolkit";
import { FinanceState } from "../../models/FinanceState";
import { GetFutureMoneySuccessPayload } from "../../payload/GetFutureMoney/GetFutureMoneySuccessPayload";

const getFutureMoneySuccess = (state: FinanceState, action: PayloadAction<GetFutureMoneySuccessPayload>) => {
    const future = action.payload;
   
    state.finance.future = future;
    state.isLoading = true;
    state.isError = null;
}

export default getFutureMoneySuccess;