import { PayloadAction } from "@reduxjs/toolkit";
import createItem from "../../../../helpers/createItem";
import { LocationsState } from "../../models/LocationsState";
import { CreateLocationSuccessPayload } from "../../payload/CreateLocation/CreateLocationSuccessPayload";

const createLocationSuccess = (state: LocationsState, action: PayloadAction<CreateLocationSuccessPayload>) => {
    const { location } = action.payload;

    const locations = state.locationsArray;
    createItem(state, locations, location);

    state.isLoading = true;
    state.isError = null;
    state.message = "Локацію успішно створено";
    state.messageVisiable = true;
    state.newItemId = location.id;
}

export default createLocationSuccess;