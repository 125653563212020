import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Notifications } from "../../../../services/reddyApi/Notifications/Notifications";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { changeNotificationsStatusFetch, changeNotificationsStatusSuccess } from "../notifications.slice";
import { ChangeNotificationsStatusPayload } from "../payload/ChangeNotificationsStatus/ChangeNotificationsStatusPayload";

function* changeNotificationsStatusWorker(action: PayloadAction<ChangeNotificationsStatusPayload>) {
    const { vendorId, statusId, notificationsIds, prevStatusId } = action.payload;
    const notifications = new Notifications();

    try {
        const { status, data } = yield call(notifications.changeNotificationsStatus, { vendorId, statusId, notificationsIds });

        yield put(changeNotificationsStatusSuccess({ statusId, notificationsIds, prevStatusId }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => changeNotificationsStatusFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default changeNotificationsStatusWorker;