import { PayloadAction } from "@reduxjs/toolkit";
import createItem from "../../../../helpers/createItem";
import { FinanceState } from "../../models/FinanceState";
import { CreateTransactionSuccessPayload } from "../../payload/CreateMoneyTransaction/CreateTransactionSuccessPayload";

const createMoneyTransactionSuccess = (state: FinanceState, action: PayloadAction<CreateTransactionSuccessPayload>) => {
    const transaction = action.payload;

    const transactions = state.finance.transactions;
    createItem(state.finance, transactions, transaction);

    state.finance.message = "Транзакцію було створено";
    state.finance.messageVisiable = true;
    state.isLoading = false;
    state.isError = null;
}

export default createMoneyTransactionSuccess;