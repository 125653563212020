import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { User } from "../../../../services/reddyApi/User/User";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { searchUsersForVendorFetch, searchUsersForVendorSuccess } from "../finance.slice";
import { SearchUsersForVendorPayload } from "../payload/SearchUsersForVendor/SearchUsersForVendorPayload";

function* searchUsersForVendorWorker(action: PayloadAction<SearchUsersForVendorPayload>) {
    const { vendorId, value } = action.payload;
    const dataParams = {
        vendorId,
        value
    }

    try {
        const { data } = yield call(User.searchUsersForVendor, dataParams);

        yield put(searchUsersForVendorSuccess(data));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => searchUsersForVendorFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default searchUsersForVendorWorker;