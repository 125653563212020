import { createSlice } from '@reduxjs/toolkit';
import { VendorsState } from './models/VendorsState';
import getVendorsFetchReducer from './reducer/getVendors/getVendorsFetch';
import getVendorsSuccessReducer from './reducer/getVendors/getVendorsSuccess';
import confirmVendorFetchReducer from './reducer/confirmVendor/confirmVendorFetch';
import confirmVendorSuccessReducer from './reducer/confirmVendor/confirmVendorSuccess';
import setErrorReducer from './reducer/setError';
import setActiveDateFormReducer from './reducer/setActiveDateForm';
import setPageVendorsReducer from './reducer/setPageVendors';
import setMessageReducer from './reducer/setMessage';
import setMessageVisiableReducer from './reducer/setMessageVisiable';
import resetVendorsReducer from './reducer/resetVendors';

export const initialState: VendorsState = {
    vendorsArray: [],
    isLoading: false,
    isError: null,
    message: null,
    messageVisiable: false,
    getAllItems: false,
    pageAll: 0,
    pageSize: 10,
    page: 1,
    totalCount: 0,
}

export const VendorsSlice = createSlice({
    name: 'vendors',
    initialState,
    reducers: {
        getVendorsFetch: getVendorsFetchReducer,
        getVendorsSuccess: getVendorsSuccessReducer,
        confirmVendorFetch: confirmVendorFetchReducer,
        confirmVendorSuccess: confirmVendorSuccessReducer,
        setError: setErrorReducer,
        setActiveDateForm: setActiveDateFormReducer,
        setPageVendors: setPageVendorsReducer,
        setMessage: setMessageReducer,
        setMessageVisiable: setMessageVisiableReducer,
        resetVendors: resetVendorsReducer,
    },
});

export const { 
    getVendorsFetch, 
    getVendorsSuccess,
    confirmVendorFetch, 
    confirmVendorSuccess,
    setActiveDateForm,
    setPageVendors,
    setMessage,
    setMessageVisiable,
    resetVendors,
} = VendorsSlice.actions

export default VendorsSlice.reducer