import { PayloadAction } from "@reduxjs/toolkit";
import { ResGetClientStatisticsForVendor } from "../../../../../services/reddyApi/Clients/Clients.response";
import { ClientsState } from "../../models/ClientsState";
import { GetClientStatisticsForVendorSuccessPayload } from "../../payload/GetClientStatisticsForVendor/GetClientStatisticsForVendorSuccessPayload";

const getClientStatisticsForVendorSuccess = (state: ClientsState, actions: PayloadAction<GetClientStatisticsForVendorSuccessPayload>) => {
    const statistics = actions.payload;

    state.statistics = statistics;
    state.isLoading = false;
    state.isError = null;
}

export default getClientStatisticsForVendorSuccess;