import { PayloadAction } from "@reduxjs/toolkit";
import { ServiceState } from "../models/ServiceState";
import { DeleteServicesImagesPayload } from "../payload/DeleteServicesImages/DeleteServicesImagesPayload";

type Payload = Pick<DeleteServicesImagesPayload, "serviceId" | "links">;

const deleteServiceImages = (state: ServiceState, action: PayloadAction<Payload>) => {
    const { serviceId, links } = action.payload;

    const services = state.basics;

    const indexService = services.findIndex(service => service.id === serviceId);

    if(indexService > -1) {
        const service = services[indexService];
        service.images = service.images?.filter(image => links.findIndex(link => link.localeCompare(image) === 0) === -1);;
    }
}

export default deleteServiceImages;