import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Auth } from "../../../../services/reddyApi/Authorization/Authorization";
import { ResetPasswordPayload } from "../payload/ResetPasswordPayload";
import { resetPasswordFailure, resetPasswordSuccess } from "../user.slice";

function* resetPasswordWorker(action: PayloadAction<ResetPasswordPayload>) {
    const { email, password, resetToken } = action.payload;

    try {
        const {status} = yield call(Auth.resetPassword, email, password, resetToken);
        if(status === 200) {
            yield put(resetPasswordSuccess());
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const statusCode = String(error.response?.status);
            const message = String(error.response?.data.error.message);

            if(statusCode === "404") {
                yield put(resetPasswordFailure({error: "Користувача не знайдено"}));
            } else if(statusCode === "400") {
                yield put(resetPasswordFailure({error: "Надіслані дані не вірні"}));
            } else if(statusCode === "401") {
                yield put(resetPasswordFailure({error: "Час життя токена минув"}));
            } else {
                yield put(resetPasswordFailure({error: "Невідома помилка"}));
            }
        }
    }
}

export default resetPasswordWorker;