import { PayloadAction } from "@reduxjs/toolkit";
import getItems from "../../../../../helpers/getItems";
import { Upsell } from "../../models/Upsell";
import { UpsellState } from "../../models/UpsellsState";
import { GetUpsellsListSuccessPayload } from "../../payload/GetUpsellsList/GetUpsellsListSuccessPayload";

const defaultUpsellItem: Upsell = {
    id: -1,
    name: "Upsell",
    description: "",
    price: 0,
    active: true,
    confirmed: false,
}

const getAdditionalListSuccess = (state: UpsellState, action: PayloadAction<GetUpsellsListSuccessPayload>) => {
    const { items, filter } = action.payload;
    const upsells = state.upsells;

    const totalCount = 0;
    state.upsells = getItems(
        state,
        upsells,
        items,
        totalCount,
        defaultUpsellItem,
        filter,
    );

    state.isLoading = false;
    state.isError = null;
}

export default getAdditionalListSuccess;