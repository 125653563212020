import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Groups } from "../../../../services/reddyApi/Groups/Groups";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { getStatisticsDashboard, getStatisticsFinance, getStatisticsGroup } from "../../statistics/statistics.saga";
import { getStudentsListForGroupFetch, updateStudentForGroupFetch, updateStudentForGroupSuccess } from "../groups.slice";
import { UpdateStudentForGroupPayload } from "../payload/UpdateStudentForGroup/UpdateStudentForGroupPayload";

function* updateStudentForGroupWorker(action: PayloadAction<UpdateStudentForGroupPayload>) {
    const { vendorId, groupId, studentData, studentId, filter } = action.payload;

    try {
        const { data } = yield call(Groups.updateStudentForGroup, { vendorId, groupId, studentId, studentData });

        if (studentData) {
            yield put(updateStudentForGroupSuccess({ studentData, groupId, studentId }));

            yield getStatisticsGroup({ vendorId, groupId });

            yield put(getStudentsListForGroupFetch({ vendorId, groupId, filter }));

            yield getStatisticsDashboard({ vendorId, filter });

            yield getStatisticsFinance({ vendorId, filter });

            yield getNotifications(vendorId);

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => updateStudentForGroupFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default updateStudentForGroupWorker;