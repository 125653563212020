import * as Yup from 'yup';
import { DateApi } from '../../services/Date/Date';
import { TODAY } from '../../utl/constants/date';

export default Yup.object().shape({
    confirmedTo: Yup.date()
        .transform(DateApi.parseDateString)
        .min(TODAY)
        .required("Заповніть це поле"),
    confirmedFrom: Yup.date()
        .transform(DateApi.parseDateString)
        .min(TODAY)
        .required("Заповніть це поле"),
});