import React, { FC } from "react";
import ButtonIcon from "../ButtonIcon/ButtonIcon";
import styles from "./ButtonNotifications.module.css";
import classNames from "classnames";
import IconFont from "../../icons/IconFont/IconFont";
const cx = classNames.bind(styles);

interface Props {
    width?: number;
    height?: number;
    handleClick: () => void;
    iconClass: string;
    active: boolean;
    type: "message" | "nitification";
}

const ButtonNotifications: FC<Props> = ({handleClick, width = 30, height = 30, iconClass, active, type}) => {
    return (
        <div 
            className={
                cx(
                    styles.buttonNotifications, 
                    active && styles.active, 
                    type.localeCompare("nitification") === 0 ? styles.nitification : styles.message,
                )
            }
            style={{width, height}}
        >
            <ButtonIcon 
                icon={<IconFont color="#A6AEC5" iconClass={iconClass} />} 
                handleClick={handleClick} 
            />
        </div>
    );
}

export default ButtonNotifications;