import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Auth } from "../../../../services/reddyApi/Authorization/Authorization";
import { User } from "../../../../services/reddyApi/User/User";
import token from "../../../../services/Token/token";
import { UserLoginPayload } from "../payload/UserLoginPayload";
import { getUserDataFailure, getUserDataSuccess, resetPasswordFailure, setTokenStatus } from "../user.slice";

function* loginWorker(action: PayloadAction<UserLoginPayload>) {
    const { email, password, actionCreator } = action.payload;

    try {
        if (email && password) {
            const { data } = yield call(Auth.loginUser, email, password);
            const { refreshToken, accessToken } = data;

            token.addHeaderToken(refreshToken, accessToken);
            yield put(setTokenStatus({ status: true }));
        }

        const { data: { user: { id, roleId, fullname, roleName, image, vendorId } } } = yield call(User.getStatusUser);

        yield put(getUserDataSuccess({
            id, roleId, name: fullname,
            roleName, vendorId
        }));
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const statusCode = String(error.response?.status);
            const message = String(error.response?.data.error.message);
            if (statusCode === "401" && message.localeCompare("Unauthorized") === 0) {
                try {
                    token.removeAccessToken();
                    const { data, status } = yield call(Auth.setRefreshToken);
                    const { refreshToken, accessToken } = data;

                    if (status === 200) {
                        token.addHeaderToken(refreshToken, accessToken);

                        const { data: { user: { id, roleId, fullname, roleName, image, vendorId } } } = yield call(User.getStatusUser);

                        yield put(getUserDataSuccess({
                            id, roleId,
                            name: fullname,
                            roleName: roleName, vendorId
                        }));

                        yield put(setTokenStatus({ status: true }));

                        if (actionCreator) {
                            yield put(actionCreator());
                        }
                    }
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        const statusCode = String(error.response?.status);
                        if (statusCode === "400") {
                            yield put(getUserDataFailure({ error: "Надіслані дані не вірні" }));
                        } else if (statusCode === "401") {
                            yield put(setTokenStatus({ status: false }));
                            yield put(getUserDataFailure({ error: "Час життя токена минув" }));
                        } else {
                            yield put(getUserDataFailure({ error: "Невідома помилка" }));
                        }
                    }
                }

            } else if (statusCode === "401") {
                yield put(getUserDataFailure({ error: "Ви ввели неправильний email або пароль" }));
            } else if (statusCode === "400") {
                yield put(getUserDataFailure({ error: "Ви ввели неправильний email або пароль" }));
            } else {
                yield put(resetPasswordFailure({ error: "Невідома помилка" }));
            }
        }
    }
}

export default loginWorker;