import React, { FC } from "react";
import styles from "./Notification.module.css";
import classNames from "classnames";
import Checkbox from "../../../dataEntry/inputs/Checkbox/Checkbox";
import IconFont from "../../../general/icons/IconFont/IconFont";

const cx = classNames.bind(styles);

interface Props {
    icon: JSX.Element;
    backgroundColor?: string;
    marginBottom?: number;
    notification: JSX.Element;
    datatime: string;
    origin: string;
    selected?: boolean;
    clickSelectButtonHandler?: () => void;
    active?: boolean;
    clickActiveButtonHandler?: () => void;
    displayCheckbox?: boolean;
    checked?: boolean;
}

const Notification: FC<Props> = ({ backgroundColor = "#FFF", icon, marginBottom = 15, notification, datatime, origin,
    selected, clickSelectButtonHandler, active, clickActiveButtonHandler, displayCheckbox }) => {

    const iconClassArchive = active ? "icon-vector-8" : "icon-vector-19";
    const iconClassSelected = selected ? "icon-star" : "icon-star-empty"
    return (
        <div className={styles.notification} style={{ backgroundColor, marginBottom }}>
            {clickSelectButtonHandler && <button
                onClick={clickSelectButtonHandler}
                className={cx(styles.star)}
            >
                <IconFont iconClass={iconClassSelected} color="#A6AEC5" fontSize={17} />
            </button>
            }
            {clickActiveButtonHandler && <button
                onClick={clickActiveButtonHandler}
                className={cx(styles.eye, displayCheckbox && styles.small)}
            >
                <IconFont iconClass={iconClassArchive} color="#A6AEC5" fontSize={15} />
            </button>
            }
            {
                displayCheckbox && <div className={styles.checkbox}><Checkbox /></div>
            }
            <div className={styles.icon}>
                {icon}
            </div>
            <div className={styles.content}>
                <div className={styles.notificationContent}>
                    {notification}
                </div>
                <p className={styles.source}>
                    {datatime} - {origin}
                </p>
            </div>
        </div>
    );
}

export default Notification;