import moment from "moment";
import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { getClientStatisticsForVendorFetch } from "../../store/state/clients/clients.slice";
import { getFutureMoneyFetch, getMoneyPursesFetch, getPlannedMoneyFetch, getVendorMoneyTransactionsFetch } from "../../store/state/finance/finance.slice";
import { SYSTEM_ADMIN_ID } from "../../utl/constants/role";
import { culcPage } from "../../utl/helpers/culcPage";

function withLoadingFinance(Component: React.ComponentType) {
    const ComponentWithLoadingFinance: FC = () => {
        const dispatch = useAppDispatch();
        const page = useAppSelector(state => state.finance.finance.page, shallowEqual);
        const totalCount = useAppSelector(state => state.finance.finance.totalCount, shallowEqual);
        const pageAll = useAppSelector(state => state.finance.finance.pageAll, shallowEqual);
        const pageSize = useAppSelector(state => state.finance.finance.pageSize, shallowEqual);
        const getAllItems = useAppSelector(state => state.finance.finance.getAllItems, shallowEqual);
        const pagesRange = culcPage(page, pageAll, totalCount, pageSize);
        const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
        const roleId = useAppSelector(state => state.user.roleId, shallowEqual) || -1;
        const today = new Date();
        
        useEffect(() => {
            if(roleId > SYSTEM_ADMIN_ID) {
                let filter = undefined;
                
                if(!getAllItems) {
                    filter = {
                        range: {
                            from: pagesRange.from,
                            to: pagesRange.to
                        }
                    }  
                }

                const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

                const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        
                dispatch(getVendorMoneyTransactionsFetch({vendorId, filter}));
                dispatch(getMoneyPursesFetch({vendorId}));
                dispatch(getFutureMoneyFetch({vendorId, filter: {
                    period: {
                        from: moment(firstDay).format("YYYY-MM-DD"),
                        to: moment(lastDay).format("YYYY-MM-DD"),
                    }
                }}));
                dispatch(getPlannedMoneyFetch({vendorId, filter: {
                    period: {
                        from: moment(firstDay).format("YYYY-MM-DD"),
                        to: moment(lastDay).format("YYYY-MM-DD"),
                    }
                }}));
                dispatch(getClientStatisticsForVendorFetch({vendorId, filter: {
                    period: {
                        from: moment(firstDay).format("YYYY-MM-DD"),
                        to: moment(lastDay).format("YYYY-MM-DD"),
                    }
                }}));
            }
        }, [vendorId, roleId]);

        return (
            <Component />
        );
    }

    return ComponentWithLoadingFinance;
}

export default withLoadingFinance;