import { ConfirmVendorRequest, GetVendorsRequest } from './Vendors.request';
import { GetVendorsResponse, ConfirmVendorResponse } from './Vendors.response';
import instance from '../instance';
import { AxiosResponse } from 'axios';

interface Vendors {
    getVendors: (data: GetVendorsRequest) => Promise<AxiosResponse<GetVendorsResponse, any>>;
    confirmVendor: (date: ConfirmVendorRequest) => Promise<AxiosResponse<ConfirmVendorResponse, any>>;
}

export const Vendors: Vendors = {
    getVendors: (data) => {
        const {filter} = data;
        
        return instance.get("/support/vendors", {params: {
            rangeFrom: filter?.range?.from, 
            rangeTo: filter?.range?.to
        }});
    },
    confirmVendor: ({vendorId, confirmedTo, confirmedFrom}) => {
        return instance.put(`/support/vendors/${vendorId}/confirm`, {confirmedFrom, confirmedTo});
    },
}