import React, { FC } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./Paragraph.module.css";

interface Props {
    content: string;
    color?: string;
    marginBottom?: number;
    textAlign?: "center" | "left" | "right";
    fontSize?: number;
    fontWeight?: string;
    lineHeight?: number;
    visiable?: boolean;
}

const Paragraph: FC<Props> = ({ content, color = "#131313", marginBottom, textAlign,
    fontSize = 14, fontWeight = "500", lineHeight = 16, visiable = true }) => {

    return (
        <CSSTransition
            in={visiable}
            timeout={400}
            unmountOnExit
            classNames="paragraph"
        >
            <p
                style={{ fontWeight, lineHeight: `${lineHeight}px`, marginBottom, color, textAlign, fontSize }}
                className={styles.paragraph}
            >
                {content}
            </p>
        </CSSTransition>
    );
}

export default Paragraph;