import React, { FC } from "react";
import styles from "./DataBlock.module.css";

interface Props {
    maxWidth?: number;
    children: JSX.Element[] | JSX.Element;
}

const DataBlock: FC<Props> = ({children, maxWidth}) => {
    return (
        <div style={{maxWidth}} className={styles.data}>
            {children}
        </div>
    );
}

export default DataBlock;