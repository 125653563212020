import React, { FC, ChangeEvent, FocusEvent, useState, useEffect, MouseEvent, KeyboardEvent, useRef } from "react";
import { isValid } from "../../../../utl/helpers/isValid";
import Position from "../../../layout/position/Position";
import styles from "./Field.module.css";
import classNames from "classnames";
import "./transition.css";
import { CSSTransition } from "react-transition-group";
import useDebounce from "../../../../utl/hooks/useDebounce";

const cx = classNames.bind(styles);

interface PropsField {
    button?: JSX.Element;
    type?: string;
    label?: string;
    icon?: JSX.Element;
    value?: string | number;
    handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    handleBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    handleFocus?: (event: FocusEvent<HTMLInputElement>) => void;
    backgroundColor?: string;
    colorText?: string;
    error?: string | undefined;
    touched?: boolean | undefined;
    isRequired?: boolean;
    maxWidth?: number | string;
    marginLeft?: number;
    marginRight?: number;
    marginBottom?: number;
    marginTop?: number;
    labelLeftShift?: number;
    info?: JSX.Element | JSX.Element[];
    topPositionIcon?: number | string;
    paddingLeft?: number;
    positionTopMessage?: number;
    searchList?: string[];
    keyIndex?: string;
    setFieldValue?: (value: string | number) => void;
    mask?: string;
    step?: number;
    roundNumber?: (value: number) => number;
    discount?: number;
    culcDiscount?: boolean;
}

const Field: FC<PropsField> = ({ type = "text", value, handleBlur, handleChange, label, error, touched,
    backgroundColor = "#FFF", colorText = "#131313", isRequired = true, maxWidth, icon,
    marginLeft = 0, marginBottom = 20, marginTop = 0, marginRight = 20, labelLeftShift = 15,
    button, info, handleFocus, topPositionIcon = "55%", paddingLeft = 30, positionTopMessage = -5,
    searchList, keyIndex, setFieldValue, mask, step, roundNumber, discount, culcDiscount }) => {
    const valid = isValid(error, touched);
    const isHidden = type === "hidden";
    const [toggle, setToggle] = useState<boolean>(false);
    const [keyDeletePress, setKeyDeletePress] = useState(false);
    const [zIndexValues, setZIndexValues] = useState({
        zIndexBlock: 5,
        zIndexInput: 4,
        zIndexList: 3,
    });
    const input = useRef<HTMLInputElement | null>(null);
    const [list, setList] = useState<JSX.Element[] | undefined>([]);
    const [discountValue, setDiscountValue] = useState<number>(0);
    const [changed, setChanged] = useState(false);

    const timeout = discount ? 700 : 500;
    const debouncedValue = useDebounce(value, timeout);

    const blurInputHandler = (event: FocusEvent<HTMLInputElement>) => {
        setToggle(false);

        if (list && list.length < 1) {
            setZIndexValues({ zIndexInput: 4, zIndexList: 3, zIndexBlock: 5 });
        }

        if (handleBlur) {
            handleBlur(event);
        }
    }

    const changeInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const targetValue = event.target.value

        if (mask) {
            const value = targetValue.replaceAll(/(^[+]38)|\D/g, '');
            const match = value.match(/^(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
            if (match) {

                let phone = "+38 (";
                phone += match[1] ? match[1] : "";
                phone += match[2] ? ") " + match[2] : "";
                phone += match[3] ? "-" + match[3] : "";
                phone += match[4] ? "-" + match[4] : "";

                event.target.value = phone;
            }
        }

        if (roundNumber) {
            const number = parseFloat(targetValue);

            event.target.value = String(roundNumber(number));
        }

        if (handleChange) {
            handleChange(event);
        }

        setChanged(true);
    }

    const clickKeyboardHandler = (event: KeyboardEvent<HTMLElement>) => {
        setKeyDeletePress(event.key === "Backspace");
    }

    const focusInputHandler = (event: FocusEvent<HTMLInputElement>) => {
        if (handleFocus)
            handleFocus(event);
    }

    const clickFieldHandler = (event: MouseEvent<HTMLElement>) => {
        setZIndexValues({ zIndexInput: 7, zIndexList: 6, zIndexBlock: 8 });
    }

    useEffect(() => {
        const searchParams = searchList && debouncedValue !== "" && searchList.filter(item => {
            const searchValue = String(debouncedValue).toUpperCase();
            const itemValue = item.toUpperCase();

            return itemValue.includes(searchValue) && itemValue !== searchValue;
        }) || [];

        const searchItems = searchParams && searchParams.map((item, index) => {
            const clickSearchListItemHandler = (event: MouseEvent<HTMLElement>) => {
                if (setFieldValue) {
                    setFieldValue(item);
                }
            }

            return (
                <li
                    key={index + "_" + "SEARCH_ITEM_" + keyIndex}
                    className={styles.serchListItem}
                    onClick={clickSearchListItemHandler}
                >
                    {item}
                </li>
            );
        });

        setList(searchItems);
        setToggle(searchItems.length > 0);

        if (debouncedValue
            && discount !== undefined
            && setFieldValue
            && changed
            && culcDiscount
        ) {
            const price = Number(debouncedValue);
            let discountPrice = price * (discount / 100);
            discountPrice = parseFloat(discountPrice.toFixed(1));
            setFieldValue(price - discountPrice);
            setDiscountValue(discountPrice);
        }

        setChanged(false);
    }, [debouncedValue]);

    return (
        <label
            onClick={clickFieldHandler}
            className={styles.label}
            style={{
                maxWidth, marginLeft, marginBottom, marginTop, marginRight,
                visibility: isHidden ? "hidden" : "visible",
                position: isHidden ? "absolute" : "relative",
                zIndex: zIndexValues.zIndexBlock,
            }}
        >
            {label && <p
                style={{ marginLeft: labelLeftShift }}
                className={cx(styles.name)}
            >
                {label}
            </p>}
            {icon && <Position zIndex={zIndexValues.zIndexInput + 1} type="absolute" top={topPositionIcon} left={20}>
                {icon}
            </Position>
            }
            {button && <Position zIndex={zIndexValues.zIndexInput + 1} type="absolute" top={label ? 13 : 8} right={7}>
                {button}
            </Position>
            }

            {info && <Position zIndex={zIndexValues.zIndexInput + 1} type="absolute" top={0} bottom={0} right={0}>
                <div className={styles.info}>{info}</div>
            </Position>
            }

            <div className={styles.inputBlock}>
                <input
                    ref={input}
                    min={0}
                    step={step}
                    type={type}
                    className={cx(styles.field, !valid && styles.error, icon && styles.icon, button && styles.button)}
                    style={{
                        backgroundColor,
                        color: colorText,
                        paddingLeft: icon ? paddingLeft : undefined,
                        zIndex: zIndexValues.zIndexInput
                    }}
                    value={value}
                    onClick={clickFieldHandler}
                    onKeyDown={clickKeyboardHandler}
                    onFocus={focusInputHandler}
                    onChange={changeInputHandler}
                    onBlur={blurInputHandler}
                    placeholder={mask}
                />

                <CSSTransition
                    in={discount !== undefined}
                    timeout={400}
                    unmountOnExit
                    classNames="search-list"
                >
                    <Position
                        zIndex={zIndexValues.zIndexInput + 1}
                        type="absolute"
                        top={0}
                        right={0}
                    >
                        <div className={styles.discountBlock}>
                            <div className={styles.discountIcon}>
                                %
                            </div>
                            {culcDiscount ?
                                <span className={styles.discount}>
                                    ₴ {discountValue}
                                </span>
                                :
                                <span className={styles.discount}>
                                    {discount}
                                </span>
                            }
                        </div>
                    </Position>
                </CSSTransition>
            </div>
            {/* {isRequired && <Position zIndex={zIndexValues.zIndexInput} type="absolute" top={positionTopMessage} left={"55%"}>
                <MessageInfo 
                    borderBottomLeftRadius={0} 
                    borderBottomRightRadius={10} 
                    borderTopLeftRadius={10} 
                    borderTopRightRadius={10} 
                    text={error || ""} 
                    visiable={!valid} 
                />
            </Position>} */}
            {
                <CSSTransition
                    in={toggle}
                    timeout={400}
                    unmountOnExit
                    classNames="search-list"
                >
                    <>{<ul
                        style={{ zIndex: zIndexValues.zIndexList }}
                        className={styles.serchList}
                    >
                        {list}
                    </ul>}</>
                </CSSTransition>
            }
        </label>
    );
}

export default Field;