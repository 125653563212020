import React, { FC, useState } from "react";
import styles from "./LessonView.module.css";
import classNames from "classnames";

const cx = classNames.bind(styles);

interface Props {
    date: string;
    nameLesson: string;
    attended: boolean;
}

const LessonView: FC<Props> = ({ date, nameLesson, attended }) => {

    return (
        <div className={styles.lessonBlock}>
            <p className={styles.date}>
                <span className={cx("icon-date", styles.iconDate)}></span>
                {date}
            </p>
            <p className={styles.nameLesson}>
                {nameLesson}
            </p>
            <div className={cx(styles.iconVisit, !attended && styles.false)}></div>
        </div>
    )
}

export default LessonView;