import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Money } from "../../../../services/reddyApi/Money/Money";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getCategoriesFetch, getCategoriesSuccess } from "../money.slice";
import { GetCategoriesPayload } from "../payload/GetCategories/GetCategoriesPayload";

function* getCategoriesWorker(action: PayloadAction<GetCategoriesPayload>) {
    const { vendorId } = action.payload;

    try {
        const { data } = yield call(Money.getCategories, { vendorId });
        const { purses, operations, discounts } = data;

        yield put(getCategoriesSuccess({ purses, operations, discounts }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getCategoriesFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getCategoriesWorker;