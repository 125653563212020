import { PayloadAction } from "@reduxjs/toolkit";
import { LocationsState } from "../../models/LocationsState";
import { UploadLocationImagesSuccessPayload } from "../../payload/UploadLocationImages/UploadLocationImagesSuccessPayload";

const uploadLocationImagesSuccess = (state: LocationsState, action: PayloadAction<UploadLocationImagesSuccessPayload>) => {
    const { locationId, links } = action.payload;

    const locations = state.locationsArray;

    const indexLocation = locations.findIndex(location => location.id === locationId);

    if(indexLocation > -1) {
        const location = locations[indexLocation];
        location.images = links;
    }
}

export default uploadLocationImagesSuccess;