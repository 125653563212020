import { takeLatest } from "redux-saga/effects";
import createMoneyTransactionWorker from "./createMoneyTransactionWorker";
import createMoneyTransferWorker from "./createMoneyTransferWorker";
import getFutureMoneyWorker from "./getFutureMoneyWorker";
import getMoneyPursesWorker from "./getMoneyPursesWorker";
import getPlannedMoneyWorker from "./getPlannedMoneyWorker";
import getVendorMoneyTransactionsWorker from "./getVendorMoneyTransactionsWorker";
import searchCounteragentsAsClientsWorker from "./searchCounteragentsAsClientsWorker";
import searchCounteragentsWorker from "./searchCounteragentsWorker";
import searchUsersForVendorWorker from "./searchUsersForVendorWorker";

export function* financeWatcher() {
    yield takeLatest("finance/createMoneyTransactionFetch", createMoneyTransactionWorker);
    yield takeLatest("finance/createMoneyTransferFetch", createMoneyTransferWorker);
    yield takeLatest("finance/getVendorMoneyTransactionsFetch", getVendorMoneyTransactionsWorker);
    yield takeLatest("finance/searchUsersForVendorFetch", searchUsersForVendorWorker);
    yield takeLatest("finance/getMoneyPursesFetch", getMoneyPursesWorker);
    yield takeLatest("finance/getFutureMoneyFetch", getFutureMoneyWorker);
    yield takeLatest("finance/getPlannedMoneyFetch", getPlannedMoneyWorker);
    yield takeLatest("finance/searchCounteragentsFetch", searchCounteragentsWorker);
    yield takeLatest("finance/searchCounteragentsAsClientsFetch", searchCounteragentsAsClientsWorker);
}