import React, { FC } from "react";
import { useFormik } from "formik";
import validationSchema from "../../Vendors.scheme";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { confirmVendorFetch, setActiveDateForm } from "../../../../store/state/vendors/vendors.slice";
import { setFonVisiable } from "../../../../store/state/all/all.slice";
import { VendorsValues } from "../../models/VendorsValues";
import VendorsModal from "./VendorsModal";

interface Props {
    vendorId: number;
    active: boolean;
    confirmedFrom: string;
    confirmedTo: string;
}

const VendorsModalContainer: FC<Props> = ({ vendorId, active, confirmedFrom, confirmedTo }) => {
    const dispatch = useAppDispatch();

    const initialValues: VendorsValues = {
        confirmedFrom,
        confirmedTo
    }

    const submitHandler = (values: VendorsValues) => {
        dispatch(confirmVendorFetch(
            { vendorId, confirmedTo: values.confirmedTo, confirmedFrom: values.confirmedFrom }
        ));
        dispatch(setActiveDateForm({ vendorId, active: false }));
        dispatch(setFonVisiable({ visiable: false }));
    }

    const formik = useFormik<VendorsValues>({
        initialValues,
        validationSchema,
        onSubmit: submitHandler
    });

    const closeModalHandler = () => {
        dispatch(setFonVisiable({ visiable: false }));
        dispatch(setActiveDateForm({ vendorId, active: false }));
    }

    return (
        <VendorsModal
            formik={formik}
            closeModalHandler={closeModalHandler}
            active={active}
        />
    )
}

export default VendorsModalContainer;