import React, { ChangeEvent, FC } from "react";
import styles from "./RadioBlock.module.css";

export interface Props {
    name: string;
    leftCol: JSX.Element;
    rightCol: JSX.Element;
    handleChange: (value: any) => void; 
    value: any;
}

const RadioBlock: FC<Props> = ({ name, leftCol, rightCol, handleChange, value}) => {
    const changeValueHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.currentTarget.checked;
        if(handleChange && checked) {
            handleChange(value);
        }
    }

    return (
        <div className={styles.radioBlock}>
            <div className={styles.leftCol}>
                {leftCol}
            </div>

            <div className={styles.leftCol}>
                <label className={styles.radioLabel}>
                    <input
                        onChange={changeValueHandler}
                        className={styles.radioInput}
                        type="radio"
                        name={name}
                    />
                    <div className={styles.radio}></div>
                </label>

                {rightCol}
            </div>
        </div>
    );
}

export default RadioBlock;