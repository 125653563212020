import { createSlice } from '@reduxjs/toolkit';
import { LocationsState } from './models/LocationsState';
import getLocationsDataFetchReducer from './reducer/getLocationsData/getLocationsDataFetch';
import getLocationsDataSuccessReducer from './reducer/getLocationsData/getLocationsDataSuccess';
import setPageLocationsReducer from './reducer/setPageLocations';
import createLocationFetchReducer from './reducer/createLocation/createLocationFetch';
import createLocationSuccessReducer from './reducer/createLocation/createLocationSuccess';
import updateLocationFetchReducer from './reducer/updateLocation/updateLocationFetch';
import updateLocationSuccessReducer from './reducer/updateLocation/updateLocationSuccess';
import deleteLocationFetchReducer from './reducer/deleteLocation/deleteLocationFetch';
import deleteLocationSuccessReducer from './reducer/deleteLocation/deleteLocationSuccess';
import setErrorReducer from './reducer/setError';
import setMessageReducer from './reducer/setMessage';
import setMessageVisiableReducer from './reducer/setMessageVisiable';
import setNewItemIdReducer from './reducer/setNewItemId';
import resetLocationsReducer from './reducer/resetLocations';
import changeLocationActivityFetchReducer from './reducer/changeLocationActivity/changeLocationActivityFetch';
import changeLocationActivitySuccessReducer from './reducer/changeLocationActivity/changeLocationActivitySuccess';
import getLocationFetchReducer from './reducer/getLocation/getLocationFetch';
import getLocationSuccessReducer from './reducer/getLocation/getLocationSuccess';
import uploadLocationImagesFetchReducer from './reducer/uploadLocationImages/uploadLocationImagesFetch';
import uploadLocationImagesSuccessReducer from './reducer/uploadLocationImages/uploadLocationImagesSuccess';
import deleteLocationImagesReducer from './reducer/deleteLocationImages';

export const initialState: LocationsState = {
    locationsArray: [],
    isLoading: false,
    isError: null,
    message: null,
    messageVisiable: false,
    newItemId: null,
    page: 1,
    pageAll: 0,
    pageSize: 5,
    totalCount: 0,
    getAllItems: true,
}

export const LocationsSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        getLocationsDataFetch: getLocationsDataFetchReducer,
        getLocationsDataSuccess: getLocationsDataSuccessReducer,
        setPageLocations: setPageLocationsReducer,
        createLocationFetch: createLocationFetchReducer,
        createLocationSuccess: createLocationSuccessReducer,
        updateLocationFetch: updateLocationFetchReducer,
        updateLocationSuccess: updateLocationSuccessReducer,
        deleteLocationFetch: deleteLocationFetchReducer,
        deleteLocationSuccess: deleteLocationSuccessReducer,
        setError: setErrorReducer,
        setMessage: setMessageReducer,
        setMessageVisiable: setMessageVisiableReducer,
        setNewItemId: setNewItemIdReducer,
        resetLocations: resetLocationsReducer,
        changeLocationActivityFetch: changeLocationActivityFetchReducer,
        changeLocationActivitySuccess: changeLocationActivitySuccessReducer,
        getLocationFetch: getLocationFetchReducer,
        getLocationSuccess: getLocationSuccessReducer,
        uploadLocationImagesFetch: uploadLocationImagesFetchReducer,
        uploadLocationImagesSuccess: uploadLocationImagesSuccessReducer,
        deleteLocationImages: deleteLocationImagesReducer,
    },
});

export const {
    getLocationsDataFetch,
    getLocationsDataSuccess,
    setError,
    createLocationFetch,
    createLocationSuccess,
    updateLocationFetch,
    updateLocationSuccess,
    deleteLocationFetch,
    deleteLocationSuccess,
    setMessage,
    setMessageVisiable,
    setNewItemId,
    setPageLocations,
    resetLocations,
    changeLocationActivityFetch,
    changeLocationActivitySuccess,
    getLocationFetch,
    getLocationSuccess,
    uploadLocationImagesFetch,
    uploadLocationImagesSuccess,
    deleteLocationImages,
} = LocationsSlice.actions;

export default LocationsSlice.reducer;