import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Employees } from "../../../../services/reddyApi/Employees/Employees";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getEmployeesListFetch, getEmployeesListSuccess } from "../employees.slice";
import { GetEmployeesListPayload } from "../payload/GetEmployeesList/GetEmployeesListPayload";

function* getEmployessWorker(action: PayloadAction<GetEmployeesListPayload>) {
    const { vendorId, filter } = action.payload;

    try {
        const { data } = yield call(Employees.getEmployeesList, { vendorId, filter });
        const { items, totalCount } = data;

        yield put(getEmployeesListSuccess({ items, totalCount, filter }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getEmployeesListFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getEmployessWorker;