import React, { FC } from "react";
import Button from "../../../../components/general/buttons/Button/Button";
import IconFont from "../../../../components/general/icons/IconFont/IconFont";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { setFonVisiable } from "../../../../store/state/all/all.slice";
import { setVisiableCommentForm } from "../../../../store/state/clients/clients.slice";

const AddCommentButton: FC = () => {
    const dispatch = useAppDispatch();

    const clickButtonHandler = () => {
        dispatch(setVisiableCommentForm({visiable: true}));
        dispatch(setFonVisiable({visiable: true}));
    }

    return (
        <Button
            backgroundColor="#FA5D54" 
            title="Коментар" 
            icon={<IconFont iconClass="icon-plus" color="#FFF" fontSize={14} />} 
            handleClick={clickButtonHandler} 
            marginRight={0}
        />
    );
}

export default AddCommentButton;