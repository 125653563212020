import * as Yup from 'yup';

export default Yup.object().shape({
    email: Yup.string()
        .email('Invalid email').required('Заповніть це поле').max(255, "Максимальна кількість символів досягнута"),
    name: Yup.string()
        .required('Заповніть це поле')
        .max(255, "Максимальна кількість символів досягнута"),
    vendorName: Yup.string()
        .required('Заповніть це поле')
        .max(255, "Максимальна кількість символів досягнута"),
    tel: Yup.string()
        .required('Заповніть це поле')
        .max(20, "Максимальна кількість символів досягнута"),
    password: Yup.string()
        // .matches(/\w*[a-z]\w*/,  "")
        // .matches(/\w*[A-Z]\w*/,  "")
        // .matches(/\d/, "")
        .min(5, ({ min }) => `Мінімальна кiлькість символів ${min}`)
        .required('Заповніть це поле')
        .max(255, "Максимальна кількість символів досягнута"),
    passwordCopy: Yup.string()
        .oneOf([Yup.ref('password')], "Проли не совпадают")
        .required("Заповніть це поле")
        .max(255, "Максимальна кількість символів досягнута"),
});

