import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Locations } from "../../../../services/reddyApi/Locations/Locations";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { deleteLocationFetch, deleteLocationSuccess } from "../locations.slice";
import { DeleteLocationPayload } from "../payload/DeleteLocation/DeleteLocationPayload";

function* deleteLocationWorker(action: PayloadAction<DeleteLocationPayload>) {
    const {id, vendorId} = action.payload;
    const deleteData = {id, vendorId};
    
    try {
        const {status} = yield call(Locations.delete, deleteData);
       
        if(status === 200) {
            yield put(deleteLocationSuccess({id}));

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => deleteLocationFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default deleteLocationWorker;