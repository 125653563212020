import { FormikProps } from "formik";
import React, { ChangeEvent, FC } from "react";
import Button from "../../components/general/buttons/Button/Button";
import AuthForm from "../../components/dataEntry/forms/AuthForm/AuthForm";
import Field from "../../components/dataEntry/inputs/Field/Field";
import CenterLayout from "../../components/layout/CenterLayout/CenterLayout";
import Margin from "../../components/layout/Margin/Margin";
import Position from "../../components/layout/position/Position";
import LinkText from "../../components/general/typography/LinkText/LinkText";
import { SignInValues } from "./models/SignInValues";

interface Props {
    formik: FormikProps<SignInValues>;
    clickNavButtonHandler: () => void;
    error: string | null;
    disabledError: () => void;
}

const SignIn: FC<Props> = ({ formik, clickNavButtonHandler, error, disabledError }) => {
    const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit } = formik;
   
    const clickButtomSubmitHandler = () => {
        handleSubmit();
    }

    const changeEmailHandler = (event: ChangeEvent<HTMLInputElement>) => {
        disabledError();
        handleChange("email")(event);
    }

    const changePasswordHandler = (event: ChangeEvent<HTMLInputElement>) => {
        disabledError();
        handleChange("password")(event);
    }

    return (
        <CenterLayout>
            <AuthForm>
                <Field
                    marginRight={0}
                    label="Email"
                    value={values.email}
                    handleChange={changeEmailHandler}
                    handleBlur={handleBlur("email")}
                    error={errors.email || error || undefined}
                    touched={touched.email}
                />

                <Field
                    marginRight={0}
                    type="password"
                    label="Пароль"
                    value={values.password}
                    handleChange={changePasswordHandler}
                    handleBlur={handleBlur("password")}
                    error={errors.password || error || undefined}
                    touched={touched.password}
                />

                <Button
                    disabled={!isValid}
                    title="Увійти"
                    handleClick={clickButtomSubmitHandler}
                    backgroundColor="#4775FE"
                />

                <Margin marginTop={20}>
                    <LinkText type="link" link="/recovery/pass-email" content="Забули пароль?" />
                </Margin>
            </AuthForm>

            <Position width="100%" maxWidth={160} type="fixed" top={30} right={30}>
                <Button
                    maxWidth={160}
                    title="Реєстрація"
                    handleClick={clickNavButtonHandler}
                    backgroundColor="#FA5D54"
                />
            </Position>
        </CenterLayout>
    )
}

export default SignIn;