import React, { FC, MouseEvent } from "react";
import styles from "./StatusView.module.css";
import classNames from "classnames";

const cx = classNames.bind(styles);


interface Props {
    statusOn?: string;
    statusOff?: string;
    statusExp?: string;
    clicked?: boolean;
    status: number;
    handleClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    isStudentsStatus?: boolean;
} 

const StatusView: FC<Props> = ({clicked = false, statusOn = "Активна", statusOff = "Архів", 
    statusExp = "Чекає підтвердження", status, handleClick, isStudentsStatus}) => {
    let statusText = status === 0 ? statusExp : status === 1 ? statusOn : statusOff;
    if(isStudentsStatus) {
        statusText = status === 0 ? statusExp : status === 1 ? statusOn : statusOff;
    }
    
    return (
        !clicked ?
            <p className={cx(
                    styles.status,
                    ((isStudentsStatus && status === 1) || status === 1) && styles.active,
                    ((isStudentsStatus && status === 2) || status === 2) && styles.archive,
                )}
            > 
                {statusText}
            </p> 
        :
            <button 
                className={cx(
                    styles.statusButton, 
                    ((isStudentsStatus && status === 1) || status === 1) && styles.active,
                    ((isStudentsStatus && status === 2) || status === 2) && styles.archive,
                )}
                onClick={handleClick}
            >
                {statusText}
            </button>
    );
}

export default StatusView;