interface State {
    totalCount: number;
    pageAll: number;
    pageSize: number;
}

function createItem<T, A extends State>(state: A | null, items: T[], data: T) {
    items.unshift(data);

    if (state) {
        state.totalCount = state.totalCount + 1;
        state.pageAll = Math.ceil(state.totalCount / state.pageSize);
    }
}

export default createItem;