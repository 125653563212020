import { FormikProps } from "formik";
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import Message from "../../../../components/feedback/messages/Message/Message";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import {
    setMessageVisiable,
} from "../../../../store/state/services/upsells/upsells.slice";
import { useMessageActions } from "../../../../utl/hooks/useMessageActions";
import { UpsellValue } from "../models/UpsellValue";
import Upsell from "./Upsell";

interface Props {
    formik: FormikProps<UpsellValue>;
    active: boolean;
}

const UpsellMessage: FC<Props> = ({ formik, active }) => {
    const dispatch = useAppDispatch();

    const message = useAppSelector(state => state.upsellsServices.message, shallowEqual);
    const visiable = useAppSelector(state => state.upsellsServices.messageVisiable, shallowEqual);

    const setMessage = () => {
        dispatch(setMessageVisiable({ visiable: false }));
    }

    const visiableActions = useMessageActions(visiable, setMessage);

    return (
        <>
            <Message
                content={message || ""}
                visiable={visiableActions}
                position="top"
            />

            <Upsell
                formik={formik}
                active={active}
            />
        </>
    )
}

export default React.memo(UpsellMessage);