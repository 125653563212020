import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Employees } from "../../../../services/reddyApi/Employees/Employees";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { changeEmployeeActivityFetch, changeEmployeeActivitySuccess, deleteEmployeeSuccess, getEmployeeFetch, getTeachersListFetch } from "../employees.slice";
import { ChangeEmployeeActivityPayload } from "../payload/ChangeEmployeeActivity/ChangeEmployeeActivityPayload";

function* changeEmployeeActivityWorker(action: PayloadAction<ChangeEmployeeActivityPayload>) {
    const { vendorId, employeeId, active } = action.payload;

    try {
        const { status } = yield call(Employees.changeEmployeeActivity, { vendorId, employeeId, active });

        if (status === 200) {
            yield put(changeEmployeeActivitySuccess({ employeeId, active }));

            if (active) {
                yield put(getEmployeeFetch({ vendorId, employeeId }));
            } else {
                // yield put(deleteEmployeeSuccess({ employeeId }));
            }

            yield put(getTeachersListFetch({ vendorId }));

            yield getNotifications(vendorId);

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => changeEmployeeActivityFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default changeEmployeeActivityWorker;