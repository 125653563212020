import { useFormik } from "formik";
import React from "react";
import PassRecovery from "./PassRecovery";
import validationSchema from "./PassRecovery.scheme";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import { shallowEqual } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { resetPasswordFetch } from "../../../store/state/user/user.slice";
import { PassRecoveryValues } from "./models/PassRecoveryValues";

const PassRecoveryContainer = () => {
    const [searchParams] = useSearchParams();
    const isSubmit = useAppSelector(state => state.user.isResetPassword, shallowEqual);
    const email = searchParams.get('email') || "";
    const token = searchParams.get('token') || "";
    const dispatch = useAppDispatch();
    const error = useAppSelector(state => state.user.isError, shallowEqual);

    const initialValues: PassRecoveryValues = {
        password: '',
        passwordCopy: '',
    }

    const formik = useFormik<PassRecoveryValues>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            dispatch(resetPasswordFetch({email, resetToken: token, password: values.password}));
        },
    });
    
    return (
        <PassRecovery error={error} isSubmit={isSubmit} formik={formik} />
    );
}

export default React.memo(PassRecoveryContainer);