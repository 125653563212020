import { PayloadAction } from "@reduxjs/toolkit";
import { UpsellState } from "../../models/UpsellsState";
import { UploadUpsellIconSuccessPayload } from "../../payload/UploadUpsellIcon/UploadUpsellIconSuccessPayload";

const uploadUpsellIconSuccess = (state: UpsellState, action: PayloadAction<UploadUpsellIconSuccessPayload>) => {
    const { upsellId, link } = action.payload;

    const upsells = state.upsells;

    const indexUpsell = upsells.findIndex(upsell => upsell.id === upsellId);

    if(indexUpsell > -1) {
        const service = upsells[indexUpsell];
        service.image = link;
    }
}

export default uploadUpsellIconSuccess;