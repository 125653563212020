import { PayloadAction } from "@reduxjs/toolkit";
import { ClientsState } from "../models/ClientsState";
import { CreateClientTransactionSuccessPayload } from "../payload/CreateClientMoneyTransaction/CreateClientTransactionSuccessPayload";

const createTransactionSuccess = (state: ClientsState, actions: PayloadAction<CreateClientTransactionSuccessPayload>) => {
    const { clientId, transaction } = actions.payload;

    const clients = state.pageData;

    const clientIndex = clients.findIndex(client => client.clientId === clientId);
    
    if (clientIndex > -1) {
        const transactions = state.pageData[clientIndex].transactions;
        if (transactions) {
            const newTranasctions = [...transactions];
            newTranasctions.unshift({ ...transaction });
            state.pageData[clientIndex].transactions = newTranasctions;

            state.transactionsPages.totalCount = state.transactionsPages.totalCount + 1;
            state.transactionsPages.pageAll = Math.ceil(state.transactionsPages.totalCount / state.transactionsPages.pageSize);
        }
    }

    state.message = "Транзакцію було створено";
    state.messageVisiable = true;
    state.isLoading = false;
    state.isError = null;
}

export default createTransactionSuccess