import { useEffect } from "react";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { getCommentsByVendorFetch } from "../../../../store/state/clients/clients.slice";

interface Payload {
    commentsLength: number;
    vendorId: number;
    clientId: number;
    updated?: boolean;
}

const useGetCommentsByVendor = (payload: Payload) => {
    const { commentsLength, vendorId, clientId, updated } = payload;

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (commentsLength === 0 || updated) {
            dispatch(getCommentsByVendorFetch({ vendorId, clientId }));
        }
    }, [vendorId, clientId, updated]);
}

export default useGetCommentsByVendor;