import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import withMatch from "../../components/other/hoc/withMatch";
import { useAppSelector } from "../../store/hooks/selector.hook";
import Notifications from "./Notifications";

const NotificationsContainer: FC = () => {
    const active = useAppSelector(state => state.notifications.active, shallowEqual);
    const archive = useAppSelector(state => state.notifications.archive, shallowEqual);
    const selected = useAppSelector(state => state.notifications.selected, shallowEqual);
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    return (
        <Notifications 
            active={active}
            archive={archive}
            selected={selected}
            vendorId={vendorId}
        />
    )
}

export default  withMatch(React.memo(NotificationsContainer), [2, 3, 4]);