import * as Yup from 'yup';

export default Yup.object().shape({
    name: Yup.string()
        .max(255, "Максимальна кількість символів досягнута")
        .required('Заповніть це поле'),
    city: Yup.string()
        .max(255, "Максимальна кількість символів досягнута")
        .required('Заповніть це поле'),
    address: Yup.string()
        .max(255, "Максимальна кількість символів досягнута")
        .required('Заповніть це поле'),
    desc: Yup.string()
        .required('Заповніть це поле')
        .max(2050, "Максимальна кількість символів досягнута"),
    coord: Yup.string()
        .matches(/(\d+)\.(\d+), (\d+)\.(\d+)/, "not valid string")
        .required('Заповніть це поле'),
    phone: Yup.string()
        .required('Заповніть це поле'),
    confirmed: Yup.boolean()
        .required('Заповніть це поле'),
});