import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Services } from "../../../../../services/reddyApi/Services/Services";
import handleServerError from "../../../all/saga/handleServerError";
import setServerErrorDefault from "../../../all/saga/setServerErrorDefault";
import getNotifications from "../../../notifications/saga/getNotifications";
import { getStatisticsDashboard, getStatisticsFinance, getStatisticsService } from "../../../statistics/statistics.saga";
import { ChangeServiceActivityPayload } from "../payload/ChangeServiceActivity/ChangeServiceActivityPayload";
import { changeServiceActivityFetch, changeServiceActivitySuccess, deleteServiceSuccess, getServiceFetch } from "../service.slice";
import getShortServices from "./getShortServices";

function* changeServiceActivityWorker(action: PayloadAction<ChangeServiceActivityPayload>) {
    const { vendorId, serviceId, active } = action.payload;

    try {
        const { status } = yield call(Services.changeServiceActivity, { vendorId, serviceId, active });

        if (status === 200) {
            yield put(changeServiceActivitySuccess({ serviceId, active }));

            if (active) {
                yield put(getServiceFetch({ vendorId, serviceId }));
            } else {
                // yield put(deleteServiceSuccess({ serviceId }));
            }

            yield getShortServices({ vendorId });

            yield getStatisticsDashboard({ vendorId });

            yield getStatisticsFinance({ vendorId });

            yield getStatisticsService({ vendorId, serviceId });

            yield getNotifications(vendorId);

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => changeServiceActivityFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default changeServiceActivityWorker;