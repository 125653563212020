import React, { FC } from "react";
import Column from "../../../components/layout/Column/Column";
import Row from "../../../components/layout/Row/Row";
import Wrp from "../../../components/layout/Wrp/Wrp";
import TabPanel from "../../../components/navigation/TabPanel/TabPanel";
import AddTransactionButton from "../ClientForms/AddTransactionForm/Button";
import ParametersConatiner from "./components/Parameters/Parameters.container";
import TableClientContainer from "./components/Transactions/Transactions.container";
import UserDataBlockContainer from "./components/UserDataBlock/UserDataBlock.container";
import AddTransactionFormContainer from "../ClientForms/AddTransactionForm/AddTransactionFrom.container";
import AddCommentButton from "../ClientForms/AddCommentForm/Button";
import AddCommentFormContainer from "../ClientForms/AddCommentForm/AddCommentForm.container";
import CommentsContainer from "./components/Comments/Comments.container";
import DiscountsContainer from "./components/Discounts/Discounts.container";

interface Props {
    panelItems: JSX.Element[];
    locations: string[];
}

const Client: FC<Props> = ({ panelItems, locations }) => {
    return (
        <>
            <Wrp justifyContent="space-between">
                <Column maxWidth={845}>
                    <ParametersConatiner />
                    <Row justifyContent="space-between" marginBottom={30}>
                        <Row maxWidth={530} justifyContent="space-between">
                            <TabPanel>
                                {panelItems}
                            </TabPanel>
                            <AddCommentButton />
                            <AddTransactionButton />
                        </Row>
                        
                        <DiscountsContainer />
                    </Row>
                    <>
                        {locations.includes("money") && <TableClientContainer />}
                        {locations.includes("communication") && <CommentsContainer />}
                    </>
                </Column>
                <Column maxWidth={430} width={"30%"}>
                    <UserDataBlockContainer />
                </Column>
            </Wrp>
            <AddCommentFormContainer />
            <AddTransactionFormContainer />
        </>
    );
}

export default Client;