import { PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { Upsells } from "../../../../../services/reddyApi/Upsells/Upsells";
import { GetUpsellsListResponse } from "../../../../../services/reddyApi/Upsells/Upsells.response";
import handleServerError from "../../../all/saga/handleServerError";
import setServerErrorDefault from "../../../all/saga/setServerErrorDefault";
import { GetUpsellsListPayload } from "../payload/GetUpsellsList/GetUpsellsListPayload";
import { getAdditionalListFetch, getAdditionalListSuccess } from "../upsells.slice";

function* getUpsellsListWorker(action: PayloadAction<GetUpsellsListPayload>) {
    const { vendorId, filter } = action.payload;

    try {
        const { data }: AxiosResponse<GetUpsellsListResponse> = yield call(Upsells.getUpsellsList, { vendorId, filter });

        yield put(getAdditionalListSuccess({ items: data, filter }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getAdditionalListFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getUpsellsListWorker;