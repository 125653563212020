import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Services } from "../../../../services/reddyApi/Services/Services";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getSelectedServiceFetch, getSelectedServiceSuccess } from "../groups.slice";
import { GetSelectedServicePayload } from "../payload/GetSelectedService/GetSelectedServicePayload";

function* getSelectedServiceWorker(action: PayloadAction<GetSelectedServicePayload>) {
    const { vendorId, serviceId } = action.payload;
    const dataParams = {
        vendorId,
        serviceId
    }

    try {
        const { data } = yield call(Services.getService, dataParams);

        yield put(getSelectedServiceSuccess({ ...data }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getSelectedServiceFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getSelectedServiceWorker;