import { useFormik } from "formik";
import React, { FC } from "react";
import validationSchema from "./EmployeeUpdate.scheme";
import { EmployeeValues } from "../models/EmployeeValues";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { changeEmployeeActivityFetch, deleteEmployeeFetch, updateEmployeeFetch } from "../../../store/state/employees/employees.slice";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import { shallowEqual } from "react-redux";
import { useDeleteRedirect } from "../../../utl/hooks/useDeleteRedirect";
import withMatch from "../../../components/other/hoc/withMatch";
import { convertStringToArray } from "../../../utl/helpers/convertStringToArray";
import { Filter } from "../../../utl/models/Filters";
import moment from "moment";
import EmployeeMessage from "./Employee.message";
import useNoMatch from "../../../utl/hooks/useNoMatch";
import { SalaryForServices } from "../../../store/state/employees/models/SalaryForServices";
import useSubmitAction from "../../../utl/hooks/useSubmitAction";
import { SUBMIT_ACTIONS } from "../../../utl/constants/submitActions";

const EmployeeContainer: FC = () => {
    let { id } = useParams();
    const employeeId = Number(id);
    const employees = useAppSelector(state => state.employees.employeesArray, shallowEqual);
    const deleteRedirect = useDeleteRedirect();
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;

    const employee = employees[employees.findIndex(item => item.id === employeeId)];

    const noMatch = useNoMatch(employee);

    const salaryForServicesItem: SalaryForServices = {
        serviceId: -1,
        salary: 0,
    }

    const active = employee?.active;

    const initialValues: EmployeeValues = employee ? {
        fullname: employee.fullname,
        startDate: employee.startDate,
        roleId: employee.roleId,
        phone: employee.phone,
        email: employee.email,
        salary: employee.salary,
        locationsIds: employee.locationsIds.join(","),
        salaryForServices: employee.salaryForServices || [salaryForServicesItem],
        active,
        submitAction: SUBMIT_ACTIONS.SAVE,
    } : {
        fullname: "",
        startDate: "",
        roleId: 3,
        phone: "",
        email: "",
        salary: 0,
        locationsIds: "",
        salaryForServices: [],
        active: false,
        submitAction: SUBMIT_ACTIONS.SAVE,
    }

    const submitActionHandler = useSubmitAction(active ? deleteRedirect : undefined);

    const formik = useFormik<EmployeeValues>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const { submitAction, fullname, startDate, roleId, phone, email, salary, locationsIds, salaryForServices, active } = values;
            const locationsIdsArray = convertStringToArray(locationsIds);

            const today = new Date();
            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const filter: Filter = {
                period: {
                    from: moment(firstDay).format("YYYY-MM-DD"),
                    to: moment(lastDay).format("YYYY-MM-DD"),
                }
            }

            submitActionHandler(
                submitAction,
                () => updateEmployeeFetch(
                    {
                        vendorId,
                        employeeId,
                        employee: {
                            startDate,
                            fullname,
                            roleId,
                            phone,
                            email,
                            salary,
                            locationsIds: locationsIdsArray,
                            salaryForServices,
                            active,
                        },
                        filter
                    }
                ),
                () => changeEmployeeActivityFetch(
                    { vendorId, employeeId, active: !active }
                )
            );
        },
    });

    if (noMatch.isNoMatch) {
        return noMatch.noMatchComponent
    }

    return (
        <EmployeeMessage
            formik={formik}
            active={active}
        />
    )
}

export default React.memo(withMatch(EmployeeContainer, [2, 3, 4]));