import axios, { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { Configuration } from "../../../../services/reddyApi/Configuration/Configuration";
import { ResGetVendorConfig } from "../../../../services/reddyApi/Configuration/Configuration.response";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getVendorConfigFetch, getVendorConfigSuccess } from "../configuration.slice";

function* getVendorConfigWorker() {
    try {
        const { data }: AxiosResponse<ResGetVendorConfig> = yield call(Configuration.getVendorConfig);
       
        const { employee, service, client, notification } = data;

        yield put(getVendorConfigSuccess({ vendorConfig: { employee, service, client, notification } }));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getVendorConfigFetch();

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getVendorConfigWorker;