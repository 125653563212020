import { PayloadAction } from "@reduxjs/toolkit";
import { ServiceState } from "../../models/ServiceState";
import { GetServiceSuccessPayload } from "../../payload/GetService/GetServiceSuccessPayload";

const getServiceSuccess = (state: ServiceState, action: PayloadAction<GetServiceSuccessPayload>) => {
    const service = action.payload;
   
    const pageServicesData = state.pageData;
    const newPageServicesData = [...pageServicesData];

    const indexService = newPageServicesData.findIndex(pageData => pageData.id === service.id);
    if (indexService === -1) {
        newPageServicesData.push(service);
    } else {
        const oldService = newPageServicesData[indexService];
        newPageServicesData[indexService] = {...oldService, ...service};
    }

    state.pageData = newPageServicesData;
}

export default getServiceSuccess;