import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Money } from "../../../../services/reddyApi/Money/Money";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { getStatisticsDashboard, getStatisticsFinance } from "../../statistics/statistics.saga";
import { updateCategoriesFetch, updateCategoriesSuccess } from "../money.slice";
import { UpdateCategoriesPayload } from "../payload/UpdateCategories.ts/UpdateCategoriesPayload";

function* updateCategoriesWorker(action: PayloadAction<UpdateCategoriesPayload>) {
    const { vendorId, purses, discounts, operations, filter } = action.payload;
    const dataParams = {
        vendorId,
        purses,
        discounts,
        operations
    }

    try {
        const { data } = yield call(Money.updateCategories, dataParams);
        const { purses, operations, discounts } = data;
        yield put(updateCategoriesSuccess({ purses, operations, discounts }));

        yield getStatisticsDashboard({ vendorId, filter });

        yield getStatisticsFinance({ vendorId, filter });

        yield getNotifications(vendorId);

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => updateCategoriesFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default updateCategoriesWorker;