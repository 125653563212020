import { PayloadAction } from "@reduxjs/toolkit";
import { MoneyTransaction } from "../../../finance/models/MoneyTransaction";
import { GetClientMoneyTransactionsByVendorSuccessPayload } from "../../../finance/payload/GetClientMoneyTransactionsByVendor/GetClientMoneyTransactionsByVendorSuccessPayload";
import { ClientsState } from "../../models/ClientsState";

const defaultTransactionsItem: MoneyTransaction = {
    id: -1,
    counteragent: {
        id: -1,
        name: "Counteragent",
    },
    purseId: -1,
    operationId: -2,
    sum: 0,
    comment: "",
    responsibleUser: {
        id: -1,
        fullname: "responsibleUser",
        roleId: -1,
    },
    createdAt: "",
}

const getClientMoneyTransactionsByVendorSuccess = (state: ClientsState, action: PayloadAction<GetClientMoneyTransactionsByVendorSuccessPayload>) => {
    const { totalCount, items, filter, clientId } = action.payload;

    state.transactionsPages.totalCount = totalCount;
    state.transactionsPages.pageAll = Math.ceil(totalCount / state.transactionsPages.pageSize);

    const clients = state.pageData;

    const clientIndex = clients.findIndex(client => client.clientId === clientId);

    if (clientIndex > -1) {
        const client = state.pageData[clientIndex];
        let transactions = client.transactions;
        let newTranasctions: MoneyTransaction[] = [];

        if (transactions) {
            transactions = transactions.map(transaction => ({ ...transaction }));

            if (filter?.range) {
                for (let i = 0; i < totalCount; i++) {
                    if (transactions[i]) {
                        newTranasctions[i] = { ...transactions[i] };
                        continue;
                    }

                    newTranasctions[i] = { ...defaultTransactionsItem };
                }

                let j = 0;
                for (let i = filter.range.from; i <= filter.range.to; i++) {
                    if (items[j]) {
                        newTranasctions[i] = { ...items[j] };
                    }
                    j++;
                }
            } else {
                newTranasctions = [...transactions];
                items.forEach(function (item) {
                    if (newTranasctions.findIndex(elem => elem.id === item.id) === -1) {
                        newTranasctions.push(item);
                    }
                });
            }
        } else {
            items.forEach(function (item) {
                if (newTranasctions.findIndex(elem => elem.id === item.id) === -1) {
                    newTranasctions.push(item);
                }
            });
        }

        state.pageData[clientIndex].transactions = [...newTranasctions];
    }
    state.isLoading = false;
    state.isError = null;
}

export default getClientMoneyTransactionsByVendorSuccess;