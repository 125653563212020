import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { getClientsListForVendorFetch } from "../../store/state/clients/clients.slice";
import { SYSTEM_ADMIN_ID } from "../../utl/constants/role";
import { culcPage } from "../../utl/helpers/culcPage";
import { Filter } from "../../utl/models/Filters";

function withLoadingClients(Component: React.ComponentType) {
    const ComponentWithLoadingClients: FC = () => {
        const dispatch = useAppDispatch();
        const page = useAppSelector(state => state.clients.clientsPages.page, shallowEqual);
        const totalCount = useAppSelector(state => state.clients.clientsPages.totalCount, shallowEqual);
        const pageAll = useAppSelector(state => state.clients.clientsPages.pageAll, shallowEqual);
        const pageSize = useAppSelector(state => state.clients.clientsPages.pageSize, shallowEqual);
        const getAllItems = useAppSelector(state => state.clients.clientsPages.getAllItems, shallowEqual);
        const pagesRange = culcPage(page, pageAll, totalCount, pageSize);
        const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
        const roleId = useAppSelector(state => state.user.roleId, shallowEqual) || -1;

        useEffect(() => {
            if (roleId > SYSTEM_ADMIN_ID) {
                let filter: Filter = {};

                if (!getAllItems) {
                    filter.range = {
                        from: pagesRange.from,
                        to: pagesRange.to
                    }
                }

                dispatch(getClientsListForVendorFetch({ vendorId, filter }));
            }
        }, [vendorId, roleId]);

        return (
            <Component />
        );
    }

    return ComponentWithLoadingClients;
}

export default withLoadingClients;