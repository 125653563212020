import { PayloadAction } from "@reduxjs/toolkit";
import { GroupsState } from "../../models/GroupsState";
import { DeleteStudentForGroupSuccessPayload } from "../../payload/DeleteStudentForGroup/DeleteStudentForGroupSuccessPayload";

const deleteStudentForGroupSuccess = (state: GroupsState, action: PayloadAction<DeleteStudentForGroupSuccessPayload>) => {
    const { groupId, studentId } = action.payload;

    const groups = state.pageData;

    const indexGroup = groups.findIndex(group => group.id === groupId);
    if (indexGroup > -1) {
        const group = groups[indexGroup];
        group.students = group.students?.filter(student => student.student.id !== studentId);
        group.studentsAmount -= 1;
    }

    state.pageData = [...groups]
}

export default deleteStudentForGroupSuccess;