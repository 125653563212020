import { put } from "redux-saga/effects";
import { getShortServicesListFetch } from "../service.slice";

interface Payload {
    vendorId: number;
}

function* getShortServices(payload: Payload) {
    const { vendorId } = payload;

    yield put(getShortServicesListFetch({ vendorId, filter: { short: true, active: true, confirmed: true } }));
}

export default getShortServices;