import { all } from 'redux-saga/effects'
import { vendorsWatcher } from './vendors/saga/watcher';
import { userWatcher } from './user/saga/watcher';
import { servicesWatcher } from './services/service/saga/watcher';
import { upsellsWatcher } from './services/upsells/saga/watcher';
import { notificationsWatcher } from './notifications/saga/watcher';
import { moneyWatcher } from './money/saga/watcher';
import { locationsWatcher } from './locations/saga/watcher';
import { groupsWatcher } from './groups/saga/watcher';
import { financeWatcher } from './finance/saga/watcher';
import { employeesWatcher } from './employees/saga/watcher';
import { dashboardWatcher } from './dashboard/saga/watcher';
import { configWatcher } from './configuration/saga/watcher';
import { clientsWatcher } from './clients/saga/watcher';

export default function* rootSaga() {
  yield all([
    userWatcher(),
    vendorsWatcher(),
    locationsWatcher(),
    moneyWatcher(),
    employeesWatcher(),
    upsellsWatcher(),
    servicesWatcher(),
    configWatcher(),
    financeWatcher(),
    groupsWatcher(),
    clientsWatcher(),
    dashboardWatcher(),
    notificationsWatcher(),
  ]);
}