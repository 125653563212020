import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Clients } from "../../../../services/reddyApi/Clients/Clients";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getVendorClientDataFetch, getVendorClientDataSuccess } from "../clients.slice";
import { GetVendorClientDataPayload } from "../payload/GetVendorClientData/GetVendorClientDataPayload";

function* getVendorClientDataWorker(action: PayloadAction<GetVendorClientDataPayload>) {
    const { vendorId, clientId } = action.payload;
    const dataParams = {
        vendorId,
        clientId
    }

    try {
        const { data } = yield call(Clients.getVendorClientData, dataParams);

        yield put(getVendorClientDataSuccess(data));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getVendorClientDataFetch(action.payload);
            
            yield handleServerError(error, actionCreator);
        }
    }
}

export default getVendorClientDataWorker;