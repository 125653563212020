import { PayloadAction } from "@reduxjs/toolkit";
import { FinanceState } from "../models/FinanceState";
import { SetTransactionsFilterPayload } from "../payload/SetTransactionsFilterPayload";

const setTransactionsFilter = (state: FinanceState, action: PayloadAction<SetTransactionsFilterPayload>) => {
    const { type } = action.payload;

    state.finance.filter.type = type;
}

export default setTransactionsFilter;