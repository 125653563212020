import React, { FC } from "react";
import Dropdown from "../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { User } from "../../../../../../store/state/clients/models/User";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../../utl/constants/defaultValue";

interface Props {
    userId: number;
    users: Pick<User, "id" | "name">[];
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
    error: string | undefined;
    touched: boolean | undefined;
} 

const UsersDropdown: FC<Props> = ({ users, userId, setFieldTouched, setFieldValue, error, touched }) => {
    const indexUser = users.findIndex(user => user.id === userId);

    const defaultUser = users[indexUser];
    const defaultValueUser: DefaultValue = {
        valueInput: defaultUser && String(defaultUser.id) || DEFAULT_VALUES_DROPDOWN.valueInput,
        valueText: defaultUser && defaultUser.name || DEFAULT_VALUES_DROPDOWN.valueText,
    }

    const userItems = users.map(user => (
        <DropdownItem
            item={user.name}
            valueInput={String(user.id)}
            valueText={user.name}
        />
    ));

    const blurHandler = (touched: boolean) => {
        setFieldTouched("userId", touched)
    }

    const changeHandler = (userId: string) => {
        setFieldValue("userId", parseInt(userId))
    }

    return (
        <Dropdown
            maxWidth={440}
            label="Контрагент"
            defaultValue={defaultValueUser}
            doubleClickDropdown={false}
            handleBlur={blurHandler}
            error={error}
            touched={touched}
            handleChange={changeHandler}
            marginBottom={20}
        >
            {userItems}
        </Dropdown>
    );
}

export default UsersDropdown;