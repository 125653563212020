import { PayloadAction } from "@reduxjs/toolkit";
import { GroupsState } from "../../models/GroupsState";
import { ChangeStudentStatusForGroupSuccessPayload } from "../../payload/ChangeStudentStatusForGroup/ChangeStudentStatusForGroupSuccessPayload";

const changeStudentStatusForGroupSuccess = (state: GroupsState, action: PayloadAction<ChangeStudentStatusForGroupSuccessPayload>) => {
    const status = action.payload.status;
    
    state.isLoading = false;
    state.isError = null;
}

export default changeStudentStatusForGroupSuccess;