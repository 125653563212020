import { PayloadAction } from "@reduxjs/toolkit";
import { GroupsState } from "../models/GroupsState";
import { ShowDataPayload } from "../payload/ShowDataPayload";

const showStudentAttendance = (state: GroupsState, action: PayloadAction<ShowDataPayload>) => {
    const { show, groupId, studentId } = action.payload;

    const groups = state.pageData;

    const indexGroup = groups.findIndex(group => group.id === groupId);
    if (indexGroup > -1) {
        const group = groups[indexGroup];
        let students = group.students;
        const indexStudent = students?.findIndex(student => student.student.id === studentId);

        if (students && indexStudent !== undefined && indexStudent > -1) {
            const student = students[indexStudent];
            student.attendanceVisiable = show;
        }
    }
}

export default showStudentAttendance;