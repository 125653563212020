import { put } from "redux-saga/effects";
import { setErrorEmailEmployee, setErrorTelEmployee } from "../employees.slice";

function* handleError(statusCode: number | undefined) {
    switch (statusCode) {
        case 472:
            yield put(setErrorEmailEmployee({
                error: "Така пошта вже вже використовується"
            }));
            break;
        case 473:
            yield put(setErrorTelEmployee({
                error: "Такий телефон вже використовується"
            }));
            break;
    }
}

export default handleError;