import React, { FC } from "react";
import Title from "../../../general/typography/Title/Title";
import styles from "./ProgressGraph.module.css";
import classNames from "classnames";
import { Diagrams } from "./models/Diagrams";
const cx = classNames.bind(styles);

interface Props {
    maxWidth?: number;
    height?: number; 
    diagrams: Diagrams[];
}

const ProgressGraph: FC<Props> = ({maxWidth = 955, height = 335, diagrams}) => {
    const diagramsItems = diagrams.map(diagram => {
        const date = new Date(diagram.date).toLocaleDateString("ro-RO")
        const actual = diagram.general.actual * 100 / diagram.general.maximum;
        const planned = diagram.general.planned * 100 / diagram.general.maximum;
        
        return (
            <div className={styles.diagramBlock}>
                <div className={styles.diagram}>
                    <div className={styles.scheduled} style={{height: `${planned}%`}}></div>
                    <div className={styles.actual} style={{height: `${actual}%`}}></div>
                </div>
                <span className={styles.number}>
                    {date}
                </span>
            </div>
        )
    });

    return (
        <div
            className={styles.progressGraphWrp}
            style={{maxWidth, height}}
        >
            <Title level={2} title="Відвідуваність" marginBottom={15} />
            <div className={styles.progressMarkersBlock}>
                <div className={styles.progressMarkerBlock}>
                    <div className={styles.progressType}></div>
                    <p className={styles.progressTypeName}>
                        Можлива
                    </p>
                </div>
                <div className={styles.progressMarkerBlock}>
                    <div className={cx(styles.progressType, styles.darkGrey)}></div>
                    <p className={styles.progressTypeName}>
                        Запланована
                    </p>
                </div>
                <div className={styles.progressMarkerBlock}>
                    <div className={cx(styles.progressType, styles.black)}></div>
                    <p className={styles.progressTypeName}>
                        Фактична
                    </p>
                </div>
            </div>
            <div className={styles.progressGraph}>
                <div className={styles.progressGraphNumbers}>
                    <span>100</span>
                    <span>50</span>
                    <span>25</span>
                    <span>0</span>
                </div>
                <div className={styles.diagrams}>
                    {diagramsItems}
                </div>
            </div>
        </div>
    );
}

export default ProgressGraph;