import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Locations } from "../../../../services/reddyApi/Locations/Locations";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { changeLocationActivityFetch, changeLocationActivitySuccess, deleteLocationSuccess, getLocationFetch } from "../locations.slice";
import { ChangeLocationActivityPayload } from "../payload/ChangeLocationActivity/ChangeLocationActivityPayload";

function* changeLocationActivityWorker(action: PayloadAction<ChangeLocationActivityPayload>) {
    const { vendorId, locationId, active } = action.payload;

    try {
        const { status } = yield call(Locations.changeLocationActivity, { vendorId, locationId, active });

        if (status === 200) {
            yield put(changeLocationActivitySuccess({ locationId, active }));

            if (active) {
                yield put(getLocationFetch({ vendorId, locationId }));
            } else {
                // yield put(deleteLocationSuccess({ id: locationId }));
            }

            yield getNotifications(vendorId);

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => changeLocationActivityFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default changeLocationActivityWorker;