import React, { FC, MouseEvent } from "react";
import { PagePagination } from "../../components/dataDisplay/table/models/PagePagination";
import Table from "../../components/dataDisplay/table/Table";
import Wrp from "../../components/layout/Wrp/Wrp";
import { DATA_INDEX_CLIENTS } from "../../utl/constants/tables";
import { ClientsColumn } from "./models/ClientsColumn";

interface Props {
    data: ClientsColumn[];
    page: number;
    pages: PagePagination[];
    handleLeftButtonPagination: () => void;
    handleRightButtonPagination: () => void;
    handleClickRow: (item: any, event: MouseEvent<HTMLTableRowElement>) => void;
}

const Clients: FC<Props> = ({data, page, pages, handleLeftButtonPagination, handleRightButtonPagination, handleClickRow}) => {
    return (
        <Wrp maxWidth={880}>
            <Table
                handleClickRow={handleClickRow}
                data={data} 
                columns={DATA_INDEX_CLIENTS} 
                paginated={true}
                page={page}
                pages={pages}
                handleLeftButtonPagination={handleLeftButtonPagination}
                handleRightButtonPagination={handleRightButtonPagination}
            />
        </Wrp>
    );
}

export default Clients;