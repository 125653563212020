import { FormikProps } from "formik";
import React, { FC } from "react";
import ModalForm from "../../../../../../components/dataEntry/forms/ModalForm/ModalForm";
import RadioBlockSkeleton from "../../../../../../components/dataEntry/inputs/RadioBlock/RadioBlock.skeleton";
import SearchField from "../../../../../../components/dataEntry/inputs/SearchField/SearchField";
import Button from "../../../../../../components/general/buttons/Button/Button";
import IconFont from "../../../../../../components/general/icons/IconFont/IconFont";
import Paragraph from "../../../../../../components/general/typography/Paragraph/Paragraph";
import Row from "../../../../../../components/layout/Row/Row";
import Wrp from "../../../../../../components/layout/Wrp/Wrp";
import { Client } from "../../../../../../store/state/clients/models/Client";
import { SearchClient } from "../../../../../../store/state/groups/models/GroupsState";
import { SearchClientsForm } from "../../../../models/SearchClientsForm";
import AddClientToGroupFormContainer from "../AddClientToGroupForm/AddClientToGroupForm.container";
interface Props {
    formik: FormikProps<SearchClientsForm>;
    visiable: boolean;
    closeModalHandler: () => void;
    handleSearchUser: (value: string) => void;
    searchListUsers: Client[];
    isCreationScript: boolean;
    isMaxNumberOfStudents: boolean;
    visiableStudentSkeleton: boolean;
}

const SearchClients: FC<Props> = ({ visiable, closeModalHandler, formik, handleSearchUser, searchListUsers, 
    isCreationScript, isMaxNumberOfStudents, visiableStudentSkeleton }) => {
    const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched } = formik;

    const submitButtonHandler = () => {
        handleSubmit();
    }

    const clickCancelButtonHandler = () => {
        closeModalHandler();
        setFieldValue("value", "");
    }

    const visiableElementsOfNewClients = searchListUsers.length === 0 && !!values.value;

    let paragraph = !visiableElementsOfNewClients ? "Такий клієнт вже є в базі даних" : "Такого клієнта немає в базі даних"
    if(isMaxNumberOfStudents) { 
        paragraph = "Досягнута максимальна кількість студентів";
    }

    return (
        <ModalForm
            title="Додати клієнта"
            visiable={visiable}
            maxWidth={480}
            handleClick={closeModalHandler}
        >
            <Wrp flexDirection="column" alignItems="center">
                <SearchField
                    topPositionIcon={32}
                    paddingLeft={50}
                    icon={<IconFont iconClass="icon-search" fontSize={20} color="#A6AEC5" />}
                    maxWidth={440}
                    label="Пошук"
                    placeholder={"Введіть ім’я або номер телефону клієнта"}
                    marginBottom={20}
                    labelLeftShift={0}
                    marginRight={0}
                    handleBlur={(touched: boolean) => setFieldTouched("value", touched)}
                    setFieldValue={(value: any) => setFieldValue("value", value)}
                    handleSearch={handleSearchUser}
                    searchList={searchListUsers}
                    showSearchList={false}
                />

                <>
                    {!visiableElementsOfNewClients && searchListUsers.length > 0 && !isCreationScript && !isMaxNumberOfStudents  &&
                        <AddClientToGroupFormContainer />
                    }
                </>

                <Paragraph
                    visiable={
                        visiableElementsOfNewClients && !visiableStudentSkeleton
                        || (!visiableElementsOfNewClients && isCreationScript) 
                        || isMaxNumberOfStudents
                    }
                    content={paragraph}
                    marginBottom={20}
                />

                <RadioBlockSkeleton visiable={visiableStudentSkeleton} />
                
                <Row maxWidth={340} visiable={visiableElementsOfNewClients && !isMaxNumberOfStudents && !visiableStudentSkeleton}>
                    <Button
                        maxWidth={160}
                        handleClick={submitButtonHandler}
                        icon={<IconFont iconClass="icon-plus" fontSize={15} color="#FFF" />}
                        backgroundColor="#4775FE"
                        title="Створити"
                        marginBottom={0}
                        marginRight={15}
                    />

                    <Button
                        maxWidth={160}
                        handleClick={clickCancelButtonHandler}
                        backgroundColor="#A6AEC5"
                        title="Скасувати"
                        marginBottom={0}
                        marginRight={0}
                    />
                </Row>
            </Wrp>
        </ModalForm>
    );
}

export default SearchClients;