import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Services } from "../../../../../services/reddyApi/Services/Services";
import handleServerError from "../../../all/saga/handleServerError";
import setServerErrorDefault from "../../../all/saga/setServerErrorDefault";
import { GetServicePayload } from "../payload/GetService/GetServicePayload";
import { getServiceFetch, getServiceSuccess, setPageDataServices } from "../service.slice";

function* getServiceWorker(action: PayloadAction<GetServicePayload>) {
    const {vendorId, serviceId} = action.payload;

    try {
        const {data} = yield call(Services.getService, {vendorId, serviceId});

        yield put(getServiceSuccess(data));

        yield put(setPageDataServices({services: [{...data}]}));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getServiceFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getServiceWorker;