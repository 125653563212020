import { PayloadAction } from "@reduxjs/toolkit";
import { GroupsState } from "../../models/GroupsState";
import { SearchGroupsByFilterSuccessPayload } from "../../payload/SearchGroupsByFilter/SearchGroupsByFilterSuccessPayload";

const searchGroupsByFilterSuccess = (state: GroupsState, actions: PayloadAction<SearchGroupsByFilterSuccessPayload>) => {
    const serchedGroups = actions.payload;
    
    state.serchedGroups = serchedGroups;

    state.isLoading = false;
    state.isError = null;
}

export default searchGroupsByFilterSuccess;