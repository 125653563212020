import { PayloadAction } from "@reduxjs/toolkit";
import { GroupsState } from "../models/GroupsState";
import { SetPageDataGroupsPayload } from "../payload/SetPageDataGroupsPayload";

const setPageDataGroups = (state: GroupsState, action: PayloadAction<SetPageDataGroupsPayload>) => {
    const { groups } = action.payload;

    const pageGroupsData = state.pageData;
    const newPageGroupsData = pageGroupsData.map(pageGroupData => ({ ...pageGroupData }));

    groups.forEach(function (group) {
        const indexGroup = newPageGroupsData.findIndex(pageData => pageData.id === group.id);

        if (indexGroup === -1) {
            newPageGroupsData.push(group);
        } else {
            newPageGroupsData[indexGroup] = { ...newPageGroupsData[indexGroup], ...group };
        }
    });
    
    state.pageData = newPageGroupsData;
}

export default setPageDataGroups;