import React, { ChangeEvent, FC, useRef } from "react";
import styles from "./Checkbox.module.css";

interface Props {
    text?: string;
    handleChange?: (value: boolean) => void;
    marginBottom?: number;
    value?: boolean;
}

const Checkbox: FC<Props> = ({text, handleChange, marginBottom, value}) => {
    const changeValueHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.currentTarget.checked;
        if(handleChange) {
            handleChange(checked);
        }
    }

    return (
        <label className={styles.radioLabel} style={{marginBottom}}>
            <input 
                onChange={changeValueHandler} 
                type="checkbox" 
                className={styles.radioInput} 
                checked={value}
            />
            <div className={styles.radio}></div>
            {text && <p className={styles.text}>{text}</p>}
        </label>
    );
}

export default Checkbox;