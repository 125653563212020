import React, { FC } from "react";
import Dropdown from "../../../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { DateApi } from "../../../../../../../../services/Date/Date";
import { Lessons } from "../../../../../../../../store/state/groups/models/Group";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../../../../utl/constants/defaultValue";

interface Props {
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    lessons: Lessons[];
    lessonId: number;
    error: string | undefined;
    touched: boolean | undefined;
}

const LessonDateDropdown: FC<Props> = ({ setFieldTouched, setFieldValue, lessons, lessonId, error, touched }) => {
    const indexLesson = lessons.findIndex(lesson => lesson.id === lessonId);
    const defaultLesson = lessons[indexLesson];

    const defaultValueLesson: DefaultValue = {
        valueInput: (defaultLesson && String(defaultLesson.id)) || DEFAULT_VALUES_DROPDOWN.valueInput,
        valueText: defaultLesson && DateApi.parseDateTimeStringPrint(defaultLesson.datetime) || DEFAULT_VALUES_DROPDOWN.valueText,
    }

    const lessonsItems = lessons.map((lesson, index) => {
        const date = DateApi.parseDateTimeStringPrint(lesson.datetime);
        const value = String(lesson.id);
        return (
            <DropdownItem
                key={"DATE_LESSON_ITEM_" + index}
                item={date}
                valueInput={value}
                valueText={date}
            />
        )
    });

    const blurDropdownLessonsHandler = (touched: boolean) => {
        setFieldTouched("lessonId", touched)
    }

    const changeDropdownLessonsHandler = (value: string) => {
        const serviceId = parseInt(value);
        setFieldValue("lessonId", serviceId);
    }

    return (
        <Dropdown
            label="Дата заняття"
            maxWidth={140}
            height={40}
            marginBottom={20}
            defaultValue={defaultValueLesson}
            doubleClickDropdown={false}
            values={lessonId}
            error={error}
            touched={touched}
            handleBlur={blurDropdownLessonsHandler}
            handleChange={changeDropdownLessonsHandler}
        >
            {lessonsItems}
        </Dropdown>
    );
}

export default LessonDateDropdown;