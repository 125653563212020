import React, { FC } from "react";
import Title from "../../general/typography/Title/Title";
import styles from "./ParameterDisplayer.module.css";
import classNames from "classnames";

const cx = classNames.bind(styles);

interface Props {
    title: string;
    param: string;
    paramStatus?: string;
    mode?: "success" | "failure"
    maxWidth?: number;
    marginBottom?: number;
    marginRight?: number;
    status?: boolean;
}

const ParameterDisplayer: FC<Props> = ({maxWidth = 270, marginBottom, marginRight, title, 
    param, paramStatus, mode, status = true}) => {
    return (
        <div 
            style={{maxWidth, marginRight, marginBottom}} 
            className={styles.parameterDisplayer}
        >
            <Title 
                level={6} 
                title={title}
                color="#A6AEC5"
                marginBottom={10}
            />

            <div className={styles.paramBlock}>
                <p className={cx(styles.param, !status && styles.failure)}>
                    {param}
                </p>
                {paramStatus && mode && 
                    <p className={cx(styles.status, mode === "success" ? styles.success : styles.failure)}>
                        {paramStatus}
                    </p>
                }
            </div>
        </div>
    );
}

export default ParameterDisplayer;