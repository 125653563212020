import React, { FC } from "react";

interface Props {
    children: JSX.Element[] | JSX.Element;
    alignItems?: "center" | "flex-end" | "flex-start";
    justifyContent?: "center" | "flex-end" | "flex-start";
    width?: string | number;
    height?: string | number;
    maxHeight?: string | number;
    maxWidth?: string | number;
    paddingRight?: number;
}

const Column: FC<Props> = ({
        children, alignItems = "flex-start", justifyContent = "flex-start", width = "100%", height,
        maxHeight, maxWidth, paddingRight
    }) => {

    return (
        <div style={
            {
                display: "flex", flexDirection: "column", alignItems, justifyContent, width, height, maxHeight, maxWidth,
                paddingRight,
            }
        }>
            {children}
        </div>
    );
}

export default Column;