import React, { FC, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import Wrp from "../../../components/layout/Wrp/Wrp";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import TabPanelItem from "../../../components/navigation/TabPanel/TabPaneltem";
import TabPanel from "../../../components/navigation/TabPanel/TabPanel";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import withMatch from "../../../components/other/hoc/withMatch";
import useNoMatch from "../../../utl/hooks/useNoMatch";

interface State {
    name: string;
    active: boolean;
    url: string;
}

const Group: FC = () => {
    let { id } = useParams();
    const groupId = Number(id) || -1;
    const navigate = useNavigate();
    let location = useLocation();
    const locations = location.pathname.split("/");
    locations.shift();
    const groups = useAppSelector(state => state.groups.pageData, shallowEqual);
    const indexGroup = groups.findIndex(item => item.id === groupId);
    const group = groups[indexGroup];

    const noMatch = useNoMatch(group);

    const [state, setState] = useState<Array<State>>([
        {
            name: "Налаштування",
            active: !locations[2],
            url: ""
        },
        {
            name: "Клієнти",
            active: locations.length > 2 && locations[2] === "clients",
            url: "clients"
        },
    ]);

    useEffect(() => {
        setState([
            {
                name: "Налаштування",
                active: !locations[2],
                url: ""
            },
            {
                name: "Клієнти",
                active: locations.length > 2 && locations[2] === "clients",
                url: "clients"
            },
        ]);
    }, [location]);

    const panelItems = state.map((item, index) => {
        const clickTabHandler = () => {
            navigate(`/groups/${groupId}${item.url !== "" ? "/" : ""}${item.url}`);
        }

        return (
            <TabPanelItem 
                key={"PANEL_ITEM_SETTINGS_" + index}
                name={item.name} 
                active={item.active} 
                handleClick={clickTabHandler} 
            />
        )
    });

    if (noMatch.isNoMatch) {
        return noMatch.noMatchComponent
    }

    return (
        <Wrp flexDirection="column" >
            <TabPanel marginBottom={20}>
                {panelItems}
            </TabPanel>
            
            <Outlet />
        </Wrp>
    )
}

export default withMatch(React.memo(Group), [2, 3, 4]);