import React, { FC, MouseEvent, useState } from "react";
import styles from "./NameView.module.css";
import classNames from "classnames";
import Position from "../../../../layout/position/Position";
import MessageInfo from "../../../../feedback/messages/MessageInfo/MessageInfo";

const cx = classNames.bind(styles);

interface Props {
    name: string;
    subname?: string;
    color?: string
    marginBottom?: number;
    marginBottomParagraph?: number;
    maxLength?: number;
    maxLengthSubName?: number;
}

const NameView: FC<Props> = ({ name, subname, color = "#131313", marginBottom, maxLength, maxLengthSubName }) => {
    const [toggleName, setToggleName] = useState(false);
    const [toggleSubname, setToggleSubname] = useState(false);

    const clickNameHandler = (event: MouseEvent<HTMLParagraphElement>) => {
        if (maxLength && name.length > maxLength) {
            event.stopPropagation();
            setToggleName(prev => !prev);
            setToggleSubname(false);
        }
    }

    const subnameVisiable = subname !== undefined && subname !== "";

    const clickSubnameHandler = (event: MouseEvent<HTMLParagraphElement>) => {
        if (maxLengthSubName && subnameVisiable && subname !== undefined && subname.length > maxLengthSubName) {
            event.stopPropagation();
            setToggleSubname(prev => !prev);
            setToggleName(false);
        }
    }

    return (
        <div style={{ marginBottom }} className={styles.nameView}>
            <p
                style={{ color }}
                className={cx(styles.name)}
                onClick={clickNameHandler}
            >
                {maxLength && name.length > maxLength ?
                    `${name.substring(0, maxLength)}...` : name
                }
            </p>
            {subnameVisiable &&
                <p className={styles.subname} onClick={clickSubnameHandler}>
                    {maxLengthSubName && subname !== undefined && subname.length > maxLengthSubName ?
                        `${subname.substring(0, maxLengthSubName)}...` : subname
                    }
                </p>
            }
            <Position type="absolute" bottom={"100%"} left={0}>
                <MessageInfo
                    borderBottomLeftRadius={0}
                    borderBottomRightRadius={10}
                    borderTopLeftRadius={10}
                    borderTopRightRadius={10}
                    text={name}
                    visiable={toggleName}
                    minWidth={120}
                    type="string"
                />
            </Position>
            {subnameVisiable &&
                <Position type="absolute" bottom={"100%"} left={0}>
                    <MessageInfo
                        borderBottomLeftRadius={10}
                        borderBottomRightRadius={0}
                        borderTopLeftRadius={10}
                        borderTopRightRadius={10}
                        text={subname || ""}
                        visiable={toggleSubname}
                        minWidth={120}
                        type="string"
                    />
                </Position>
            }
        </div>
    );
}

export default NameView;