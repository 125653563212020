import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import Dropdown from "../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { useAppSelector } from "../../../../../store/hooks/selector.hook";
import { configListAdapter } from "../../../../../utl/adapters/ConfigListAdapter";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../utl/constants/defaultValue";

interface Props {
    rolesId: number;
    error: string | undefined;
    touched: boolean | undefined;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setFieldTouched?: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
}

const RolesDropdown: FC<Props> = ({ rolesId, error, touched, setFieldValue, setFieldTouched }) => {
    let rolesIds = useAppSelector(state => state.config.userConfig?.user.roles, shallowEqual);
    const rolesIdsList = configListAdapter(rolesIds);

    const indexRole = rolesIdsList.findIndex(role => role.id === rolesId);

    const defaultRole = rolesIdsList[indexRole];
    const defaultValueRoles: DefaultValue = {
        valueInput: (defaultRole && String(defaultRole.id)) || DEFAULT_VALUES_DROPDOWN.valueInput,
        valueText: defaultRole && defaultRole.name || DEFAULT_VALUES_DROPDOWN.valueText,
    }
    const rolesItems = rolesIdsList.map((role, index) => {
        const name = role.name;
        const id = String(role.id);

        return (
            <DropdownItem
                key={"ROLE_ITEM_" + index}
                item={name}
                valueInput={id}
                valueText={name}
            />
        )
    });

    const changeHandler = (typeId: string) => {
        setFieldValue("roleId", parseInt(typeId));
    }

    return (
        <Dropdown
            label="Посада"
            maxWidth={280}
            height={40}
            defaultValue={defaultValueRoles}
            doubleClickDropdown={false}
            values={rolesId}
            handleChange={changeHandler}
        >

            {rolesItems}
        </Dropdown>
    )
}

export default RolesDropdown;