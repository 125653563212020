import React, { FC } from "react";
import CenterLayout from "../../components/layout/CenterLayout/CenterLayout";
import logo from "../../assets/svg/Logo.svg"
import CircleProgress from "../../components/dataDisplay/progress/Circle/CircleProgress";

interface Props {
    percentage: number;
}

const Preloader: FC<Props> = ({percentage}) => {
    return (
        <CenterLayout flexDirection="column">
            <img src={logo} alt="logo" style={{marginBottom: 60}}/>
            <CircleProgress 
                sqSize={40} 
                strokeWidth={7} 
                percentage={percentage} 
            />
        </CenterLayout>
    );
}

export default Preloader;