import { AxiosResponse } from 'axios';
import {
    CreateEmployeeResponse,
    GetEmployeeResponse,
    GetEmployeesListResponse,
    GetTeachersListResponse
} from './Employees.response';
import instance from '../instance';
import {
    ChangeEmployeeActivityRequest,
    CreateEmployeeRequest,
    DeleteEmployeeRequest,
    GetEmployeeRequest,
    GetEmployeesListRequest,
    GetTeachersListRequest,
    SendEmployeePasswordRequest,
    UpdateEmployeeRequest
} from './Employees.request';

interface Employees {
    create: (data: CreateEmployeeRequest) => Promise<AxiosResponse<CreateEmployeeResponse>>;
    delete: (data: DeleteEmployeeRequest) => Promise<AxiosResponse>;
    getEmployeesList: (data: GetEmployeesListRequest) => Promise<AxiosResponse<GetEmployeesListResponse>>;
    update: (data: UpdateEmployeeRequest) => Promise<AxiosResponse>;
    sendPassword: (data: SendEmployeePasswordRequest) => Promise<AxiosResponse>;
    getTeachersList: (data: GetTeachersListRequest) => Promise<AxiosResponse<GetTeachersListResponse>>;
    getEmployee: (data: GetEmployeeRequest) => Promise<AxiosResponse<GetEmployeeResponse>>;
    changeEmployeeActivity: (data: ChangeEmployeeActivityRequest) => Promise<AxiosResponse>;
}

export const Employees: Employees = {
    create: async (data) => {
        const { vendorId, employee } = data;

        return instance.post(`/vendors/${vendorId}/employees`, employee);
    },
    delete: async (data) => {
        const { employeeId, vendorId } = data;

        return instance.delete(`/vendors/${vendorId}/employees/${employeeId}`);
    },
    getEmployeesList: async (data) => {
        const { vendorId, filter } = data;

        return instance.get(`/vendors/${vendorId}/employees`, {
            params: {
                rangeFrom: filter?.range?.from,
                rangeTo: filter?.range?.to,
                active: filter?.active,
            }
        });
    },
    update: async (data) => {
        const { vendorId, employeeId, employee } = data;

        return instance.put(`/vendors/${vendorId}/employees/${employeeId}`, employee);
    },
    sendPassword: async (data) => {
        const { vendorId, employeeId } = data;

        return instance.put(`/vendors/${vendorId}/employees/${employeeId}/password`);
    },
    getTeachersList: async (data) => {
        const { vendorId } = data;

        return instance.get(`/vendors/${vendorId}/teachers`);
    },
    getEmployee: (data) => {
        const { vendorId, employeeId } = data;

        return instance.get(`/vendors/${vendorId}/employees/${employeeId}`);
    },
    changeEmployeeActivity: (data) => {
        const { vendorId, employeeId, active } = data;

        return instance.put(`/vendors/${vendorId}/employees/${employeeId}/active`, { active });
    }
}