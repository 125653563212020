import { createSlice } from '@reduxjs/toolkit';
import { ClientsState } from './models/ClientsState';
import setVisiableCommentFormReducer from './reducer/setVisiableCommentForm';
import setVisiableTransactionFormReducer from './reducer/setVisiableTransactionForm';
import createByVendorFecthReducer from './reducer/createByVendor/createByVendorFecth';
import createByVendorSuccessReducer from './reducer/createByVendor/createByVendorSuccess';
import addCommentFecthReducer from './reducer/addComment/addCommentFecth';
import addCommentSuccessReducer from './reducer/addComment/addCommentSuccess';
import getCommentsByVendorFetchReducer from './reducer/getCommentsByVendor/getCommentsByVendorFetch';
import getCommentsByVendorSuccessReducer from './reducer/getCommentsByVendor/getCommentsByVendorSuccess';
import createTransactionSuccessReducer from './reducer/createTransactionSuccess';
import getClientMoneyTransactionsByVendorFetchReducer from './reducer/getClientMoneyTransactionsByVendor/getClientMoneyTransactionsByVendorFetch';
import getClientMoneyTransactionsByVendorSuccessReducer from './reducer/getClientMoneyTransactionsByVendor/getClientMoneyTransactionsByVendorSuccess'
import getClientsListForVendorFetchReducer from './reducer/getClientsListForVendor/getClientsListForVendorFetch';
import getClientsListForVendorSuccessReducer from './reducer/getClientsListForVendor/getClientsListForVendorSuccess';
import getClientStatisticsForVendorFetchReducer from './reducer/getClientStatisticsForVendor/getClientStatisticsForVendorFetch';
import getClientStatisticsForVendorSuccessReducer from './reducer/getClientStatisticsForVendor/getClientStatisticsForVendorSuccess';
import getFinanceInfoForVendorFetchReducer from './reducer/getFinanceInfoForVendor/getFinanceInfoForVendorFetch';
import getFinanceInfoForVendorSuccessReducer from './reducer/getFinanceInfoForVendor/getFinanceInfoForVendorSuccess';
import setErrorReducer from './reducer/setError';
import deleteForVendorFetchReducer from './reducer/deleteForVendor/deleteForVendorFetch';
import deleteForVendorSuccessReducer from './reducer/deleteForVendor/deleteForVendorSuccess';
import setPageClientsReducer from './reducer/setPageClients';
import setPageClientsTransactionsReducer from './reducer/setPageClientsTransactions';
import setMessageClientsReducer from './reducer/setMessageClients';
import setMessageVisiableClientsReducer from './reducer/setMessageVisiableClients';
import setDefaultValueClientsReducer from './reducer/setDefaultValueClients';
import setPageDataClientsReducer from './reducer/setPageDataClients';
import resetClientsReducer from './reducer/resetClients';
import searchClientForVendorFetchReducer from './reducer/searchClientForVendor/searchClientForVendorFetch';
import searchClientForVendorSuccessReducer from './reducer/searchClientForVendor/searchClientForVendorSuccess';
import getVendorClientDataFetchReducer from './reducer/getVendorClientData/getVendorClientDataFetch';
import getVendorClientDataSuccessReducer from './reducer/getVendorClientData/getVendorClientDataSuccess';
import updateClientDiscountFetchReducer from './reducer/updateClientDiscount/updateClientDiscountFetch';
import updateClientDiscountSuccessReducer from './reducer/updateClientDiscount/updateClientDiscountSuccess';
import downloadClientsTemplateFetchReducer from './reducer/downloadClientsTemplate/downloadClientsTemplateFetch';
import downloadClientsTemplateSuccessReducer from './reducer/downloadClientsTemplate/downloadClientsTemplateSuccess';
import importClientsByVendorFetchReducer from './reducer/importClientsByVendor/importClientsByVendorFetch';
import importClientsByVendorSuccessReducer from './reducer/importClientsByVendor/importClientsByVendorSuccess';

export const Pages = {
    getAllItems: false,
    pageAll: 0,
    pageSize: 15,
    page: 1,
    totalCount: 0,
}

export const initialState: ClientsState = {
    clients: [],
    pageData: [],
    searchedClients: [],
    statistics: null,
    notificationsPages: { ...Pages, getAllItems: true },
    clientsPages: { ...Pages },
    transactionsPages: { ...Pages },
    templateCSV: null,
    isLoading: false,
    isError: null,
    message: null,
    importedClients: false,
    messageVisiable: false,
    visiableCommentForm: false,
    visiableTransactionForm: false,
}

export const ClientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        setVisiableCommentForm: setVisiableCommentFormReducer,
        setVisiableTransactionForm: setVisiableTransactionFormReducer,
        createByVendorFecth: createByVendorFecthReducer,
        createByVendorSuccess: createByVendorSuccessReducer,
        addCommentFecth: addCommentFecthReducer,
        addCommentSuccess: addCommentSuccessReducer,
        getCommentsByVendorFetch: getCommentsByVendorFetchReducer,
        getCommentsByVendorSuccess: getCommentsByVendorSuccessReducer,
        createTransactionSuccess: createTransactionSuccessReducer,
        getClientMoneyTransactionsByVendorFetch: getClientMoneyTransactionsByVendorFetchReducer,
        getClientMoneyTransactionsByVendorSuccess: getClientMoneyTransactionsByVendorSuccessReducer,
        getClientsListForVendorFetch: getClientsListForVendorFetchReducer,
        getClientsListForVendorSuccess: getClientsListForVendorSuccessReducer,
        getClientStatisticsForVendorFetch: getClientStatisticsForVendorFetchReducer,
        getClientStatisticsForVendorSuccess: getClientStatisticsForVendorSuccessReducer,
        getFinanceInfoForVendorFetch: getFinanceInfoForVendorFetchReducer,
        getFinanceInfoForVendorSuccess: getFinanceInfoForVendorSuccessReducer,
        setError: setErrorReducer,
        deleteForVendorFetch: deleteForVendorFetchReducer,
        deleteForVendorSuccess: deleteForVendorSuccessReducer,
        setPageClients: setPageClientsReducer,
        setPageClientsTransactions: setPageClientsTransactionsReducer,
        setMessageClients: setMessageClientsReducer,
        setMessageVisiableClients: setMessageVisiableClientsReducer,
        setDefaultValueClients: setDefaultValueClientsReducer,
        setPageDataClients: setPageDataClientsReducer,
        searchClientForVendorFetch: searchClientForVendorFetchReducer,
        searchClientForVendorSuccess: searchClientForVendorSuccessReducer,
        getVendorClientDataFetch: getVendorClientDataFetchReducer,
        getVendorClientDataSuccess: getVendorClientDataSuccessReducer,
        updateClientDiscountFetch: updateClientDiscountFetchReducer,
        updateClientDiscountSuccess: updateClientDiscountSuccessReducer,
        downloadClientsTemplateFetch: downloadClientsTemplateFetchReducer,
        downloadClientsTemplateSuccess: downloadClientsTemplateSuccessReducer,
        importClientsByVendorFetch: importClientsByVendorFetchReducer,
        importClientsByVendorSuccess: importClientsByVendorSuccessReducer,
        resetClients: resetClientsReducer,
    },
});

export const {
    setVisiableCommentForm,
    setVisiableTransactionForm,
    createByVendorFecth,
    createByVendorSuccess,
    getClientsListForVendorFetch,
    getClientsListForVendorSuccess,
    getCommentsByVendorFetch,
    getCommentsByVendorSuccess,
    getClientStatisticsForVendorFetch,
    getClientStatisticsForVendorSuccess,
    getFinanceInfoForVendorFetch,
    getFinanceInfoForVendorSuccess,
    setError,
    addCommentFecth,
    addCommentSuccess,
    deleteForVendorFetch,
    deleteForVendorSuccess,
    setPageClients,
    setMessageClients,
    setMessageVisiableClients,
    createTransactionSuccess,
    getClientMoneyTransactionsByVendorFetch,
    getClientMoneyTransactionsByVendorSuccess,
    setPageClientsTransactions,
    setDefaultValueClients,
    setPageDataClients,
    searchClientForVendorFetch,
    searchClientForVendorSuccess,
    getVendorClientDataFetch,
    getVendorClientDataSuccess,
    updateClientDiscountFetch,
    updateClientDiscountSuccess,
    downloadClientsTemplateFetch,
    downloadClientsTemplateSuccess,
    importClientsByVendorFetch,
    importClientsByVendorSuccess,
    resetClients,
} = ClientsSlice.actions

export default ClientsSlice.reducer
