import React, { FC } from "react";
import Button from "../../../../components/general/buttons/Button/Button";
import IconFont from "../../../../components/general/icons/IconFont/IconFont";
import NotificationsBlock from "../../../../components/layout/NotificationsBlock/NotificationsBlock";
import { useAppDispatch } from "../../../../store/hooks/dispatch.hook";
import { changeNotificationsStatusFetch, NotificationStatuses } from "../../../../store/state/notifications/notifications.slice";
import { Notification as NotificationType } from "../../../../store/state/notifications/models/Notification";
import { DateApi } from "../../../../services/Date/Date";
import { TYPES } from "../../../../utl/constants/types";
import Notification from "../../../../components/feedback/notifications/Notification/Notification";
import Paragraph from "../../../../components/general/typography/Paragraph/Paragraph";

interface Props {
    vendorId: number;
    selected: NotificationType[];
}

const NotificationsSelected: FC<Props> = ({ vendorId, selected }) => {
    const dispatch = useAppDispatch();

    const notifications = selected.map((notification, index) => {
        let marginBottom = 15;
        const message = notification.message;
        const datetime = DateApi.parseDateTime(notification.date);
        const date = `${DateApi.parseDateStringPrint(datetime[0])} ${datetime[1]}`;
        const origin = notification.typeId === TYPES.SYSTEM ? "Система" : "Клієнти";

        const prevStatusId = notification.statusId;
        const notificationsIds = [notification.id];

        const clickSelectButtonHandler = () => {
            dispatch(changeNotificationsStatusFetch({ 
                vendorId, 
                statusId: NotificationStatuses.ARCHIVE, 
                prevStatusId, notificationsIds 
            }));
        }

        return (
            <Notification
                marginBottom={marginBottom}
                notification={<Paragraph content={message} />}
                datatime={date}
                origin={origin}
                icon={<></>}
                selected={true}
                clickSelectButtonHandler={clickSelectButtonHandler}
            />
        );
    });

    return (
        notifications.length > 0 ?
            <NotificationsBlock maxWidth={475} title="Обрані">
                {notifications}
            </NotificationsBlock>
        :
            <></>
    );
}

export default NotificationsSelected;