import * as Yup from 'yup';
import { DateApi } from '../../../../services/Date/Date';
import { TODAY } from '../../../../utl/constants/date';

export default Yup.object().shape({
    userId: Yup.number().required('Required'),
    sum: Yup.number()
        .min(1, "invalid value")
        .required("Required"),
    date: Yup.date()
        .transform(DateApi.parseDateString)
        .min(TODAY)
        .required("Заповніть це поле"),
    purseId: Yup.number().required("Required"),
    operationId: Yup.number().required("Required"),
    groupId: Yup.number().min(0, "Invalid Value").required('Required'),
    studentId: Yup.number().required("Required"),
    comment: Yup.string(),
    scheduled: Yup.boolean().required('Required'),
});