import React, { ChangeEvent, FC } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./RadioBlock.module.css";
import classNames from "classnames";
import "./transition.css";

const cx = classNames.bind(styles);

export interface Props {
    visiable: boolean;
}

const RadioBlockSkeleton: FC<Props> = ({ visiable }) => {
    return (
        <CSSTransition
            in={visiable}
            timeout={400}
            unmountOnExit
            classNames="skeleton"
        >
            <div className={cx(styles.radioBlock, styles.skeleton)}></div>
        </CSSTransition>
    );
}

export default RadioBlockSkeleton;