import { FormikProps } from "formik";
import React, { FC } from "react";
import ModalForm from "../../../../components/dataEntry/forms/ModalForm/ModalForm";
import Checkbox from "../../../../components/dataEntry/inputs/Checkbox/Checkbox";
import Dropdown from "../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import Field from "../../../../components/dataEntry/inputs/Field/Field";
import SearchField from "../../../../components/dataEntry/inputs/SearchField/SearchField";
import Button from "../../../../components/general/buttons/Button/Button";
import IconFont from "../../../../components/general/icons/IconFont/IconFont";
import Row from "../../../../components/layout/Row/Row";
import Wrp from "../../../../components/layout/Wrp/Wrp";
import { Counteragent } from "../../../../store/state/finance/models/Counteragent";
import { Counterparty } from "../../../../store/state/finance/models/Counterparty";
import { Operations } from "../../../../store/state/money/models/Operations";
import { Purses } from "../../../../store/state/money/models/Purses";
import PaymentFormValue from "../../models/PaymentFormValue";
import OperationsDropdown from "../AddForm/components/OperationsDropdown/OperationsDropdown";
import PursesDropdown from "../AddForm/components/PursesDropdown/PursesDropdown";

interface Props {
    formik: FormikProps<PaymentFormValue>;
    visiable: boolean;
    closeModalHandler: () => void;
    handleSearchUser: (value: string) => void;
    counteragents: Counteragent[];
}

const PaymentForm: FC<Props> = ({ visiable, closeModalHandler, formik, handleSearchUser, counteragents }) => {
    const { isValid, values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched } = formik;

    const submitButtonHandler = () => {
        handleSubmit();
    }

    const purseId = values.purseId;

    const operationId = values.operationId;

    return (
        <ModalForm
            title="Додати витрати"
            visiable={visiable}
            maxWidth={480}
            handleClick={closeModalHandler}
        >
            <Wrp flexDirection="column" alignItems="center">
                <SearchField
                    topPositionIcon={32}
                    paddingLeft={50}
                    icon={<IconFont iconClass="icon-search" fontSize={20} color="#A6AEC5" />}
                    maxWidth={440}
                    label="Контрагент"
                    marginBottom={20}
                    labelLeftShift={0}
                    marginRight={0}
                    handleBlur={(touched: boolean) => setFieldTouched("counteragent.name", touched)}
                    setFieldValue={(value: any) => setFieldValue("counteragent", value)}
                    touched={touched.counteragent?.name}
                    error={errors.counteragent?.name}
                    handleSearch={handleSearchUser}
                    searchList={counteragents}
                    createField={true}
                    defaultFields={{ id: null, userId: null }}
                    fieldForEnteredInformation="name"
                />

                <Row marginBottom={20}>
                    <Field
                        label="Сума"
                        type="number"
                        maxWidth={210}
                        marginBottom={0}
                        labelLeftShift={0}
                        paddingLeft={35}
                        topPositionIcon={29.2}
                        icon={<span style={{ fontSize: 14, fontWeight: "500" }}>₴</span>}
                        value={values.sum}
                        handleBlur={handleBlur("sum")}
                        handleChange={handleChange("sum")}
                        touched={touched.sum}
                        error={errors.sum}
                    />

                    <PursesDropdown
                        lable="Рахунок"
                        nameField="purseId"
                        purseId={purseId}
                        error={errors.purseId}
                        touched={touched.purseId}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                    />
                </Row>
                <Row marginBottom={20}>
                    <OperationsDropdown
                        income={false}
                        operationId={operationId}
                        error={errors.operationId}
                        touched={touched.operationId}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                    />

                    <Field
                        label="Дата витрати"
                        type="date"
                        maxWidth={210}
                        marginBottom={0}
                        marginRight={0}
                        labelLeftShift={0}
                        value={values.date}
                        handleBlur={handleBlur("date")}
                        handleChange={handleChange("date")}
                        touched={touched.date}
                        error={errors.date}
                    />
                </Row>

                <Field
                    maxWidth={440}
                    label="Коментар"
                    marginBottom={20}
                    labelLeftShift={0}
                    marginRight={0}
                    value={values.comment}
                    handleBlur={handleBlur("comment")}
                    handleChange={handleChange("comment")}
                    touched={touched.comment}
                    error={errors.comment}
                />

                <Checkbox
                    handleChange={(value) => setFieldValue("scheduled", value)}
                    text="Зробити повторюваним"
                    marginBottom={20}
                />

                <Button
                    disabled={!isValid}
                    maxWidth={160}
                    handleClick={submitButtonHandler}
                    backgroundColor="#4775FE"
                    title="Додати"
                    marginBottom={0}
                    marginRight={15}
                />
            </Wrp>
        </ModalForm>
    );
}

export default PaymentForm;