import { PayloadAction } from "@reduxjs/toolkit";
import { ClientsState } from "../../models/ClientsState";
import { AddCommentSuccessPayload } from "../../payload/AddComment/AddCommentSuccessPayload";

const addCommentSuccess = (state: ClientsState, actions: PayloadAction<AddCommentSuccessPayload>) => {
    const { clientId, comment } = actions.payload;

    const clients = state.pageData;

    const clientIndex = clients.findIndex(client => client.clientId === clientId);
    if (clientIndex > -1) {
        const comments = state.pageData[clientIndex].comments
        if (comments) {
            const newComments = [...comments];
            newComments.unshift({ ...comment });
            state.pageData[clientIndex].comments = newComments;

            state.notificationsPages.totalCount = state.notificationsPages.totalCount + 1;
            state.notificationsPages.pageAll = Math.ceil(state.notificationsPages.totalCount / state.notificationsPages.pageSize);
        }
    }

    state.message = "Коментар було створено";
    state.messageVisiable = true;
    state.isLoading = false;
    state.isError = null;
}

export default addCommentSuccess;