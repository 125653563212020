import { AxiosResponse } from 'axios';
import instance from '../instance';
import { ChangeServiceActivityRequest, CreateServiceRequest, DeleteServiceRequest, DeleteServicesImagesRequest, GetAttendanceStatisticRequest, GetServiceRequest, GetServicesListRequest, UpdateServiceRequest, UploadServicesImagesRequest } from './Services.request';
import { CreateResponse, GetServiceResponse, GetServicesListResponse, ResGetAttendanceStatistic, UploadServicesImagesResponse } from './Services.response';

interface Services {
    create: (data: CreateServiceRequest) => Promise<AxiosResponse<CreateResponse>>;
    update: (data: UpdateServiceRequest) => Promise<AxiosResponse<any>>;
    delete: (data: DeleteServiceRequest) => Promise<AxiosResponse<any>>;
    getServicesList: (data: GetServicesListRequest) => Promise<AxiosResponse<GetServicesListResponse>>;
    getService: (data: GetServiceRequest) => Promise<AxiosResponse<GetServiceResponse>>;
    getAttendanceStatistic: (data: GetAttendanceStatisticRequest) => Promise<AxiosResponse<ResGetAttendanceStatistic>>;
    changeServiceActivity: (data: ChangeServiceActivityRequest) => Promise<AxiosResponse>;
    uploadServiceImages: (data: UploadServicesImagesRequest) => Promise<AxiosResponse<UploadServicesImagesResponse>>;
    deleteServiceImages: (data: DeleteServicesImagesRequest) => Promise<AxiosResponse>;
}

export const Services: Services = {
    create: async (data) => {
        const { vendorId, service } = data;

        return instance.post(`/vendors/${vendorId}/services`, service);
    },
    update: async (data) => {
        const { vendorId, service, serviceId } = data;

        return instance.put(`/vendors/${vendorId}/services/${serviceId}`, service);
    },
    delete: async (data) => {
        const { vendorId, serviceId } = data;

        return instance.delete(`/vendors/${vendorId}/services/${serviceId}`);
    },
    getServicesList: (data) => {
        const { vendorId, filter } = data;

        return instance.get(`/vendors/${vendorId}/services`, {
            params: {
                rangeFrom: filter?.range?.from,
                rangeTo: filter?.range?.to,
                short: filter?.short,
                active: filter?.active,
                confirmed: filter?.confirmed,
                typeId: filter?.typeId,
                categoryId: filter?.categoryId,
            }
        });
    },
    getService: (data) => {
        const { vendorId, serviceId } = data;

        return instance.get(`/vendors/${vendorId}/services/${serviceId}`);
    },
    getAttendanceStatistic: (data) => {
        const { vendorId, filter } = data;

        return instance.get(`/vendors/${vendorId}/services/attendance`, {
            params: {
                periodFrom: filter?.period?.from,
                periodTo: filter?.period?.to,
            }
        });
    },
    changeServiceActivity: (data) => {
        const { vendorId, serviceId, active } = data;

        return instance.put(`/vendors/${vendorId}/services/${serviceId}/active`, { active });
    },
    uploadServiceImages: (data) => {
        const { vendorId, serviceId, files } = data;

        const formData = new FormData();
        files.forEach(file => formData.append('files', file));

        return instance.post(`/vendors/${vendorId}/services/${serviceId}/images`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },
    deleteServiceImages: (data) => {
        const { vendorId, serviceId, links } = data;

        return instance.delete(`/vendors/${vendorId}/services/${serviceId}/images`, { data: { links } });
    },
}