import { PayloadAction } from "@reduxjs/toolkit";
import { GroupsState } from "../../models/GroupsState";
import { GetGroupSuccessPayload } from "../../payload/GetGroup/GetGroupSuccessPayload";

const getGroupSuccess = (state: GroupsState, action: PayloadAction<GetGroupSuccessPayload>) => {
    const group = action.payload;

    const pageGroupsData = state.pageData;
    const newPageGroupsData = [...pageGroupsData];

    const indexGroup = newPageGroupsData.findIndex(pageData => pageData.id === group.id);
    if (indexGroup === -1) {
        newPageGroupsData.push(group);
    } else {
        const oldGroup = newPageGroupsData[indexGroup];
        newPageGroupsData[indexGroup] = { ...oldGroup, ...group };
    }

    state.pageData = newPageGroupsData;
}

export default getGroupSuccess;