import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import Dropdown from "../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { useAppSelector } from "../../../../../../store/hooks/selector.hook";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../../utl/constants/defaultValue";

interface Props {
    locationId: number;
    error: string | undefined;
    touched: boolean | undefined;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
}

const LocationsDropdown: FC<Props> = ({ locationId, error, touched, setFieldValue, setFieldTouched }) => {
    const locations = useAppSelector(state => state.locations.locationsArray, shallowEqual);

    const indexLocations = locations.findIndex(location => location.id === locationId);

    const defaultLocations = locations.length > 0 && locations[indexLocations];
    const defaultValueLocations: DefaultValue = {
        valueInput: (defaultLocations && String(defaultLocations.id)) || DEFAULT_VALUES_DROPDOWN.valueInput,
        valueText: defaultLocations && defaultLocations.name || DEFAULT_VALUES_DROPDOWN.valueText,
    }

    const locationItems = locations.map((location, index) => (
        <DropdownItem
            key={"LOCATION_ITEM_" + index}
            item={location.name}
            valueInput={String(location.id)}
            valueText={location.name}
        />
    ));

    const changeHandler = (locationId: string) => {
        setFieldValue("locationId", parseInt(locationId));
    }

    const blurHandler = (touched: boolean) => {
        setFieldTouched("serviceId", touched)
    }

    return (
        <Dropdown
            label="Локація"
            maxWidth={240}
            height={40}
            marginRight={20}
            defaultValue={defaultValueLocations}
            doubleClickDropdown={false}
            error={error}
            touched={touched}
            handleBlur={blurHandler}
            handleChange={changeHandler}
        >
            {locationItems}
        </Dropdown>
    );
}

export default LocationsDropdown;