import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Clients } from "../../../../services/reddyApi/Clients/Clients";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getStatisticsDashboard, getStatisticsFinance } from "../../statistics/statistics.saga";
import { deleteForVendorFetch, deleteForVendorSuccess } from "../clients.slice";
import { DeleteClientForVendorPayload } from "../payload/DeleteClientForVendor/DeleteClientForVendorPayload";

function* deleteForVendorWorker(action: PayloadAction<DeleteClientForVendorPayload>) {
    const { vendorId, clientId, filter } = action.payload;
    const dataParams = {
        vendorId,
        clientId
    }

    try {
        const { data } = yield call(Clients.deleteForVendor, dataParams);

        yield put(deleteForVendorSuccess({ clientId }));

        yield getStatisticsDashboard({ vendorId, filter });

        yield getStatisticsFinance({ vendorId, filter });

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => deleteForVendorFetch(action.payload);
            
            yield handleServerError(error, actionCreator);
        }
    }
}

export default deleteForVendorWorker;