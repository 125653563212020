import { takeLatest } from "redux-saga/effects";
import changeEmployeeActivityWorker from "./changeEmployeeActivityWorker";
import createWorker from "./createWorker";
import deleteWorker from "./deleteWorker";
import getEmployeeWorker from "./getEmployeeWorker";
import getEmployessWorker from "./getEmployessWorker";
import getTeachersListWorker from "./getTeachersListWorker";
import sendPasswordWorker from "./sendPasswordWorker";
import updateWorker from "./updateWorker";

export function* employeesWatcher() {
    yield takeLatest("employees/getEmployeesListFetch", getEmployessWorker);
    yield takeLatest("employees/createEmployeeFetch", createWorker);
    yield takeLatest("employees/updateEmployeeFetch", updateWorker);
    yield takeLatest("employees/deleteEmployeeFetch", deleteWorker);
    yield takeLatest("employees/sendPasswordFetch", sendPasswordWorker);
    yield takeLatest("employees/getTeachersListFetch", getTeachersListWorker);
    yield takeLatest("employees/getEmployeeFetch", getEmployeeWorker);
    yield takeLatest("employees/changeEmployeeActivityFetch", changeEmployeeActivityWorker);
}