import React, { FC, MouseEvent } from "react";
import { PagePagination } from "../../../components/dataDisplay/table/models/PagePagination";
import Table from "../../../components/dataDisplay/table/Table";
import Wrp from "../../../components/layout/Wrp/Wrp";
import { DATA_INDEX_BASIC_SERVICES } from "../../../utl/constants/tables";
import { BasicsColumn } from "./models/BasicsColumn";

interface Props {
    data: BasicsColumn[];
    page: number;
    pages: PagePagination[];
    handleLeftButtonPagination: () => void;
    handleRightButtonPagination: () => void;
    handleClickRow: (item: any, event: MouseEvent<HTMLTableRowElement>) => void;
}

const Basics: FC<Props> = ({data, pages, page, handleLeftButtonPagination, handleRightButtonPagination, handleClickRow}) => {
    return (
        <Wrp maxWidth={1280}>
            <Table 
                handleClickRow={handleClickRow}
                data={data} 
                columns={DATA_INDEX_BASIC_SERVICES} 
                page={page}
                pages={pages}
                handleLeftButtonPagination={handleLeftButtonPagination}
                handleRightButtonPagination={handleRightButtonPagination} 
                paginated={true}
            />
        </Wrp>
    );
}

export default Basics;