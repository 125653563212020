import { PayloadAction } from "@reduxjs/toolkit";
import { DateApi } from "../../../../../services/Date/Date";
import { GroupsState } from "../../models/GroupsState";
import { SetStudentsVisitForLessonSuccessPayload } from "../../payload/SetStudentsVisitForLesson/SetStudentsVisitForLessonSuccessPayload";

const setStudentsVisitForLessonSuccess = (state: GroupsState, action: PayloadAction<SetStudentsVisitForLessonSuccessPayload>) => {
    const { students, lessonId, groupId } = action.payload;
    const groups = state.pageData;
    const groupIndex = groups.findIndex(item => item.id === groupId);
    if (groupIndex > -1) {
        const group = groups[groupIndex];
        group.students?.forEach(student => {
            const studentId = student.student.id;
            const indexStudentLessons = students.findIndex(studentLessons => studentLessons.id === studentId);
            if (indexStudentLessons > -1) {
                const studentVisit = students[indexStudentLessons];
                let studentLessons = student.lessons;
                const lessonStudentIndex = studentLessons.findIndex(studentLesson => studentLesson.id === lessonId);
                const lessons = group.lessons;
                const lessonIndex = lessons.findIndex(lesson => lesson.id === lessonId);
                if (lessonIndex > -1) {
                    const lesson = lessons[lessonIndex];
                    if (lessonStudentIndex > -1) {
                        const studentLesson = studentLessons[lessonStudentIndex];
                        studentLesson.attended = studentVisit.attended;
                    } else {
                        const datetime = DateApi.parseDateTime(lesson.datetime);
                        studentLessons.push({
                            id: lessonId,
                            name: lesson.name,
                            date: datetime[0],
                            attended: studentVisit.attended,
                        });
                    }
                    let amountAttendedLessons = 0;
                    studentLessons.forEach(item => {
                        if (item.attended) {
                            amountAttendedLessons++;
                        }
                    });
                    student.attendance = (amountAttendedLessons * 100) / lessons.length;
                    lesson.completed = true;
                }
            }
        });
    }
    state.isLoading = false;
    state.isError = null;
}

export default setStudentsVisitForLessonSuccess;