import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Vendors } from "../../../../services/reddyApi/Vendors/Vendors";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { ConfirmVendorPayload } from "../payload/ConfirmVendor/ConfirmVendorPayload";
import { confirmVendorFetch, confirmVendorSuccess } from "../vendors.slice";

function* confirmVendorsWorker(action: PayloadAction<ConfirmVendorPayload>) {
    const payload = action.payload;

    try {
        const { data, status } = yield call(Vendors.confirmVendor,
            { vendorId: payload.vendorId, confirmedTo: payload.confirmedTo, confirmedFrom: payload.confirmedFrom }
        );
        const { vendorId, active } = data;
        if (status === 200) {
            yield put(confirmVendorSuccess({ vendorId, active }));

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => confirmVendorFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default confirmVendorsWorker;