import React, { FC } from "react";
import { NEGATIVE_MEANING } from "../../../../../utl/constants/operationsWithNumbers";
import NameView from "../NameView/NameView";

interface Props {
    price: number;
    subprice?: number;
    exchange?: boolean;
    debt?: boolean;
    income?: boolean;
}

const PriceView: FC<Props> = ({price, subprice, exchange = false, debt = false, income}) => {
    let color = "#131313";
    let name = "0";
    
    if(debt) {
        name = `₴ ${price < 0 ? price.toFixed(1) : 0}`;
        color = price < 0 ? "#FA5D54" : "#131313";
    } else if(exchange) {
        name = price > NEGATIVE_MEANING ? "+" + price.toFixed(1) : `${price}`;
        color = "#A6AEC5";
    } else {
        name = price > NEGATIVE_MEANING ? "+" + price.toFixed(1) : `${price}`;
        color = price < 0 ? "#FA5D54" : "#4775FE";
    }

    const subname = (subprice && `${(`₴ ${subprice}`)}`) || "";
    
    return (
        <NameView 
            name={name}  
            subname={subname}
            color={color}
        />
    );
}

export default PriceView;