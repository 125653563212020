import { AxiosResponse } from "axios"
import instance from "../instance";
import {
    AddStudentToGroupRequest,
    ChangeGroupActivityRequest,
    ChangeStudentStatusForGroupRequest,
    CreateRequest,
    DeleteGroupRequest,
    DeleteStudentForGroupRequest,
    GetGroupGeneralDataRequest,
    GetGroupRequest,
    getGroupsListForVendorRequest,
    getStudentDataForGroupRequset,
    getStudentsListForGroupRequest,
    SearchGroupsByFilterRequest,
    SetStudentsVisitForLessonRequest,
    UpdateRequest,
    UpdateStudentForGroupRequest
} from "./Groups.request";
import {
    ChangeStudentStatusForGroupResponse,
    CreateResponse,
    GetGroupGeneralDataResponse,
    getGrouprResponse,
    getGroupsListForVendorResponse,
    GetStudentDataForGroupResponse,
    GetStudentsListForGroupResponse,
} from "./Groups.response";

interface Groups {
    create: (data: CreateRequest) => Promise<AxiosResponse<CreateResponse>>;
    update: (data: UpdateRequest) => Promise<AxiosResponse>;
    addStudentToGroup: (data: AddStudentToGroupRequest) => Promise<AxiosResponse>;
    changeStudentStatusForGroup: (data: ChangeStudentStatusForGroupRequest) =>
        Promise<AxiosResponse<ChangeStudentStatusForGroupResponse, any>>;
    setStudentsVisitForLesson: (data: SetStudentsVisitForLessonRequest) => Promise<AxiosResponse>;
    getStudentDataForGroup: (data: getStudentDataForGroupRequset) =>
        Promise<AxiosResponse<GetStudentDataForGroupResponse>>;
    getStudentsListForGroup: (data: getStudentsListForGroupRequest) =>
        Promise<AxiosResponse<GetStudentsListForGroupResponse>>;
    getGroupGeneralData: (data: GetGroupGeneralDataRequest) => Promise<AxiosResponse<GetGroupGeneralDataResponse>>;
    getGroupsListForVendor: (data: getGroupsListForVendorRequest) =>
        Promise<AxiosResponse<getGroupsListForVendorResponse>>;
    searchGroupsByFilter: (date: SearchGroupsByFilterRequest) => Promise<AxiosResponse<getGroupsListForVendorResponse>>;
    updateStudentForGroup: (date: UpdateStudentForGroupRequest) => Promise<AxiosResponse>;
    getGroup: (data: GetGroupRequest) => Promise<AxiosResponse<getGrouprResponse>>;
    deleteGroup: (data: DeleteGroupRequest) => Promise<AxiosResponse>;
    deleteStudentForGroup: (data: DeleteStudentForGroupRequest) => Promise<AxiosResponse>;
    changeGroupActivity: (data: ChangeGroupActivityRequest) => Promise<AxiosResponse>;
}

export const Groups: Groups = {
    create: (data) => {
        const { vendorId, group } = data;

        return instance.post(`/vendors/${vendorId}/groups`, group);
    },
    update: (data) => {
        const { vendorId, group, groupId } = data;

        return instance.put(`/vendors/${vendorId}/groups/${groupId}`, group);
    },
    addStudentToGroup: (data) => {
        const { vendorId, groupId, student } = data;

        return instance.post(`/vendors/${vendorId}/groups/${groupId}/students`, student);
    },
    changeStudentStatusForGroup: (data) => {
        const { vendorId, groupId, status, studentId } = data;

        return instance.put(`/vendors/${vendorId}/groups/${groupId}/students/${studentId}/status`, { status });
    },
    setStudentsVisitForLesson: (data) => {
        const { vendorId, groupId, lessonId, students, employeesIds } = data;

        return instance.put(`/vendors/${vendorId}/groups/${groupId}/lessons/${lessonId}/visits`, { students, employeesIds });
    },
    getStudentDataForGroup: (data) => {
        const { vendorId, groupId, studentId } = data;

        return instance.get(`/vendors/${vendorId}/groups/${groupId}/students/${studentId}`);
    },
    getStudentsListForGroup: (data) => {
        const { vendorId, groupId, filter } = data;

        return instance.get(`/vendors/${vendorId}/groups/${groupId}/students`, {
            params: {
                statusId: filter?.statusId,
            }
        });
    },
    getGroupGeneralData: (data) => {
        const { vendorId, groupId } = data;

        return instance.get(`/vendors/${vendorId}/groups/${groupId}/general`);
    },
    getGroupsListForVendor: (data) => {
        const { vendorId, filter } = data;

        return instance.get(`/vendors/${vendorId}/groups`, {
            params: {
                rangeFrom: filter?.range?.from,
                rangeTo: filter?.range?.to,
                confirmed: filter?.confirmed,
                typeId: filter?.typeId,
                categoryId: filter?.categoryId,
                active: filter?.active,
            }
        });
    },
    searchGroupsByFilter: (data) => {
        const { vendorId, name } = data;

        return instance.post(`/vendors/${vendorId}/groups/search`, { name });
    },
    updateStudentForGroup: (data) => {
        const { vendorId, groupId, studentData, studentId } = data;

        return instance.put(`/vendors/${vendorId}/groups/${groupId}/students/${studentId}`, studentData);
    },
    getGroup: (data) => {
        const { vendorId, groupId } = data;

        return instance.get(`/vendors/${vendorId}/groups/${groupId}`);
    },
    deleteGroup: (data) => {
        const { groupId, vendorId } = data;

        return instance.delete(`/vendors/${vendorId}/groups/${groupId}`);
    },
    deleteStudentForGroup: (data) => {
        const { groupId, vendorId, studentId } = data;

        return instance.delete(`/vendors/${vendorId}/groups/${groupId}/students/${studentId}`);
    },
    changeGroupActivity: (data) => {
        const { vendorId, groupId, active } = data;

        return instance.put(`/vendors/${vendorId}/groups/${groupId}/active`, { active });
    }
}