import { PayloadAction } from "@reduxjs/toolkit";
import { UserState } from "../../models/UserState";
import { DataUserPayload } from "../../payload/DataUserPayload";

const getUserDataSuccess = (state: UserState, action: PayloadAction<DataUserPayload>) => {
    const { id, roleName, roleId, vendorId, name } = action.payload;

    state.id = id;
    state.name = name;
    state.roleName = roleName;
    state.roleId = roleId;
    state.vendorId = vendorId;
    
    state.isLoading = false;
    state.isError = null;
}

export default getUserDataSuccess;