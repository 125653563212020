import { PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { fi } from "date-fns/locale";
import { call, put } from "redux-saga/effects";
import { Services } from "../../../../../services/reddyApi/Services/Services";
import { UploadServicesImagesResponse } from "../../../../../services/reddyApi/Services/Services.response";
import handleServerError from "../../../all/saga/handleServerError";
import setServerErrorDefault from "../../../all/saga/setServerErrorDefault";
import getNotifications from "../../../notifications/saga/getNotifications";
import { DeleteServicesImagesPayload } from "../payload/DeleteServicesImages/DeleteServicesImagesPayload";
import { UploadServicesImagesPayload } from "../payload/UploadServicesImages/UploadServicesImagesPayload";
import { deleteServiceImages, uploadServiceImagesFetch, uploadServiceImagesSuccess } from "../service.slice";

type Payload = UploadServicesImagesPayload & DeleteServicesImagesPayload;

function* uploadServiceImagesWorker(action: PayloadAction<Payload>) {
    const { files, links, serviceId, vendorId } = action.payload;

    try {
        if (files.length > 0) {
            const { status, data }: AxiosResponse<UploadServicesImagesResponse> =
                yield call(Services.uploadServiceImages, { files, serviceId, vendorId });

            if (status === 200) {
                const linksImeges = data.links;

                yield put(uploadServiceImagesSuccess({ links: linksImeges, serviceId }));
            }
        }

        if (links.length > 0) {
            const { status } = yield call(Services.deleteServiceImages, { links, serviceId, vendorId });

            if (status === 200) {
                yield put(deleteServiceImages({ serviceId, links }));
            }
        }

        yield getNotifications(vendorId);

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => uploadServiceImagesFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default uploadServiceImagesWorker;