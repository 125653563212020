import { Discounts } from "../../store/state/money/models/Discounts";

interface ReturnValue {
    name: string;
    value: number;
}

export const discountsAdapter = (discounts: Discounts[], discountId: number): ReturnValue | null => {
    for(let discount of discounts) {
        if(discount.id === discountId) {
            const {name, value} = discount;

            return {name, value};
        }
    }

    return null;
}