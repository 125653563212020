import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Employees } from "../../../../services/reddyApi/Employees/Employees";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { getStatisticsDashboard, getStatisticsFinance } from "../../statistics/statistics.saga";
import { createEmployeeFetch, createEmployeeSuccess } from "../employees.slice";
import { CreateEmployeePayload } from "../payload/CreateEmployee/CreateEmployeePayload";
import handleError from "./handleError";

function* createWorker(action: PayloadAction<CreateEmployeePayload>) {
    const { vendorId, employee, filter } = action.payload;
    try {
        const { data } = yield call(Employees.create, action.payload);
        const { employeeId } = data;

        yield put(createEmployeeSuccess({ ...employee, id: employeeId }));

        yield getStatisticsDashboard({ vendorId, filter });

        yield getStatisticsFinance({ vendorId, filter });

        yield getNotifications(vendorId);

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const statusCode = error.response?.status;

            if (statusCode === 472 || statusCode === 473) {
                handleError(statusCode);
            } else {
                const actionCreator = () => createEmployeeFetch(action.payload);

                yield handleServerError(error, actionCreator);
            }
        }
    }
}

export default createWorker;