import { takeLatest } from "redux-saga/effects"
import loginWorker from "./loginWorker"
import registerVendorWorker from "./registerVendorWorker"
import resetPasswordWorker from "./resetPasswordWorker"
import sendEmailWorker from "./sendEmailWorker"

export function* userWatcher() {
    yield takeLatest("user/getUserDataFetch", loginWorker);
    yield takeLatest("user/forgotPasswordFetch", sendEmailWorker);
    yield takeLatest("user/resetPasswordFetch", resetPasswordWorker);
    yield takeLatest("user/registerVendorFetch", registerVendorWorker);
}