import React, { FC } from "react";
import { Diagrams } from "../../../../components/dataDisplay/schedules/ProgressGraph/models/Diagrams";
import ProgressGraph from "../../../../components/dataDisplay/schedules/ProgressGraph/ProgressGraph";
import Wrp from "../../../../components/layout/Wrp/Wrp";

interface Props {
    diagrams: Diagrams[]
}

const GraphBlock: FC<Props> = ({diagrams}) => {
    return (
        diagrams.length > 0 ? <Wrp marginBottom={40}>
            <ProgressGraph 
                diagrams={diagrams} 
            />
        </Wrp> 
        :
        <></>
    )
}

export default GraphBlock;