import React, { FC } from "react";
import styles from "./Info.module.css";

interface Props {
    text: string
    type?: "string" | "email" | "tel";
}

const Info: FC<Props> = ({type, text}) => {
    switch (type) {
        case "email" :
            return (
                <a href={`mailto:${text}`} className={styles.link}>
                    {text}
                </a>
            );
        case "tel" :
            return (
                <a href={`tel:${text}`} className={styles.link}>
                    {text}
                </a>
            );
        default : return (
            <p className={styles.text}>
                {text}
            </p>
        );
    }
}

export default Info;