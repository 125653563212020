import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Groups } from "../../../../services/reddyApi/Groups/Groups";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getGroupFetch, getGroupSuccess } from "../groups.slice";
import { GetGroupPayload } from "../payload/GetGroup/GetGroupPayload";

function* getGroupWorker(action: PayloadAction<GetGroupPayload>) {
    const { vendorId, groupId } = action.payload;

    try {
        const { data } = yield call(Groups.getGroup, { vendorId, groupId });

        yield put(getGroupSuccess(data));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getGroupFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getGroupWorker;