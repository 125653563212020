import * as Yup from 'yup';
import { DateApi } from '../../../../../../services/Date/Date';

export default Yup.object().shape({
    name: Yup.string()
        .required('Required'),
    surname: Yup.string()
        .required('Required'),
    email: Yup.string()
        .required(),
    phone: Yup.string()
        .required('Required'),
    relationshipStatus: Yup.number()
        .min(0, "Invalid Value")
        .required('Required'),
    discountId: Yup.number().nullable(),
    students: Yup.array(Yup.object().shape({
            name: Yup.string(),
            surname: Yup.string(),
            birthday:  Yup.date()
                .transform(DateApi.parseDateString)
                .required('Required'),
        })
    )
    .required('Required'),
});