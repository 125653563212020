import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Groups } from "../../../../services/reddyApi/Groups/Groups";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { 
    getStatisticsClient,
    getStatisticsDashboard, 
    getStatisticsFinance, 
    getStatisticsService 
} from "../../statistics/statistics.saga";
import { deleteGroupFetch, deleteGroupSuccess } from "../groups.slice";
import { DeleteGroupPayload } from "../payload/DeleteGroup/DeleteGroupPayload";

function* deleteGroupWorker(action: PayloadAction<DeleteGroupPayload>) {
    const { vendorId, groupId, filter, serviceId, clientsIds } = action.payload;

    try {
        const { data } = yield call(Groups.deleteGroup, { vendorId, groupId });

        yield put(deleteGroupSuccess({ groupId }));

        yield getStatisticsDashboard({ vendorId, filter });

        yield getStatisticsFinance({ vendorId, filter });

        for (let clientId of clientsIds) {
            yield getStatisticsClient({ vendorId, clientId });
        }

        yield getStatisticsService({ vendorId, serviceId });

        yield getNotifications(vendorId);

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => deleteGroupFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default deleteGroupWorker;