import { PayloadAction } from "@reduxjs/toolkit";
import { Pages } from "../clients.slice";
import { ClientsState } from "../models/ClientsState";
import { SetDefaultValuePayload } from "../payload/SetDefaultValuePayload";

const setDefaultValueClients = (state: ClientsState, action: PayloadAction<SetDefaultValuePayload>) => {
    const {
        visiableCommentForm,
        visiableTransactionForm,
        transactionsPages,
        clientsPages,
        searchedClients,
        importedClients,
    } = action.payload;

    if (visiableCommentForm) {
        state.visiableCommentForm = false;
    }

    if (visiableTransactionForm) {
        state.visiableTransactionForm = false;
    }

    if (transactionsPages) {
        state.transactionsPages = { ...Pages };
    }

    if (clientsPages) {
        state.clientsPages = { ...Pages };
    }

    if (searchedClients) {
        state.searchedClients = [];
    }

    if (importedClients) {
        state.importedClients = false;
    }
}

export default setDefaultValueClients;