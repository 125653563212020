import { PayloadAction } from "@reduxjs/toolkit";
import { FinanceState } from "../models/FinanceState";
import { SetTransactionsPayload } from "../payload/SetTransactionsPayload";

const setTransactions = (state: FinanceState, action: PayloadAction<SetTransactionsPayload>) => {
    const { transactions } = action.payload;

    state.finance.transactions = transactions;
}

export default setTransactions;