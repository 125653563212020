import { FormikErrors, FormikTouched } from "formik";
import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import CheckboxBlock from "../../../../../../../../components/dataEntry/inputs/CheckboxBlock/CheckboxBlock";
import TagInput from "../../../../../../../../components/dataEntry/inputs/TagInput/TagInput";
import Wrp from "../../../../../../../../components/layout/Wrp/Wrp";
import { useAppSelector } from "../../../../../../../../store/hooks/selector.hook";
import { StudentVisit } from "../../../../../../models/SetStudentsVisitForLessonValues";

interface Props {
    groupUpsellsIds: number[];
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    students: StudentVisit[];
    error: string | string[] | FormikErrors<StudentVisit>[] | undefined;
    touched: FormikTouched<StudentVisit>[] | undefined;
}

const Students: FC<Props> = ({ groupUpsellsIds, setFieldTouched, setFieldValue, students, error, touched }) => {
    let upsellsIds = useAppSelector(state => state.upsellsServices.upsells, shallowEqual);
    const upsellsList = upsellsIds
        .filter(upsellsService => upsellsService.active)
        .filter(upsell => groupUpsellsIds.findIndex(upsellId => upsellId === upsell.id) > -1)
        .map(upsell => ({ id: upsell.id, name: upsell.name }));

    const studentsItems: JSX.Element[] = students.map((student, index) => {
        const touchedStundets = touched;
        const errorsStundets = error;

        const changetagInputHandler = (value: string) => {
            setFieldValue(`students[${index}].upsellsIds`, value)
        }

        const changeCheckboxHandler = (value: boolean) => {
            setFieldValue(`students[${index}].attended`, value)
        }

        const activeUpsells = student.upsellsIds !== "" ? student.upsellsIds.split(',')
            .map(upsellsId => (
                {
                    id: Number(upsellsId),
                    name:
                    upsellsList[upsellsList.findIndex(upsell => upsell.id === Number(upsellsId))]?.name || "Name"
                }
            )) : [];

        return (
            <Wrp flexDirection="column" marginBottom={15}>
                <CheckboxBlock
                    name={student.name}
                    value={student.attended}
                    handleChange={changeCheckboxHandler}
                />

                <>
                    {student.attended &&
                        <TagInput
                            label="Додаткові послуги"
                            tagsArray={upsellsList}
                            maxWidth={450}
                            activeTagsArray={activeUpsells}
                            handleChange={changetagInputHandler}
                            values={student.upsellsIds}
                        />
                    }
                </>
            </Wrp>
        )
    });

    return (
        <Wrp flexDirection="column" maxWidth={480}>
            {studentsItems}
        </Wrp>
    );
}

export default Students