import React, { FC } from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import styles from "./SidebarItem.module.css";
import classNames from "classnames";
const cx = classNames.bind(styles);

interface Props {
    icon: JSX.Element;
    visiableAll: boolean;
    name: string;
    link: string;
    active?: boolean;
    handleDefault?: () => void;
}

const SidebarItem: FC<Props> = ({icon, link, visiableAll, name, active, handleDefault}) => {
    
    return (
        <Link onClick={handleDefault} to={link} className={cx(styles.item, visiableAll && styles.open, active && styles.active)}>
            <div className={styles.iconBlock}>
                {icon}
            </div>
            <CSSTransition
                in={visiableAll}
                timeout={200}
                classNames={"fade"}
                unmountOnExit
            >
                <p className={styles.name}>
                    {name}
                </p>
            </CSSTransition>
        </Link>
    );
}

export default SidebarItem;