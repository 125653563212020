import React, { ChangeEvent, FC, useContext, useRef, useState } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import Avatar from "../../dataDisplay/user/Avatar/Avatar";
import Header from "./Header";
import BreadcrumbsContainer from "../Breadcrumbs/Breadcrumbs.container";
import Wrp from "../../layout/Wrp/Wrp";
import ButtonNotifications from "../../general/buttons/ButtonNotifications/ButtonNotifications";
import ButtonHeader from "./ButtonHeader";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonAddForm from "../../../screens/Finance/FinanceForms/AddForm/Button";
import ButtonExchangeForm from "../../../screens/Finance/FinanceForms/ExchangeForm/Button";
import ButtonPaymentForm from "../../../screens/Finance/FinanceForms/PaymentForm/Button";
import ButtonGoBack from "./ButtonGoBack";
import "./transition.css";
import styles from "./Header.module.css";
import { CSSTransition } from "react-transition-group";
import token from "../../../services/Token/token";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { userExit } from "../../../store/state/user/user.slice";
import { resetOtherData, setLoadingData } from "../../../store/state/all/all.slice";
import CreateClientBlock from "../../../screens/Groups/Group/GroupClients/CreateClientBlock/CreateClientBlock";
import ButtonFunctions, { Context } from "../../general/buttons/ButtonFunctions/ButtonFunctions";
import ImportFile from "../../dataEntry/inputs/ImportFile/ImportFile";
import { importClientsByVendorFetch, resetClients, setDefaultValueClients } from "../../../store/state/clients/clients.slice";
import { resetGroups, setDefaultValueGroups } from "../../../store/state/groups/groups.slice";
import { resetMoneyTransaction, setDefaultValueFinance } from "../../../store/state/finance/finance.slice";
import { resetVendors } from "../../../store/state/vendors/vendors.slice";
import { resetServices } from "../../../store/state/services/service/service.slice";
import { resetUpsells } from "../../../store/state/services/upsells/upsells.slice";
import { resetNotifications } from "../../../store/state/notifications/notifications.slice";
import { resetMoneyCategories } from "../../../store/state/money/money.slice";
import { resetLocations } from "../../../store/state/locations/locations.slice";
import { resetEmployee } from "../../../store/state/employees/employees.slice";
import { resetDashboard } from "../../../store/state/dashboard/dashboard.slice";
import { resetConfig } from "../../../store/state/configuration/configuration.slice";

const HeaderContainer: FC = () => {
    const dispatch = useAppDispatch();
    let navigation = useNavigate();
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    const user = useAppSelector(state => state.user, shallowEqual);
    const notificationsActive = useAppSelector(state => state.notifications.active, shallowEqual);
    const hasNotification = notificationsActive.length > 0;
    const templateCSV = useAppSelector(state => state.clients.templateCSV, shallowEqual);
    const hasMessage = useAppSelector(state => state.all.hasMessage, shallowEqual);
    let location = useLocation();
    const path = location.pathname;

    const locations = path.split("/");
    locations.shift();

    const isFinancePage = path.includes("finance");
    const isClientsPage = locations[0].localeCompare("clients") === 0 && locations.length < 2;

    let widthRight = 110;
    let button = <></>;
    if (isFinancePage) {
        button = <><ButtonAddForm /><ButtonPaymentForm /><ButtonExchangeForm /></>;
    } else if (isClientsPage) {
        button = <>
            <ButtonFunctions name="Імпорт" marginRight={15}>
                <DownloadLinkFile
                    template={templateCSV}
                    title="Скачати приклад файлу"
                />

                <ImportClientsFile
                    vendorId={vendorId}
                />
            </ButtonFunctions>
            <CreateClientBlock isCreationScript={true} buttonName="Додати" />
        </>
    } else if (((path.includes("settings") || path.includes("employee") || path.includes("services") || path.includes("groups"))
        && locations.length > 2)
        || (locations.length > 1
            && !(path.includes("settings") || path.includes("services")))) {
        button = <ButtonGoBack />
    } else if (((path.includes("settings") || (path.includes("employee") || path.includes("groups")) && locations.length < 2)
        || path.includes("services")) && !path.includes("money") && locations.length < 3) {
        button = <ButtonHeader />;
    }

    switch (locations[0]) {

        case "finance":
            widthRight = 715;
            break;
        case "clients":
            if(locations.length === 1) {
                widthRight = 550;
            } else {
                widthRight = 305;
            }
           
            break;
        case "settings":
            if (locations.length > 1 && locations[1].localeCompare("money") === 0) {
                widthRight = 110;
            } else {
                widthRight = 305;
            }
            break;
        case "groups":
        case "services":
        case "employees":
            widthRight = 305;
            break;
        default:
            widthRight = 110;
    }

    const clickButtonNotificationsHandler = () => {
        navigation('notifications');
        
        dispatch(setDefaultValueGroups({
            searchCleints: true, 
            searchGroups: true, 
            selectedService: true,
        }));
        dispatch(setDefaultValueClients({
            transactionsPages: true
        }));
        dispatch(setDefaultValueFinance({
            counterpartis: true,
            counteragents: true,
        }));
    }

    return (
        <Header
            left={<BreadcrumbsContainer />}
            widthRight={widthRight}
            right={
                <Wrp alignItems="center" justifyContent="space-between">
                    {button}
                    <Wrp maxWidth={110} alignItems="center" justifyContent="space-between">
                        <ButtonNotifications
                            iconClass="icon-notification"
                            type="nitification"
                            width={25}
                            height={25}
                            handleClick={clickButtonNotificationsHandler}
                            active={hasNotification}
                        />

                        <UserMenu userName={user.name} userAvatar={user.avatar} />
                    </Wrp>
                </Wrp>
            }
        />
    )
}

interface PropsDLF {
    title: string;
    template: string | null;
}

const DownloadLinkFile: FC<PropsDLF> = ({ title, template }) => {
    const context = useContext(Context);
    let templateURL = "";
    if (template) {
        const templateCSV = new Blob([template], { type: 'text/csv;charset=utf-8' });
        templateURL = URL.createObjectURL(templateCSV);
    }

    const inputRef = useRef<HTMLAnchorElement | null>(null);

    const clickLinkHandler = () => {
        context?.blurHandler();
    }
    return (
        <li>
            <a ref={inputRef} onClick={clickLinkHandler} href={templateURL} download={"template-clients.csv"}>
                {title}
            </a>
        </li>
    )
}

interface PropsICF {
    vendorId: number;
}

const ImportClientsFile: FC<PropsICF> = ({ vendorId }) => {
    const context = useContext(Context);
    const dispatch = useAppDispatch();
    const changeFileHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target && event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

            dispatch(importClientsByVendorFetch({ vendorId, file }));
            context?.blurHandler();
        }
    }

    return (
        <li>
            <ImportFile changeFileHandler={changeFileHandler} acceptFile=".CSV" />
        </li>
    )
}

interface PropsAB {
    userName: string;
    userAvatar: string;
}

const UserMenu: FC<PropsAB> = ({ userName, userAvatar }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [toggle, setToggle] = useState(false);
    const clickUserMenuHandler = () => {
        setToggle(prev => !prev);
    }

    const clickExitButtonHandler = () => {
        token.clear();

        dispatch(userExit());
        dispatch(resetVendors());
        dispatch(resetServices());
        dispatch(resetUpsells());
        dispatch(resetNotifications());
        dispatch(resetMoneyCategories());
        dispatch(resetLocations());
        dispatch(resetGroups());
        dispatch(resetMoneyTransaction());
        dispatch(resetEmployee());
        dispatch(resetDashboard());
        dispatch(resetConfig());
        dispatch(resetClients());
        dispatch(resetOtherData());

        navigate("signin");
        dispatch(setLoadingData({ status: false }));
    }

    const blurUserMenuHandler = () => {
        setToggle(false);
    }

    return (
        <div className={styles.menuBlock}
            onClick={clickUserMenuHandler}
            onBlur={blurUserMenuHandler}
        >
            <Avatar name={userName} />
            <CSSTransition
                in={toggle}
                timeout={400}
                unmountOnExit
                classNames="menu"
            >
                <div className={styles.menu}>
                    <button className={styles.buttonExit} onClick={clickExitButtonHandler}>
                        Вихід
                    </button>
                </div>
            </CSSTransition>
        </div>
    );
}

export default React.memo(HeaderContainer);