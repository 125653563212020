import { PayloadAction } from "@reduxjs/toolkit";
import deleteItem from "../../../../helpers/deleteItem";
import { ClientsState } from "../../models/ClientsState";
import { DeleteClientForVendorSuccessPayload } from "../../payload/DeleteClientForVendor/DeleteClientForVendorSuccessPayload";

const deleteForVendorSuccess = (state: ClientsState, action: PayloadAction<DeleteClientForVendorSuccessPayload>) => {
    const { clientId } = action.payload;

    const clients = state.clients;
    deleteItem(state.clientsPages, clients, clientId);

    state.isLoading = false;
    state.isError = null;
}

export default deleteForVendorSuccess;