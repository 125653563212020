import React, { FC, FocusEvent } from "react";
import TagInput from "../../../../../../components/dataEntry/inputs/TagInput/TagInput";
import { DateApi } from "../../../../../../services/Date/Date";
import { DURATION_TYPE_MOUNTH } from "../../../../../../utl/constants/duration";
import { Lessons } from "../../../../models/GroupsValue";

interface Props {
    lessonWeekDays: string;
    startDate: string;
    error: string | undefined;
    touched: boolean | undefined;
    handleBlur: (event: FocusEvent<HTMLElement>) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    lessons: Lessons[];
    isSelectedService: boolean; 
}

const DaysWeekTagInput: FC<Props> = ({ lessonWeekDays, error, touched, handleBlur, setFieldValue, startDate, isSelectedService, lessons }) => {
    const countLessons = lessons.length;

    let daysWeekList = [
        { name: "Неділя", id: 0 },
        { name: "Понеділок", id: 1 },
        { name: "Вівторок", id: 2 },
        { name: "Середа", id: 3 },
        { name: "Четвер", id: 4 },
        { name: "П’ятниця", id: 5 },
        { name: "Субота", id: 6 },
    ];

    const activeDaysWeek = lessonWeekDays.length > 0 ? lessonWeekDays.split(',')
        .map(dayWeek => (
            {
                id: Number(dayWeek),
                name:
                    daysWeekList[daysWeekList.findIndex(elem => elem.id === Number(dayWeek))]?.name || "Name"
            }
        )) : [];

        const changeLessonWeekDaysHandler = (value: string) => {
            setFieldValue("lessonWeekDays", value);
            const weekdays = value.split(",").map(lessonWeekDay => parseInt(lessonWeekDay));
    
            if (isSelectedService) {
                const schedule = DateApi.calculateSchedule(
                    startDate,
                    weekdays,
                    DURATION_TYPE_MOUNTH,
                    countLessons
                );
    
                setFieldValue("endDate", schedule[schedule.length - 1]);

                const scheduleLessons = lessons.map((lesson, index) => ({
                    ...lesson,
                    date: schedule[index],
                }));

                setFieldValue("lessons", scheduleLessons);
            }
        }

    return (
        <TagInput
            label="Дні тижня"
            tagsArray={daysWeekList}
            maxWidth={330}
            activeTagsArray={activeDaysWeek}
            handleBlur={handleBlur}
            handleChange={changeLessonWeekDaysHandler}
            error={error}
            touched={touched}
            values={lessonWeekDays}
            marginRight={20}
        />
    )
}

export default DaysWeekTagInput;