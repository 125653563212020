import React, { FC, SetStateAction } from "react";
import Dropdown from "../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../../utl/constants/defaultValue";
import { PriceTypes } from "../../../../models/PriceTypes";
import { PaymentParts } from "../../../../../../store/state/groups/models/Group";

interface Props {
    priceTypes: PriceTypes[];
    priceTypeId: number;
    multiline: boolean;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setPriceTypeValue: (value: SetStateAction<string>) => void;
    paymentParts: PaymentParts[];
}

const PriceTypeDropdown: FC<Props> = ({ priceTypes, priceTypeId, multiline, setFieldValue, setPriceTypeValue, paymentParts }) => {
    const indexPriceType = priceTypes.findIndex(priceType => priceType.id === priceTypeId);

    const defaultPriceType = priceTypes[indexPriceType];
    const defaultValuePriceType: DefaultValue = {
        valueInput: (defaultPriceType && String(defaultPriceType.id)) || DEFAULT_VALUES_DROPDOWN.valueInput,
        valueText: defaultPriceType && defaultPriceType.name || DEFAULT_VALUES_DROPDOWN.valueText,
    }

    const priceTypeItems = priceTypes.map((priceType, index) => (
        <DropdownItem
            key={"PRICE_TYPE_ITEM_" + index}
            item={priceType.name}
            valueInput={String(priceType.id)}
            valueText={priceType.name}
        />
    ));

    const dropdownPriceTypesHandler = (value: string) => {
        if (value === "1" && multiline) {
            setFieldValue("paymentParts", [paymentParts[0]]);
        }

        setPriceTypeValue(value);
    }

    return (
        <Dropdown
            label="Тип оплати"
            maxWidth={180}
            height={40}
            marginRight={20}
            defaultValue={defaultValuePriceType}
            doubleClickDropdown={false}
            handleChange={dropdownPriceTypesHandler}
        >
            {priceTypeItems}
        </Dropdown>
    );
}

export default PriceTypeDropdown;