import React, { ChangeEvent, FC, useRef } from "react";
import styles from "./ImportFile.module.css";

interface Props {
    changeFileHandler: (event: ChangeEvent<HTMLInputElement>) => void;
    acceptFile: string;
}

const ImportFile: FC<Props> = ({ acceptFile, changeFileHandler }) => {
    const inputFile = useRef<HTMLInputElement | null>(null);

    const clickButtonHandler = async () => {
        inputFile.current?.click();
    };

    return (
        <div>
            <button
                className={styles.btnImport} 
                onClick={() => clickButtonHandler()}
            >
                Завантажити Файл
            </button>

            <input
                accept={acceptFile} 
                onChange={changeFileHandler} 
                type='file' 
                ref={inputFile} 
                style={{display: "none"}} 
            />
        </div>
    );
}

export default ImportFile;