import React, {FC} from "react";
import styles from "./CenterLayout.module.css";

interface Props {
    children: JSX.Element[] | JSX.Element;
    flexDirection?: "row" | "column";
}

const CenterLayout: FC<Props> = ({children, flexDirection = "row"}) => {
    return (
        <main style={{flexDirection}} className={styles.centerLayout}>
            {children}
        </main>
    );
}

export default CenterLayout;