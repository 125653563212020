import { PayloadAction } from "@reduxjs/toolkit";
import { GroupsState } from "../../models/GroupsState";
import { ChangeGroupActivitySuccessPayload } from "../../payload/ChangeGroupActivity/ChangeGroupActivitySuccessPayload";

const changeGroupActivitySuccess = (state: GroupsState, action: PayloadAction<ChangeGroupActivitySuccessPayload>) => {
    const { groupId, active } = action.payload;

    const groups = state.groups;

    const indexGroup = groups.findIndex(group => group.id === groupId);

    if(indexGroup > -1) {
        const group = groups[indexGroup];

        group.active = active;
    }
}

export default changeGroupActivitySuccess;