import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import Dropdown from "../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { useAppSelector } from "../../../../../../store/hooks/selector.hook";
import { configListAdapter } from "../../../../../../utl/adapters/ConfigListAdapter";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../../utl/constants/defaultValue";

interface Props {
    categoryId: number;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
    error: string | undefined;
    touched: boolean | undefined;
}

const CategoryCommentDropdown: FC<Props> = ({ categoryId, setFieldTouched, setFieldValue, error, touched }) => {
    const categoris = useAppSelector(state => state.config.vendorConfig?.client.logCategories, shallowEqual);
    const categoryList = configListAdapter(categoris);

    const indexCategory = categoryList.findIndex(category => category.id === categoryId);
    const defaultCategory = categoryList[indexCategory];
    const defaultValueCategory: DefaultValue = {
        valueInput: defaultCategory && String(defaultCategory.id) || DEFAULT_VALUES_DROPDOWN.valueInput,
        valueText: defaultCategory && defaultCategory.name || DEFAULT_VALUES_DROPDOWN.valueText,
    } 
    
    const categoryItems = categoryList.map(category => (
        <DropdownItem 
            item={category.name} 
            valueInput={String(category.id)}
            valueText={category.name} 
        />
    ));

    const blurHandler = (touched: boolean) => {
        setFieldTouched("categoryId", touched)
    }

    const changeHandler = (categoryId: string) => {
        setFieldValue("categoryId", parseInt(categoryId))
    }

    return (
        <Dropdown
            maxWidth={440}
            label="Контрагент"
            defaultValue={defaultValueCategory}
            doubleClickDropdown={false}
            handleBlur={blurHandler}
            handleChange={changeHandler}
            error={error}
            touched={touched}
            marginBottom={20}
        >
            {categoryItems}
        </Dropdown>
    );
}

export default CategoryCommentDropdown;