import { createSlice } from '@reduxjs/toolkit';
import { UserState } from './models/UserState';
import getUserDataFetchReducer from './reducer/getUserData/getUserDataFetch';
import getUserDataSuccessReducer from './reducer/getUserData/getUserDataSuccess';
import getUserDataFailureReducer from './reducer/getUserData/getUserDataFailure';
import forgotPasswordFetchReducer from './reducer/forgotPassword/forgotPasswordFetch';
import forgotPasswordSuccessReducer from './reducer/forgotPassword/forgotPasswordSuccess';
import forgotPasswordFailureReducer from './reducer/forgotPassword/forgotPasswordFailure';
import resetPasswordFetchReducer from './reducer/resetPassword/resetPasswordFetch';
import resetPasswordSuccessReducer from './reducer/resetPassword/resetPasswordSuccess';
import resetPasswordFailureReducer from './reducer/resetPassword/resetPasswordFailure';
import registerVendorFetchReducer from './reducer/registerVendor/registerVendorFetch';
import registerVendorSuccessReducer from './reducer/registerVendor/registerVendorSuccess';
import registerVendorFailureReducer from './reducer/registerVendor/registerVendorFailure';
import setErrorEmailVendorReducer from './reducer/setErrorEmailVendor';
import setErrorTelVendorReducer from './reducer/setErrorTelVendor';
import setErrorNameVendorReducer from './reducer/setErrorNameVendor';
import disabledErrorReducer from './reducer/disabledError';
import setTokenStatusReducer from './reducer/setTokenStatus';
import userExitReducer from './reducer/userExit';
import token from '../../../services/Token/token';

const accessToken = token.getAccessToken();
const refreshToken = token.getRefreshToken();

export const initialState: UserState = {
    id: null,
    name: "",
    avatar: "",
    roleName: "",
    roleId: null,
    vendorId: null,
    isLoading: false,
    isError: null,
    isSendEmail: false,
    isResetPassword: false,
    tokenStatus: Boolean(accessToken && refreshToken),
    vendor: {
        isRegistration: false,
        emailError: null,
        telError: null,
        vendorNameError: null,
    }
}

export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getUserDataFetch: getUserDataFetchReducer,
        getUserDataSuccess: getUserDataSuccessReducer,
        getUserDataFailure: getUserDataFailureReducer,
        forgotPasswordFetch: forgotPasswordFetchReducer,
        forgotPasswordSuccess: forgotPasswordSuccessReducer,
        forgotPasswordFailure: forgotPasswordFailureReducer,
        resetPasswordFetch: resetPasswordFetchReducer,
        resetPasswordSuccess: resetPasswordSuccessReducer,
        resetPasswordFailure: resetPasswordFailureReducer,
        registerVendorFetch: registerVendorFetchReducer,
        registerVendorSuccess: registerVendorSuccessReducer,
        registerVendorFailure: registerVendorFailureReducer,
        setErrorEmailVendor: setErrorEmailVendorReducer,
        setErrorTelVendor: setErrorTelVendorReducer,
        setErrorNameVendor: setErrorNameVendorReducer,
        disabledError: disabledErrorReducer,
        setTokenStatus: setTokenStatusReducer,
        userExit: userExitReducer,
    },
});

export const {
    getUserDataFetch, getUserDataSuccess, getUserDataFailure,
    forgotPasswordFailure, forgotPasswordFetch, forgotPasswordSuccess,
    resetPasswordFailure, resetPasswordFetch, resetPasswordSuccess,
    registerVendorFailure, registerVendorFetch, registerVendorSuccess,
    disabledError, setErrorEmailVendor, setErrorNameVendor, setErrorTelVendor,
    setTokenStatus, userExit
} = UserSlice.actions

export default UserSlice.reducer