import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Clients } from "../../../../services/reddyApi/Clients/Clients";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { getStatisticsDashboard } from "../../statistics/statistics.saga";
import { createByVendorFecth, createByVendorSuccess } from "../clients.slice";
import { CreateClientByVendorPayload } from "../payload/CreateClientByVendor/CreateClientByVendorPayload";

function* createByVendorWorker(action: PayloadAction<CreateClientByVendorPayload>) {
    const { vendorId, user, students, discountId, filter } = action.payload;
    const dataParams = {
        vendorId,
        user,
        students,
        discountId
    }

    try {
        const { data } = yield call(Clients.createByVendor, dataParams);

        yield put(createByVendorSuccess({ ...data }));

        yield getStatisticsDashboard({ vendorId, filter });

        yield getNotifications(vendorId);

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => createByVendorFecth(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default createByVendorWorker;