import React, { FC, MouseEvent, useState } from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Position from "../../layout/position/Position";
import styles from "./LocationView.module.css";
import { Location } from "./models/location";
import "./transition.css";

interface Props {
    count: number;
    locations: Location[]; 
    link: string;
}

const LocationView: FC<Props> = ({count, locations, link}) => {
    const [toggle, setToggle] = useState(false);

    const clickButtonHandler = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setToggle(prev => !prev)
    }

    const blurButtonHandler = () => {
        setToggle(false);
    }

    const clickLinkHandler = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    }

    const listItems = locations.map(location => (
        <li className={styles.listItem}>
            <Link onClick={clickLinkHandler} to={`/${link}/${location.id}`} className={styles.link}>
                {location.name}
            </Link>
        </li>
    ));

    return (
        <div className={styles.btnBlock}>
            <button  
                className={styles.btnInfo} 
                onClick={clickButtonHandler}
                onBlur={blurButtonHandler}
            >
                {count}
            </button>
            <Position type="absolute" zIndex={5} top={30} left={0}>
                <CSSTransition 
                    in={toggle} 
                    timeout={400} 
                    unmountOnExit 
                    classNames="list"
                >
                <ul 
                    className={styles.list}
                >
                    {listItems}
                </ul>
            </CSSTransition>
            </Position>
        </div>
    )
}

export default LocationView;