import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Upsells } from "../../../../../services/reddyApi/Upsells/Upsells";
import handleServerError from "../../../all/saga/handleServerError";
import setServerErrorDefault from "../../../all/saga/setServerErrorDefault";
import getNotifications from "../../../notifications/saga/getNotifications";
import { CreateUpsellPayload } from "../payload/CreateUpsell/CreateUpsellPayload";
import { createAdditionalFetch, createAdditionalSuccess } from "../upsells.slice";

function* createUpsellWorker(action: PayloadAction<CreateUpsellPayload>) {
    const { vendorId, upsell } = action.payload;

    try {
        const { data, status } = yield call(Upsells.create, { vendorId, upsell });
        const { upsellId } = data;

        if (status === 200) {
            yield put(createAdditionalSuccess({ ...upsell, id: upsellId }));

            yield getNotifications(vendorId);

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => createAdditionalFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default createUpsellWorker;