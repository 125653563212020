import React, { FC } from "react";
import Button from "../../../../../../components/general/buttons/Button/Button";
import IconFont from "../../../../../../components/general/icons/IconFont/IconFont";
import { useAppDispatch } from "../../../../../../store/hooks/dispatch.hook";
import { setFonVisiable } from "../../../../../../store/state/all/all.slice";
import { setSearchClientsFormVisiable } from "../../../../../../store/state/groups/groups.slice";

interface Props {
    buttonName: string;
}

const ButtonSearchClients: FC<Props> = ({ buttonName }) => {
    const dispatch = useAppDispatch();

    const clickButtonHandler = () => {
        dispatch(setSearchClientsFormVisiable({ visiable: true }));
        dispatch(setFonVisiable({ visiable: true }));
    }

    return (
        <Button
            maxWidth={160}
            handleClick={clickButtonHandler}
            icon={<IconFont iconClass="icon-plus" fontSize={15} color="#FFF" />}
            backgroundColor="#4775FE"
            title={buttonName}
            marginBottom={0}
            marginRight={15}
        />
    );
}

export default ButtonSearchClients;