import React, {FC} from "react";
import styles from "./Button.module.css";
import classNames from "classnames";

const cx = classNames.bind(styles);

interface Props {
    title: string | JSX.Element;
    icon?: JSX.Element;
    colorText?: string;
    justifyContent?: "center" | "flex-start" | "flex-end";
    backgroundColor?: string;
    handleClick: (event: any) => void;
    disabled?: boolean; 
    textDecoration?: "underline" | "line-through" | "overline" | "none";
    marginRight?: number;
    marginBottom?: number;
    marginTop?: number;
    maxWidth?: number; 
}

const Button: FC<Props> = ({textDecoration, justifyContent = "center", title, handleClick, colorText = "#FFF", 
    icon, backgroundColor, disabled = false, marginRight, marginBottom, marginTop, maxWidth = 160}) => {
    const clickButtonHandler = (event: any) => {
        if(!disabled) {
            handleClick(event);
        }
    }

    return (
        <button
            disabled={disabled}
            type="button"
            className={cx(styles.btn)} 
            onClick={clickButtonHandler} 
            style={{
                justifyContent,
                textDecoration,
                color: colorText, 
                backgroundColor: !disabled ? backgroundColor : "#EBEEF6",
                marginRight,
                marginBottom,
                marginTop,
                maxWidth,
            }}
        >
            {icon && 
                <div className={styles.icon}>
                    {icon}
                </div>
            }
            {title}
        </button>
    );
}

export default Button;