import React, {FC} from "react";

interface Props {
    type: "absolute" | "fixed";
    top?: number | string;
    left?: number | string;
    right?: number | string;
    bottom?: number | string;
    zIndex?: number;
    children: JSX.Element[] | JSX.Element;
    maxWidth?: number;
    width?: string | number;
}

const Position: FC<Props> = ({width, maxWidth, type, top , left, right, bottom, zIndex = 0, children}) => {
    return (
        <div style={{width, maxWidth, position: type, top, left, right, bottom, zIndex}}>
            {children}
        </div>
    );
}

export default Position;