import { PayloadAction } from "@reduxjs/toolkit";
import { GroupsState } from "../../models/GroupsState";
import { GetStudentDataForGroupSuccessPayload } from "../../payload/GetStudentDataForGroup/GetStudentDataForGroupSuccessPayload";

const getStudentDataForGroupSuccess = (state: GroupsState, action: PayloadAction<GetStudentDataForGroupSuccessPayload>) => {
    const { groupId, student } = action.payload;
    const groups = state.pageData;
    const groupIndex = groups.findIndex(group => group.id === groupId);
    if (groupIndex > -1) {
        const group = groups[groupIndex];
        const students = group.students;
        if (students) {
            const indexStudent = students.findIndex(studentGroup => studentGroup.student.id === student.student.id);
            students[indexStudent] = student;
        }
    }
    state.isLoading = false;
    state.isError = null;
}

export default getStudentDataForGroupSuccess;