import { useFormik } from "formik";
import React from "react";
import EmailPassRecovery from "./EmailPassRecovery";
import validationSchema from "./EmailPassRecovery.scheme";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { forgotPasswordFetch } from "../../../store/state/user/user.slice";
import { EmailPassRecoveryValues } from "./models/EmailPassRecoveryValues";
import withAuthCheckStatus from "../../../components/other/hoc/withAuthCheckStatus";

const EmailPassRecoveryContainer = () => {
    const dispatch = useAppDispatch();
    const isSubmit = useAppSelector(state => state.user.isSendEmail, shallowEqual);
    const error = useAppSelector(state => state.user.isError, shallowEqual);

    const initialValues: EmailPassRecoveryValues = {
        email: "",
    }

    const formik = useFormik<EmailPassRecoveryValues>({
        initialValues,
        validationSchema,
        onSubmit: values => {
            dispatch(forgotPasswordFetch({email: values.email}));
        },
    });

    return (
        <EmailPassRecovery error={error} isSubmit={isSubmit} formik={formik} />
    );
}

export default React.memo(EmailPassRecoveryContainer);