import React, { FC } from "react";
import styles from "./ButtonToggle.module.css";

interface Props {
    active: JSX.Element;
    inactive: JSX.Element;
    isActive: boolean;
    handleClick: () => void;
}

const ButtonToggle: FC<Props> = ({active, inactive, isActive, handleClick}) => {
    return (
        <button type="button" className={styles.btn} onClick={handleClick}>
            {isActive ? active : inactive}
        </button>
    );
}

export default ButtonToggle;