import { PayloadAction } from "@reduxjs/toolkit";
import deleteItem from "../../../../helpers/deleteItem";
import { GroupsState } from "../../models/GroupsState";
import { DeleteGroupSuccessPayload } from "../../payload/DeleteGroup/DeleteGroupSuccessPayload";

const deleteGroupSuccess = (state: GroupsState, action: PayloadAction<DeleteGroupSuccessPayload>) => {
    const { groupId } = action.payload;

    const groups = state.groups;
    state.groups = deleteItem(state, groups, groupId);

    state.isLoading = true;
    state.isError = null;
}

export default deleteGroupSuccess;