import React, { ChangeEvent, FC } from "react";
import Field from "../../../../../../components/dataEntry/inputs/Field/Field";
import { Lessons } from "../../../../models/GroupsValue";

interface Props {
    lessons: Lessons[];
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const TimeField: FC<Props> = ({ lessons, setFieldValue }) => {
    const time = lessons[0].time;

    const changeTimeLessons = (event: ChangeEvent<HTMLInputElement>) => {
        const time = event.target.value;

        const lessonsWithTime = lessons.map((lesson, index) => ({
            ...lesson,
            time,
        }));

        setFieldValue("lessons", lessonsWithTime);
    }

    return (
        <Field
            label="Години занять"
            type="time"
            maxWidth={110}
            marginBottom={0}
            labelLeftShift={0}
            value={time}
            handleChange={changeTimeLessons}
        />
    );
}

export default TimeField;