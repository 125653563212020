import React, { FC } from "react";
import Paragraph from "../../general/typography/Paragraph/Paragraph";
import Title from "../../general/typography/Title/Title";
import Wrp from "../../layout/Wrp/Wrp";

interface Props {
    title?: string;
    briefInfo?: string;
    children: JSX.Element | JSX.Element[];
    flexDirection?: "column" | "row";
    marginBottom?: number;
    maxWidth?: number;
}

const ParametersBlock: FC<Props> = ({title, briefInfo, children, flexDirection = "column", 
    marginBottom = 30, maxWidth = 270}) => {
        
    return (
        <Wrp
            flexDirection={flexDirection}
            marginBottom={marginBottom}
            maxWidth={maxWidth}
        >
            <>{title && <Title title={title} level={5} marginBottom={10} />}</>
            <>{briefInfo && <Paragraph content={briefInfo} color="#A6AEC5" marginBottom={15} />}</>
            <>{children}</>
        </Wrp>
    );
}

export default ParametersBlock;