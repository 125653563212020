import { PayloadAction } from "@reduxjs/toolkit";
import createItem from "../../../../../helpers/createItem";
import { ServiceState } from "../../models/ServiceState";
import { CreateServiceSuccessPayload } from "../../payload/CreateService/CreateServiceSuccessPayload";

const createServicesSuccess = (state: ServiceState, action: PayloadAction<CreateServiceSuccessPayload>) => {
    const service = action.payload.service;

    const services = state.basics;
    createItem(state, services, service);

    const servicesPage = state.pageData;
    createItem(null, servicesPage, service);

    state.shortServices = [...state.shortServices, { ...service}];

    state.message = "Послугу створено"
    state.messageVisiable = true;
    state.isLoading = false;
    state.isError = null;
    state.newItemId = service.id;
}

export default createServicesSuccess;