import React, { FC } from "react";
import Wrp from "../../components/layout/Wrp/Wrp";
import DataTableBlockContainer from "./components/DataTableBlock/DataTableBlock.container";
import StatisticsBlockContainer from "./components/StatisticsBlock/StatisticsBlock.container";

interface Props {
   
}

const Finance: FC<Props> = ({}) => {
    return (
        <Wrp>
            <DataTableBlockContainer />
            
            <StatisticsBlockContainer />
        </Wrp>
    );
}

export default Finance;