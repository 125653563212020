import { JsxEmit } from "typescript";
import { Column } from "../../components/dataDisplay/table/models/Column";

export const DATA_INDEX_VENDORS: Column[] = [
    {
        title: "",
        key: "HEAD_AVATAR",
        dataIndex: "avatar"
    },
    {
        title: "Назва компанії",
        key: "HEAD_NAME",
        dataIndex: "name"
    },
    {
        title: "Керівник",
        key: "HEAD_OWNER",
        dataIndex: "ownerName"
    },
    {
        title: "Статус",
        key: "HEAD_STATUS",
        dataIndex: "status"
    },
    {
        title: "Закінчення",
        key: "HEAD_CONFIRMED_TO",
        dataIndex: "confirmedTo"
    },
    {
        title: "К-ть учнів",
        key: "HEAD_COUNT_STUDENTS",
        dataIndex: "studentsCount"
    },
    {
        title: "Локації",
        key: "HEAD_LOCATIONS",
        dataIndex: "locationsCount"
    },
    {
        title: "Заповненість",
        key: "HEAD_PROGRESS",
        dataIndex: "progress"
    },
    {
        title: "",
        key: "HEAD_PHONE",
        dataIndex: "phone"
    },
    {
        title: "",
        key: "HEAD_EMAIL",
        dataIndex: "email"
    },
];

export const DATA_INDEX_EMPLOYEES: Column[] = [
    {
        title: "",
        key: "HEAD_AVATAR",
        dataIndex: "avatar"
    },
    {
        title: "Працівник",
        key: "HEAD_NAME",
        dataIndex: "name",
    },
    {
        title: "Локація",
        key: "HEAD_LOCATIONS",
        dataIndex: "location"
    },
    {
        title: "Статус",
        key: "HEAD_STATUS",
        dataIndex: "status"
    },
    {
        title: "Роль",
        key: "HEAD_VENDOR",
        dataIndex: "role"
    },
    {
        title: "",
        key: "HEAD_LINK",
        dataIndex: "link"
    },
    {
        title: "",
        key: "HEAD_PHONE",
        dataIndex: "phone"
    },
    {
        title: "",
        key: "HEAD_EMAIL",
        dataIndex: "email"
    },
];

export const DATA_INDEX_LOCATIONS: Column[] = [
    {
        title: "Назва",
        key: "HEAD_NAME",
        dataIndex: "name"
    },
    {
        title: "Місто",
        key: "HEAD_CITY",
        dataIndex: "city"
    },
    {
        title: "Адреса",
        key: "HEAD_ADDRESS",
        dataIndex: "address",
    },
    {
        title: "Статус",
        key: "HEAD_STATUS",
        dataIndex: "status"
    },
    {
        title: "",
        key: "HEAD_PHONE",
        dataIndex: "phone"
    },
    {
        title: "",
        key: "HEAD_EDIT",
        dataIndex: "edit"
    },
    {
        title: "",
        key: "HEAD_DELETE",
        dataIndex: "delete"
    },
];

export const DATA_INDEX_BASIC_SERVICES: Column[] = [
    {
        title: "",
        key: "HEAD_MINIATURE",
        dataIndex: "miniature"
    },
    {
        title: "Назва курсу",
        key: "HEAD_NAME",
        dataIndex: "name",
        // sorted: true,
    },
    // {
    //     title: "К-ть груп",
    //     key: "HEAD_COUNT_GROUP",
    //     dataIndex: "countGroup",
    // },
    {
        title: "Тривалість",
        key: "HEAD_DURATION",
        dataIndex: "duration",
    },
    {
        title: "Тип",
        key: "HEAD_DURATION_TYPE",
        dataIndex: "durationType",
    },
    {
        title: "Категорія",
        key: "HEAD_CATEGORY",
        dataIndex: "category",
    },
    {
        title: "Локація",
        key: "HEAD_LOCATIONS",
        dataIndex: "locations",
    },
    {
        title: "Статус",
        key: "HEAD_STATUS",
        dataIndex: "status",
    },
    {
        title: "Заповненість",
        key: "HEAD_OCCUPANCY",
        dataIndex: "occupancy",
    },
    {
        title: "",
        key: "HEAD_EDIT",
        dataIndex: "edit",
    }
];

export const DATA_INDEX_BASIC_ACTIVE_SERVICES: Column[] = [
    {
        title: "",
        key: "HEAD_MINIATURE",
        dataIndex: "miniature"
    },
    {
        title: "Назва курсу",
        key: "HEAD_NAME",
        dataIndex: "name",
        // sorted: true,
    },
    // {
    //     title: "К-ть груп",
    //     key: "HEAD_COUNT_GROUP",
    //     dataIndex: "countGroup",
    // },
    {
        title: "Тривалість",
        key: "HEAD_DURATION",
        dataIndex: "duration",
    },
    {
        title: "Тип",
        key: "HEAD_DURATION_TYPE",
        dataIndex: "durationType",
    },
    {
        title: "Категорія",
        key: "HEAD_CATEGORY",
        dataIndex: "category",
    },
    {
        title: "Локація",
        key: "HEAD_LOCATIONS",
        dataIndex: "locations",
    },
    {
        title: "Заповненість",
        key: "HEAD_OCCUPANCY",
        dataIndex: "occupancy",
    },
    {
        title: "",
        key: "HEAD_EDIT",
        dataIndex: "edit",
    }
];

export const DATA_INDEX_ADDITIONAL_SERVICES: Column[] = [
    {
        title: "",
        key: "HEAD_MINIATURE",
        dataIndex: "miniature"
    },
    {
        title: "Назва додаткової послугии",
        key: "HEAD_NAME",
        dataIndex: "name"
    },
    // {
    //     title: "К-ть груп",
    //     key: "HEAD_COUNT_GROUP",
    //     dataIndex: "countGroup"
    // },
    // {
    //     title: "Локація",
    //     key: "HEAD_LOCATIONS",
    //     dataIndex: "locations",
    // },
    {
        title: "Вартість",
        key: "HEAD_COST",
        dataIndex: "cost",
    },
    {
        title: "Статус",
        key: "HEAD_STATUS",
        dataIndex: "status",
    },
    {
        title: "",
        key: "HEAD_EDIT",
        dataIndex: "edit"
    }
];

export const DATA_INDEX_CLIENTS: Column[] = [
    {
        title: "",
        key: "HEAD_AVATAR",
        dataIndex: "avatar"
    },
    {
        title: "Клієнт",
        key: "HEAD_CLIENTS",
        dataIndex: "clients"
    },
    {
        title: "К-ть учнів",
        key: "HEAD_COUNT_Students",
        dataIndex: "countStudents"
    },
    {
        title: "Послуги",
        key: "HEAD_COUNT_SERVICES",
        dataIndex: "services",
    },
    {
        title: "Заборгованість",
        key: "HEAD_CONGESTION",
        dataIndex: "congestion",
    },
    {
        title: "",
        key: "HEAD_EMAIL",
        dataIndex: "email"
    },
    {
        title: "",
        key: "HEAD_TEL",
        dataIndex: "tel"
    },
    {
        title: "",
        key: "HEAD_EDIT",
        dataIndex: "edit"
    },
    {
        title: "",
        key: "HEAD_DELETE",
        dataIndex: "delete"
    }
];

export const DATA_INDEX_FINANCE: Column[] = [
    {
        title: "Дата / Час",
        key: "HEAD_DATETIME",
        dataIndex: "datetime"
    },
    {
        title: "Сума",
        key: "HEAD_SUM",
        dataIndex: "sum"
    },
    {
        title: "Рахунок",
        key: "HEAD_COUNT_SCORE",
        dataIndex: "score"
    },
    {
        title: "Контрагент",
        key: "HEAD_COUNT_COUNTERPARTY",
        dataIndex: "counterparty",
    },
    {
        title: "Категорія",
        key: "HEAD_CATEGORY",
        dataIndex: "category",
    },
    {
        title: "Відповідальний",
        key: "HEAD_RESPONSIBLE",
        dataIndex: "responsible",
    },
    {
        title: "Коментар",
        key: "HEAD_COMMENT",
        dataIndex: "comment"
    },
];

export const DATA_INDEX_GROUPS: Column[] = [
    {
        title: "",
        key: "HEAD_MINIATURE",
        dataIndex: "miniature"
    },
    {
        title: "Назва групи / послуга",
        key: "HEAD_NAME",
        dataIndex: "name"
    },
    {
        title: "Локація",
        key: "HEAD_LOCATION",
        dataIndex: "location"
    },
    {
        title: "Тип",
        key: "HEAD_TYPE",
        dataIndex: "type",
    },
    {
        title: "Статус",
        key: "HEAD_STATUS",
        dataIndex: "status",
    },
    {
        title: "Закінчення",
        key: "HEAD_END_DATE",
        dataIndex: "endDate"
    },
    {
        title: "К-ть учнів",
        key: "HEAD_COUNT_STUDENTS",
        dataIndex: "countStudents"
    },
    {
        title: "Заповненість",
        key: "HEAD_OCCUPANCY",
        dataIndex: "occupancy"
    },
    {
        title: "",
        key: "HEAD_EDIT",
        dataIndex: "edit"
    },
];

export const DATA_INDEX_FINANCE_CLIENT: Column[] = [
    {
        title: "Дата / Час",
        key: "HEAD_DATETIME",
        dataIndex: "datetime"
    },
    {
        title: "Сума",
        key: "HEAD_SUM",
        dataIndex: "sum"
    },
    {
        title: "Рахунокв",
        key: "HEAD_COUNT_SCORE",
        dataIndex: "score"
    },
    {
        title: "Категорія",
        key: "HEAD_TYPE",
        dataIndex: "category",
    },
    {
        title: "Відповідальний",
        key: "HEAD_RESPONSIBLE",
        dataIndex: "responsible",
    },
    {
        title: "Коментар",
        key: "HEAD_COMMENT",
        dataIndex: "comment"
    },
];

export const DATA_INDEX_GROUP_CLIENTS: Column[] = [
    {
        title: "Учень / клієнт",
        key: "HEAD_NAME",
        dataIndex: "clients"
    },
    {
        title: "Борг",
        key: "HEAD_DEBT",
        dataIndex: "debt"
    },
    {
        title: "Додаткові послуги",
        key: "HEAD_UPSELLS",
        dataIndex: "upsells",
    },
    {
        title: "Статус",
        key: "HEAD_STATUS",
        dataIndex: "status",
    },
    {
        title: "Знижка",
        key: "HEAD_DISCOUNT",
        dataIndex: "discount",
    },
    {
        title: "Відвідуваність",
        key: "HEAD_ATTENDANCE",
        dataIndex: "attendance",
    },
    {
        title: "",
        key: "HEAD_EMAIL",
        dataIndex: "email",
    },
    {
        title: "",
        key: "HEAD_PHONE",
        dataIndex: "phone",
    },
    {
        title: "",
        key: "HEAD_DELETE",
        dataIndex: "delete",
    }
];

