import { PayloadAction } from "@reduxjs/toolkit";
import { Error } from "../../../../utl/models/Error";
import { MoneyState } from "../models/MoneyState";

const setError = (state: MoneyState, action: PayloadAction<Error>) => {
    state.isLoading = false;
    state.isError = action.payload.error;
    state.message = "Виникла помилка";
    state.messageVisiable = true;
}

export default setError;