import { useEffect } from "react";
import { useAppDispatch } from "../../../../../store/hooks/dispatch.hook";
import { getSelectedServiceFetch, getSelectedServiceSuccess } from "../../../../../store/state/groups/groups.slice";
import { Group } from "../../../../../store/state/groups/models/Group";
import { Service } from "../../../../../store/state/services/service/models/Service";

interface Payload {
    group: Group;
    service: Service | null;
    serviceId: number;
    vendorId: number;
}

const useGetSelectedService = (payload: Payload) => {
    const { group, serviceId, service, vendorId } = payload;

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (group) {
            if (service) {
                dispatch(getSelectedServiceSuccess({ ...service }));
            } else {
                dispatch(getSelectedServiceFetch({ vendorId, serviceId }));
            }
        }
    }, [serviceId > -1]);
}

export default useGetSelectedService;