import moment from "moment";
import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { getAttendanceStatisticFetch, getServicesListFetch, getShortServicesListFetch } from "../../store/state/services/service/service.slice";
import { SYSTEM_ADMIN_ID } from "../../utl/constants/role";
import { culcPage } from "../../utl/helpers/culcPage";
import { Filter } from "../../utl/models/Filters";

function withLoadingServices(Component: React.ComponentType) {
    const ComponentWithLoadingServices: FC = () => {
        const dispatch = useAppDispatch();

        const page = useAppSelector(state => state.services.page, shallowEqual);
        const totalCount = useAppSelector(state => state.services.totalCount, shallowEqual);
        const pageAll = useAppSelector(state => state.services.pageAll, shallowEqual);
        const pageSize = useAppSelector(state => state.services.pageSize, shallowEqual);
        const getAllItems = useAppSelector(state => state.services.getAllItems, shallowEqual);
        const pagesRange = culcPage(page, pageAll, totalCount, pageSize);

        const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
        const roleId = useAppSelector(state => state.user.roleId, shallowEqual) || -1;

        const active = useAppSelector(state => state.services.filter.active, shallowEqual);

        const today = new Date();

        useEffect(() => {
            if (roleId > SYSTEM_ADMIN_ID) {
                let filter: Filter = { active };

                if (!getAllItems) {
                    filter.range = {
                        from: pagesRange.from,
                        to: pagesRange.to
                    }
                }

                const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
                const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

                dispatch(getServicesListFetch({ vendorId, filter }));
                dispatch(getShortServicesListFetch({ vendorId, filter: { short: true, active: true, confirmed: true } }));
                dispatch(getAttendanceStatisticFetch({
                    vendorId, filter: {
                        period: {
                            from: moment(firstDay).format("YYYY-MM-DD"),
                            to: moment(lastDay).format("YYYY-MM-DD"),
                        }
                    }
                }));
            }
        }, [vendorId, roleId]);

        return (
            <Component />
        );
    }

    return ComponentWithLoadingServices;
}

export default withLoadingServices;