import { Purses } from "../../store/state/finance/models/Purses";

export const allPursesSumMoneyAdapter = (purses: Purses[]): number => {
    let sum = 0;

    purses.forEach(purse => {
        sum += purse.sum;
    });

    return sum;
}