import { createSlice } from '@reduxjs/toolkit';
import { FinanceState } from './models/FinanceState';
import searchUsersForVendorFetchReducer from './reducer/searchUsersForVendor/searchUsersForVendorFetch';
import searchUsersForVendorSuccessReducer from './reducer/searchUsersForVendor/searchUsersForVendorSuccess';
import setErrorReducer from './reducer/setError';
import createMoneyTransactionFetchReducer from './reducer/createMoneyTransaction/createMoneyTransactionFetch';
import createMoneyTransactionSuccessReducer from './reducer/createMoneyTransaction/createMoneyTransactionSuccess';
import createMoneyTransferFetchReducer from './reducer/createMoneyTransfer/createMoneyTransferFetch';
import createMoneyTransferSuccessReducer from './reducer/createMoneyTransfer/createMoneyTransferSuccess';
import getVendorMoneyTransactionsFetchReducer from './reducer/getVendorMoneyTransactions/getVendorMoneyTransactionsFetch';
import getVendorMoneyTransactionsSuccessReducer from './reducer/getVendorMoneyTransactions/getVendorMoneyTransactionsSuccess';
import getMoneyPursesFetchReducer from './reducer/getMoneyPurses/getMoneyPursesFetch';
import getMoneyPursesSuccessReducer from './reducer/getMoneyPurses/getMoneyPursesSuccess';
import getFutureMoneyFetchReducer from './reducer/getFutureMoney/getFutureMoneyFetch';
import getFutureMoneySuccessReducer from './reducer/getFutureMoney/getFutureMoneySuccess';
import getPlannedMoneyFetchReducer from './reducer/getPlannedMoney/getPlannedMoneyFetch';
import getPlannedMoneySuccessReducer from './reducer/getPlannedMoney/getPlannedMoneySuccess';
import setVisiableAddFormReducer from './reducer/setVisiableAddForm';
import setVisiableExchangeFormReducer from './reducer/setVisiableExchangeForm';
import setVisiablePaymentFormReducer from './reducer/setVisiablePaymentForm';
import setPageTransactionsReducer from './reducer/setPageTransactions';
import setMessageReducer from './reducer/setMessage';
import setMessageVisiableReducer from './reducer/setMessageVisiable';
import setDefaultValueFinanceReducer from './reducer/setDefaultValueFinance';
import resetMoneyTransactionReducer from './reducer/resetMoneyTransaction';
import searchCounteragentsFetchReducer from './reducer/searchCounteragents/searchCounteragentsFetch';
import searchCounteragentsSuccessReducer from './reducer/searchCounteragents/searchCounteragentsSuccess';
import searchCounteragentsAsClientsFetchReducer from './reducer/searchCounteragentsAsClients/searchCounteragentsAsClientsFetch';
import searchCounteragentsAsClientSuccessReducer from './reducer/searchCounteragentsAsClients/searchCounteragentsAsClientSuccess';
import setTransactionsReducer from './reducer/setTransactions';
import setTransactionsFilterReducer from './reducer/setTransactionsFilter';

const filter = {
    type: undefined,
}

const finance = {
    transactions: [],
    purses: [],
    filter,
    future: null,
    planned: null,
    message: null,
    messageVisiable: false,
    getAllItems: false,
    pageAll: 0,
    pageSize: 15,
    page: 1,
    totalCount: 0,
}

export const initialState: FinanceState = {
    finance,
    counterpartis: [],
    counteragents: [],
    counteragentsAsClients: [],
    isLoading: false,
    isError: null,
    visiableAddForm: false,
    visiableExchangeForm: false,
    visiablePaymentForm: false,
}

export const FinanceSlice = createSlice({
    name: 'finance',
    initialState,
    reducers: {
        searchUsersForVendorFetch: searchUsersForVendorFetchReducer,
        searchUsersForVendorSuccess: searchUsersForVendorSuccessReducer,
        setError: setErrorReducer,
        createMoneyTransactionFetch: createMoneyTransactionFetchReducer,
        createMoneyTransactionSuccess: createMoneyTransactionSuccessReducer,
        createMoneyTransferFetch: createMoneyTransferFetchReducer,
        createMoneyTransferSuccess: createMoneyTransferSuccessReducer,
        getVendorMoneyTransactionsFetch: getVendorMoneyTransactionsFetchReducer,
        getVendorMoneyTransactionsSuccess: getVendorMoneyTransactionsSuccessReducer,
        getMoneyPursesFetch: getMoneyPursesFetchReducer,
        getMoneyPursesSuccess: getMoneyPursesSuccessReducer,
        getFutureMoneyFetch: getFutureMoneyFetchReducer,
        getFutureMoneySuccess: getFutureMoneySuccessReducer,
        getPlannedMoneyFetch: getPlannedMoneyFetchReducer,
        getPlannedMoneySuccess: getPlannedMoneySuccessReducer,
        setVisiableAddForm: setVisiableAddFormReducer,
        setVisiableExchangeForm: setVisiableExchangeFormReducer,
        setVisiablePaymentForm: setVisiablePaymentFormReducer,
        setPageTransactions: setPageTransactionsReducer,
        setMessage: setMessageReducer,
        setMessageVisiable: setMessageVisiableReducer,
        setDefaultValueFinance: setDefaultValueFinanceReducer,
        searchCounteragentsFetch: searchCounteragentsFetchReducer,
        searchCounteragentsSuccess: searchCounteragentsSuccessReducer,
        searchCounteragentsAsClientsFetch: searchCounteragentsAsClientsFetchReducer,
        searchCounteragentsAsClientSuccess: searchCounteragentsAsClientSuccessReducer,
        resetMoneyTransaction: resetMoneyTransactionReducer,
        setTransactions: setTransactionsReducer,
        setTransactionsFilter: setTransactionsFilterReducer,
    },
});

export const { 
    setError,
    createMoneyTransactionFetch,
    createMoneyTransactionSuccess,
    createMoneyTransferFetch,
    createMoneyTransferSuccess,
    getVendorMoneyTransactionsFetch,
    getVendorMoneyTransactionsSuccess,
    getMoneyPursesFetch,
    getMoneyPursesSuccess,
    setVisiableAddForm,
    setVisiableExchangeForm, 
    setVisiablePaymentForm,
    setPageTransactions,
    setMessage,
    setMessageVisiable,
    searchUsersForVendorFetch,
    searchUsersForVendorSuccess,
    getFutureMoneyFetch,
    getFutureMoneySuccess,
    getPlannedMoneyFetch,
    getPlannedMoneySuccess,
    setDefaultValueFinance,
    searchCounteragentsFetch,
    searchCounteragentsSuccess,
    searchCounteragentsAsClientSuccess,
    searchCounteragentsAsClientsFetch,
    resetMoneyTransaction,
    setTransactions,
    setTransactionsFilter,
} = FinanceSlice.actions

export default FinanceSlice.reducer