import * as Yup from 'yup';
import { DateApi } from '../../../services/Date/Date';
import { TODAY } from '../../../utl/constants/date';

export default Yup.object().shape({
    fullname: Yup.string()
        .matches(/^([^<>()[\]\.,;:\s@\"]+)\s([^<>()[\]\.,;:\s@\"]+)$/, "Is not in correct format")
        .required('Required'),
    startDate: Yup.date()
        .transform(DateApi.parseDateString)
        .min(TODAY)
        .required("Заповніть це поле"),
    roleId: Yup.number().required("Required"),
    phone: Yup.string()
        .required('Required'),
    email: Yup.string()
        .email('Invalid email').required('Required'),
    salary: Yup.number().required("Required"),
    locationsIds: Yup.string().required("Required"),
    salaryForServices: Yup.array(Yup.object().shape({
        serviceId: Yup.number()
            .min(0, 'invalid value')
            .required('Required'),
        salary: Yup.number()
            .min(0, 'invalid value')
            .required('Required')
    })).required('Required'),
});