import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Groups } from "../../../../services/reddyApi/Groups/Groups";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import getNotifications from "../../notifications/saga/getNotifications";
import { 
    getStatisticsClient, 
    getStatisticsDashboard, 
    getStatisticsFinance, 
    getStatisticsGroup, 
    getStatisticsService 
} from "../../statistics/statistics.saga";
import { 
    deleteStudentForGroupFetch, 
    deleteStudentForGroupSuccess 
} from "../groups.slice";
import { DeleteStudentForGroupPayload } from "../payload/DeleteStudentForGroup/DeleteStudentForGroupPayload";

function* deleteStudentForGroupWorker(action: PayloadAction<DeleteStudentForGroupPayload>) {
    const { vendorId, groupId, studentId, filter, serviceId, clientId } = action.payload;

    try {
        const { data } = yield call(Groups.deleteStudentForGroup, { vendorId, groupId, studentId });

        yield put(deleteStudentForGroupSuccess({ groupId, studentId }));

        yield getStatisticsGroup({ vendorId, groupId });

        yield getStatisticsDashboard({ vendorId, filter });

        yield getStatisticsFinance({ vendorId, filter });

        yield getStatisticsClient({ vendorId, clientId });

        yield getStatisticsService({ vendorId, serviceId });

        yield getNotifications(vendorId);

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => deleteStudentForGroupFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default deleteStudentForGroupWorker;