import React, { FC } from "react";
import Dropdown from "../../../../../../components/dataEntry/inputs/Dropdown/Dropdown";
import DropdownItem from "../../../../../../components/dataEntry/inputs/Dropdown/DropdownItem";
import { DefaultValue } from "../../../../../../components/dataEntry/inputs/Dropdown/models/DefaultValue";
import { DEFAULT_VALUES_DROPDOWN } from "../../../../../../utl/constants/defaultValue";

interface Props {
    days: number;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const NotificationDropdown: FC<Props> = ({ days, setFieldValue }) => {
    const daysNotifiactions = [
        { id: 0, name: "В день коли буде заннятя" },
        { id: 1, name: "За день до заняття" },
        { id: 2, name: "За два дні до заняття" }
    ];

    const indexDaysNotifacation = daysNotifiactions.findIndex(daysNotifiaction => daysNotifiaction.id === days);

    const defaultDaysNotifacation = daysNotifiactions[indexDaysNotifacation];
    const defaultValueDaysNotifacation: DefaultValue = {
        valueInput: (defaultDaysNotifacation && String(defaultDaysNotifacation.id)) || DEFAULT_VALUES_DROPDOWN.valueInput,
        valueText: defaultDaysNotifacation && defaultDaysNotifacation.name || DEFAULT_VALUES_DROPDOWN.valueText,
    }

    const daysNotifacationItems = daysNotifiactions.map((daysNotifiaction, index) => (
        <DropdownItem
            key={"DAYS_NOTIFACATION_ITEM_" + index}
            item={daysNotifiaction.name}
            valueInput={String(daysNotifiaction.id)}
            valueText={daysNotifiaction.name}
        />
    ));

    const changeHandler = (notifiactionId: string) => {
        setFieldValue("notificationBeforeLesson.days", parseInt(notifiactionId))
    }

    return (
        <Dropdown
            label=""
            maxWidth={240}
            height={40}
            marginRight={20}
            defaultValue={defaultValueDaysNotifacation}
            doubleClickDropdown={false}
            handleChange={changeHandler}
        >
            {daysNotifacationItems}
        </Dropdown>
    );
}

export default NotificationDropdown;