import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Services } from "../../../../../services/reddyApi/Services/Services";
import handleServerError from "../../../all/saga/handleServerError";
import setServerErrorDefault from "../../../all/saga/setServerErrorDefault";
import { GetAttendanceStatisticPayload } from "../payload/GetAttendanceStatistic/GetAttendanceStatisticPayload";
import { getAttendanceStatisticFetch, getAttendanceStatisticSuccess } from "../service.slice";

function* getAttendanceStatisticWorker(action: PayloadAction<GetAttendanceStatisticPayload>) {
    const {vendorId, filter} = action.payload;

    try {
        const {status, data} = yield call(Services.getAttendanceStatistic, {vendorId, filter});
        
        if(status === 200) {
            yield put(getAttendanceStatisticSuccess({attendanceStatistic: data}));

            yield setServerErrorDefault();
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getAttendanceStatisticFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getAttendanceStatisticWorker;