import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Money } from "../../../../services/reddyApi/Money/Money";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getPlannedMoneyFetch, getPlannedMoneySuccess } from "../finance.slice";
import { GetPlannedMoneyPayload } from "../payload/GetPlannedMoney/GetPlannedMoneyPayload";

function* getPlannedMoneyWorker(action: PayloadAction<GetPlannedMoneyPayload>) {
    const { vendorId, filter } = action.payload;
    const dataParams = {
        vendorId,
        filter
    }

    try {
        const { data } = yield call(Money.getPlannedMoney, dataParams);

        yield put(getPlannedMoneySuccess(data));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getPlannedMoneyFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getPlannedMoneyWorker;