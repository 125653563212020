import * as Yup from 'yup';
import { DateApi } from '../../../services/Date/Date';

export default Yup.object().shape({
    fullname: Yup.string()
        .matches(/^[A-Za-zА-Яа-я]+\s[A-Za-zА-Яа-я]+$/, "Is not in correct format")
        .required('Required'),
    startDate: Yup.date()
        .transform(DateApi.parseDateString)
        .required("Заповніть це поле"),
    roleId: Yup.number().required("Required"),
    phone: Yup.string()
        .required('Required'),
    email: Yup.string()
        .email('Invalid email').required('Required'),
    salary: Yup.number().required("Required"),
    locationsIds: Yup.string().required("Required"),
});