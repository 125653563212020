import React, { FC, MouseEvent, useState } from "react";
import MessageInfo from "../../../feedback/messages/MessageInfo/MessageInfo";
import Position from "../../../layout/position/Position";
import styles from "./ButtonInfo.module.css";

interface Props {
    icon: JSX.Element;
    info: string;
    type?: "string" | "email" | "tel"; 
}

const ButtonInfo: FC<Props> = ({icon, info, type = "string"}) => {
    const [toggle, setToggle] = useState(false);

    const clickButtonHandler = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setToggle(prev => !prev)
    }

    const blurButtonHandler = () => {
        setToggle(false);
    }

    return (
        <div className={styles.btnBlock}>
            <button  
                className={styles.btnInfo} 
                onClick={clickButtonHandler}
                onBlur={blurButtonHandler}
            >
                {icon}
            </button>
            <Position type="absolute" top={-45} right={15}>
                <MessageInfo 
                    borderBottomLeftRadius={10} 
                    borderBottomRightRadius={0} 
                    borderTopLeftRadius={10} 
                    borderTopRightRadius={10}
                    text={info} 
                    visiable={toggle} 
                    minWidth={120} 
                    type={type} 
                />
            </Position>
        </div>
    )
}

export default ButtonInfo;