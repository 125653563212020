import React, { FC } from "react";
import Checkbox from "../Checkbox/Checkbox";
import styles from "./CheckboxBlock.module.css";

interface Props {
    name: string;
    handleChange?: (value: boolean) => void;
    value?: boolean;
}

const CheckboxBlock: FC<Props> = ({name, handleChange, value = false}) => {
    return (
        <div className={styles.radioBlock}>
            <p className={styles.name}>
                {name}
            </p>
            <Checkbox value={value} handleChange={handleChange} />
        </div>
    );
}

export default CheckboxBlock;
