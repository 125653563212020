import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { Money } from "../../../../services/reddyApi/Money/Money";
import handleServerError from "../../all/saga/handleServerError";
import setServerErrorDefault from "../../all/saga/setServerErrorDefault";
import { getFutureMoneyFetch, getFutureMoneySuccess } from "../finance.slice";
import { GetFutureMoneyPayload } from "../payload/GetFutureMoney/GetFutureMoneyPayload";

function* getFutureMoneyWorker(action: PayloadAction<GetFutureMoneyPayload>) {
    const { vendorId, filter } = action.payload;
    const dataParams = {
        vendorId,
        filter
    }

    try {
        const { data } = yield call(Money.getFutureMoney, dataParams);

        yield put(getFutureMoneySuccess(data));

        yield setServerErrorDefault();
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const actionCreator = () => getFutureMoneyFetch(action.payload);

            yield handleServerError(error, actionCreator);
        }
    }
}

export default getFutureMoneyWorker;