import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import withMatch from "../../../../../components/other/hoc/withMatch";
import { useAppDispatch } from "../../../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../../../store/hooks/selector.hook";
import { getGroupGeneralDataFetch } from "../../../../../store/state/groups/groups.slice";
import { GeneralData } from "../../../../../store/state/groups/models/Group";
import GroupStatistics from "./GroupStatistics";

const GroupStatisticsContainer: FC = () => {
    let { id } = useParams();
    const groupId = Number(id);
    const dispatch = useAppDispatch();
    const groups = useAppSelector(state => state.groups.pageData, shallowEqual);
    const indexGroup = groups.findIndex(group => group.id === groupId);
    const generalData = useAppSelector(state => state.groups.pageData[indexGroup].generalData, shallowEqual);
    const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
    
    const defaultGeneralData: GeneralData = {
        money: {
            planned: 0,
            actual: 0
        },
        lessons: {
            completed: 0,
            total: 0
        }
    }
    
    useEffect(() => {
        if(!generalData) {
            dispatch(getGroupGeneralDataFetch({groupId, vendorId}));
        }
    }, []);

    return (
        <GroupStatistics 
            statistics={generalData || defaultGeneralData}
        />
    );
}

export default  React.memo(withMatch(GroupStatisticsContainer, [2, 3, 4]));