import { PayloadAction } from "@reduxjs/toolkit";
import { EmployeesState } from "../../models/EmployeesState";
import { GetTeachersListSuccessPayload } from "../../payload/GetTeachersList/GetTeachersListSuccessPayload";

const getTeachersListSuccess = (state: EmployeesState, action: PayloadAction<GetTeachersListSuccessPayload>) => {
    const teachers = action.payload;
    
    state.teachers = [...teachers];
    state.isLoading = false;
    state.isError = null;
}

export default getTeachersListSuccess;