import { PayloadAction } from "@reduxjs/toolkit";
import updateItem from "../../../../../helpers/updateItem";
import { UpsellState } from "../../models/UpsellsState";
import { UpdateUpsellSuccessPayload } from "../../payload/UpdateUpsell/UpdateUpsellSuccessPayload";

const updateAdditionalSuccess = (state: UpsellState, action: PayloadAction<UpdateUpsellSuccessPayload>) => {
    const upsell = action.payload;

    const upsells = state.upsells;
    updateItem(upsells, upsell);

    state.message = "Додаткову послугу оновлено"
    state.messageVisiable = true;
    state.isLoading = false;
    state.isError = null;
}

export default updateAdditionalSuccess;