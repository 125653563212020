import React, { FC, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch } from "../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../store/hooks/selector.hook";
import { getNotificationsFetch, NotificationStatuses } from "../../store/state/notifications/notifications.slice";
import { SYSTEM_ADMIN_ID } from "../../utl/constants/role";
import { culcPage } from "../../utl/helpers/culcPage";

function withLoadingNotifications(Component: React.ComponentType) {
    const ComponentWithLoadingNotifications: FC = () => {
        const dispatch = useAppDispatch();

        //Clients => Notifications
        const page = useAppSelector(state => state.clients.clientsPages.page, shallowEqual);
        const totalCount = useAppSelector(state => state.clients.clientsPages.totalCount, shallowEqual);
        const pageAll = useAppSelector(state => state.clients.clientsPages.pageAll, shallowEqual);
        const pageSize = useAppSelector(state => state.clients.clientsPages.pageSize, shallowEqual);
        const getAllItems = useAppSelector(state => state.clients.clientsPages.getAllItems, shallowEqual);
        const pagesRange = culcPage(page, pageAll, totalCount, pageSize);
        const vendorId = useAppSelector(state => state.user.vendorId, shallowEqual) || -1;
        const roleId = useAppSelector(state => state.user.roleId, shallowEqual) || -1;

        useEffect(() => {
            let handler: NodeJS.Timeout | undefined = undefined;

            if(roleId > SYSTEM_ADMIN_ID) {
                let filter = undefined;
            
                if(!getAllItems) {
                    filter = {
                        range: {
                            from: pagesRange.from,
                            to: pagesRange.to
                        }
                    }  
                }

                dispatch(getNotificationsFetch({vendorId, filters: {
                    statusId: NotificationStatuses.ACTIVE
                }}));

                dispatch(getNotificationsFetch({vendorId, filters: {
                    statusId: NotificationStatuses.ARCHIVE
                }}));

                dispatch(getNotificationsFetch({vendorId, filters: {
                    statusId: NotificationStatuses.SELECTED
                }}));

                handler = setInterval(() => {
                    dispatch(getNotificationsFetch({vendorId, filters: {
                        statusId: NotificationStatuses.ACTIVE
                    }}));
    
                    dispatch(getNotificationsFetch({vendorId, filters: {
                        statusId: NotificationStatuses.ARCHIVE
                    }}));
    
                    dispatch(getNotificationsFetch({vendorId, filters: {
                        statusId: NotificationStatuses.SELECTED
                    }}));
                }, 900000);
            }

            return () => {
                if(handler) {
                    clearInterval(handler);
                }
            }
        }, [vendorId, roleId]);

        return (
            <Component />
        );
    }

    return ComponentWithLoadingNotifications;
}

export default withLoadingNotifications;