import React, { ChangeEvent, FC } from "react";
import styles from "./Search.module.css";

interface Props {
    placeholder?: string;
    value: string;
    changeValueHandler: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Search: FC<Props> = ({placeholder = "", value, changeValueHandler}) => {
    return (
        <input 
            type="search" 
            className={styles.search} 
            placeholder={placeholder} 
            value={value} 
            onChange={changeValueHandler}
        />
    );
}

export default Search;