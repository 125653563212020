import React, { FC } from "react";
import { useAppSelector } from "../../../../store/hooks/selector.hook";
import GraphBlock from "./GraphBlock";

const GraphBlockContainer: FC = () => {
    const diagrams = useAppSelector(state => state.services.attendanceStatistic);
    
    return (
        <GraphBlock diagrams={diagrams.slice(0, 11)} />
    )
}

export default GraphBlockContainer;