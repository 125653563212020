import { es } from "date-fns/locale";

export const getTitleBreadcrumbs = (type: string, prevType?: string | undefined | null): string => {
    switch(type) {
        case "vendors" :
            return "Вендори";
        case "settings" :
            return "Налаштування";
        case "employees" :
            return "Працівники";
        case "clients" :
            return "Клієнти";
        case "services" :
            return "Послуги";
        case "groups" :
            return "Групи";
        case "finance" :
            return "Гроші";
        case "notifications" :
            return "Сповіщення";
        case "dashboard" :
            return "Дашборд";
        case "locations" :
            return "Локації";
        case "basics" :
            return "Основні"
        case "upsells" :
            return "Додаткові";
        case "clients" :
            return "Клієнти";
        case "communication" :
            return "Спілкування";
        case "money" :
            return "Гроші";
        case "new" : 
            return getNewItemName(prevType); 
        case "" :
            return "Дашборд"
        default : return ""; 
    }
}

const getNewItemName = (type: string | undefined | null) => {
    switch (type) {
        case "services" :
            return "Нова послуга"  
        case "locations" :
            return "Нова локація"
        case "employees" :
            return "Новий працівник"
        case "groups" :
            return "Нова група"
        default : return "Новий елемент"; 
    }
}