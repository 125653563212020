import React, { FC } from "react";
import IconFont from "../../../general/icons/IconFont/IconFont";
import Avatar from "../Avatar/Avatar";
import Contacts from "../Contacts/Contacts";
import styles from "./DataUser.module.css";

interface Props {
    name: string;
    avatar?: string;
    belonging: string;
    emails?: string[];
    phones: string[];
    marginBottom?: number;
}

const DataUser: FC<Props> = ({name, avatar, belonging, emails, phones, marginBottom}) => {
    return (
        <div style={{marginBottom}} className={styles.dataUser}>
            <div className={styles.leftCol}>
                <Avatar
                    name={name}
                    uri={avatar}
                    width={50}
                    height={50}
                    background="#A6AEC5" 
                    backgroundName="#A6AEC5" 
                    fontSize={18}
                    lineHeight={21}
                    maxHeightImg={"100%"}
                    maxWidthImg={"100%"}
                />
            </div>
            <div className={styles.rightCol}>
                <p className={styles.name}>{name}</p>
                <p className={styles.belonging}>{belonging}</p>
                <Contacts 
                    keyIndex="TELS_" 
                    values={phones} 
                    icon={<IconFont iconClass="icon-tel" fontSize={13} color="#A6AEC5" />} 
                    maxWidth={150} 
                    marginBottom={10}
                />
                {emails && <Contacts 
                    keyIndex="EMAILS_" 
                    values={emails} 
                    icon={<IconFont iconClass="icon-email" fontSize={13} color="#A6AEC5" />} 
                    maxWidth={220}
                />}
            </div>
        </div>
    );
}

export default DataUser;