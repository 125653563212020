import React, { FC } from "react";

interface Props {
    marginTop?: number;
    marginLeft?: number;
    marginBottom?: number;
    marginRight?: number;
    children: JSX.Element[] | JSX.Element;
}

const Margin: FC<Props> = ({marginTop = 0, marginLeft = 0, marginBottom = 0, marginRight = 0, children}) => {
    return (
        <div style={{marginTop, marginLeft, marginBottom, marginRight}}>
            {children}
        </div>
    )
}

export default Margin;