import React, { FC } from "react";
import styles from "./RectProgress.module.css";

interface Props {
    progress: number;
}

const RectProgress: FC<Props> = ({progress}) => {
    return (
        <div className={styles.rectProgressBlock}>
            <span>{progress}%</span>
            <div className={styles.backProgress}>
                <div className={styles.progress} style={{width: `${progress}%`}}></div>
            </div>
        </div>
    );
}

export default RectProgress;